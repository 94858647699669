@import './general.scss';

$nav-background-position: var(--admin-nav-background-position);
$nav-background-no-repeat: var(--admin-nav-background-no-repeat);
$nav-background-size: var(--admin-nav-background-size);
$nav-bar-height:var(--admin-nav-bar-height);
$nav-open-width:var(--admin-nav-open-width);
$nav-collapse-width: var(--admin-nav-collapse-width);
$nav-collapse-margin-left: var(--admin-nav-collapse-margin-left);
$nav-collapse-border-radius: var(--admin-nav-collapse-border-radius);
$nav-collapse-svg-width:var(--admin-nav-collapse-svg-width);
$nav-collapse-svg-height:var(--admin-nav-collapse-svg-height);
$nav-admin-section-margin-left:var(--admin-nav-admin-section-margin-left);
$nav-admin-section-svg-width:var(--admin-nav-admin-section-svg-width);
$nav-admin-section-svg-height:var(--admin-nav-admin-section-svg-height);
$background-transparent: var(--admin-background-transparent);
$header-section-height:var(--admin-header-section-height);
$header-section-padding:var(--admin-header-section-padding);
$border-radius-50:var(--border-radius-50);
$button-border-radius: var(--button-border-radius);
$sm-card-border:var(--sm-card-border);
$tab-nav-padding-left:var(--tab-nav-padding-left);
$page-content-padding:var(--page-content-padding);
$overlay-background-transition:var(--overlay-background-transition);
$input-margin-left-header:var(--input-margin-left-header);
$header-input-height:var(--header-input-height);
$button-padding-sm-x:var(--button-padding-sm-x);
$button-padding-sm-y:var(--button-padding-sm-y);
$button-padding-xs-x:var(--button-padding-xs-x);
$button-padding-xs-y:var(--button-padding-xs-y);
$button-padding-md-x:var(--button-padding-md-x);
$button-padding-md-y:var(--button-padding-md-y);
$button-padding-lg-x:var(--button-padding-lg-x);
$button-padding-lg-y:var(--button-padding-lg-y);
$profile-overlay-image: var(--profile-overlay-image);
