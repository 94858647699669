.user-credentials {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;
    flex-wrap: nowrap;
    height: 100vh;
    background-color: #fff !important;
}

.user-info {
    flex: 0 auto;
    height: 100vh;
    background-size: cover;
    width: calc(50% - 40px);
    position: relative;
    background-color: #fff !important;
}

.info-conent {
    position: absolute;
    top: 0;
    left: 0;
    padding: 30px;
    width: 100%;
    height: 100%;
    z-index: 3;
}
.login-logo {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: nowrap;
    text-align: center;
    max-width: 240px
}

.login-logo span {
    flex: 0 auto;
    margin-left: 8px;
    font-size: 18px;
    color: #fff !important;
}

.login-logo img {
    /* height: 44px; */
}

.user-details {
    flex: 1 auto;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
}

.user-details ul {
    display: block;
    margin: 30px 0 0 0;
    padding: 0;
}

.user-details li {
    display: block;
    margin-bottom: 20px;
    text-align: center;
}

.user-details li a {
    color: #067ae1;
}

.user-details li input,
.password-wrap li input {
    display: block;
    background: #fff;
    border-color: #ddd;
    border-width: 0 0 2px 0;
    border-style: solid;
    padding: 0px;
    line-height: 35px;
    width: 100%;
    -webkit-border-radius: 0;
    border-radius: 0;
}

.user-details li input:-webkit-autofill,
.password-wrap li input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px white inset;
}

.user-details li input:focus,
.password-wrap li input:focus {
    outline: 0px;
    bottom: 0px;
}

.login-division {
    margin-top: 20px;
}

.login-social {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: stretch;
    margin-top: 20px;
    padding: 0 10%;
}

.login-with {
    display: block;
    position: relative;
    font-size: 14px;
    text-transform: uppercase;
}

.login-with:before,
.login-with:after {
    position: absolute;
    content: '';
    height: 2px;
    background-color: #f2f2f2;
    top: 50%;
    transform: translate(0, -50%);
}

.login-with:before {
    left: 0;
    width: 45%;
}

.login-with:after {
    width: 45%;
    right: 0;
}





.microsoft-icon {
    background: #fff url('../images/mic_logo.svg') no-repeat center center;
    background-size: 18px 18px;
}

.google-btn {
    flex: 0 0 auto;
    position: relative;
    width: 45%;
}

.google-btn button {
    width: 100% !important;
    height: 47px;
    border-radius: 0px !important;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .25);
    position: relative;
    background-color: #067ae1 !important;
    font-family: 'Open Sans', sans-serif !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    font-style: normal !important;
    line-height: 48px !important;
    padding: 0 0 0 48px !important;
}

.google-btn button::after {
    position: absolute;
    content: '';
    background: #fff url('../images/google-btn.svg') no-repeat center center;
    background-size: 32px;
    width: 46px;
    height: 45px;
    left: 0px;
    top: 0px;
}

.forget-password {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    height: 100vh;
}

.forget-password .password-wrap {
    background-color: #fff !important;
    padding: 20px;
    border-radius: 4px !important;
    min-width: 450px;
}

.forget-password .password-wrap__inner {
    display: block;
}

.forget-password .password-wrap__inner .logo {
    display: block;
    text-align: center;
    margin-bottom: 30px;
    height: auto !important;
}

.forget-password .password-wrap__inner .logo span {
    display: inline-block;
    margin: 0;
    padding: 0;
    max-width: 140px;
    height: auto;
}

.forget-password .password-wrap__inner h3 {
    font-size: 20px;
    margin-bottom: 10px;
    text-align: center;
}

.forget-password .password-wrap__inner ul {
    display: block;
    margin-top: 10px;
}

.forget-password .password-wrap__inner li {
    margin-bottom: 20px;
    text-align: center;
}

.forget-password .password-wrap__inner li .signup-text {
    display: block;
    text-align: center;
}

/* BOTTOM BARS ================================= */

.bar {
    position: relative;
    display: block;
    width: 100%;
}

.bar:before,
.bar:after {
    content: '';
    height: 2px;
    width: 0;
    bottom: 1px;
    position: absolute;
    background: #067ae1;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
}

.bar:before {
    left: 50%;
}

.bar:after {
    right: 50%;
}

/* active state */

.user-details li input:focus~.bar:before,
.user-details li input:focus~.bar:after,
.password-wrap li input:focus~.bar:before,
.password-wrap li input:focus~.bar:after {
    width: 50%;
}

/* HIGHLIGHTER ================================== */

.highlight {
    position: absolute;
    height: 0px;
    width: 0px;
    top: 0;
    left: 0;
    pointer-events: none;
    opacity: 0;
}

/* active state */

.user-details li input:focus~.highlight,
.password-wrap li input:focus~.highlight {
    -webkit-animation: inputHighlighter 0.3s ease;
    -moz-animation: inputHighlighter 0.3s ease;
    animation: inputHighlighter 0.3s ease;
}

/* ANIMATIONS ================ */

@-webkit-keyframes inputHighlighter {
    from {
        background: #ef6262;
    }
    to {
        width: 0;
        background: transparent;
    }
}

@-moz-keyframes inputHighlighter {
    from {
        background: #c06666;
    }
    to {
        width: 0;
        background: transparent;
    }
}

@keyframes inputHighlighter {
    from {
        background: #ae4646;
    }
    to {
        width: 0;
        background: transparent;
    }
}

.user-details li button,
.password-wrap li button {
    display: inline-block;
    border: 0;
    background: #067ae1;
    color: #fff !important;
    padding: 12px 20px;
    text-align: center;
    text-transform: uppercase;
    -webkit-border-radius: 3px;
    border-radius: 3px;
}

.signup-text {
    font-size: 14px;
    margin-bottom: 5px;
}

.error-message {
    width: 100%;
    display: block;
    margin-top: 15px;
    color: #e10000;
    text-align: center;
}

.error-message:before {
    content: '*';
}

.password-strength-indicator {
    position: absolute;
    right: -25px;
    top: 22px;
    font-size: 18px;
}

.login-verification {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
    height: 100vh;
}

.verification-block {
    flex: 0 0 auto;
    min-width: 500px;
    max-width: 500px;
    background: #fff;
    border-radius: 8px;
    padding: 20px;
    position: relative;
    width: 100%;
}

.verification-block .logo {
    display: block;
    text-align: center;
    margin-bottom: 20px;
    height: 80px;
}

.verification-block .logo img {
    display: inline-block;
    max-width: 80px;
}

.verification-block h3 {
    font-size: 18px;
    color: #067AE1;
    margin-bottom: 15px;
    text-align: center;
}

.verification-block p {
    font-size: 14px;
    color: #555;
    text-align: center;
}


.language-dropdown {
    width: 120px
}