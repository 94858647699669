/* 
factweavers.com Stylesheet
v2.0.1
Last Updated: 2018-November
Author: Jagadeeshlal - jagadeeshlal@factweavers.com 
*/
@import './sass/base/print';
@import './sass/base/reset';
@import './sass/helpers/controls';
@import './sass/vendors/common';
@import './sass/vendors/_bootstrap';
@import './sass/vendors/antd';
@import './sass/themes/index.scss';
@import './sass/fonts/index.scss';
@import './sass/general/index.scss';
@import './sass/icons/index.scss';
* {
	margin: 0;
	padding: 0;
	// @include transition(all 0.4s ease-in-out);
	// &:hover {
	// 	@include transition(all 0.3s ease-in-out);
	// }
}

ul,
ol,
li {
	list-style: none;
	margin: 0;
	padding: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	-webkit-box-shadow: 0 0 0 30px $white-color inset;
}

::selection {
	color: #fff !important;
	background: $theam-red;
}

button,
input,
textarea {
	@include box-shadow(0 0 0 transparent);

	&:hover,
	&:visited,
	&:focus,
	&:active {
		@include box-shadow(0 0 0 transparent);
		outline: 0;
	}
}

img {
	border: 0;
	max-width: 100%;
	outline: 0;
	display: inline-block;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
	padding: 0;
	color: $text-color;
}
@for $i from 1 through 6 {
	h#{$i} {
		font-size: nth($font-size, $i);
	}
}
a {
	color: $theam-blue;
	text-decoration: none;
	&:hover,
	&:focus {
		text-decoration: none;
	}
}

p {
	font-size: 14px;
	line-height: 20px;
	margin: 0;

	a {
		color: $theam-blue;
	}
}

body {
	margin: 0;
	padding: 0;
	font-family: $font-web;
	background: $gray-color;
	color: $text-color;
	line-height: 1.428;
	font-weight: 400;
	font-size: 14px;
	@include font-smoothing(off);
	.outer-wrapper {
		margin: 0;
		@include animation('fadeIn 0.8s');
		@include keyframes(fadeIn) {
			0% {
				opacity: 0;
			}

			100% {
				opacity: 1;
			}
		}
	}
}

.location-chart1 {
	display: none;
}
.test {
	display: block !important;
}

.updatedChipStyle{
	margin: 2px 14px 0 14px;
	background-color: #b08fea;
	border-radius: 50%;
	justify-content: center;
	display: inline-block;
	align-items: center !important;
	svg{
		display: flex;
		width: 1rem;
		margin-top: 2px;
	}
}
.bing {
	background-image: var(--bing-icon);
	background-size: cover;
  }
  
  .yelp {
	background-image: var(--yelp-icon);
background-size: cover;
  }
  
  .booking {
	background-image: var(--booking-icon);
background-size: cover;
  }
  
  .reviewshakebooking {
	background-image: var(--reviewshakebooking-icon);
	background-size: cover;
  }
  
  .cellarpass {
	background-image: var(--cellarpass-icon);
	background-size: cover;
  }
  
  .google {
	background-image: var(--google-icon);
	background-size: cover;
  }
  
  .facebook {
	background-image: var(--facebook-icon);
	background-size: cover;
  }
  
  .tripadvisor {
	background-image: var(--tripadvisor-icon);
	background-size: cover;
  }
  
  .lawyers {
	background-image: var(--lawyers-icon);
	background-size: cover;
  }
  
  .ratemds {
	background-image: var(--ratemds-icon);
	background-size: cover;
  }
  
  .vitals {
	background-image: var(--vitals-icon);
	background-size: cover;
  }
  
  .healthgrades {
	background-image: var(--healthgrades-icon);
	background-size: cover;
  }
  
  .productreview {
	background-image: var(--productreview-icon);
	background-size: cover;
  }
  
  .apartments {
	background-image: var(--apartments-icon);
	background-size: cover;
  }
  
  .zomato {
	background-image: var(--zomato-icon);
	background-size: cover;
  }
  
 .glassdoor {
	background-image: var(--glassdoor-icon);
	background-size: cover;
  }
  
  .hostellingscotland {
	background-image: var(--hostellingscotland-icon);
	background-size: cover;
  }
  
  .hostelworld {
	background-image: var(--hostelworld-icon);
	background-size: cover;
  }
  
  .expedia {
	background-image: var(--expedia-icon);
	background-size: cover;
  }
  
  .indeed {
	background-image: var(--indeed-icon);
	background-size: cover;
  }
  
  .opentable {
	background-image: var(--opentable-icon);
	background-size: cover;

	
  }
  
  .zillow {
	background-image: var(--zillow-icon);
	background-size: cover;

  }
  
  .hotels {
	background-image: var(--hotels-icon);
	background-size: cover;

  }
  
  .foursquare {
	background-image: var(--fourSquare-icon);
	background-size: cover;

  }
  
  .reviewshakegmb {
	background-image: var(--reviewshakegmb-icon);
	background-size: cover;

  }
  
  .googleplaystore {
	background-image: var(--googleplaystore-icon);
	background-size: cover;

  }
  
  .appstore {
	background-image: var(--appstore-icon);
	background-size: cover;

  }
  
  .zocdoc {
	background-image: var(--zocdoc-icon);
	background-size: cover;

  }
  
  .cars {
	background-image: var(--cars-icon);
	background-size: cover;

  }
  
  .cargurus {
	background-image: var(--cargurus-icon);
	background-size: cover;

  }
  
  .edmunds {
	background-image: var(--edmunds-icon);
	background-size: cover;

  }
  
  .dealerrater {
	background-image: var(--dealerrater-icon);
	background-size: cover;

  }
  
  .talabat {
	background-image: var(--talabat-icon);
	background-size: cover;

  }
  
  .reserveout {
	background-image: var(--reserveout-icon);
	background-size: cover;

  }
  
  .homeadvisor {
	background-size: cover;
	background-image: var(--homeadvisor-icon);
  }
  
  
  .trustpilot {
	background-image: var(--trustpilot-icon);
	background-size: cover;

  }
  
  .trustradius {
	background-image: var(--trustradius-icon);
		background-size: cover;

  }
  
  .reviewshaketrustpilot {
	background-image: var(--reviewshaketrustpilot-icon);
		background-size: cover;

  }
  
  .capterra {
	background-image: var(--capterra-icon);
		background-size: cover;

  }
  
  .gtwo {
	background-image: var(--gtwo-icon);
	background-size: cover;

  }
  
  .airbnb {
	background-image: var(--airbnb-icon);
	background-size: cover;

  }
  
  .avvo {
	background-image: var(--avvo-icon);
	background-size: cover;

  }
  
  .houzz {
	background-image: var(--houzz-icon);
	background-size: cover;

  }
  
  .homestars {
	background-image: var(--homestarts-icon);
	background-size: cover;

  }
  
  .rent {
	background-image: var(--rent-icon);
	background-size: cover;

  }
  
  .highya {
	background-image: var(--highya-icon);
	background-size: cover;

  }
  
  .crowd {
	background-image: var(--crowd-icon);
	background-size: cover;

  }
  
  .angieslist {
	background-image: var(--angieslist-icon);
	background-size: cover;

  }
  
  .reviewshakefacebook {
	background-image: var(--reviewshakefacebook-icon);
	background-size: cover;

  }
  
  .betterbusinessbureau {
	background-image: var(--betterbusinessbureau-icon);
		background-size: cover;

  }
  
 .playstore {
	background-image: var(--playstore-icon);
	background-size: cover;

  }
  
  .appleappstore {
	background-image: var(--appstore-icon);
	background-size: cover;

  }
  
  .applemaps {
	background-image: var(--applemaps-icon);
	background-size: cover;

  }

  .applebusinessconnect{
	background-image: var(--applemaps-icon);
	background-size: cover;
  }
  .googlepublic{
	background-image: var(--googlePublic-icon);
	background-size: cover;
  }