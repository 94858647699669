.main-container {
    display:flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100vh;
}
.logo {
    margin-bottom: 15px;
}
.reset-wrapper {
    background-color: #fff !important;
    padding: 20px;
    border-radius: 4px !important;
    min-width: 450px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.title {
    margin-bottom: 5px;
}
.description {
    font-size: 17px;
    margin-bottom: 25px;
}