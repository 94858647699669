/*---------Color-----------*/
$white-color: #ffffff;
$black-color: #000000;
$text-color: #3c4043;
$side-bar: #4c4d4f;
$graytbl-font: #202124;
$red-color: #cc0000;
$gray-color: #f2f2f2;
$border-color: #e0e0e0;
$line-color: #ebebeb;
$blue-color: #1a73e8;
$theam-blue: #06a7e1;
$theam-red: #f15922;
$theam-orange: #f99d1b;
$theam-green: #2fb66a;
$primary-blue: #007bff;
$success-green: #28a745;
$placeholder: #70757a;
$table-head: #f7f7f7;
$card-header: #6e8593;
/*--  font size  --*/
$font-size: (28px, 26px, 22px, 18px, 16px, 14px);
/*--  Group color  --*/
$growth-colors: #f46d43, #69a5a2, #3d728b, #4575b4, #5c6970;

$image-management-primary-text-color: #1e293b;
$image-management-primary-text-color-half-opaque: #1e293b7f;

$image-management-light-text-color: #64748b;


