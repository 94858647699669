@import url(https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,300;1,400;1,500;1,700;1,900&display=swap);
/*---------Color-----------*/
/*--  font size  --*/
/*--  Group color  --*/
/*=====
alpha
========*/
/*=====
linear-gradien
========*/
/*====
transition
======*/
/*====
transform
======*/
/*----
placeholder
-----*/
/*----
flex
-----*/
/*-=====
opasity
=======*/
/*-=====
appearance
=======*/
/*-=====
filter
=======*/
/*-=====
react select
=======*/
/*-=====
mediaquery
=======*/
/*-=====
Animation
=======*/
/*=====
alpha
========*/
/*=====
linear-gradien
========*/
/*====
transition
======*/
/*====
transform
======*/
/*----
placeholder
-----*/
/*----
flex
-----*/
/*-=====
opasity
=======*/
/*-=====
appearance
=======*/
/*-=====
filter
=======*/
/*-=====
react select
=======*/
/*-=====
mediaquery
=======*/
/*-=====
Animation
=======*/
.flex-column {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column; }

.inline-flex {
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: horizontal;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row; }

.flexbox {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: horizontal;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row; }

.flex-reverse {
  -webkit-box-direction: reverse;
  -webkit-box-orient: horizontal;
  -webkit-flex-direction: row-reverse;
  -moz-flex-direction: row-reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse; }

.column-reverse {
  -webkit-box-direction: reverse;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column-reverse;
  -moz-flex-direction: column-reverse;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse; }

.all-center {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center; }

.justify-center {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center; }

.justify-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between; }

.justify-around {
  -ms-flex-pack: distribute;
  -webkit-justify-content: space-around;
  -moz-justify-content: space-around;
  justify-content: space-around; }

.justify-start {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  justify-content: flex-start; }

.justify-end {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  -webkit-justify-content: flex-end;
  -moz-justify-content: flex-end;
  justify-content: flex-end; }

.item-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center; }

.item-start {
  -webkit-box-align: start;
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  -moz-align-items: flex-start;
  align-items: flex-start; }

.item-end {
  -webkit-box-align: end;
  -ms-flex-align: end;
  -webkit-align-items: flex-end;
  -moz-align-items: flex-end;
  align-items: flex-end; }

.item-stretch {
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  -webkit-align-items: stretch;
  -moz-align-items: stretch;
  align-items: stretch; }

.item-nowrap {
  -ms-flex-wrap: none;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap; }

.item-wrap {
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap; }

.graytbl-header {
  color: #202124;
  font-size: 12px;
  font-weight: 700; }

.whitetbl-header {
  color: #3c4043 !important;
  font-size: 12px;
  font-weight: 700; }

.tblrow-color {
  color: #3c4043;
  font-size: 13px;
  font-weight: 400; }

.tblprimary-color {
  color: #3c4043;
  font-size: 13px;
  font-weight: 700; }

.modalForm {
  display: flex; }

.leftSideSection {
  width: 30%;
  padding: 10px 0; }

.leftHead {
  display: flex;
  align-items: center;
  padding-top: 15px;
  gap: 5px;
  padding: 10px 20px; }
  .leftHead > h2 {
    font-weight: 800; }
  .leftHead > img {
    width: 40px;
    border-radius: 7px; }

.setUpProfile {
  padding: 15px 0px 15px 0px; }

.setUpProfile > p {
  margin-top: 10px; }

.leftFooterButton {
  padding-right: 10px; }

.leftFooter {
  font-size: 20px;
  font-weight: 700; }

.rightSideSection {
  flex: 1 1 auto;
  padding: 15px 15px 0px 20px; }

.rightHead {
  display: flex;
  flex-direction: column;
  padding: 0px 15px 20px 0px;
  border-bottom: 3px solid #eee; }
  .rightHead > div > h2 {
    font-weight: 800; }

.rightContent {
  padding: 12px 15px 20px 0px; }
  .rightContent > div > span {
    font-size: 20px;
    font-weight: 600; }
  .rightContent__uploadButton {
    font-weight: 800; }

.profileIcon {
  display: flex;
  align-items: center;
  gap: 3px; }
  .profileIcon > div > img {
    width: 38px;
    border-radius: 8px; }

.profileDetails {
  padding-top: 15px;
  display: flex;
  flex-direction: column;
  row-gap: 1rem; }
  .profileDetails > div > span {
    font-size: 20px;
    font-weight: 600; }
  .profileDetails .reviewLanguage {
    flex-direction: row; }
  .profileDetails .ConfirmDetailSubHead {
    flex-direction: row; }

.profileDetails > div {
  display: flex;
  flex-direction: column; }

.rightFooter {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-right: 10px; }
  .rightFooter__buttonBorder {
    margin-left: auto;
    display: flex;
    justify-content: space-between;
    background-color: #101727;
    color: #ffff; }

.reviewLanguage {
  display: flex;
  justify-content: space-between; }

.ConfirmDetailSubHead {
  display: flex;
  gap: 3px;
  align-items: center; }
  .ConfirmDetailSubHead > div > img {
    width: 45px;
    border-radius: 7px; }

.confirmDetailsSettings {
  display: flex;
  flex-direction: column; }
  .confirmDetailsSettings__heading {
    padding: 4px;
    display: flex;
    flex: 0 1 auto;
    border-bottom: 1px solid #aaa; }
  .confirmDetailsSettings__content {
    padding: 8px;
    display: grid;
    justify-content: space-between;
    grid-template-columns: 1fr 1fr; }
    .confirmDetailsSettings__content > span {
      display: flex;
      width: 100%;
      align-items: center; }

.reviewLanguageParent {
  display: flex;
  flex-direction: column;
  row-gap: 6px; }
  .reviewLanguageParent .inviteUserItem {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center; }
    .reviewLanguageParent .inviteUserItem__option {
      display: flex;
      width: max-content;
      column-gap: 4px; }
      .reviewLanguageParent .inviteUserItem__option button {
        width: max-content;
        height: 100%;
        border-radius: 4px;
        background-color: #101727;
        color: #fff;
        padding: 4px 14px;
        border: none;
        display: flex;
        align-items: center; }

.inviteUsersParent {
  display: flex; }
