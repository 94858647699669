@import "../../sass/themes/index.scss";

.sourceItem {
  display: flex;
  width: 100%;
  align-items: center;
  gap: 12px;

  span {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    flex: 1 0 0px;
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 0em;
    color: $med-drk-text;
  }

  :last-child {
    // font: 400 12px/20px "Inter";
    font-size: 14px;
    font-weight: 600;
    gap: 10px;
  }
}

.icon {
  height: 20px;
  flex: 0 0 auto;
  object-fit: cover;

}

.name {
  flex: 1;
}

.rating {
  // font-weight: bold;
}

.count {
  color: #888;
}