@import '../../src/sass/themes/_light.scss';
.pos-mention {
	color: #06a7e1 !important;
}

.neg-mention {
	color: #f15821 !important;
}

.tab-active:before {
	content: '';
	width: 100%;
	position: absolute;
	bottom: -17px;
	height: 3px;
	background: #46b0f4;
}

.reviews-wrapper {
	border-right: 1px solid #dce5eb;
}

.reviews-wrapper li {
	padding: 5px 15px;
	border-bottom: 1px solid #dce5eb;
}

.reviews-wrapper li:last-child {
	border-bottom: 0px;
}

.single-review {
	cursor: pointer;
}

.single-review:hover, .activated {
	background-color: #f0f4f7;
}


.address span {
	font-size: 12px;
	font-weight: 300;
}

.ant-layout-sider-collapsed .logo-expanded {
	display: none;
}

.ant-layout-sider-collapsed .anticon,
.ant-layout-sider-collapsed i {
	font-size: 16px;
}

.ant-layout-sider-collapsed .ant-menu-item .nav-label {
	display: none;
}

.disabled-option {
	color: #acafaf;
}

.expose {
	position: relative;
	z-index: 91;
}

.review-filter {
	display: block;
	padding: 20px;
	background: #e6e5e5;
}

.review-filter ul {
	display: block;
}

.review-filter li {
	display: inline-block;
	vertical-align: top;
	padding: 0px;
	vertical-align: top;
	margin-right: 15px;
}

.review-filter li:last-child {
	margin-right: 0;
	margin-top: 2px;
}

.review-filter li:last-child .ant-btn-primary {
	background-color: #06a7e1;
	border-color: #06a7e1;
}

.review-filter li:last-child .ant-btn-primary:hover {
	background-color: #06a7e1;
	border-color: #06a7e1;
}

.review-list-outer {
	display: block;
	margin: 0;
	padding: 0;
}

.review-list-outer [class*='col-'] {
	padding: 0px;
}

.popular-review-outer {
	margin-right: 10px;
	border-right: #f0f0f0 solid 1px;
}

.pop-review-head {
	display: block;
	background: #57c3e7;
	padding: 6px 15px;
}

.pop-review-head h3 {
	display: block;
	float: left;
	color: #fff !important;
	font-size: 15px;
}

.pop-review-head ul {
	display: block;
	float: right;
}

.pop-review-head li {
	float: left;
	display: inline-block;
	margin-left: 10px;
}

.pop-review-head li span {
	display: inline-block;
	color: #fff !important;
}

.pop-review-head li span:last-child {
	font-size: 16px;
	padding-left: 8px;
}

.review-item-detail {
	display: block;
	padding: 0 10px;
}

.review-item-detail h3 {
	font-size: 18px;
}

.review-graph-dropdown {
	margin-bottom: 15px;
	max-width: 195px;
	position: relative;
	display: inline-block;
	padding-top: 15px;
}

.review-graph-dropdown .ant-dropdown-trigger {
	-webkit-border-radius: 3px;
	border-radius: 3px;
}

.reviews-insights-graph {
	width: 100%;
	height: 300px;
	position: relative;
}

.review-graphtab {
	border: #e9eef2 solid 1px;
	padding: 5px 10px;
}

.reply-button {
	display: block;
	margin: 0;
	padding: 6px 0px;
	text-align: left;
	background: #f8f8f8;
}

.reply-button button {
	margin-left: 6px;
	padding: 0px 14px;
}

.delete-draft {
	float: right;
	margin-right: 10px;
	font-size: 17px !important;
}

.reviews-icon {
	display: block;
	padding: 4px;
	text-align: center;
	position: relative;
}

.reviews-icon:before {
	display: block;
	position: absolute;
	height: 1px;
	width: 100%;
	content: '';
	background-color: #e1e1e1;
	top: 50%;
	left: 0;
	z-index: 0;
}

.reviews-icon span {
	display: inline-block;
	position: relative;
	background-color: #fff !important;
	padding: 4px;
	-webkit-border-radius: 3px;
	border-radius: 3px;
	border: #ebebeb solid 1px;
	z-index: 1;
}

.custom-filter {
	padding-top: 10px;
	border-top: 1px solid #e9e9e9;
}

.reply-profile {
	display: block;
	position: absolute;
	width: 30px;
	height: 30px;
	border: #ccc solid 1px;
	border-radius: 50%;
	left: 0px;
	top: 15px;
}

.reply-feild {
	display: block;
	padding: 10px 4px;
}

.reply-feild textarea {
	border: 0;
	width: 100%;
	height: 85px;
}

.reply-feild textarea:focus {
	border: 0px;
	outline: 0px;
}

.post-address {
	display: block;
	margin: 0;
	padding: 10px 0;
}

.post-address .address-item-one,
.post-address .address-item-two {
	float: left;
	vertical-align: top;
	margin-right: 8px;
}

.post-address .address-item-one {
	min-width: 10%;
	margin-right: 10px;
}

.post-address .address-item-two {
	width: 70%;
	overflow: hidden;
}

.post-address .address-item-two i {
	padding-right: 8px;
}

.reply-content {
	display: block;
	padding: 0;
	margin-top: 15px;
}

.reply-content h4 {
	font-size: 15px;
	font-weight: normal;
	margin-bottom: 10px;
}

.post-content-title {
	display: block;
	padding: 5px 0 15px 0;
}

.reply-content ul {
	display: block;
	padding: 0;
	margin: 0;
}

.reply-content li {
	display: block;
	padding: 6px 0px 6px 60px;
	margin: 0 0 5px 0;
	background: #f2f2f2;
	position: relative;
	border-radius: 3px;
}

.reply-content li p {
	padding: 0;
	margin: 0;
}

.author-name {
	font-size: 14px;
	font-weight: bold;
	padding-right: 20px;
	position: relative;
}

.author-name:before {
	display: block;
	position: absolute;
	width: 1px;
	height: 12px;
	content: '';
	background: #ddd;
	top: 2px;
	right: 8px;
}

.time-block {
	font-style: italic;
	font-size: 14px;
	color: #555;
}

.author-image {
	display: block;
	overflow: hidden;
	position: absolute;
	width: 30px;
	height: 30px;
	top: 6px;
	left: 10px;
	border: #ccc solid 1px;
	border-radius: 50%;
}

.author-image img {
	border-radius: 50%;
	border: 0px;
	max-width: 100%;
}

.summary-stats {
	padding: 10px 0;
}

.summary-stats i {
	color: #f06262;
}

.review-summary {
	display: flex;
	flex-wrap: nowrap;
	justify-content: space-between;
	align-items: stretch;
	align-content: center;
	border-bottom: #dce5eb solid 1px;
}

.review-summary li {
	flex: 1 1 auto;
	padding: 8px 15px;
	border-right: #dce5eb solid 1px;
}

.review-summary li span {
	display: block;
	text-align: center;
}

.review-summary li span:last-child {
	font-size: 22px;
}

.org-local-filter {
	flex: 0 0 auto;
	min-width: 200px;
	margin-left: 8px;
	text-align: left !important;
}

.org-local-filter .Select-placeholder {
	left: 0 !important;
}

.org-local-filter Select-menu-outer {
	text-align: left !important;
}

.sort-by {
	text-align: center;
	padding-top: 20px;
	display: inline;
}

.sort-by span {
	font-size: 12px !important;
}

.sort-by button {
	margin: 0px 3px;
}

.answers-heading:after {
	content: '';
	position: absolute;
	height: 2px;
	width: 60px;
	background: #4285f4;
	left: 14px;
	bottom: 7px;
}

.pie-chart-inner,
.pie-chart-outer {
	height: 100%;
}

.insights-graph {
	width: 100%;
	height: 300px;
	position: relative;
}

.reviews-graph {
	width: 100%;
	height: 209px;
	position: relative;
}

.hint-text-portfolio {
	color: #9ea3c2;
	margin: 0px 10px 10px 0;
}
.filter-pane {
	display: block;
	margin: 0;
	padding: 0;
}

.filter-pane li {
	display: block;
	margin-bottom: 15px;
}

.no-data {
	position: absolute;
	top: 50%;
	left: 50%;
	margin: -20px 0 0 -20px;
}

.summary-pane {
	display: block;
	margin: 0;
	padding: 0;
}

.summary-pane ul {
	display: block;
	border-left: #dce5eb 1px solid;
	border-right: #dce5eb 1px solid;
}

.summary-pane li {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: stretch;
	align-content: center;
	border-bottom: #dce5eb 1px solid;
}

.summary-pane li:first-child {
	border-top: #dce5eb 1px solid;
}

.summary-pane li span {
	flex: 0 0 auto;
	line-height: 40px;
	padding: 0px 20px;
}

.summary-pane li span:first-child {
	width: 50%;
	border-right: #dce5eb 1px solid;
}

.summary-pane li span:last-child {
	font-size: 16px;
	font-weight: bold;
}

.review-filter-search {
	position: relative;
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
	flex-wrap: nowrap;
}

.review-filter-search:hover {
	border: 0px;
}

.filter-search-icon {
	position: absolute;
	left: 10px;
	top: 9px;
}

.filter-search-icon i {
	font-size: 18px;
	color: #cccccc;
}

.filter-organisation {
	border: 1px solid #d9d9d9;
	border-radius: 4px;
	display: block;
	text-align: center;
	padding: 6px 0px;
}

.sentiment-console {
	text-align: right;
}

.filter-sentiment {
	border-radius: 0;
	height: auto;
	box-shadow: none;
	font-size: 14px;
	margin: 10px 0px;
}

.filter-sentiment-date {
	display: inline-block;
	vertical-align: top;
	margin-right: 5px;
}

.summary-content {
	display: block;
	font-size: 16px;
}

.picker-wrapper {
	width: 23%;
	margin-bottom: 10px;
}

.recharts-legend-wrapper ul li {
	margin-right: 25px !important;
}

.review-set {
	display: block;
}

.reviews-values {
	text-align: center;
	font-size: 48px;
	font-weight: 600;
}

.reviews-content {
	padding: 2px 0 8px 0;
	font-size: 16px;
	text-align: center;
}

.reviews-content span:last-child {
	font-weight: 600;
	padding-left: 6px;
}

.location-selectors {
	display: block;
	margin-bottom: 20px;
	border-radius: 4px;
	padding: 35px 20px;
	background-color: #f2f2f2;
}

.rating-bar {
	display: block;
}

.rating-bar .bar-item {
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	justify-content: flex-start;
	align-content: center;
	margin-bottom: 8px;
}

.rating-bar .bar-item:last-child {
	margin-bottom: 0px;
}

.bar-item .bar-label {
	flex: 0 0 auto;
	font-size: 11px;
	letter-spacing: 4px;
	margin-right: 2px;
}

.bar-item [class*='ratingbar-'] {
	flex: 1 1 auto;
	position: relative;
	margin: 0px;
}

.bar-item .bar {
	display: block;
	margin: 0px;
	padding: 0px;
	height: 18px;
	opacity: 0.8;
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
	-ms-transition: all 0.3s ease;
	transition: all 0.3s ease;
}

.ratingbar-5-star .bar,
.ratingbar-5-star-replies .bar {
	background-color: #01b0f1;
}

.ratingbar-5-star:hover .bar {
	background-color: #01b0f1;
}

.ratingbar-4-star .bar,
.ratingbar-4-star-replies .bar {
	background-color: #91cf50;
}

.ratingbar-4-star:hover .bar {
	background-color: #91cf50;
}

.ratingbar-3-star .bar,
.ratingbar-3-star-replies .bar {
	background: #dadfe4;
}

.ratingbar-3-star:hover .bar {
	background-color: #dadfe4;
}

.ratingbar-2-star .bar,
.ratingbar-2-star-replies .bar {
	background-color: #febf00;
}

.ratingbar-2-star:hover .bar {
	background-color: #febf00;
}

.ratingbar-1-star .bar,
.ratingbar-1-star-replies .bar {
	background-color: #e22f30;
}

.ratingbar-1-star:hover .bar {
	background-color: #e22f30;
}

.bar-value {
	color: #333;
	position: absolute;
	font-size: 11px;
	top: 0px;
	left: 3px;
}

.score {
	font-size: 50px;
	text-align: center;
	line-height: 55px;
	font-style: normal !important;
	font-weight: 200 !important;
}

.avg-rating-number {
	margin-top: 30px;
}

.score-label {
	font-size: 15px;
	display: block;
	padding-right: 12px;
	text-align: center;
}

.insight-values {
	display: block;
	padding: 20px 0;
	background-color: #f7f7f7;
}

.insight-values span {
	display: block;
	padding-left: 20px;
}

.insight-values span:first-child {
	font-size: 34px;
	font-weight: 600;
}

.insight-values span:last-child {
	font-size: 15px;
	color: #9f9f9f;
}

.insight-labels {
	padding-top: 10px;
	padding-bottom: 20px;
}

.insight-labels span {
	display: block;
	text-align: center;
	font-size: 16px;
}

.insight-labels span:first-child {
	font-weight: 600;
	font-size: 24px;
}

.seperator {
	border-right: #bfbfbf solid 1px;
}

.review-pagination {
	text-align: center;
	padding: 10px 15px;
	border: 1px solid #dce5eb;
	border-top: 0px;
}

.filter-close-btn i:hover {
	border-radius: 2px;
	background-color: #999;
	color: white;
}

.funnel-outer {
	margin-top: 18px;
	display: block;
	height: 144px;
}

.fun-itrm-block {
	display: block;
	text-align: center;
	height: 44px;
	margin-bottom: 2px;
}

.funnel-legend-items {
	text-align: center;
}

.funnel-legend-items li {
	display: inline-block;
	margin-left: 25px;
}

.funnel-top,
.funnel-middle,
.funnel-bottom {
	color: #fff !important;
	display: inline-block;
	height: 45px;
	line-height: 45px;
	position: relative;
	font-size: 20px;
	text-align: center;
	clear: both;
}

.funnel-top {
	width: 200px;
	background-color: #7190bb;
}

.funnel-middle {
	width: 158px;
	background-color: #88a2c7;
}

.funnel-bottom {
	width: 116px;
	background-color: #abbfdc;
}

.funnel-top:before,
.funnel-top:after,
.funnel-middle:before,
.funnel-middle:after,
.funnel-bottom:before,
.funnel-bottom:after {
	width: 0;
	position: absolute;
	content: '';
	height: 0;
	border-style: solid;
	top: 0px;
}

.funnel-top:before,
.funnel-middle:before,
.funnel-bottom:before {
	border-width: 45px 0px 0 20px;
	left: -20px;
}

.funnel-top:before,
.funnel-top:after {
	border-color: #7190bb transparent transparent transparent;
}

.funnel-middle:before,
.funnel-middle:after {
	border-color: #88a2c7 transparent transparent transparent;
}

.funnel-bottom:before,
.funnel-bottom:after {
	border-color: #abbfdc transparent transparent transparent;
}

.funnel-top:after,
.funnel-middle:after,
.funnel-bottom:after {
	border-width: 45px 20px 0 0px;
	right: -20px;
}

.funnel-top .indicator,
.funnel-middle .indicator {
	display: block;
	height: 2px;
	position: absolute;
	bottom: 0px;
	left: 0;
}

.funnel-top .indicator {
	background-color: #7190bb;
	width: 230px;
}

.funnel-middle .indicator {
	background-color: #88a2c7;
	width: 209px;
}

.fun-text1,
.fun-text2 {
	font-weight: 300;
	display: block;
	text-align: right;
	font-size: 24px;
	padding-right: 10px;
}

.fun-text1 {
	padding-top: 27px;
}

.fun-text2 {
	padding-top: 12px;
}

.fun-text-block span {
	display: inline-block;
	vertical-align: middle;
}

.fun-text-block span:first-child {
	font-weight: bold;
	text-align: left;
}

.funnel-arrow {
	position: relative;
	display: block;
	background: #7a98bf;
	width: 4px;
	height: 130px;
}

.funnel-arrow:before {
	position: absolute;
	content: '';
	bottom: -6px;
	left: -6px;
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 8px 8px 0 8px;
	border-color: #7a98bf transparent transparent transparent;
}

.summary-icons {
	display: block;
	font-size: 35px;
	height: 68px;
}

.summary-padding {
	padding-bottom: 0px;
}

.published-summary {
	text-align: center;
	padding: 10px 0;
}

.published-summary h3 {
	font-weight: 200;
	font-size: 38px;
	line-height: 32px;
}

.published-summary span {
	font-size: 14px;
}

.published-summary-sub {
	/*padding: 6.5px 15px;*/
}

.published-summary-sub:last-child {
	border-right: 0px;
}

.published-summary-sub span {
	font-size: 14px;
	display: block;
	text-align: left;
}

.published-summary-sub span:first-child {
	font-weight: 200;
	font-size: 23px;
	line-height: 32px;
}

.add-account {
	margin: 10px 0;
}

.add-account button {
	margin: 10px 0;
}

.slide-icon,
.edit-icon {
	flex: 0 auto;
	/* color: #696f7c !important; */
}

.slide-icon i,
.edit-icon i {
	display: block;
	cursor: pointer;
	font-size: 20px;
}

.profile-name-error {
	color: red;
	height: 10px;
	text-align: center;
	margin-top: 8px !important;
}

.profile-name-error span:before {
	content: '*';
}

.integration-wrap {
	flex: 0 auto;
	margin-left: auto;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
}

.integration-wrap span {
	padding-right: 2px;
}

.profile-details {
	flex: 0 auto;
	margin-left: auto;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
}

.profile-details li:after {
	content: '|';
	margin: 0 5px;
}

.profile-details li:last-child:after {
	content: '';
}

.edit-icon i {
	color: #696f7b;
}

.edit-icon {
	margin-left: 15px;
}

.wordcloud-wrapper {
	width: 100%;
	margin-top: 10px;
}

.star-rating-selector {
	padding-left: 10px;
}

.star-rating-selector [class*='col-'] {
	padding-left: 0px;
	padding-right: 0px;
}

.universal-btn {
	display: inline-block;
	width: 162px;
	float: left;
}

.no-reviews {
	font-weight: 600;
	text-align: center;
}

.sort-label {
	float: left;
	text-align: center;
	background-color: #fff !important;
	padding: 0px 6px;
	line-height: 36px;
	border-color: #d9d9d9;
	border-width: 2px 0px 2px 2px;
	border-style: solid;
	-webkit-border-radius: 0;
	border-radius: 0;
}

.star-rating-selector button {
	border-radius: 0px;
	border-right: 0px;
	width: 100%;
}

.star-rating-selector .ant-input-number {
	border-bottom-left-radius: 0px;
	border-top-left-radius: 0px;
}

.search-panel {
	padding: 10px;
	border-bottom: #dce5eb 1px solid;
	border-right: #dce5eb 1px solid;
}

.loader-accounts {
	height: 60%;
	float: left;
	position: absolute;
	left: 62%;
	top: 36%;
	margin: -15px 0 0 -10px;
}

.notes-loader {
	height: 80px;
}

.custom-tooltip {
	width: 180px;
	margin: 0;
	line-height: 24px;
	border: 1px solid #f5f5f5;
	background-color: rgba(255, 255, 255, 0.8);
	font-size: 14px;
	padding: 10px;
}

.custom-tooltip .label {
	margin: 0;
	color: #666;
	font-weight: 700;
}

.custom-tooltip .desc {
	margin: 0;
	color: #999;
}

.custom-tooltip .intro {
	border-top: 1px solid #f5f5f5;
	margin: 0;
}

.right-profile {
	flex: 0 auto;
	padding: 0 15px;
	margin-left: auto;
}

.right-profile.dropdown.open:hover {
	color: #fff !important;
}

.right-profile a {
	display: block;
	margin: 0;
	padding: 0 15px;
	color: #696f7b;
	font-weight: bold;
}

.right-profile.open a {
	background: #06a7e1;
	color: #fff !important;
}

.right-profile.open:hover {
	color: #fff !important;
}

.right-profile.open a:hover {
	color: #fff !important;
}

.right-profile .dropdown-menu a:hover {
	color: black;
}

.right-profile a span {
	display: inline-block;
	vertical-align: middle;
	line-height: 30px !important;
}

.right-profile a span.profile-image {
	width: 30px;
	height: 30px;
	border-radius: 50%;
	border: #ddd solid 1px;
	text-align: center;
	overflow: hidden;
	margin-right: 8px;
}

.right-profile a span.profile-image img {
	display: block;
	width: 100%;
}

.right-profile .dropdown-menu {
	right: 15px !important;
	padding: 0px !important;
	margin: 0px !important;
	left: auto !important;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
	border-color: rgba(0, 0, 0, 0.1);
}

.right-profile .dropdown-menu:after {
	border-bottom: 8px solid #fff;
	border-left: 8px solid transparent;
	border-right: 8px solid transparent;
	top: -8px;
	content: '';
	display: inline-block;
	right: 15px;
	margin-right: -6px;
	position: absolute;
}

.right-profile .dropdown-menu li a {
	padding: 6px 20px;
	background-color: #ffffff !important;
	color: #555;
}

.right-profile .dropdown-menu li a i {
	display: inline-block;
	margin-right: 6px;
}

.no-padding {
	padding: 0px !important;
}

.account-card {
	margin: 30px;
}

.account-card-buttons {
	margin: 10px 0px 0 0;
}

.single-account-card {
	display: inline-block;
	margin: 10px;
}

.authorize-btn {
	margin-bottom: 10px;
	margin-left: 10px;
	text-align: right;
}

.replier-tag .ant-tag {
	border-radius: 0px;
	cursor: default;
}

.filter-tag .ant-tag {
	margin-top: 2px;
	margin-left: 10px;
	cursor: default;
}

.chat-tag .ant-tag {
	margin-bottom: 8px;
	margin-left: 4px;
}

.dropdown-wrapper {
	flex: 0 auto;
	margin-left: 17px;
}

.add-organization-icon {
	display: inline-block;
	cursor: pointer;
	width: 40px;
	height: 40px;
	-webkit-border-radius: 50%;
	border-radius: 50%;
	background: #56c386;
	text-align: center;
	line-height: 56px;
	margin-bottom: 10px;
}

.add-organization-icon i {
	font-size: 26px;
	color: #fff !important;
}

.spinner-container {
	display: inline-block;
}

.fetch-status {
	margin-left: 10px;
}

.fetch-status-text {
	color: #3c4043;
	margin-left: 10px;
}

.recharts-legend-wrapper {
	padding: 14px 0;
	border-top: #e0e0e0 solid 1px;
}

.recharts-default-legend {
	display: flex;
	padding: 0;
	margin: 0 !important;
	justify-content: space-around;
}

.legend-block {
	display: block;
	margin-left: 28px;
	/*padding-top: 40px;*/
}

.legend-block li {
	cursor: pointer;
	display: inline-block;
	margin: 5px 15px;
}

.custom-legend {
	display: inline-block;
	margin-right: 5px;
	height: 10px;
	width: 10px;
	border: 1px solid;
}

.legend-block li span {
	font-size: 12px;
}

.summary-graph {
	margin-top: 35px;
	height: 300px;
	width: 100%;
}

.filter-tag,
.dash-button-block {
	display: inline-block;
	vertical-align: top;
}

.filter-tag {
	margin-right: 15px;
	line-height: 36px;
	font-weight: 400;
	color: #717171;
}

.password-dialog {
	padding: 20px 40px;
}

.chart-info {
	border-top: 1px solid #e0e0e0;
	margin: 30px 0 0;
	text-align: center;
	padding-top: 15px;
}

.chart-info span {
	display: block;
}

.chart-info span.title {
	margin-bottom: 3px;
	text-transform: uppercase;
	color: #b2b2b2;
	font-size: 12px;
}

.chart-info span.number {
	font-size: 18px;
	font-weight: 300;
}

.number span {
	display: inline-block;
}

.users-table {
	/*border-top: 2px solid #ddd;*/
}

.users-table td {
	text-align: left;
	border: 0;
}

.invite-page-outer {
	display: block;
	background: #fff;
	position: relative;
	padding: 30px 50px;
	text-align: center;
	width: 90%;
	margin: 20px auto 0 auto;
	max-width: 650px;
	-webkit-border-radius: 6px;
	border-radius: 6px;
}

.auth-logo {
	flex: 0 auto;
	max-width: 180px;
}

.auth-logo a {
	display: block;
	padding: 0 15px;
	overflow: hidden;
}

.select-role {
	display: block;
	text-align: center;
	margin-bottom: 20px;
}

.add-email {
	display: block;
	text-align: center;
}

.add-email span {
	display: inline-block;
	margin-right: 8px;
}

.user-role-select {
	text-align: center;
	padding-left: 23px;
	margin-bottom: 5px;
}

.user-role-select > span {
	padding: 14px;
}

.permissions-button-block {
	flex: 0 auto;
	margin-left: auto;
}

.user-permissions {
	padding-left: 23px;
	margin: 10px 0px;
}

.email-input {
	margin-top: 15px;
	padding-left: 23px;
}

.email-input span {
	margin-right: 10px;
}

/*=========
Manage Profile
============*/

.activated .card-top p,
.card-top p {
	text-align: center;
	padding-top: 20px;
	font-size: 14px;
}

.activated .card-top p {
	color: #ffffff;
}

.card-title span .material-icons {
	font-size: 18px !important;
	color: #fff !important;
}

.card-title h3 {
	flex: 0 auto;
	font-size: 14px;
	color: #555;
	text-transform: uppercase;
	margin-right: auto;
}

.activated .card-title h3 {
	color: #fff !important;
}

.card-info {
	height: 104px;
	padding: 0px 10px;
}

.card-info ul {
	display: block;
	text-align: center;
	border-bottom: #d7d7d7 solid 1px;
}

.card-info li {
	display: inline-block;
	padding: 15px 0px;
	text-align: center;
	width: 50%;
}

.card-info li:first-child {
	border-right: #d7d7d7 solid 1px;
}

.card-info li span {
	display: block;
}

.card-info li span:first-child {
	font-size: 24px;
	color: #06a7e1;
	padding: 6px 0;
}

.card-info li span:last-child {
	color: #888888;
	padding: 4px 0;
}

.card-icon-outer {
	display: flex;
	justify-content: space-around;
	padding: 25px 10px;
}

.icon-item {
	text-align: center;
	flex: 0 0 auto;
}

.icon-item span {
	cursor: pointer;
}

.card-icon-image {
	display: inline-block;
	width: 34px;
	height: 34px;
	-webkit-border-radius: 50%;
	border-radius: 50%;
}

.card-icon-image.blue {
	background: #7a98bf;
}

.card-icon-image.cyan {
	background: #8dcadf;
}

.card-icon-image.yellow {
	background: #ffc870;
}

.card-icon-image .imprt-icon,
.card-icon-image .activate-icon,
.card-icon-image .invite-icon {
	display: block;
	width: 34px;
	height: 34px;
	background: url(../images/add-icon-sprite.png) no-repeat;
}

.card-icon-image .imprt-icon {
	background-position: 6px 5px;
}

.card-icon-image .activate-icon {
	background-position: 5px -23px;
}

.card-icon-image .invite-icon {
	background-position: 5px -55px;
}

.card-icon-text {
	display: block;
	clear: both;
}

.last-update {
	display: block;
	padding: 20px 0px;
	text-align: center;
}

.profile-view-icon {
	display: block;
	text-align: center;
}

.profile-view-icon button {
	margin-top: 15px;
	border: 0px !important;
}

.profile-view-icon .ant-btn-default {
	color: #fff !important;
	background-color: #6bcbee;
	border-color: #6bcbee;
}

.profile-view-icon .ant-btn-default:hover {
	color: #fff !important;
	background-color: #06a7e1;
	border-color: #06a7e1;
}

.enter-icon {
	display: inline-block;
	vertical-align: middle;
	margin-right: 6px;
	width: 15px;
	height: 15px;
	background-image: url(../images/enter-icon.png);
	background-repeat: no-repeat;
	background-position: 0 0;
}

.porfile-details {
	display: block;
	float: right;
	margin: 0;
	padding: 15px 0;
}

.porfile-details li {
	display: inline-block;
	float: left;
	position: relative;
	padding-right: 15px;
}

.porfile-details li:before {
	position: absolute;
	content: '';
	width: 1px;
	height: 22px;
	background: #ddd;
}

.porfile-details li:last-child {
	padding-right: 0px;
}

.porfile-details li:last-child:before {
	background: none;
}

.porfile-details li span {
	display: inline-block;
	vertical-align: top;
	line-height: 22px;
}

.porfile-details li span:first-child {
	padding-right: 6px;
}

.toheight-1 {
	min-height: 341px;
}

.toheight-2 {
	margin-top: 10px;
	min-height: 473px;
}

.reviews-stats {
	height: 185px;
}

.success-message {
	color: green;
	font-size: 14px;
}

.blank-text {
	font-weight: 200;
}

.reply-notes {
	margin-left: 20px;
}

.reply-notes .reply-thread:first-child {
	border-top: 1px solid #dce5eb;
}

.reply-notes .reply-thread:last-child {
	border-bottom: none;
}

.notes-thread {
	padding-left: 20px !important;
	background: #f0f4f7;
}

.reply-modal .reply-thread:last-child {
	border-bottom: none !important;
}

.reply-conversation {
}

.delete-note-button {
	margin: 0px 5px;
	font-size: 15px;
}

.delete-note-button i {
	cursor: pointer;
}

.delete-note-button:before {
	content: '|';
}

/*=========
Review desk Page
============*/

.sentiment-filter {
	display: block;
	text-align: right;
	margin-bottom: 5px;
}

.label-sent {
	background-color: #06a7e1;
}

.label-drafts {
	background-color: #4db9e2;
}

.label-default {
	background-color: #777;
}

.label-primary {
	background-color: #f58258;
}

.label-success {
	background-color: #108ee9;
}

.label-approval {
	background-color: #ffb64f;
}

.label-outbox {
	background-color: #05843c;
}

.review-desk__content {
	flex: 1 1 auto;
	flex-basis: 1;
	margin: 0;
	background: #fff;
	padding: 0 20px 0 0;
}

.review-desk__content [class^='col-']:first-child,
[class*=' col-']:first-child {
	padding-right: 0px;
}

.review-desk__content--header {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-between;
	align-content: center;
	padding: 15px;
	border-bottom: 1px solid #e6e6e6;
}

.review-desk__content--header .sort-label {
	line-height: 25px;
	border-width: 0px;
}

.review-desk__content--header .ant-dropdown-trigger {
	padding: 0 6px 0;
	line-height: 25px;
	border-width: 1px;
}

.review-desk__content--header .ant-dropdown-trigger:hover {
	border-color: #e6e6e6;
}

.review-desk__content--header h4 {
	float: left;
	font-size: 16px;
	font-weight: 300;
	line-height: 27px;
}

.review-desk__content--header .ant-btn {
	position: relative;
}

.review-desk__content--header .ant-btn:last-child {
	margin-left: -1px;
}

.review-icon {
	flex: 0 0 auto;
	margin-left: auto;
}

.review-icon img {
	height: 24px;
}

.reviewqa-icon {
	flex: 0 0 auto;
	margin-left: auto;
}

.reviewqa-icon img {
	height: 24px;
}

.info-details {
	margin: 0;
	padding-top: 10px;
}

.review-rating__head,
.review-rating__stars {
	display: flex;
	justify-content: flex-start;
	flex-direction: row;
	flex-flow: row;
	align-items: center;
	margin: 0;
}

.review-rating__head .review-location {
	flex: 0 0 auto;
	font-size: 14px;
}

.review-rating__head .review-date {
	flex: 0 0 auto;
	margin-left: auto;
}

.review-date span {
	display: inline-block;
	margin-left: 6px;
	font-size: 12px;
}
.review-date-block span {
	display: inline-block;
	margin-left: 6px;
	font-size: 12px;
}
.review-comment {
	font-size: 14px;
}
.review-rating__stars span {
	flex: 0 0 auto;
	font-size: 14px;
	font-weight: 700;
}

.review-rating__stars span:first-child {
	margin-right: 25px;
}

.list-item {
	display: flex;
	flex-direction: row;
	flex-flow: row;
	justify-content: flex-start;
	align-items: center;
	margin: 0;
}

.list-item span {
	flex: 0 0 auto;
	margin-right: 10px;
}

.list-item span:first-child {
	font-size: 14px;
}

.list-item span:last-child {
	margin-right: 0px;
	/*margin-left: auto;*/
	margin: auto;
}

.review-rating {
	margin: 0px;
	padding: 0px;
}

.reply-location {
	padding-top: 10px;
	padding-bottom: 2px;
}

.reply-location span:first-child {
	font-size: 14px;
}

.review-rating div span {
	padding: 0px !important;
	margin: 0px !important;
}

.review-item-detail__head {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: center;
}

.saved-response {
	display: flex;
	flex-direction: column;
	padding: 10px 0;
	cursor: pointer;
	border-bottom: 1px solid #e9eef2;
}

.saved-response:hover {
	background-color: #f0f4f7;
}

/*.canned-response{
border: #d9d9d9 solid 1px;
border-radius: 2px;
}*/

.new-canned-response {
	height: 500px;
}

.new-canned-response textarea {
	height: 400px;
	width: 100%;
}

.new-canned-response button {
	margin-top: 10px;
}

.filter-popover {
	display: block;
	width: 225px;
	margin: 0;
	padding: 0;
}

.filter-popover li {
	display: block;
	margin-bottom: 10px;
}

.filter-popover li:last-child {
	margin-bottom: 0px;
}

.filter-popover li .ant-dropdown-trigger {
	width: 137px;
}

.reviews-pagination {
	flex: 0 0 auto;
	padding-top: 3px;
}

.custom-save {
	flex: 0 auto;
	margin-right: 8px;
}

.custom-delete {
	flex: 0 auto;
}

.disable-button {
	cursor: not-allowed;
}

.disable-button-text {
	visibility: hidden;
	width: 200px;
	background-color: rgba(64, 64, 64, 0.85);
	border-radius: 4px;
	color: #fff !important;
	text-align: center;
	right: 50px;
	padding: 5px 0;
	/* Position the tooltip */
	position: absolute;
	z-index: 1;
}

.disable-button:hover .disable-button-text {
	visibility: visible;
}

.admin-option {
	margin-left: 30px;
}

.locations-table td,
.locations-table th {
	text-align: center;
}

.tag-block {
	min-height: 200px;
}

.location-header-edit {
	position: absolute;
	top: 10px;
	right: 40px;
	cursor: pointer;
	font-size: 20px;
}
.location-review-avg {
	color: #06a7e1;
	margin-right: 5px;
}
.location-content {
	display: block;
	padding: 0px 10px;
}

.rating-wrap {
	display: flex;
	align-items: center;
	flex-direction: row;
	justify-content: flex-start;
	padding: 10px 0;
	border-bottom: #dce5eb solid 1px;
}
.rating-wrap .post-date {
	margin-left: auto;
}

.rating-wrap .rating-wrap__icon {
	flex: 0 auto;
	width: 22px;
	height: 22px;
}

.post-rating-replyblock {
	display: block;
	float: left;
}

.post-rating__item {
	display: block;
}

.post-rating__item span {
	display: inline-block;
	vertical-align: top;
	line-height: 24px;
}

.post-rating__item span a {
	color: #06a7e1;
}

.post-reviews {
	display: block;
	margin-top: 15px;
}

.post-reviews ul {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-items: flex-start;
}

.post-reviews li {
	flex: 0 0 auto;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-items: center;
	background-color: #f1f9ff;
	border: #e8f1f8 solid 1px;
	padding: 3px;
	margin-right: 8px;
	margin-bottom: 8px;
	border-radius: 2px;
}

.post-reviews li:last-child {
	margin-right: 0;
}

.post-reviews li img {
	width: 20px;
	height: 20px;
	margin-right: 6px;
}

.post-reviews li i {
	font-size: 14px;
	color: #f58258;
}

.post-reviews li a {
	margin-left: 6px;
	padding-left: 6px;
	position: relative;
}

.post-reviews li a:before {
	content: '';
	display: block;
	position: absolute;
	width: 1px;
	height: 10px;
	background-color: #d9e2e9;
	top: 4px;
	left: 0;
}

.post-web__address {
	display: block;
	border-bottom: solid 1px #f2f2f2;
	padding: 8px 0;
}

.post-web__address ul {
	display: flex;
	justify-content: flex-start;
	flex-direction: row;
	align-items: center;
	flex-wrap: nowrap;
	padding-left: 5px;
}

.post-web__address li {
	flex: 0 auto;
	border-left: solid 1px #f2f2f2;
}

.post-web__address li:first-child {
	border-left: 0;
}
.post-web__address li:last-child {
	border-left: 0;
	margin-left: auto;
	margin-right: 15px;
	color: #70757a;
}
.post-web__address li .icon__image {
	display: block;
	margin: 0 12px;
	width: 26px;
	overflow: hidden;
}

.post-web__address li .icon__image i {
	font-size: 27px;
	margin: 0;
	padding: 0;
}

.post-web__address li span img {
	float: left;
}

.hours-table tr td {
	padding: 0px 5px 0px 5px;
}

.sub-address-info i {
	font-size: 15px;
}

.address-info {
	display: block;
}

.address-info-address .sub-address-info i {
	margin-right: 5px;
	color: #06a7e1;
}

.open-times {
	position: relative;
}

.open-times i {
	top: 4px;
	color: #49a9ee !important;
	font-size: 9px;
	margin-left: 3px;
	position: absolute;
}

.address-info .sub-address-info {
	margin: 10px;
}

.address-info-fieldname {
	font-weight: bold;
	margin-right: 5px;
}

.address-block {
	min-height: 75px;
	padding: 8px;
	display: block;
	border: 1px solid #d9d9d9;
	-webkit-border-radius: 3px;
	border-radius: 3px;
}

.address-block h4 {
	font-size: 16px;
	font-weight: 600;
	margin-bottom: 4px;
}

.post-image__block {
	/* height: 200px; */
	display: block;
	padding: 10px 0;
}
.react-multi-carousel-list {
	max-width: 720px;
	height: auto !important;
	align-items: flex-start !important;
}
.react-multi-carousel-dot-list{
	padding-bottom: 10px !important;
	align-items: center !important;
	width: 100% !important;
}
.react-multi-carousel-list
	.react-multi-carousel-track
	.react-multi-carousel-item {
	overflow: hidden;
	margin: 10px;
	display: block;
	width: 120px !important;
	height: 90px;
}

.post-review {
	display: block;
	padding: 10px 0;
}

.post-review ul {
	display: block;
}

.post-review li {
	display: block;
	padding: 2px 0px 0px 40px;
	position: relative;
	border-bottom: 1px solid #d9d9d9;
}

.post-review li .profile-icon,
.post-review li .disabled-profile-icon {
	width: 30px;
	display: block;
	position: absolute;
	left: -35px;
	top: 15px;
	text-align: center;
	line-height: 30px;
	font-size: 20px;
	height: 30px;
	-webkit-border-radius: 50px;
	border-radius: 50px;
	-webkit-transition: all 200ms ease-in-out;
	-moz-transition: all 200ms ease-in-out;
	-o-transition: all 200ms ease-in-out;
	transition: all 200ms ease-in-out;
}

.post-review li .profile-icon {
	color: #fff !important;
	line-height: 14px;
	padding: 4px;
	border: solid 1px #ccc;
}

.post-review li .disabled-profile-icon {
	color: #06a7e1;
	background: #f5f5f6;
	cursor: default;
}

.post-review li .profile-icon:hover {
	border: solid 1px #018cbe;
	-webkit-transition: all 200ms ease-in-out;
	-moz-transition: all 200ms ease-in-out;
	-o-transition: all 200ms ease-in-out;
	transition: all 200ms ease-in-out;
}

.post-review li h4 {
	font-size: 16px;
	font-weight: 600;
}

.post-review li span {
	font-size: 14px;
}

.post-date {
	float: right;
	line-height: 26px;
}

.activeRowClass {
	background-color: #ecf6fd;
}


.reply-options-tab {
	padding: 10px 5px;
	margin: 0px 1px;
	background: #f5f5f5;
	border-bottom: 1px solid #e9eef2;
}

.feedback-page {
	background: #fff;
	padding: 20px;
	height: 600px;
}

.helpdesk-send {
	text-align: center;
	margin-top: 20px;
}

.edit-field {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: stretch;
	margin: 8px 0;
}

.edit-field-item:first-child {
	flex: 0 0 auto;
	min-width: 180px;
	line-height: 34px;
}

.edit-field-item:last-child {
	flex: 1 1 auto;
}

/*.edit-timeline table{
    margin: 10px;
}
.edit-timeline table td, th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px !important;
}*/

.edit-timeline .show {
	visibility: visible;
}

.edit-timeline .card {
	display: block;
	height: auto;
	margin-bottom: 10px;
	box-shadow: none;
	background: rgb(255, 255, 255);
	overflow: hidden;
}

.more-photos-overlay {
	display: block;
	font-weight: bold;
	color: white;
	height: 95%;
	position: absolute;
	text-align: center;
	background: rgba(0, 0, 0, 0.5);
	width: 95%;
}

.more-photos-overlay span {
	position: relative;
	top: 50%;
}

.more-photos {
	position: relative;
}

.map-overlay {
	display: block;
	position: absolute;
	text-align: center;
	height: 100%;
	width: 67%;
	z-index: 11;
}

.old-edit {
	color: #a9a9a9;
	padding: 0px 5px;
	font-weight: bold;
}

.new-edit {
	padding: 0px 5px;
	color: #7a98bf;
	font-weight: bold;
}

.field-edit {
	padding: 0px 5px 0px 0px;
	color: #06a7e1;
	font-weight: bold;
}

.edit-item i {
	margin-right: 5px;
	margin-bottom: 5px;
}

.edit-timeline {
	transition: all 0.3s;
	position: absolute;
	padding: 25px 30px;
}

.edit-timeline .ant-btn {
	border-color: grey;
	padding: 0px;
	line-height: 0px;
	height: 28px;
	-webkit-border-radius: 50%;
	border-radius: 50%;
	margin-left: 5px;
}

.timeline-header {
	font-size: 20px;
	margin-top: 20px;
	margin-left: 10px;
}

.edit-timeline .ant-btn:hover {
	background: #4baaee;
	color: #fff !important;
	transition: all 0.2s;
}

.edit-timeline .ant-timeline-item {
	padding: 0 0 25px;
}

.edit-timeline .ant-timeline-item-content {
	font-size: 14px;
	top: -6px;
}

.edit-timeline .ant-timeline-item-head {
	width: 14px;
	height: 14px;
}

.timeline-icon {
	display: inline-block;
	padding: 4px;
	background: #8cd3c7;
	box-shadow: 0 0 0 3px #e3e4e4;
	border-radius: 0;
	-webkit-border-radius: 0;
	transform: rotate(45deg);
	min-width: 11px;
	min-height: 11px;
}

.edit-field-actions {
	margin: 10px;
}

.edit-field-actions button {
	margin: 10px 10px;
}

.month-picker-wrapper {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: center;
}

.month-label {
	margin-right: 30px;
	margin-bottom: 1px;
	margin-left: 14px;
}

.map-picker {
	height: 100% !important;
	width: 100% !important;
	position: relative !important;
}

.map-container {
	height: 200px;
	width: 100px;
}

.map-expanded {
	height: 400px;
}

.single-plan:hover {
	-webkit-box-shadow: 0 0 2px 1px #d9d9d9;
	box-shadow: 0 0 2px 1px #d9d9d9;
}

.role-action-btn {
	padding: 3px 5px;
	border-radius: 2px;
	color: #fff !important;
}

.role-action-btn:hover {
	color: #fff !important;
}

.feature-access {
	padding: 0px !important;
	border: 0px !important;
	border-bottom: 2px solid #d9d9d9 !important;
	margin: 7px;
}

.roles-features-table td {
	padding-left: 50px;
}

.roles-expanded {
	display: inline-block;
}

.roles-expanded-feature {
	padding: 1px 5px;
	display: block;
	margin: 0px 5px;
}

.roles-expanded-feature span {
	margin: 0px 5px;
}

.roles-expanded-heading {
	margin: 2px;
	font-weight: bold;
}
.hint-text-roles {
	color: #9ea3c2;
	margin-top: 10px;
}

.roles-users-box {
	margin: 0px;
	padding: 0px;
	text-align: center;
	min-height: 250px;
	margin: 15px;
	border: 2px solid #d9d9d9;
}

.roles-users-box:first-child {
	float: left;
}

.roles-users-box:last-child {
	float: right;
}

.box-users {
	margin: 10px;
	font-weight: 500;
}

.roles-box-header {
	margin-top: 10px;
	padding-bottom: 10px;
}

.roles-box-search {
	border-width: 0px 0px 1px !important;
	border-color: #d9d9d9;
}

.roles-box-search .ant-input-search {
	border-width: 2px 0px 2px;
	border-color: #d9d9d9;
}

.role-name-field {
	margin: 10px 0px;
}

.role-name-field input {
	line-height: 30px !important;
	margin-top: 10px;
}

.transfer-box {
	margin: 10px;
	text-align: center;
}

.transfer-box .ant-input {
	border-width: 1px 0px 1px 0px;
	border-color: #d9d9d9;
}

.transfer-box .ant-btn {
	padding: 0px 14px;
}

.ant-transfer-list-content-item {
	text-align: left;
}

.user-detail-roles {
	display: inline-block;
	width: 153px;
	float: left;
}

.user-detail-save {
	display: inline-block;
	font-size: 24px;
	vertical-align: middle;
	margin-left: 10px;
	margin-top: 4px;
}

.no-permission {
	position: relative;
	background: #ccc !important;
	cursor: not-allowed;
}

.no-permission .tooltiptext {
	visibility: hidden;
	margin-left: -70px;
	max-width: 250px;
	font-size: 12px;
	width: 140px;
	bottom: 100%;
	left: 50%;
	background-color: rgba(64, 64, 64, 0.85);
	color: #fff !important;
	text-align: center;
	padding: 5px 0;
	border-radius: 4px;
	position: absolute;
	z-index: 1;
}

.no-permission:hover .tooltiptext {
	visibility: visible;
}

.no-permission .tooltiptext::after {
	content: ' ';
	position: absolute;
	top: 100%;
	/* At the bottom of the tooltip */
	left: 50%;
	margin-left: -5px;
	border-width: 5px;
	border-style: solid;
	border-color: rgba(64, 64, 64, 0.85) transparent transparent transparent;
}

.role-operations .show {
	visibility: visible;
}

.role-operations .card {
	box-shadow: none;
}

.assign .card {
	height: 500px;
}

.role-panel {
	border: 1px solid #ddd;
}

.edit .card {
	height: 500px;
}

.role-panel {
	margin: 3px;
	background: #f7f7f7;
}

.role-select {
	display: inline-block;
	width: 200px;
}

.compulsory-star:before {
	content: '*';
	color: #f15922;
	font-size: 11px;
	vertical-align: top;
}

.payment-page-outer {
	display: block;
	padding: 15px;
}

.payment-header {
	text-align: center;
	background: #067ae1;
	color: white;
	font-weight: bold;
	font-size: 20px;
	padding: 15px;
}

.payment-plans {
	background: #fff;
	padding: 20px 0px;
	min-height: calc(100vh - 165px);
}

.payment-plans .payment-wrap {
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	justify-content: center;
	align-content: stretch;
}

.payment-plans .payment-wrap .payment-details {
	margin: 0px 6px;
	flex: 0 0 auto;
}

.payment-plans .price-block {
	display: block;
	text-align: center;
	margin-top: 30px;
}

.payment-preference {
	width: 70%;
}

.payment-action i {
	margin-right: 5px;
}

.payment-ledger {
	width: 50% !important;
	margin: 20px 10px;
}

.payment-preference-options {
	width: 100%;
	margin: 20px 10px;
}

.payment-preference td {
	margin: 20px 10px;
}

.payment-preference .header {
	margin-left: 10px;
}

.payment-invoices .header {
	margin-left: 10px;
}

.payment-update-option {
	display: inline-block;
}

.payment-remark-container {
	position: relative;
	font-weight: 300;
}

.payment-remark-text {
	margin-left: 10px;
}

.payment-remark-text:before {
	content: '*';
	font-size: 10px;
	position: absolute;
	left: 2px;
}

.payment-group-header {
	background: #f9f9f9;
}

.payment-portal-container {
	height: 650px;
	width: 100%;
}

.payment-portal-container iframe {
	border: 0px !important;
	height: 626px;
	width: 100% !important;
	visibility: visible !important;
}

.plan-details {
	padding: 10px;
	margin: 10px;
}

.plan-details li {
	display: block;
	border-bottom: #f2f2f2 solid 1px;
	padding: 10px 0;
	text-align: center;
}

.plan-details li:first-child {
	border-top: #f2f2f2 solid 1px;
}

.plan-text {
	text-align: center;
	margin: 15px;
	font-size: 16px;
	color: #067ae2;
}

.plan-details li i {
	float: right;
	font-size: 15px;
}

.payment-details {
	margin: 20px;
	border: 1px solid #d9d9d9;
	max-width: 350px;
	width: 100%;
	margin: 0 auto;
}

.payment-details-line {
	text-align: center;
	font-size: 20px;
}

.payment-price {
	margin-top: 10px;
	text-align: center;
	display: block;
}

.payment-price h3 {
	font-size: 20px;
	margin: 10px;
	font-weight: 300;
	text-align: center;
	color: #2fb66a;
}

.price-line {
	font-size: 14px;
	position: relative;
}

.price-symbol {
	font-size: 15px;
	position: absolute;
	left: -10px;
	bottom: 15px;
}

.price-amount {
	font-size: 33px;
}

.price-range {
	font-weight: 500;
}

.remark-text {
	position: relative;
	margin-left: 15px;
	margin-top: 20px;
}

.remark-text:before {
	content: '*';
	top: 0px;
	left: -7px;
	font-size: 12px;
	position: absolute;
}

.expiry-warning-label {
	margin-left: 10px;
	cursor: pointer;
}

.expiry-warning-label i {
	color: #f2602c;
}

.locations-graph-outer {
	display: block;
	padding: 15px;
}

.locations-graph {
	background: #fff;
	width: 100%;
	height: 300px;
	padding: 5px;
}

.locations-status-filters .status-filter-item {
	display: inline-block;
	width: 190px;
	margin: 10px;
	vertical-align: top;
}

.status-filter-item .ant-input,
.status-filter-item .ant-btn {
	line-height: 34px;
	border-color: #d9d9d9;
}

.json-tree-block ul {
	background: #fff !important;
	margin: 10px !important;
}

/*======checkbox=========*/

.option-input {
	-webkit-appearance: none;
	-moz-appearance: none;
	-ms-appearance: none;
	-o-appearance: none;
	appearance: none;
	width: 20px;
	height: 20px;
	-webkit-transition: all 0.15s ease-out 0;
	-moz-transition: all 0.15s ease-out 0;
	transition: all 0.15s ease-out 0;
	background: #fff;
	border: #555 solid 1px;
	color: #fff !important;
	cursor: pointer;
	display: inline-block;
	outline: 0px !important;
	position: relative;
	z-index: 1000;
}

.option-input:hover,
.option-input:focus {
	background: #fff;
	outline: 0px !important;
}

.option-input:checked {
	background: #fff;
	border: #108ee9 solid 1px;
	outline: 0px !important;
	z-index: 100;
}

.option-input.checkbox:checked::before {
	width: 10px;
	height: 10px;
	position: absolute;
	content: '\2713';
	font-size: 16px;
	display: inline-block;
	color: #108ee9;
	text-align: center;
	line-height: 10px;
	top: 3px;
	left: 3px;
	border-radius: 50%;
	outline: 0px;
	z-index: 100;
}

.option-input:checked::after {
	-webkit-animation: click-wave 0.65s;
	-moz-animation: click-wave 0.65s;
	animation: click-wave 0.65s;
	background: #40e0d0;
	content: '';
	display: block;
	position: relative;
	z-index: 100;
	outline: 0px;
}

.option-input.radio {
	border-radius: 50%;
}

.option-input.radio::after {
	border-radius: 50%;
}

@-webkit-keyframes click-wave {
	0% {
		width: 20px;
		height: 20px;
		opacity: 0.35;
		position: relative;
	}

	100% {
		width: 60px;
		height: 60px;
		margin-left: -24px;
		margin-top: -24px;
		opacity: 0;
	}
}

/*=====animation for the icon=====*/

.location-featching {
	display: inline-block;
	background: url(../images/map.png) no-repeat center 30px;
	width: 173px;
	height: 135px;
	position: relative;
	margin-top: 20px;
}

.location-map-one {
	position: absolute;
	left: 0px;
	top: 0px;
}

.location-map-two {
	position: absolute;
	right: 0px;
	top: 0px;
}

.location-map-three {
	position: absolute;
	left: 40%;
	top: 0;
}

.animation-hover-one {
	animation: hover-1 1.5s cubic-bezier(0.645, 0.045, 0.355, 1) infinite both
		alternate;
}

@keyframes hover-1 {
	0% {
		transform: translateY(-20px);
	}

	100% {
		transform: translateY(0px);
	}
}

.animation-hover-two {
	animation: hover-2 1.5s cubic-bezier(0.645, 0.045, 0.355, 1) infinite both
		alternate;
}

@keyframes hover-2 {
	0% {
		transform: translateY(-20px);
	}

	100% {
		transform: translateY(0px);
	}
}

.animation-hover-three {
	animation: hover-3 1.5s cubic-bezier(0.645, 0.045, 0.355, 1) infinite both
		alternate;
}

@keyframes hover-3 {
	0% {
		transform: translateY(-20px);
	}

	100% {
		transform: translateY(30px);
	}
}

.queue-icon {
	position: relative;
	background: url(../images/queue.png) no-repeat center 50px;
	width: 191px;
	height: 145px;
}

.queue-animation {
	position: absolute;
	left: 50%;
	height: 10px;
	background-color: #737f91;
	animation-name: queue-bounce;
	-o-animation-name: queue-bounce;
	-ms-animation-name: queue-bounce;
	-webkit-animation-name: queue-bounce;
	-moz-animation-name: queue-bounce;
	animation-duration: 1.5s;
	-o-animation-duration: 1.5s;
	-ms-animation-duration: 1.5s;
	-webkit-animation-duration: 1.5s;
	-moz-animation-duration: 1.5s;
	animation-iteration-count: infinite;
	-o-animation-iteration-count: infinite;
	-ms-animation-iteration-count: infinite;
	-webkit-animation-iteration-count: infinite;
	-moz-animation-iteration-count: infinite;
	animation-direction: normal;
	-o-animation-direction: normal;
	-ms-animation-direction: normal;
	-webkit-animation-direction: normal;
	-moz-animation-direction: normal;
	transform: scale(0.3);
	-o-transform: scale(0.3);
	-ms-transform: scale(0.3);
	-webkit-transform: scale(0.3);
	-moz-transform: scale(0.3);
	border-radius: 19px;
	-o-border-radius: 19px;
	-ms-border-radius: 19px;
	-webkit-border-radius: 19px;
	-moz-border-radius: 19px;
}

.queue-one {
	top: 0px;
	width: 130px;
	margin-left: -66px;
	animation-delay: 0.6s;
	-o-animation-delay: 0.6s;
	-ms-animation-delay: 0.6s;
	-webkit-animation-delay: 0.6s;
	-moz-animation-delay: 0.6s;
}

.queue-two {
	top: 30px;
	width: 77px;
	margin-left: -39px;
	animation-delay: 0.75s;
	-o-animation-delay: 0.75s;
	-ms-animation-delay: 0.75s;
	-webkit-animation-delay: 0.75s;
	-moz-animation-delay: 0.75s;
}

.queue-three {
	top: 60px;
	width: 55px;
	margin-left: -27px;
	animation-delay: 0.9s;
	-o-animation-delay: 0.9s;
	-ms-animation-delay: 0.9s;
	-webkit-animation-delay: 0.9s;
	-moz-animation-delay: 0.9s;
}

@keyframes queue-bounce {
	0% {
		transform: scale(1);
		background-color: rgb(115, 127, 145);
	}

	100% {
		transform: scale(1);
		background-color: rgb(228, 229, 231);
	}
}

@-o-keyframes queue-bounce {
	0% {
		-o-transform: scale(1);
		background-color: rgb(115, 127, 145);
	}

	100% {
		-o-transform: scale(1);
		background-color: rgb(228, 229, 231);
	}
}

@-ms-keyframes queue-bounce {
	0% {
		-ms-transform: scale(1);
		background-color: rgb(115, 127, 145);
	}

	100% {
		-ms-transform: scale(1);
		background-color: rgb(228, 229, 231);
	}
}

@-webkit-keyframes queue-bounce {
	0% {
		-webkit-transform: scale(1);
		background-color: rgb(115, 127, 145);
	}

	100% {
		-webkit-transform: scale(1);
		background-color: rgb(228, 229, 231);
	}
}

@-moz-keyframes queue-bounce {
	0% {
		-moz-transform: scale(1);
		background-color: rgb(115, 127, 145);
	}

	100% {
		-moz-transform: scale(1);
		background-color: rgb(228, 229, 231);
	}
}

/*-----style edite------*/

/*==========sentiment analysis=======*/

.term-tag {
	margin: 0px 2px;
}

.review-gyan {
	display: block;
	border: #f2f2f2 solid 1px;
	padding: 20px;
}

.review-gyan h3 {
	font-size: 20px;
	font-weight: 500;
}

.review-gyan h2 {
	text-align: center;
	color: #84a65a;
	font-size: 22px;
	margin: 0px;
	padding: 0px;
}

.review-gyan p {
	margin: 0px;
	padding-top: 4px;
	font-size: 12px;
	color: #555;
}

.review-list {
	padding: 0px;
	margin: 0px;
}

.review-list li {
	display: block;
	padding: 5px 0;
}

.review-list__inner {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-between;
	align-items: center;
}

.review-gyan-content,
.review-value {
	flex: 0 0 auto;
}

.review-gyan-content h3 {
	margin: 0px;
	padding: 0px;
	font-size: 20px;
	text-align: left;
	color: #84a65a;
	text-transform: capitalize;
}

.review-gyan-content p {
	text-align: left;
	margin: 0px;
	padding: 2px 0 0 0;
}

.review-value {
	color: #84a65a;
	font-size: 24px;
}

.progress-line {
	display: block;
	height: 2px;
	background: #f2f2f2;
	margin-top: 10px;
}

.progress-line span {
	background: #84a65a;
	height: 2px;
	display: block;
}

.sentiment-container {
	margin: 30px;
	background: #fff;
}

.review-gyan-outer {
	background: #fff;
}

.bubble-chart-outer {
	width: 800px;
	height: 600px;
	margin: 0 auto;
}

.bubble-chart-container {
	width: 600px;
	height: 600px;
}

.bubble-legend {
	left: -90px !important;
}

.sentiment-detail-container {
	width: 600px;
	height: 300px;
}

.sentiment-piecharts {
	height: 240px;
	width: 100%;
}

.sentiment-piecharts-title {
	display: inline-block;
	font-size: 20px;
	text-align: center;
}

.sentiment-piecharts-top {
	text-align: center;
}

.single-sentiment-piecharts {
	height: 100%;
}

.sentiment-icon.flip {
	-webkit-transform: rotateY(180deg);
	-moz-transform: rotateY(180deg);
	-o-transform: rotateY(180deg);
	-ms-transform: rotateY(180deg);
}

.sentiment-icon {
	font-size: 17px;
	margin: 0px;
}

.cats {
	margin-right: 8px;
	margin-left: 15px;
}

.tags {
	display: inline-block;
	vertical-align: middle;
	background: #f2f2f2;
	border-radius: 3px;
	margin: 0px 5px 1px 0px;
	line-height: 20px;
	font-size: 11px;
	padding: 0 4px;
	position: relative;
	-webkit-transition: all 0s ease-in-out;
	-moz-transition: all 2s ease-in-out;
	-o-transition: all 2s ease-in-out;
	transition: all 2s ease-in-out;
}

.tags a {
	position: absolute;
	display: none;
	width: 12px;
	height: 12px;
	background: rgba(241, 89, 34, 0.4);
	top: -4px;
	right: -4px;
	border-radius: 50%;
	text-align: center;
	line-height: 10px;
	font-weight: bold;
	font-size: 11px;
	color: #fff !important;
	-webkit-transition: all 1s ease-in-out;
	-moz-transition: all 1s ease-in-out;
	-o-transition: all 1s ease-in-out;
	transition: all 1s ease-in-out;
}

.tags a:hover {
	background: rgba(241, 89, 34, 0.8);
	color: #fff !important;
	-webkit-transition: all 1s ease-in-out;
	-moz-transition: all 1s ease-in-out;
	-o-transition: all 1s ease-in-out;
	transition: all 1s ease-in-out;
}

.tags:hover a {
	display: block;
	-webkit-transition: all 2s ease-in-out;
	-moz-transition: all 2s ease-in-out;
	-o-transition: all 2s ease-in-out;
	transition: all 2s ease-in-out;
}

.two-button {
	font-family: 'Open Sans', sans-serif !important;
	font-size: 14px;
	line-height: 1.42857143;
	color: #555;
	border: 1px solid #d4d7dd !important;
}

.no-category {
	padding-left: 40px !important;
}

.no-category li {
	display: block !important;
	padding: 0px !important;
	margin: 0px !important;
}

.no-category li a {
	padding: 6px 15px 6px 0px !important;
	margin: 0px !important;
	line-height: 22px;
}

.no-category li a .label {
	position: static !important;
	right: 0px;
	top: 0px;
	transform: translateY(0);
}

.no-category li:first-child {
	padding: 8px 0px 4px 0 !important;
}

.no-category li input {
	border: 1px dashed lightblue;
	width: 160px;
}

.zoho-waiter {
	display: block;
	position: relative;
	min-height: 100vh;
}

.zoho-waiter .ant-spin {
	position: absolute;
	left: 50%;
	top: 45%;
	transform: translate(-50%, -50%);
}

.information-block {
	display: flex;
	flex-wrap: nowrap;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	height: 100vh;
}

.add-report {
	display: block;
	border-top: 1px solid #e9e9e9;
	padding-top: 15px;
	margin-top: 15px;
}

.shedule-type {
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	padding: 2px 0px;
}

.shedule-type {
	button{
		min-height: 19px !important;
	}
}

.shedule-type .ant-radio-group {
	flex: 0 0 auto;
	margin-left: 8px;
}

.shedule-type .ant-radio-group .ant-radio-wrapper {
	line-height: 30px;
}

.shedule-type .ant-radio-group .ant-radio-wrapper span:last-child {
	display: inline-block;
}

.granularity-radio {
	display: flex;
	flex-direction: row;
}

.granularity-radio .granularity-label {
	display: flex;
	flex-direction: row;
	margin: 10px 10px 10px 0px;
	font-size: 14px;
	font-weight: normal;
	padding: 0px;
}

.shedule-type label {
	flex: 0 0 auto;
	font-size: 14px;
	font-weight: normal;
	padding: 0px;
	margin: 0px;
}

.shedule-type span label {
	font-weight: normal;
	line-height: 36px;
	font-size: 14px;
	margin-bottom: 0px;
	float: left;
	margin-right: 6px;
}

.shedule-type .ant-time-picker-input {
	border: 1px solid #a0a7b4;
	height: 36px;
	line-height: 36px;
	border-radius: 0px;
}

.shedule-type .ant-input-number {
	border-width: 1px;
	margin-right: 6px;
	line-height: 34px;
}

.shedule-type .ant-input-number-input-wrap {
	height: 35px;
	line-height: 35px;
}

.report-header-logo img {
	width: 250px;
}

.report-options {
	padding: 10px;
}

.copy-text {
	top: 8px;
	font-size: 14px;
	position: absolute;
	color: #2fb66a;
	margin-left: 10px;
}

.report-filter-capitalize {
	text-transform: capitalize;
}

.content-wrapper {
	display: block;
}

.reply-head {
	display: flex;
	flex-wrap: nowrap;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.head-title {
	flex: 1 0 auto;
	display: flex;
	gap: 10px;
	
}
.head-title .head-block{
	display: flex;
	width: 100%;
	gap: 10px;
	align-items: center;
	height: auto;
	svg{
		width: 10px;
		height: 10px;
		fill: var(--med-lgt-text);
	}
}

.head-title .head-block h4 {
	font-size: 14px;
	color:  var(--drk-text);
	/* width: 100%; */
}

.head-title__group {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-between;
	align-content: center;
	padding-top: 13px;
	width: 100%;
}

.head-title__group span {
	font-size: 12px;
	color: var(--med-lgt-text);	/* width: 100%; */

}
.head-title__group .star-section {
	font-size: 12px;
	color: var(--med-lgt-text);
	width: 100%;
	/* margin-top: -10px; */
	margin-right: 10px;
}
.head-title__group .star-section span{
	width: 100%;
	display: flex;
	gap: 4px;
	margin-top: -5px;
	border-radius: 3px;
	justify-content: flex-end;
}
.head-title__group .star-section .group-rating{
	width: 100%;
	display: flex;
	gap: 4px !important;
	margin-top: -5px;
	border-radius: 3px;
	justify-content: flex-end;
}
.head-title__group .star-section .group-rating .default{
	color: var(--med-lgt-text) !important;
}

.head-title__group .star-section .group-rating .star-1{
	display: flex;
	width: 20px;
	height: 8px;
	background-color: var(--star-rating-1-color);
	border-radius: 3px;

}
.head-title__group .star-section .group-rating .star-2{
	display: flex;
	width: 20px;
	height: 8px;
	background-color:var(--star-rating-2-color);
	border-radius: 3px;

}
.head-title__group .star-section .group-rating .star-3{
	display: flex;
	width: 20px;
	height: 8px;
	background-color:var(--star-rating-3-color);
	border-radius: 3px;

}
.head-title__group .star-section .group-rating .star-4{
	display: flex;
	width: 20px;
	height: 8px;
	background-color: var(--star-rating-4-color);
	border-radius: 3px;

}
.head-title__group .star-section .group-rating .star-5{
	display: flex;
	width: 20px;
	height: 8px;
	background-color: var(--star-rating-5-color);
	border-radius: 3px;

}


.head-description {
}

.head-tag {
	flex: 0 0 auto;
}

.head-tag a {
	display: block;
	line-height: 20px;
	padding: 0 10px;
	border-radius: 3px;
}

.reply-text {
	margin: 0;
	border-top-color: transparent;
}
.reply-text-content{
	margin: 0;
	border-top-color: transparent;
	max-width: 85%;
	color:  var(--med-drk-text);
}

.reply-text .Select {
	margin: 4px 4px 0 4px;
}

.saved-replies-detail {
	display: block;
	margin-left: 10px;
}

.saved-replies-subject {
	border-bottom: 1px solid #ccc;
	padding: 9px 5px;
}

.saved-replies-header {
	border-bottom: 1px solid #ccc;
	height: 36px;
}

.saved-replies-group {
	margin: 9px 5px;
	display: inline-block;
}

.saved-replies-creator {
	display: block;
	margin: 10px 0px;
}

.saved-replies-name {
	display: inline-block;
	text-align: left;
}

.saved-replies-date {
	display: inline-block;
	text-align: right;
	float: right;
}

.saved-replies-actions {
	display: inline-block;
	float: right;
}

.saved-replies-actions button {
	border-width: 0 0 0 1px !important;
	border-color: #ccc !important;
	width: 35px;
}

.saved-replies-content {
	border: 1px solid #ccc;
	border-radius: 2px;
	min-height: 150px;
	padding-top: 2px;
	padding-right: 1px;
}

.saved-replies-text {
	padding: 10px 5px;
}

.review-desk-wrapper {
	flex: 1 1 auto;
	display: flex;
	flex-wrap: nowrap;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
}

.review-desk-wrapper h4 {
	flex: 0 0 auto;
	margin-right: 30px;
}

.review-desk-wrapper .review__type_filter {
	flex: 0 auto;
	padding: 0px 4px 0px 0px !important;
	margin: 0px !important;
}

.review-desk-wrapper .review__type_filter a {
	line-height: 25px;
	padding: 0 10px;
	border: 1px solid #d4d7dd;
	height: auto;
	margin: 0px;
}

.react-autosuggest__container {
	position: relative;
	flex: 0 0 auto;
	margin-top: 8px;
	min-width: 220px;
	width: 100%;
}

.react-autosuggest__input {
	height: 35px;
	width: 100%;
	padding: 0 10px;
	line-height: 33px;
	font-size: 14px;
	border: 1px solid #a0a7b4;
}

.react-autosuggest__input--focused {
	outline: none;
}

.react-autosuggest__input--open {
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}

.react-autosuggest__container--open .react-autosuggest__suggestions-container {
	-webkit-transform: scale(1, 1);
	transform: scale(1, 1);
	opacity: 1;
}

.react-autosuggest__suggestions-container {
	position: absolute;
	top: 100%;
	width: 100%;
	color: rgba(0, 0, 0, 0.870588);
	box-shadow: rgba(0, 0, 0, 0.117647) 0px 1px 6px,
		rgba(0, 0, 0, 0.117647) 0px 1px 4px;
	border-radius: 2px;
	z-index: 2100;
	transform-origin: left top 0px;
	max-height: 200px;
	overflow-y: auto;
	overflow-x: hidden;
	background-color: rgb(255, 255, 255);
	opacity: 0.3;
	-webkit-transform-origin: top;
	transform-origin: top;
	-webkit-animation-fill-mode: forwards;
	animation-fill-mode: forwards;
	-webkit-transform: scale(1, 0);
	transition: all 0.2s linear;
	-webkit-transition: all 0.2s linear;
	border: 1px solid #fff;
	background: #fff;
}

.react-autosuggest__suggestions-list {
	margin: 0;
	padding: 0;
	list-style-type: none;
}

.react-autosuggest__suggestion {
	cursor: pointer;
	padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
	background-color: #ddd;
}

.react-autosuggest__section-container {
	border-top: 1px dashed #ccc;
}

.react-autosuggest__section-container--first {
	border-top: 0;
}

.react-autosuggest__suggestions-list {
	padding-bottom: 10px;
}

.react-autosuggest__section-title {
	padding: 6px 0 0 10px;
	font-size: 14px;
	color: #777;
	text-transform: capitalize;
}

.reply-saved-field {
	display: block;
	padding: 0px;
}

.reply-saved-group,
.reply-saved-subject {
	padding: 6px 8px;
	border-bottom: 1px solid #e9eef2;
}

.reply-saved-group {
	border-top: 1px solid #e9eef2;
}

.reply-saved-group input,
.reply-saved-subject input,
.reply-saved-content textarea {
	border: 0px;
	background-color: transparent;
	width: 100%;
	box-sizing: border-box;
}

.reply-saved-content textarea {
	min-height: 100px;
}

.reply-saved-group input:focus,
.reply-saved-subject input:focus,
.reply-saved-content textarea:focus {
	border: 0px;
	outline: 0px;
}

.reply-saved-content {
	padding: 6px 8px;
}

.react-autosuggest__suggestion {
	padding: 0px 10px 0 20px;
	line-height: 26px;
	text-transform: capitalize;
}

.sorter {
	flex: 0 0 auto;
	padding-top: 3px;
	margin-right: 8px;
}

.tag-wrap {
	padding: 8px 15px;
	background-color: #fcfcfc;
}

.canned-responses-header {
	/* margin-bottom: 10px; */
}
.canned-responses-filters{
		margin-bottom: 10px;
}
.canned-responses-filters input{
	border-radius: 8px !important;
}
.canned-responses-pagination {
	text-align: right;
	margin-top: 10px;
}

.saved-response-subject {
	font-weight: 600;
	display: flex;
	justify-content: space-between;
	margin-right: 10px;

}
.saved-response-subject .head{
	font-weight: 600;
	color: var( --drk-text);
	min-width: 80%;
	max-width: 80%;
	font-size: 17px;
	display: flex;
	gap: 10px;
	align-items: center;
	svg{
		width: 12px;
		height: 12px;
		fill: var(--med-lgt-text);
	}
}
.saved-response-subject .group	{
	font-weight: 400;
	color: var(--lgt-text);
	padding-right: 5px;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	width: 20%;
	margin-right: 30px;
}
.saved-response-subject .group-rating{
	width: 100%;
	display: flex;
	gap: 4px;
	margin-top: 5px;
	border-radius: 3px;
	justify-content: flex-end;
}
.saved-response-subject .star-section{
	display: flex;
	gap: 4px;
}
.saved-response-subject .group-rating .star-1{
	display: flex;
	width: 20px;
	height: 8px;
	background-color: var(--star-rating-1-color);
	border-radius: 3px;

}
.saved-response-subject .group-rating .star-2{
	display: flex;
	width: 20px;
	height: 8px;
	background-color: var(--star-rating-2-color);
	border-radius: 3px;

}
.saved-response-subject .group-rating .star-3{
	display: flex;
	width: 20px;
	height: 8px;
	background-color: var(--star-rating-3-color);
	border-radius: 3px;

}
.saved-response-subject .group-rating .star-4{
	display: flex;
	width: 20px;
	height: 8px;
	background-color: var(--star-rating-4-color);
	border-radius: 3px;

}
.saved-response-subject .group-rating .star-5{
	display: flex;
	width: 20px;
	height: 8px;
	background-color: var(--star-rating-5-color);
	border-radius: 3px;

}
.saved-response-text {
	margin: 0px 10px 0px 0px;
	color: var(--med-drk-text);
	width: 85%;
	font-size: 15px;
}

.saved-response-group {
	font-weight: 600;
}

.excel-download,
.excel-download-spinner {
	float: right;
	margin: -10px 0px;
	display: inline-block;
	height: 35px;
	width: 29px;
}

.excel-download {
	background: url('../images/downlod-excel.png') no-repeat;
	cursor: pointer;
}

.excel-download-all {
	background: url('../images/downlod-excel-locations.png') no-repeat;
	cursor: pointer;
	float: right;
	margin: -10px 0px;
	display: inline-block;
	height: 35px;
	width: 29px;
}

.print-header {
	display: flex;
	flex-wrap: nowrap;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	padding: 14px 20px;
	background: #fff;
	margin: 0;
}

.print-logo {
	display: flex;
	flex-wrap: nowrap;
	flex-direction: column;
	justify-content: center;
	overflow: hidden;
	width: 250px;
	height: 80px;
}

.print-logo img {
	display: inline-block;
	vertical-align: middle;
	max-width: 100%;
	max-height: 100%;
}

.pie-chart {
	width: 100%;
	height: 100%;
}

.review-icon-google {
	flex: 0 0 auto;
	margin-left: auto;
}

.review-icon-google span {
	display: block;
	width: 20px;
	height: 20px;
	margin-right: 0 !important;
	border-radius: 50%;
	overflow: hidden;
}

.review-icon-google span img {
	border-radius: 50%;
}

.organization-table {
	padding: 14px 14px 14px 14px;
}

.tripadvisor-email-listing {
	background-color: white;
}

.custom-filter-dropdown input {
	width: 130px;
	margin-right: 8px;
}

.review-chat {
	margin: 0;
}

.schedule-post .review-chat__item {
	border-width: 0 0 1px 0;
	-webkit-border-radius: 0px;
	border-radius: 0px;
}
.review-chat__item .chat-header {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-content: center;
}

.review-chat__item .chat-header .header-content {
	flex: 0 0 auto;
	width: calc(100% - 30px);
	display: flex;
}

.review-chat__item .chat-header .header-content h5 {
	font-size: 14px;
	margin-bottom: 4px;
}

.review-chat__item .chat-header .header-content .chat-date {
	font-size: 12px;
	color: #9ba0a9;
	margin-left: 1rem;
	min-width: 100px;
	max-width: 500px;
}
.review-chat__item .chat-header .chat-filter {
	flex: 0 0 auto;
	width: 25px;
}

.review-chat__item .chat-header .chat-filter .ant-dropdown-trigger {
	width: 25px;
	height: 25px;
	text-align: center;
	line-height: 20px;
	padding: 0;
	margin: 0;
	border: 0;
}

.review-chat__item .chat-header .chat-filter .ant-dropdown-trigger span {
	display: inline-block;
	margin: 0;
	height: 3px;
	position: relative;
	width: 3px;
	background-color: #9399a2;
	-webkit-border-radius: 50%;
	border-radius: 50%;
}

.review-chat__item .chat-header .chat-filter .ant-dropdown-trigger span:after,
.review-chat__item .chat-header .chat-filter .ant-dropdown-trigger span:before {
	position: absolute;
	content: '';
	height: 3px;
	width: 3px;
	padding: 0;
	border: 0;
	left: 0;
	background-color: #9399a2;
	-webkit-border-radius: 50%;
	border-radius: 50%;
}

.review-chat__item .chat-header .chat-filter .ant-dropdown-trigger span:after {
	top: -6px;
}

.review-chat__item .chat-header .chat-filter .ant-dropdown-trigger span:before {
	bottom: -6px;
}

.review-chat__item .chat-tag {
	display: block;
	color: #b4b4b4;
	padding-left: 55px;
}

.review-chat__item .note-text {
	font-size: 14px;
	padding: 2px 45px 6px 55px;
	word-break: break-all;
}

.list-internal-note-item {
	display: block;
}

.list-internal-note-item h5 {
	font-size: 14px;
}

.list-internal-note-item span {
	font-size: 12px;
	color: #9ba0a9;
}

.list-internal-note-text {
	margin-top: 10px;
	font-size: 14px;
}

.list-internal-note-tags {
	display: block;
	padding: 6px 0;
	font-size: 14px;
}

.scroll-height {
	height: calc(100vh - 133px) !important;
}

.source-logo {
	flex: 0 0 auto;
	border: #d9d9d9 solid 1px !important;
	width: 35px;
	height: 35px;
	text-align: center;
	line-height: 22px;
}

.source-logo i {
	font-size: 18px;
}

.source-logo {
	padding: 6px;
	line-height: 20px;
}
.add-group {
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	text-align: left;
}

.add-group .ant-radio-group {
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
}

.add-group .ant-radio-group label {
	margin: 15px 0;
}

.schedule-block,
.schedule-once {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: center;
}

.schedule-block .ant-radio-group {
	margin-left: 15px;
}

.schedule-block .ant-radio-group label {
	margin: 0;
}

.schedule-once span {
	margin-right: 15px;
}

.schedule-once span:last-child {
	margin: 0;
}

.recuring-wrap {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-items: center;
	margin-top: 20px;
}

.recuring__item {
	flex: 0 0 auto;
	margin-right: 10px;
	margin-bottom: 10px;
}

.time-zone {
	display: flex;
	align-items: center;
	margin-top: 0px;
	margin-bottom: 10px;
	margin-right: 10px;
}
.time-zone span {
	margin-right: 13px;
}

.time-zone .ant-time-picker {
	margin: 0;
	min-width: 120px;
}

.recuring__item span {
	margin-right: 10px;
}

.recuring__item .ant-time-picker {
	margin: 0;
	min-width: 120px;
}

.google-title {
	margin-bottom: 8px;
}

.google-title h4 {
	font-size: 15px;
	font-weight: 700;
	text-transform: capitalize;
}

.google-title p {
	font-size: 12px;
	margin-top: 1px;
}

.google-summery {
	font-size: 14px;
}

.google-time {
	padding: 5px 0;
}

.google-post__btn {
	flex: 0 0 auto;
}

.google-post .google-post__btn .ant-btn {
	border: 0;
}

.optional-button {
	display: block;
	text-align: left !important;
}

.add-hours .add-hours__inner {
	flex: 1 auto;
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
}

.add-hours__inner .add-hours__item {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: center;
	margin-bottom: 8px;
}

.add-hours__inner .add-hours__item:last-child {
	margin-bottom: 0;
}

.add-hours__inner .add-hours__item .ant-time-picker {
	margin: 0 4px;
}

.add-hours .add-hours__btn {
	flex: 0 auto;
	align-self: flex-end;
	margin: 0 0 0 15px;
	padding-bottom: 5px;
	font-size: 14px;
	font-weight: 600;
	/* text-transform: uppercase; */
}

.add-wrapper {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 6px;
}

.add-wrapper .Select {
	flex: 1 auto;
	width: 100%;
}

.add-wrapper .close-icon {
	flex: 0 auto;
	margin-left: 10px;
	width: 36px;
	height: 36px;
	font-size: 16px;
	border: 1px solid #a0a7b4;
	color: #ccc;
	line-height: 16px;
	cursor: pointer;
	text-align: center;
	line-height: 36px;
}

.add-wrapper .close-icon:hover {
	color: #aaaaaa;
	border-color: #aaaaaa;
}
.no-insight {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: center;
	min-height: 100%;
}

.no-insight span {
	font-size: 14px;
	color: #ddd;
}

.atr-wrap {
	display: flex;
	flex-wrap: nowrap;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
}

.atr-item {
	background-color: #fff !important;
	border: 1px solid #dadce0;
	-webkit-border-radius: 32px;
	border-radius: 32px;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	color: #3c4043;
	cursor: pointer;
	display: inline-block;
	outline: none;
	padding: 6px 12px;
	-webkit-user-select: none;
	margin: 4px 4px 4px 0;
}

.atr-item span {
	display: inline-block;
}

.atr-item span:first-child {
	font-size: 16px;
	margin-right: 6px;
}

.atr-item.active {
	background-color: #e8f0fe;
	border-color: #d2e3fc;
	color: #1967d2;
}

.atr-item.banned {
	background-color: #dadce0;
	border-color: #9aa0a6;
	color: #3c4043;
}

.radio-wrap,
.repeted-enum {
	flex: 0 auto;
	background-color: #e8eaed;
	-webkit-border-radius: 16px;
	border-radius: 16px;
	border-top-left-radius: 0;
	padding: 4px;
	margin: 4px 0;
}

.radio-wrap .radio-label {
	display: block;
}

.enum-label {
	display: block;
}

.google__attributs {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: flex-start;
	padding: 16px 0;
}

.google__attributs .icon {
	flex: 0 auto;
	font-size: 26px;
	color: #108ee9;
	margin-right: 8px;
}

.google__attributs .item-label {
	flex: 0 auto;
	font-size: 14px;
	font-weight: 600;
	padding-right: 10px;
	position: relative;
	line-height: 26px;
}

.google__attributs .item-label:after {
	position: absolute;
	content: ':';
	right: 0;
	top: 0;
}

.google__attributs .working-hour {
	flex: 1 auto;
	margin-left: 15px;
	padding-top: 6px;
}

.google__attributs .edit-btn {
	flex: 0 auto;
	font-size: 22px;
	cursor: pointer;
}

.enum-availability {
	display: block;
	margin: 0;
	padding-left: 20px;
}

.availability-item {
	color: #3c4043;
	cursor: pointer;
	display: inline-block;
	outline: none;
	padding: 6px 0;
	-webkit-user-select: none;
}

.availability-item i {
	display: inline-block;
	font-size: 16px;
	margin-right: 8px;
}

.atr-display {
	display: block;
	padding: 3px 0;
}

.manage-location-control {
	flex: 0 auto;
	margin-left: auto;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	align-items: center;
	justify-content: flex-start;
}

.manage-location-control .ant-dropdown-link {
	line-height: 36px;
	height: auto;
	margin-left: 8px;
}

.review-filter-block {
	flex: 0 auto;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	align-items: center;
	justify-content: flex-start;
}

.location-search-wrap {
	display: flex !important;
	flex-direction: row !important;
	flex-wrap: nowrap !important;
	align-items: center !important;
	justify-content: flex-start !important;
	border: 1px solid #a0a7b4;
	padding: 0px 10px !important;
}

.location-search-wrap .ant-dropdown-link span {
	padding: 0 15px;
}

.review-filter-block .ant-dropdown-link {
	color: #108ee9;
}

.location_csv {
	flex: 0 auto;
	margin-left: 8px;
}

.entity_inputs {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	align-items: center;
	justify-content: flex-start;
	padding: 10px 0 20px 0;
}

.entity_inputarea {
	flex: 0 auto;
	margin-right: 10px;
}

.entity_inputarea:first-child {
	flex: 1 auto;
}

.entity_inputarea:last-child {
	margin-right: 0;
}

.showtags-wrap {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-items: center;
	justify-content: flex-start;
	margin-left: 30px;
	max-width: 30%;
}

.show-tags {
	flex: 0 auto;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
	border: #ebebeb solid 1px;
	background: #ebebeb;
	padding: 0;
	height: 100%;
	line-height: 20px;
	margin-right: 3px;
	margin-bottom: 3px;
	padding: 0 20px 0 3px;
	position: relative;
	word-break: break-all;
}

.show-tags span {
	flex: 0 0 auto;
	font-size: 11px;
	display: flex;
	flex-wrap: wrap;
	margin-right: 3px;
	word-break: break-all;
	max-width: 99%;
}

.show-tags span:last-child {
	margin-right: 0;
}

.show-tags span:nth-child(3) {
	text-overflow: ellipsis;
	overflow: hidden;
}

.show-tags .tag-close {
	padding: 0;
	width: 12px;
	height: 12px;
	text-align: center;
	position: absolute;
	right: 5px;
	top: 2px;
	cursor: pointer;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	align-items: center;
	justify-content: center;
}

.show-tags .tag-close i {
	color: #afafaf;
	font-size: 11px;
	-webkit-transition: all 100ms ease-out;
	-moz-transition: all 100ms ease-out;
	-o-transition: all 100ms ease-out;
	transition: all 100ms ease-out;
}

.show-tags .tag-close:hover i {
	color: #4f4f4f;
	-webkit-transition: all 100ms ease-out;
	-moz-transition: all 100ms ease-out;
	-o-transition: all 100ms ease-out;
	transition: all 100ms ease-out;
}

.show-tags span:nth-child(2) {
	padding: 0;
}

.placepage-atribute {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	align-items: center;
	justify-content: flex-start;
	padding: 4px 0;
}

.placepage-atribute span {
	flex: 0 auto;
	margin-right: 10px;
}

.placepage-atribute span:first-child {
	min-width: 20%;
}

.placepage-atribute span input {
	color: #555;
	border: 1px solid #a0a7b4;
	padding: 0px 10px;
	line-height: 26px;
	min-width: 400px;
}

.placepage-atribute span input:focus {
	box-shadow: none;
	outline: 0px;
}

.terms-policy {
	display: block;
	padding: 30px 5%;
}

.terms-policy h2 {
	font-size: 20px;
	margin-bottom: 15px;
}

.terms-policy h3 {
	font-size: 18px;
	margin-bottom: 15px;
}

.terms-policy h4 {
	font-size: 16px;
	margin-bottom: 15px;
}

.terms-policy p {
	font-size: 14px;
	margin-bottom: 15px;
	line-height: 22px;
}

.image-uploader {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	flex-wrap: nowrap;
}

.image-uploader .existing-logo {
	flex: 0 auto;
	margin-right: 30px;
}

.image-uploader .existing-logo h3 {
	font-size: 16px;
	margin-bottom: 20px;
}

.image-uploader .existing-logo span {
	width: 300px;
	display: block;
	overflow: hidden;
}

.image-uploader .existing-logo span img {
	max-width: 100%;
}

.image-uploader .upload-inner {
	flex: 0 auto;
	text-align: left;
	margin-top: 30px;
}

.image-uploader .upload-inner h3 {
	font-size: 16px;
	margin-bottom: 10px;
}

.image-uploader .upload-inner h4 {
	font-size: 14px;
	display: inline-block;
	padding: 8px 15px;
	border: #f2f2f2 solid 1px;
	border-radius: 4px;
}

.image-uploader .upload-inner h4 i {
	display: inline-block;
	margin-left: 8px;
	font-size: 16px;
	cursor: pointer;
}

.image-uploader .upload-inner p {
	font-size: 14px;
}

.planpreview_edit {
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
}

.planpreview_edit .edit-item {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	flex-wrap: nowrap;
	margin-bottom: 20px;
}

.planpreview_edit .edit-item label {
	flex: 0 auto;
	min-width: 100px;
}

.planpreview_edit .edit-btn {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	flex-wrap: nowrap;
}

.planpreview_edit .edit-btn .ant-btn {
	margin: 0 8px;
}

.resync_info {
	display: block;
	text-align: center;
}

.resync_info p {
	text-align: left;
	font-weight: 700;
}

.resync_info .resync_info-control {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	margin-top: 20px;
}

.resync_info .resync_info-control .ant-checkbox-wrapper {
	margin: 0;
}

.resync_info .resync_info-control .ant-checkbox input {
	margin: 0;
}

.resync_info .resync_info-message {
	display: block;
	margin-top: 10px;
	text-align: left;
}

.text_centre {
	text-align: center;
}

.change-profile-model {
	display: block;
	margin-bottom: 6px;
}

.change-profile-model input {
	width: 100%;
}

.location-verification {
	display: block;
}

.location-verification h3 {
	margin-bottom: 10px;
}

.location-verification p {
	font-size: 14px;
	color: #70757a;
}

.location-verification ul {
	display: block;
}

.location-verification li {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;
	flex-wrap: nowrap;
	padding: 20px 0;
	border-bottom: solid #f2f2f2 1px;
}

.location-verification li:last-child {
	margin-bottom: 0;
}

.location-verification .verification-icon {
	flex: 0 auto;
	margin-right: 15px;
}

.location-verification .verification-icon i {
	font-size: 24px;
}

.location-verification .verification-content {
	flex: 1 auto;
}

.location-verification .verification-content h3 {
	font-size: 18px;
	margin-bottom: 10px;
}

.location-verification .verification-content p {
	font-size: 14px;
	margin-bottom: 8px;
	color: #70757a;
}

.location-verification .verification-content .warning {
	color: red;
	font-size: 12px;
}

.location-verification .verification-content h4 {
	font-size: 16px;
	margin: 30px 0 10px 0;
}

.verification-content .verification-text {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
	flex-wrap: nowrap;
}

.verification-content .verification-text p {
	flex: 1 auto;
}

.verification-content .verification-btn {
	flex: 0 auto;
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
	flex-wrap: nowrap;
	margin-top: 10px;
}

.verification-content .verification-btn .ant-btn-primary {
	margin-left: 10px;
	padding: 2px 20px;
}

.verification-content .verification-btn .ant-btn-primary:first-child {
	margin-left: 0;
}

.otp-verification {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;
	flex-wrap: nowrap;
	margin-top: 10px;
	width: 100%;
}

.otp-verification .otp-verification-icon {
	flex: 0 auto;
	padding: 6px 0;
	margin-right: 10px;
}

.otp-verification .otp-verification-icon i {
	background-color: #06a7e1;
	width: 30px;
	height: 30px;
	color: #fff !important;
	font-size: 16px;
	text-align: center;
	line-height: 30px;
	-webkit-border-radius: 50%;
	border-radius: 50%;
}

.otp-verification .otp-verification-content {
	flex: 1 auto;
}

.otp-verification .otp-verification-content .verification-btn {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	flex-wrap: nowrap;
	margin-bottom: 8px;
}

.otp-verification .otp-verification-content .verification-btn .ant-btn {
	padding: 1px 15px;
	margin-left: 15px;
}

.otp-verification .otp-verification-content .change-methode {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	flex-wrap: nowrap;
}

.otp-verification .otp-verification-content .change-methode a {
	margin-right: 15px;
}

.google-status {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: center;
}

.google-status .status-item {
	width: 20px;
	height: 20px;
	flex: 0 auto;
	margin-right: 10px;
	line-height: 20px;
}

.google-status .status-item:last-child {
	margin-right: 0px;
}

.google-status .status-item i {
	line-height: 20px;
}

.iframe_info {
	min-height: calc(100vh - 70px);
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: stretch;
}
.authorize-iframe_info{
	display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;
    top: 20px;
    height: 100%;
}

.google_flags i {
	color: #1909f7;
	font-size: 20px;
}

.scroll-inbox-height {
	height: calc(100vh - 200px) !important;
	width: 100%;
}

.google-entity-wrap {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: flex-end;
	align-items: center;
}

.google-entity-wrap .google_entity {
	flex: 0 auto;
	padding: 0 20px;
	height: 26px;
	-webkit-border-radius: 30px;
	border-radius: 30px;
	background-color: #f99d1b;
	/* background-color: #067ae2; */
	margin-right: 15px;
	color: #fff !important;
	line-height: 24px;
}

.google-entity-wrap .entity-selector {
	width: 30px;
	height: 26px;
	text-align: center;
	line-height: 24px;
	cursor: pointer;
}

.add_contactbtn {
	position: absolute;
	top: 8px;
	right: 10px;
	margin: 0;
	padding: 0;
}

.review__type {
	color: rgba(0, 0, 0, 0.788) !important;
	background-color: rgba(255, 255, 255, 0.836) !important;
	padding-left: 15px !important;
}

.location-text {
	display: block;
	padding: 10px 15px;
}

.locationnote_display {
	margin: 15px  0 0px 0;
	background-color: #fff;
	padding: 20px;
	border-radius: 10px;
	word-break: break-all;
}

.contact-info {
	display: block;
}

.contact-info li {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: center;
}

.contact-info li a {
	margin-left: 10px;
}

.contact-info li span label {
	margin: 0;
	padding: 0;
}

.contactlist-wrapper {
	position: relative;
	margin-left: 10px;
}

.contactlist-wrapper a {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: center;
	margin: 0 !important;
}

.contactlist-wrapper a .anticon {
	font-size: 8px !important;
	margin-left: 3px;
}

.contactlist-wrapper .contactlist-info {
	background-color: #fff !important;
	position: relative;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	cursor: auto;
	max-height: 280px;
	min-width: 390px;
	position: absolute;
	bottom: 100%;
	margin-bottom: 12px;
	margin-right: -10px;
	right: 0;
	z-index: 10;
	-webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
	-webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
	border: 1px solid #ccc;
	border: 1px solid rgba(0, 0, 0, 0.2);
	padding: 10px;
}

.contactlist-wrapper .contactlist-info:after,
.contactlist-wrapper .contactlist-info:before {
	position: absolute;
	content: '';
	z-index: 9;
	bottom: -11px;
	top: 100%;
	border: solid transparent;
	content: ' ';
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
}

.contactlist-wrapper .contactlist-info:after {
	border-top-color: #fff !important;
	border-width: 11px;
	margin-left: -11px;
	right: 10px;
}

.contactlist-wrapper .contactlist-info:before {
	border-top-color: #ddd;
	border-width: 14px;
	margin-left: -14px;
	right: 8px;
}

.mail-controls {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: center;
	padding: 8px 10px;
	margin: 0px 1px;
	background: #f5f5f5;
	border-bottom: 1px solid #e9eef2;
}

.emoji-padding {
	margin-left: auto;
	cursor: pointer;
	padding-left: 25px;
}


.mail-controls .checkbox-block.inactive .ant-checkbox .ant-checkbox-input {
	cursor: not-allowed;
}

.mail-controls .checkbox-block.inactive .ant-checkbox .ant-checkbox-inner {
	color: rgba(0, 0, 0, 0.25);
	background-color: #f5f5f5;
	border-color: #d9d9d9;
	text-shadow: none;
	box-shadow: none;
}

.contact-list {
	display: block;
}

.contact-list li {
	margin-bottom: 4px;
	padding: 4px;
	background-color: #f2f2f2;
}

.contact-list li:last-child {
	margin-bottom: 0;
}

.contact-list li .contact-inner {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: center;
}

.contact-list li .contact-inner span:first-child {
	color: #a9a9a9;
	min-width: 45px;
	max-width: 45px;
	padding-right: 4px;
	margin-right: 4px;
	position: relative;
	white-space: nowrap;
}

.contact-list li .contact-inner span:first-child:after {
	content: ':';
	margin: 0;
	position: absolute;
	right: 0;
	top: 0;
}

.contact-list li .contact-inner span:last-child {
	color: #202020;
	word-break: break-all;
}

/* -- claim-locaton --*/

.claim-location-search {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: center;
}

.claim-search-wrap {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: center;
	margin-left: 10px;
	min-width: 30%;
	padding-right: 10px;
}

.claim-search-wrap span {
	flex: 0 auto;
	margin-right: 10px;
}

.claim-search-wrap .Select {
	min-width: 250px;
}

.claim-search-body {
	display: block;
	padding: 20px 0;
}

.donut-printchart {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	padding: 10px;
}

.donut-printchart .pie-chart {
	flex: 0 auto;
	height: 160px;
	width: 140px !important;
}

.donut-printchart .pie-labels {
	flex: 0 auto;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;
	flex-wrap: wrap;
	margin: -5px;
	max-width: 260px;
	padding-left: 5px;
}

.donut-printchart .pie-labels li {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	flex-wrap: nowrap;
	margin-bottom: 8px;
	word-break: break-all;
	margin: 5px;
}

.donut-printchart .pie-labels li:last-child {
	margin-bottom: 0px;
}

.donut-printchart .pie-labels li span {
	flex: 0 auto;
	margin-right: 4px;
}

.donut-printchart .pie-labels li span:first-child {
	width: 15px;
	height: 15px;
}

.donut-printchart .pie-labels li span:nth-child(2) {
	color: #06a7e1 !important;
}

.donut-printchart .pie-labels li span:nth-child(3) {
	color: #555;
	font-weight: bold;
	text-transform: capitalize;
}

.donut-printchart .pie-labels li span:last-child {
	margin-right: 0;
	position: relative;
}

.donut-printchart .pie-labels li span:last-child:after {
	content: ')';
}

.donut-printchart .pie-labels li span:last-child:before {
	content: '(';
}
.extra_padding {
	padding-left: 10px;
}

.location-count {
	display: block;
	width: 100%;
}

.location-count ul {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: stretch;
}

.location-count li {
	flex: 1 auto;
	max-width: 33.333%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.location-count li span {
	font-size: 16px;
	font-weight: 400;
	margin-top: 12px;
}

.location-count li:first-child span {
	color: #42b773 !important;
}

.location-count li:nth-child(2) span {
	color: #ec5a29 !important;
}

.location-count li:last-child span {
	color: #2f9cd5 !important;
}

.local-product {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
}

.local-product li {
	width: 50%;
	align-items: stretch;
}

.local-product li:first-child {
	padding-right: 20px;
}

.local-product li {
	align-items: stretch;
}

.local-product li:nth-child(1) {
	padding-right: 10px;
	width: 40%;
}

.local-product li:nth-child(2) {
	padding-right: 10px;
	width: 40%;
}

.local-product li:nth-child(3) {
	min-width: 20%;
}

.log-listing {
	display: flex;
	flex-direction: row;
}

.log-listing label {
	padding-top: 10px;
	font-size: 14px;
	font-weight: 500;
}

.loglist .date {
	font-weight: 700;
}

.loglist .time {
	font-weight: 500;
	color: #a99ca0;
}

.color-dot {
	height: 10px;
	width: 10px;
	border-radius: 50%;
	display: inline-block;
	/* background-color: #009EE5; */
}

.LIVE {
	background-color: rgb(4, 162, 235);
}

.REJECTED {
	background-color: rgb(228, 15, 43);
}

.SCHEDULED {
	background-color: rgb(2, 206, 114);
}

.DELETED {
	background-color: #ef6023;
}

.EXPIRED {
	background-color: #f99d1b;
}

.PROCESSING {
	background-color: #d3d3d3;
}

.antd-spin {
	text-align: center;
	background: rgb(253, 253, 253);
	border-radius: 4px;
	margin-bottom: 20px;
	padding: 350px 50px;
	margin: 0px 0;
}

.map-marker {
	position: relative;
	max-width: 79%;
	min-width: 850px;
	margin-left: auto;
}

.pull-right {
	float: right;
	margin-left: auto;
}

.add-newrole {
	top: 10px;
}

.reviewshake-wrapper textarea {
	width: 100%;
	height: 300px !important;
}
.edit-reply {
	display: block;
	padding: 10px 4px;
}
.edit-reply textarea {
	border: 1px solid #ccc;
	width: 100%;
	height: 85px;
}
.edit-reply textarea:focus {
	border: 1px solid #ccc;
	outline: 0px;
}
.reply-thread__head {
	margin-bottom: 10px;
	min-height: 25px;
}
.draftlist-item {
	display: flex;
	flex-direction: row;
	flex-flow: row;
	justify-content: flex-start;
	align-items: center;
	margin: 0;
}

.draftlist-item span {
	flex: 0 0 auto;
	margin-right: 10px;
}

.draftlist-item span:first-child {
	font-size: 14px;
}

.draftlist-item span:last-child {
	margin-right: 0px;
}
