@import './light';

$color-background: var(--admin-nav-background);
$nav-border-color: var(--admin-nav-border-color);
$nav-font-color: var(--admin-nav-font-color);
$nav-active-color:var(--admin-nav-active-color);
$sub-content-background:var(--page-content-background);
$page-content-background:var(--page-content-background);
$right-header-background:var(--admin-header-content-background);
$button-background-primary:var(--button-background-primary);
$button-background-secondary:var(--button-background-secondary);
$button-background-primary-hover:var(--button-background-primary-hover);
$button-background-outline:var(--button-background-outline);
$button-color-primary:var(--button-color-primary);
$button-color-secondary:var(--button-color-secondary);
$ant-tab-inactive-color:var(--ant-tab-inactive-color);
$ant-tab-active-color:var(--ant-tab-active-color);
$page-content-h3-color: var(--page-content-h3-color);
$page-content-h5-color:var(--page-content-h5-color);
$page-content-h4-color:var(--page-content-h4-color);
$primary-active-color:var(--primary-active-color);
$primary-inactive-color:var(--primary-inactive-color);
$primary-warning-color:var(--primary-warning-color);
$dark-text-color:var(--dark-text-color);
$sm-card-h5-color:var(--sm-card-h5-color);
$overlay-background-color:var(--overlay-background-color);
$profile-tag-color: var(--profile-tag-color);
$profile-tag-background-color:var(--profile-tag-background-color);
$navHoverBackground:var(--nav--hover-background);
$nav-admin-section-highlight: var(--nav-admin-section-highlight);
$nav-admin-section-hover:var(--nav-admin-section-hover);
$black-text:var(--black-text);
$drk-text:var(--drk-text); 
$med-drk-text:var(--med-drk-text);
$med-text: var(--med-text);
$med-lgt-text:var(--med-lgt-text);
$lgt-text: var(--lgt-text);
$warning-text:var(--warning-text);
$accent-text-color:var(--accent-text-color);
$nav-admin-section-header: var(--nav-admin-section-header);
$right-nav-logout-color:var(--right-nav-logout-color);
$dropdown-background-color:var(--dropdown-background-color);
$dropdown-border-color:var(--dropdown-border-color);
$dropdown-background-hover-color:var(--dropdown-background-hover-color);
$page-header-svg-color:var(--page-header-svg-color);
$page-header-svg-hover:var(--page-header-svg-hover);
$page-header-svg-background:var(--page-header-svg-background);
$paid-color-green:var(--paid-color-green);
$payment-due-color-orange:var(--payment-due-color-orange);
$not-paid-color-red:var(--not-paid-color-red);
$voided-color-grey:var(--voided-color-grey);
$pending-color-lgtblue:var(--pending-color-lgtblue);
$posted-color-lgtblue:var(--posted-color-lgtblue);
$table-header-color:var(--table-header-color);
$table-row-seperator:var(--table-row-seperator);
$star-rating-1-color:var(--star-rating-1-color);
$star-rating-2-color:var(--star-rating-2-color);
$star-rating-3-color:var(--star-rating-3-color);
$star-rating-4-color:var(--star-rating-4-color);
$star-rating-5-color:var(--star-rating-5-color);
$disabled-button-color: var(--disabled-button-color);
$admin-nav-logo-color:var(--admin-nav-logo-color);
$detached-toogle-button-color:var(--detached-toogle-button-color);
$filter-card-icon-color: var(--filter-card-icon-color);
$filter-location-card-background: var(--filter-location-card-background);
$walmart-audit-active-card-background: var(--walmart-audit-active-card-background);
$filter-listing-card-background: var(--filter-listing-card-background);
$background-white-color: var(--background-white-color);
$white-text: var(--white-text);
$timeline-dot-background-color: var(--timeline-dot-background);
$timeline-icon-color: var(--timeline-icon-color);
$custom-popover-background: var(--custom-popover-background);
$comment-filter-background:var(--comment-filter-background);
$account-filter-background:var(--account-filter-background);
$response-rate-background:var(--response-rate-background);
$campaign-period-color:var(--campaign-period-color);
$default-background-color: var(--default-background-color);
$svg-icon-color:var(--svg-icon-color);
$button-inactive-state-color: var(--button-inactive-state-color);
$button-active-state-color:var(--button-active-state-color);
$group-background-color: var(--group-background-color);
$location-background-color: var(--location-background-color);
$error-background-color: var(--error-background-color);
$error-field-color: var(--error-field-color);
$error-border-color: var(--error-border-color);
$background-drk-black:var(--background-drk-black);
$background-white-color:var(--background-white-color);
$background-med-black:var(--background-med-black);
$border-med-black:var(--border-med-black);
$placeholder-lgt-black:var(--placeholder-lgt-black);
$button-background-lgt-black:var(--button-background-lgt-black);

$dash-neg-background:var(--dash-neg-background);
$dash-pos-background: var(--dash-pos-background);
$dash-neutral-background:var(--dash-neutral-background);
$dash-neg-color: var(--dash-neg-color);
$dash-pos-color:var(--dash-pos-color);
$dash-neutral-color: var(--dash-neutral-color);

$dash-avg-text-color: var(--dash-avg-text-color);
$dash-info-text-color: var(--dash-info-text-color);

$dash-metric-text-color: var(--dash-metric-text-color);

$dash-card-box-shadow: var(--dash-card-box-shadow);
$dash-card-border-color: var(--dash-card-border-color)