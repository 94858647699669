@import url(https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,300;1,400;1,500;1,700;1,900&display=swap);
/*---------Color-----------*/
/*--  font size  --*/
/*--  Group color  --*/
/*=====
alpha
========*/
/*=====
linear-gradien
========*/
/*====
transition
======*/
/*====
transform
======*/
/*----
placeholder
-----*/
/*----
flex
-----*/
/*-=====
opasity
=======*/
/*-=====
appearance
=======*/
/*-=====
filter
=======*/
/*-=====
react select
=======*/
/*-=====
mediaquery
=======*/
/*-=====
Animation
=======*/
/*=====
alpha
========*/
/*=====
linear-gradien
========*/
/*====
transition
======*/
/*====
transform
======*/
/*----
placeholder
-----*/
/*----
flex
-----*/
/*-=====
opasity
=======*/
/*-=====
appearance
=======*/
/*-=====
filter
=======*/
/*-=====
react select
=======*/
/*-=====
mediaquery
=======*/
/*-=====
Animation
=======*/
.flex-column, .simplebar-wrapper {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column; }

.inline-flex {
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: horizontal;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row; }

.flexbox {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: horizontal;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row; }

.flex-reverse {
  -webkit-box-direction: reverse;
  -webkit-box-orient: horizontal;
  -webkit-flex-direction: row-reverse;
  -moz-flex-direction: row-reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse; }

.column-reverse {
  -webkit-box-direction: reverse;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column-reverse;
  -moz-flex-direction: column-reverse;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse; }

.all-center {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center; }

.justify-center {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center; }

.justify-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between; }

.justify-around {
  -ms-flex-pack: distribute;
  -webkit-justify-content: space-around;
  -moz-justify-content: space-around;
  justify-content: space-around; }

.justify-start {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  justify-content: flex-start; }

.justify-end {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  -webkit-justify-content: flex-end;
  -moz-justify-content: flex-end;
  justify-content: flex-end; }

.item-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center; }

.item-start {
  -webkit-box-align: start;
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  -moz-align-items: flex-start;
  align-items: flex-start; }

.item-end {
  -webkit-box-align: end;
  -ms-flex-align: end;
  -webkit-align-items: flex-end;
  -moz-align-items: flex-end;
  align-items: flex-end; }

.item-stretch {
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  -webkit-align-items: stretch;
  -moz-align-items: stretch;
  align-items: stretch; }

.item-nowrap {
  -ms-flex-wrap: none;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap; }

.item-wrap {
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap; }

.graytbl-header {
  color: #202124;
  font-size: 12px;
  font-weight: 700; }

.whitetbl-header {
  color: #3c4043 !important;
  font-size: 12px;
  font-weight: 700; }

.tblrow-color {
  color: #3c4043;
  font-size: 13px;
  font-weight: 400; }

.tblprimary-color {
  color: #3c4043;
  font-size: 13px;
  font-weight: 700; }

.simplebar-wrapper {
  margin: 0 !important;
  padding: 0;
  height: 100%; }
  .simplebar-wrapper .simplebar-content-wrapper {
    overflow-x: hidden !important;
    padding: 0 !important;
    height: 100% !important; }

.simplebar-scrollable-y {
  -moz-transition: all 2ms ease-in-out;
  -o-transition: all 2ms ease-in-out;
  -webkit-transition: all 2ms ease-in-out;
  transition: all 2ms ease-in-out; }
  .simplebar-scrollable-y .simplebar-track {
    min-width: 6px;
    max-width: 6px; }
    .simplebar-scrollable-y .simplebar-track.simplebar-vertical {
      overflow: hidden;
      min-width: 6px;
      max-width: 6px;
      width: 6px;
      opacity: 0;
      border-radius: 50px;
      -webkit-border-radius: 50px;
      -moz-border-radius: 50px;
      -moz-transition: all 2ms ease-in-out;
      -o-transition: all 2ms ease-in-out;
      -webkit-transition: all 2ms ease-in-out;
      transition: all 2ms ease-in-out; }
      .simplebar-scrollable-y .simplebar-track.simplebar-vertical .simplebar-scrollbar {
        width: 100%;
        border: 0px;
        background-color: #b1b1b1;
        border-radius: 50px;
        -webkit-border-radius: 50px;
        -moz-border-radius: 50px;
        border: 0 !important; }
        .simplebar-scrollable-y .simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
          display: none; }
    .simplebar-scrollable-y .simplebar-track.simplebar-horizontal {
      display: none !important;
      visibility: hidden !important; }
  .simplebar-scrollable-y:hover {
    -moz-transition: all 2ms ease-in-out;
    -o-transition: all 2ms ease-in-out;
    -webkit-transition: all 2ms ease-in-out;
    transition: all 2ms ease-in-out; }
    .simplebar-scrollable-y:hover .simplebar-vertical {
      opacity: 1; }
  .simplebar-scrollable-y.menu-scroller {
    max-height: calc(100vh - 125px); }
    .simplebar-scrollable-y.menu-scroller .simplebar-track {
      min-width: 2px;
      max-width: 2px; }
  .simplebar-scrollable-y.location-scrollbar {
    max-height: calc(100vh - 170px); }
  .simplebar-scrollable-y.filter-scroller {
    max-height: calc(100vh - 290px); }
  .simplebar-scrollable-y.posts-scroller {
    padding: 0 10px;
    max-height: calc(100vh - 290px); }
  .simplebar-scrollable-y.locations-scroller {
    position: relative;
    max-height: calc(100vh - 260px); }
  .simplebar-scrollable-y.info-scroller {
    flex: 1 1 auto;
    max-height: calc(100vh - 190px); }
  .simplebar-scrollable-y.desk-scroller {
    max-height: calc(100vh - 182px); }
  .simplebar-scrollable-y.reviews-scroller {
    max-height: calc(100vh - 400px); }
  .simplebar-scrollable-y.authorize-profile-page-scroller {
    height: 100vh;
    overflow-y: auto; }
  .simplebar-scrollable-y.authorize-overlay-page-scroller {
    max-height: 100vh;
    overflow-y: auto; }
  .simplebar-scrollable-y.authorize-nav-scroller {
    max-height: calc(100vh - 80px);
    overflow-y: auto; }
  .simplebar-scrollable-y.authorize-admin-page-scroller {
    max-height: calc(100vh - 110px);
    overflow-y: auto; }
  .simplebar-scrollable-y.authorize-admin-page-user-scroller {
    max-height: calc(100vh - 200px);
    overflow-y: auto; }
  .simplebar-scrollable-y.automation-scroller {
    max-height: calc(100vh - 240px); }
  .simplebar-scrollable-y.tag-scroller {
    max-height: 150px; }
  .simplebar-scrollable-y.mapping-scroller {
    min-height: 250px;
    max-height: 500px;
    width: 100%; }
  .simplebar-scrollable-y.parent-scroller {
    min-height: 30px;
    max-height: 300px; }
