.row {
	margin-right: -5px;
	margin-left: -5px;

	[class*='col-'] {
		// padding-left: 0px;
		// padding-right: 0px;
		padding: 0px 5px;
	}
}
.dropdown-menu {
	outline: 0px !important;
	border: #ced9ee solid 1px;
	@include border-radius(0);
	@include transform(translate(0, 0) !important);
	@include transition(all 0.8s ease-in-out);
	@include box-shadow(0, 3px, 8px, rgba(0, 0, 0, 0.15) !important);

	&:after,
	&:before {
		bottom: 100%;
		left: 90%;
		border: solid transparent;
		content: ' ';
		height: 0;
		width: 0;
		position: absolute;
		pointer-events: none;
	}

	&:after {
		border-bottom-color: $white-color !important;
		border-width: 9px;
		margin-left: -9px;
	}

	&:before {
		border-bottom-color: #ced9ee;
		border-width: 10px;
		margin-left: -10px;
	}
}

.container-fluid {
	padding: 0px;
}
.card, .card-campaign{
	border: 0;
	flex: 1 1 auto;
	@extend .flex-column;
	@include border-radius(3px);
	@include box-shadow(
		2px 6px 8px 3px rgba(0, 0, 0, 0.02),
		0px 0px 1px 1px rgba(0, 0, 0, 0.06)
	);
	&-header {
		padding: 15px;
		border: 0px;
		background-color: transparent;
		flex: 0 1 auto;
		@extend .flexbox;
		@extend .item-center;
		@extend .justify-between;
		h3 {
			color: $card-header;
			font-size: 18px;
			font-weight: 600;
			span {
				color: $theam-blue;
			}
		}
	}
	&-body {
		margin: 0;
		flex: 1 1 auto;
		padding: 15px;
		@extend .flex-column;
		&.to-no-padding {
			padding: 0px;
		}
	}

	&-footer {
		flex: 0 1 auto;
		padding: 15px;
		border: 0px;
		background-color: transparent;
		@extend .flex-column;
	}
}
.card-campaign{
	&-body {
		padding: 0px !important;
		background-color: #fff;
		width: 100%;
	.campaign-wrap{
		padding: 0;
		width: 100%;
		.schedule-settings{
			padding: 15px 35px;
		}
		.campaign-main{
			padding: 0;
			align-items: center;
			.ant-steps-item-wait{
			.ant-steps-item-icon{
				border: 2px solid rgba(0, 0, 0, 0.20);
				
			}
			
		}
	
			.steps-wrapper{
				display: flex;
				flex-direction: column;
				position: sticky;
				top: 0;
				width: 100%;
				background-color: #F1F5F9;
				padding: 10px 0;
				align-items: center;
				z-index: 900;
				@include box-shadow(
					2px 6px 8px 3px rgba(0, 0, 0, 0.02),
					0px 0px 1px 1px rgba(0, 0, 0, 0.06)
				);
				.ant-steps{
					width: 90%;
					.ant-steps-item, .ant-steps-item-process{
						.ant-steps-item-container{
								display: flex;
								flex-direction: column;
								align-items: center;
								.ant-steps-item-icon{
									font-weight: 600;
								}
								.ant-steps-item-content{
									.ant-steps-item-title{
									font-weight: 600;
									}
										
								}
							}
						}
					
					
					
				}
			}
				.steps-content, .steps-action{
					padding: 20px 35px;
				}
				.steps-action{
					button{
						border-radius: 14px;
						padding: 10px 20px;
						width: 100px;
					}
				}

			}
		}
	}

}

.form-control {
	font-size: 14px;
	background-color: $white-color;
	&:focus {
		border-color: $theam-blue;
		@include box-shadow(0 0 0 transparent);
	}
}
textarea.form-control {
	padding: 12px;
	height: 100px;
}
textarea.sample-control {
	padding: 12px;
	height: 125px;
}
