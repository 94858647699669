@import "../../sass/themes/index.scss";

// MetricCard.module.scss

.metricCard {
	background: $page-content-background;
	border-radius: 8px;
	padding: 12px;
	box-shadow: $dash-card-box-shadow;
	border: 1px solid $dash-card-border-color;
	margin: 4px;
	align-items: flex-start;
	display: flex;
	flex-direction: column;
	gap: 16px;
	width: 100%; // Make sure it occupies full width of its container

	// Responsive Width Adjustments
	@media (min-width: 768px) {
		flex: 1 1 calc(33.33% - 16px);
	}

	@media (min-width: 1024px) {
		flex: 1 1 calc(20% - 16px);
	}

	h3 {
		color: $drk-text;
		font-size: 17px;
		font-weight: 600;
		line-height: 20px;
	}
}

.loaderBlock {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 72px;
}

.metricValue {
	font-size: 30px;
	font-weight: 600;
	line-height: 36px;
	color: $dash-metric-text-color;
	font-weight: 600;
}

.avg {
	font-size: 13px;
	font-weight: 400;
	line-height: 16px;
	color: $dash-avg-text-color;
	display: flex;
	align-items: center;
	justify-content: center;
}

.additionalInfo {
	font-size: 13px;
	font-weight: 400;
	line-height: 16px;
	color: $dash-avg-text-color;
}

.footer {
	display: flex;
	align-items: center;
	width: 100%;
	justify-content: space-between;

	.info {
		display: flex;
		align-items: center;
		gap: 5px;
		min-height: 20px;

		p {
			font-size: 13px;
			font-weight: 400;
			line-height: 16px;
			letter-spacing: 0em;
			color: $dash-info-text-color;
		}
	}
}

.change {
	font-size: 0.9rem;
	font-weight: bold;
	max-width: 70px;
	&.positive {
		.arrow {
			transform: rotate(40deg); // Pointing up
		}
	}

	&.negative {
		.arrow {
			transform: rotate(140deg);
		}
	}

	&.neutral {
		.arrow {
			transform: rotate(0deg);
		}
	}

	&.positive {
		background: $dash-pos-background;
		border-radius: 4px;
		display: flex;
		height: 20px;
		gap: 0px;
		border: 1px solid $dash-pos-background;
		padding: 0px 8px;
		align-items: center;

		span {
			white-space: nowrap;
			font-size: 12px;
			font-weight: 400;;
			line-height: 16px;
			letter-spacing: 0em;
			color: $dash-pos-color;
		}
		svg{
			height: 20px;
			width: 20px;
			fill: $dash-pos-color;
		}
	}

	&.negative {
		background: $dash-neg-background;
		border-radius: 4px;
		display: flex;
		height: 20px;
		gap: 0px;
		border: 1px solid $dash-neg-background;
		padding: 0px 8px;
		align-items: center;

		span {
			white-space: nowrap;
			font-size: 12px;
			font-weight: 400;;
			line-height: 16px;
			letter-spacing: 0em;
			color: $dash-neg-color;
		}
		svg{
			height: 20px;
			width: 20px;
			fill: $dash-neg-color;
		}
	}

	&.neutral {
		background: $dash-neutral-background;
		border-radius: 4px;
		display: flex;
		height: 20px;
		gap: 0px;
		border: 1px solid $dash-neutral-background;
		padding: 0px 8px;
		align-items: center;

		span {
			white-space: nowrap;
			font-size: 12px;
			font-weight: 400;;
			line-height: 16px;
			letter-spacing: 0em;
			color: $dash-neutral-color;
		}
		svg{
			height: 20px;
			width: 20px;
			fill: $dash-neutral-color;
		}
	}
}

.infoIcon {
	margin-right: 4px;
	font-size: 0.8rem;
	color: #888;
}