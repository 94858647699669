@import './mixins';
.flex-column {
	@include flexbox();
	@include flex-direction(column);
}
.inline-flex {
	@include inline-flex();
	@include flex-direction(row);
}
.flexbox {
	@include flexbox();
	@include flex-direction(row);
}
.flex-reverse {
	@include flex-direction(row-reverse);
}
.column-reverse {
	@include flex-direction(column-reverse);
}
.all-center {
	@include justify-content(center);
	@include align-items(center);
}

.justify-center {
	@include justify-content(center);
}

.justify-between {
	@include justify-content(space-between);
}

.justify-around {
	@include justify-content(space-around);
}

.justify-start {
	@include justify-content(flex-start);
}

.justify-end {
	@include justify-content(flex-end);
}

.item-center {
	@include align-items(center);
}

.item-start {
	@include align-items(flex-start);
}

.item-end {
	@include align-items(flex-end);
}

.item-stretch {
	@include align-items(stretch);
}
.item-nowrap {
	@include flex-wrap(nowrap);
}
.item-wrap {
	@include flex-wrap(wrap);
}
.graytbl-header {
	color: $graytbl-font;
	font-size: 12px;
	font-weight: 700;
}
.whitetbl-header {
	color: $text-color !important;
	font-size: 12px;
	font-weight: 700;
}
.tblrow-color {
	color: $text-color;
	font-size: 13px;
	font-weight: 400;
}
.tblprimary-color {
	color: $text-color;
	font-size: 13px;
	font-weight: 700;
}
