body{
    --admin-nav-background-image: url(//www.gstatic.com/mobilesdk/190424_mobilesdk/nav_nachos@2x.png);
    --google-business-icon: url('/images/business.png');
    --bing-icon: url('/images/bing-places.png');
    --yelp-icon: url('/images/yelp.svg');
    --booking-icon: url('/images/booking.png');
    --reviewshakebooking-icon: url('/images/bookingIndex.png');
    --cellarpass-icon: url('/images/cellarpassicon.png');
    --facebook-icon: url('/images/facebook-reviews.png');
    --tripadvisor-icon: url('/images/TripAdvisor1.png');
    --lawyers-icon: url('/images/lawyers-icon.png');
    --ratemds-icon: url('/images/ratemds-icon.png');
    --vitals-icon: url('/images/vitals-icon.png');
    --healthgrades-icon: url('/images/healthgrades-icon.png');
    --productreview-icon: url('/images/product.png');
    --apartments-icon: url('/images/apartment.png');
    --zomato-icon: url('/images/zomato.png');
    --glassdoor-icon: url('/images/glassdoor-icon.png');
    --hostellingscotland-icon: url('/images/HostellingScotland.png');
    --hostelworld-icon: url('/images/hostelworld-icon.png');
    --expedia-icon: url('/images/expedia-icon.png');
    --indeed-icon: url('/images/indeed-icon.png');
    --opentable-icon: url('/images/opentable-icon.png');
    --zillow-icon: url('/images/zillow-icon.png');
    --hotels-icon: url('/images/hotels-icon.png');
    --foursquare-icon: url('/images/fourSquare-icon.png');
    --reviewshakegmb-icon: url('/images/GMB-Index-icon.png');
    --googleplaystore-icon: url('/images/googleplaystore-icon.png');
    --appstore-icon: url('/images/appstoreindex-icon.png');
    --zocdoc-icon: url('/images/zocdoc.png');
    --cars-icon: url('/images/cars-icon.png');
    --cargurus-icon: url('/images/cargurus-icon.png');
    --edmunds-icon: url('/images/edmunds-icon.png');
    --dealerrater-icon: url('/images/dealerrater-icon.png');
    --talabat-icon: url('/images/talabat-icon.png');
    --reserveout-icon: url('/images/reserveout-icon.png');
    --homeadvisor-icon: url('/images/homeadvisor-icon.png');
    --trustpilot-icon: url('/images/trustpilot.png');
    --trustradius-icon: url('/images/trustradius-icon.png');
    --reviewshaketrustpilot-icon: url('/images/trustpilot.png');
	--capterra-icon: url('/images/capterra-icon.png');
    --gtwo-icon: url('/images/gtwo-icon.png');
    --airbnb-icon: url('/images/airbnb-icon.png');
    --avvo-icon: url('/images/avvo-icon.png');
    --houzz-icon: url('/images/houzz-icon.png');
    --homestars-icon: url('/images/homestarts-icon.png');
    --rent-icon: url('/images/rent-icon.png');
    --highya-icon: url('/images/highya-icon.png');
    --crowd-icon: url('/images/crowd-icon.png');
    --angieslist-icon: url('/images/angieslist-icon.png');
    --reviewshakefacebook-icon: url('/images/reviewshakefacebook-icon.png');
    --betterbusinessbureau-icon: url('/images/BBB-icon.png');
    --playstore-icon: url('/images/playstore-icon.png');
    --appleappstore-icon: url('/images/appstore-icon.png');
    --applemaps-icon: url('/images/applemaps-icon.png');
    --google-icon: url('/images/googless.png');
    --new-profile-left1: url('/images/profilecreate-background.png');
    --new-profile-left2: url('/images/profilecreate-background.png');
    --new-profile-left3: url('/images/profilecreate-background.png');
    --new-profile-left4: url('/images/profilecreate-background.png');
    --new-profile-left5: url('/images/profilecreate-background.png');
    --new-profile-left6: url('/images/profilecreate-background.png');
    --applebusiness-icon: url('/images/applemaps-icon.png');
    --googlePublic-icon: url('/images/googlepublic.png');
    --ubereats-icon: url('/images/ubereats-icon.png');
    --doordash-icon: url('/images/doordash-icon.png');
    --grubhub-icon: url('/images/grubhub-icon.png');


    --icon-size-xs: 20px;
    --icon-size-sm: 28px;
    --icon-size-md: 32px;
    --icon-size-lg: 40px;
    --icon-size-xl:2rem;
    --icon-size-xxl: 3rem;
}
