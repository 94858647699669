@import "../../sass/themes/index.scss";

.card {
	background: $page-content-background;
	border-radius: 8px;
	padding: 24px;
	box-shadow: $dash-card-box-shadow;
	border: 1px solid $dash-card-border-color;
	margin: 8px 0px;
	align-items: flex-start;
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	width: 100%;



	// Responsive Width Adjustments
	@media (min-width: 768px) {
		flex: 1 1 calc(33.33% - 14px);
	}

	@media (min-width: 1024px) {
		flex: 1 1 calc(20% - 14px);
	}

	h3 {
		font-size: 17px;
		font-weight: 600;
		line-height: 20px;
		letter-spacing: 0em;
		color: $drk-text;
	}
}

.noData {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 176px;
}

.loader {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 176px;
}

.sourceList {
	display: flex;
	width: 100%;
	flex-flow: column wrap;
	align-items: flex-start;
	// justify-content: center;
	gap: 10px;
	padding-top: 20px;
	height: 176px;
}