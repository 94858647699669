@mixin font-face(
	$font-name,
	$font-path,
	$font-weight: 400,
	$font-style: normal,
	$support-required: 'modern'
) {
	@font-face {
		font-family: $font-name;
		font-style: $font-style;
		font-weight: $font-weight;

		@if $support-required== 'oldie' {
			src: url('#{$font-path}.eot');
			src: url('#{$font-path}.eot?#iefix') format('embedded-opentype'),
				url('#{$font-path}.woff2') format('woff2'),
				url('#{$font-path}.woff') format('woff'),
				url('#{$font-path}.ttf') format('truetype');
		}

		@if $support-required== 'recent' {
			src: url('#{$font-path}.woff2') format('woff2'),
				url('#{$font-path}.woff') format('woff'),
				url('#{$font-path}.ttf') format('truetype');
		}

		@if $support-required== 'modern' {
			src: url('#{$font-path}.woff2') format('woff2'),
				url('#{$font-path}.woff') format('woff');
		}
	}

	@if $support-required !=
		'oldie' and
		$support-required !=
		'recent' and
		$support-required !=
		'modern'
	{
		@error 'Invalid support-required value. Must be "oldie"==>IE8 and above/ Android native browser 4+/ Safari 4.3 OR "recent"==>IE9+/ Android native browser 4.0+/ Safari 4.3+ OR "modern"==>IE9+/ Android native browser 4.4+/ Safari 5.1+';
	}
}

@mixin font-smoothing($value: on) {
	@if $value==on {
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	} @else {
		-webkit-font-smoothing: subpixel-antialiased;
		-moz-osx-font-smoothing: auto;
	}
}

@mixin box-sizing($box-model) {
	-webkit-box-sizing: $box-model;
	-moz-box-sizing: $box-model;
	box-sizing: $box-model;
}

@mixin border-radius($radius) {
	border-radius: $radius;
	-webkit-border-radius: $radius;
	-moz-border-radius: $radius;
}
@mixin box-shadow($params...) {
	-webkit-box-shadow: $params;
	-moz-box-shadow: $params;
	box-shadow: $params;
}
@mixin hyphens($value: 'manual') {
	@each $prefix in -webkit-, -moz-, -o-, '' {
		#{$prefix}hyphens: $value;
	}
}
/*=====
alpha
========*/

@mixin background-opacity($color, $opacity: 0.3) {
	background: $color;
	background: rgba($color, $opacity);
}

/*=====
linear-gradien
========*/
@mixin linear-gradient($direction, $color-stops...) {
	@if is-direction($direction) ==false {
		$color-stops: $direction, $color-stops;
	}

	background-image: -webkit-linear-gradient(
		legacy-direction($direction),
		$color-stops
	);
	background-image: -webkit-linear-gradient($direction, $color-stops);
	background: -ms-linear-gradient($direction, $color-stops);
	background-image: -ms-linear-gradient($direction, $color-stops);
	background-image: linear-gradient($direction, $color-stops);
}

@mixin gradient-border($color1, $color2, $border-width, $direction) {
	border: none;
	background-repeat: no-repeat;
	background-image: linear-gradient(#{$direction}, $color1 0%, $color2 100%),
		linear-gradient(#{$direction}, $color1 0%, $color2 100%);

	@if $direction== 'to right' or $direction== 'to left' {
		@if $direction== 'to right' {
			border-left: $border-width solid $color1;
			border-right: $border-width solid $color2;
		} @else {
			border-left: $border-width solid $color2;
			border-right: $border-width solid $color1;
		}

		background-position: 0 0, 0 100%;
		background-size: 100% $border-width;
	}

	@if $direction== 'to top' or $direction== 'to bottom' {
		@if $direction== 'to top' {
			border-top: $border-width solid $color2;
			border-bottom: $border-width solid $color1;
		} @else {
			border-top: $border-width solid $color1;
			border-bottom: $border-width solid $color2;
		}

		background-position: 0 0, 100% 0;
		background-size: $border-width 100%;
	}
}

@mixin text-gradient($dir, $color-stops...) {
	background: -webkit-linear-gradient($dir, $color-stops);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

/*====
transition
======*/

@mixin transition($transition...) {
	-moz-transition: $transition;
	-o-transition: $transition;
	-webkit-transition: $transition;
	transition: $transition;
}

@mixin transition-property($property...) {
	-moz-transition-property: $property;
	-o-transition-property: $property;
	-webkit-transition-property: $property;
	transition-property: $property;
}

@mixin transition-duration($duration...) {
	-moz-transition-property: $duration;
	-o-transition-property: $duration;
	-webkit-transition-property: $duration;
	transition-property: $duration;
}

@mixin transition-timing-function($timing...) {
	-moz-transition-timing-function: $timing;
	-o-transition-timing-function: $timing;
	-webkit-transition-timing-function: $timing;
	transition-timing-function: $timing;
}

@mixin transition-delay($delay...) {
	-moz-transition-delay: $delay;
	-o-transition-delay: $delay;
	-webkit-transition-delay: $delay;
	transition-delay: $delay;
}

/*====
transform
======*/
@mixin rotate($degrees) {
	-webkit-transform: rotate($degrees);
	-ms-transform: rotate($degrees); // IE9 only
	-o-transform: rotate($degrees);
	transform: rotate($degrees);
}

@mixin rotateX($degrees) {
	-webkit-transform: rotateX($degrees);
	-ms-transform: rotateX($degrees); // IE9 only
	-o-transform: rotateX($degrees);
	transform: rotateX($degrees);
}

@mixin rotateY($degrees) {
	-webkit-transform: rotateY($degrees);
	-ms-transform: rotateY($degrees); // IE9 only
	-o-transform: rotateY($degrees);
	transform: rotateY($degrees);
}

// generic transform
@mixin transform($transforms) {
	-moz-transform: $transforms;
	-o-transform: $transforms;
	-ms-transform: $transforms;
	-webkit-transform: $transforms;
	transform: $transforms;
}

// rotate
@mixin rotate($deg) {
	@include transform(rotate(#{$deg}deg));
}

// scale
@mixin scale($scale) {
	@include transform(scale($scale));
}

// translate
@mixin translate($x, $y) {
	@include transform(translate($x, $y));
}
@mixin translateY($y) {
	@include transform(translateY($y));
}
@mixin translateX($x) {
	@include transform(translateX($x));
}

// skew
@mixin skew($x, $y) {
	@include transform(skew(#{$x}deg, #{$y}deg));
}

//transform origin
@mixin transform-origin($origin) {
	moz-transform-origin: $origin;
	-o-transform-origin: $origin;
	-ms-transform-origin: $origin;
	-webkit-transform-origin: $origin;
	transform-origin: $origin;
}

@mixin optional-at-root($sel) {
	@at-root #{if(not &, $sel, selector-append(&, $sel))} {
		@content;
	}
}

/*----
placeholder
-----*/

@mixin placeholder {
	@include optional-at-root('::-webkit-input-placeholder') {
		@content;
	}

	@include optional-at-root(':-moz-placeholder') {
		@content;
	}

	@include optional-at-root('::-moz-placeholder') {
		@content;
	}

	@include optional-at-root(':-ms-input-placeholder') {
		@content;
	}
}

@mixin text-fill-color($value) {
	-webkit-text-fill-color: $value;
	-moz-text-fill-color: $value;
	-o-text-fill-color: $value;
	-ms-text-fill-color: $value;
	text-fill-color: $value;
}

@mixin text-stroke-color($value) {
	-webkit-stroke-fill-color: $value;
	-moz-stroke-fill-color: $value;
	-o-stroke-fill-color: $value;
	-ms-stroke-fill-color: $value;
	stroke-fill-color: $value;
}

@mixin text-stroke-width($value) {
	-webkit-text-stroke-width: $value;
	-moz-text-stroke-width: $value;
	-o-text-stroke-width: $value;
	-ms-text-stroke-width: $value;
	text-stroke-width: $value;
}

/*----
flex
-----*/
@mixin grid($flexbox) {
	@if $flexbox {
		@include flexbox;
	} @else {
		display: block;
	}
}
@mixin flexbox() {
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
}
@mixin inline-flex {
	display: -webkit-inline-box;
	display: -moz-inline-box;
	display: -webkit-inline-flex;
	display: -ms-inline-flexbox;
	display: inline-flex;
}

@mixin justify-content($value: flex-start) {
	@if $value==flex-start {
		-webkit-box-pack: start;
		-ms-flex-pack: start;
	} @else if $value==flex-end {
		-webkit-box-pack: end;
		-ms-flex-pack: end;
	} @else if $value==space-between {
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
	} @else if $value==space-around {
		-ms-flex-pack: distribute;
	} @else if $value==center {
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
	} @else {
		-webkit-box-pack: $value;
		-ms-flex-pack: $value;
	}

	-webkit-justify-content: $value;
	-moz-justify-content: $value;
	justify-content: $value;
}

@mixin align-items($value: stretch) {
	@if $value==flex-start {
		-webkit-box-align: start;
		-ms-flex-align: start;
	} @else if $value==flex-end {
		-webkit-box-align: end;
		-ms-flex-align: end;
	} @else {
		-webkit-box-align: $value;
		-ms-flex-align: $value;
	}

	-webkit-align-items: $value;
	-moz-align-items: $value;
	align-items: $value;
}

@mixin flex-direction($value: row) {
	@if $value==row-reverse {
		-webkit-box-direction: reverse;
		-webkit-box-orient: horizontal;
	} @else if $value==column {
		-webkit-box-direction: normal;
		-webkit-box-orient: vertical;
	} @else if $value==column-reverse {
		-webkit-box-direction: reverse;
		-webkit-box-orient: vertical;
	} @else {
		-webkit-box-direction: normal;
		-webkit-box-orient: horizontal;
	}

	-webkit-flex-direction: $value;
	-moz-flex-direction: $value;
	-ms-flex-direction: $value;
	flex-direction: $value;
}

@mixin flex-wrap($value) {
	// IE 10
	@if $value==nowrap {
		-ms-flex-wrap: none;
	} @else {
		-ms-flex-wrap: $value;
	}

	-webkit-flex-wrap: $value;
	flex-wrap: $value;
}

@mixin flex-grow($grow) {
	-webkit-flex-grow: $grow;
	-moz-flex-grow: $grow;
	-ms-flex-grow: $grow;
	flex-grow: $grow;
}

@mixin flex-shrink($shrink) {
	-webkit-flex-shrink: $shrink;
	-moz-flex-shrink: $shrink;
	-ms-flex-shrink: $shrink;
	flex-shrink: $shrink;
}

@mixin flex-flow($flow) {
	-webkit-flex-flow: $flow;
	-moz-flex-flow: $flow;
	-ms-flex-flow: $flow;
	flex-flow: $flow;
}
/*-=====
opasity
=======*/

@mixin alpha-color($color, $background) {
	$percent: alpha($color) * 100%;
	$opaque: opacify($color, 1);
	$solid-color: mix($opaque, $background, $percent);
	background-color: $solid-color;
	background-color: $color;
}

@mixin border-opacity($color, $opacity) {
	$opacity: $opacity / 100;
	border-color: $color;
	border-color: rgba($color, $opacity);
	-moz-background-clip: padding;
	-webkit-background-clip: padding;
	background-clip: padding-box;
}

/*-=====
appearance
=======*/

@mixin appearance($value) {
	-webkit-appearance: $value;
	-moz-appearance: $value;
	appearance: $value;
}

/*-=====
filter
=======*/

// grayscale      ex: filter: grayscale(100%);
// sepia          ex: filter: sepia(100%);
// saturate       ex: filter: saturate(0%);
// hue-rotate     ex: filter: hue-rotate(45deg);
// invert         ex: filter: invert(100%);
// brightness     ex: filter: brightness(15%);
// contrast       ex: filter: contrast(200%);
// blur           ex: filter: blur(2px);
@mixin filter($filter-type, $filter-amount) {
	-webkit-filter: $filter-type + unquote('(#{$filter-amount})');
	-moz-filter: $filter-type + unquote('(#{$filter-amount})');
	-ms-filter: $filter-type + unquote('(#{$filter-amount})');
	-o-filter: $filter-type + unquote('(#{$filter-amount})');
	filter: $filter-type + unquote('(#{$filter-amount})');
}

/*-=====
react select
=======*/

@mixin text-shadow($x: 2px, $y: 2px, $blur: 5px, $color: rgba(0, 0, 0, 0.4)) {
	text-shadow: $x $y $blur $color;
}

/*-=====
mediaquery
=======*/

$mobile-small: 320px !default;
$mobile-min: 320px !default;
$mobile-old: 640px !default;
$mobile-max: 767px !default;
$tablet-min: 768px !default;
$tablet-max: 1023px !default;
$desktop: 1200px !default;

@mixin respond-to($media) {
	@if $media==mobile-small {
		@media only screen and (max-width: $mobile-small) and (-webkit-min-device-pixel-ratio: 2) {
			@content;
		}
	} @else if $media==mobile {
		@media only screen and (min-width: $mobile-min) and (max-width: $mobile-max) and (-webkit-min-device-pixel-ratio: 1.5) {
			@content;
		}
	} @else if $media==mobile-old {
		@media only screen and (min-width: $mobile-old) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1.5) {
			@content;
		}
	} @else if $media==mobile-portrait {
		@media only screen and (min-width: $mobile-min) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
			@content;
		}
	} @else if $media==mobile-landscape {
		@media only screen and (min-width: $mobile-max) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1.5) {
			@content;
		}
	} @else if $media==tablet {
		@media only screen and (min-width: $tablet-min) and (max-width: $tablet-max) and (-webkit-min-device-pixel-ratio: 1.5) {
			@content;
		}
	} @else if $media==tablet-portrait {
		@media only screen and (min-width: $tablet-min) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
			@content;
		}
	} @else if $media==tablet-landscape {
		@media only screen and (min-width: $tablet-max) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1.5) {
			@content;
		}
	} @else if $media==desktop {
		@media only screen and (min-width: $desktop) and (-webkit-min-device-pixel-ratio: 2) {
			@content;
		}
	}
}

/*-=====
Animation
=======*/

@mixin keyframes($animation-name) {
	@-webkit-keyframes #{$animation-name} {
		@content;
	}
	@-moz-keyframes #{$animation-name} {
		@content;
	}
	@-ms-keyframes #{$animation-name} {
		@content;
	}
	@-o-keyframes #{$animation-name} {
		@content;
	}
	@keyframes #{$animation-name} {
		@content;
	}
}
@mixin animation($str) {
	-webkit-animation: #{$str};
	-moz-animation: #{$str};
	-ms-animation: #{$str};
	-o-animation: #{$str};
	animation: #{$str};
}
$browser: null;
