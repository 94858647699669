@import url(https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,300;1,400;1,500;1,700;1,900&display=swap);
/*---------Color-----------*/
/*--  font size  --*/
/*--  Group color  --*/
/*=====
alpha
========*/
/*=====
linear-gradien
========*/
/*====
transition
======*/
/*====
transform
======*/
/*----
placeholder
-----*/
/*----
flex
-----*/
/*-=====
opasity
=======*/
/*-=====
appearance
=======*/
/*-=====
filter
=======*/
/*-=====
react select
=======*/
/*-=====
mediaquery
=======*/
/*-=====
Animation
=======*/
/*=====
alpha
========*/
/*=====
linear-gradien
========*/
/*====
transition
======*/
/*====
transform
======*/
/*----
placeholder
-----*/
/*----
flex
-----*/
/*-=====
opasity
=======*/
/*-=====
appearance
=======*/
/*-=====
filter
=======*/
/*-=====
react select
=======*/
/*-=====
mediaquery
=======*/
/*-=====
Animation
=======*/
.flex-column, .user-credentials .user-details, .user-credentials .user-details__inner, .user-credentials .user-details__inner ul li {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column; }

.inline-flex {
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: horizontal;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row; }

.flexbox, .user-credentials, .user-credentials .user-details .signup-wrap, .user-credentials .user-details__inner .language-selector, .user-credentials .user-details__inner ul li button, .user-credentials .user-details .signup-terms, .login-social {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: horizontal;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row; }

.flex-reverse {
  -webkit-box-direction: reverse;
  -webkit-box-orient: horizontal;
  -webkit-flex-direction: row-reverse;
  -moz-flex-direction: row-reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse; }

.column-reverse {
  -webkit-box-direction: reverse;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column-reverse;
  -moz-flex-direction: column-reverse;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse; }

.all-center, .user-credentials .user-details__inner ul .name-section, .user-credentials .user-details__inner ul li, .user-credentials .user-details__inner ul li button, .user-credentials .user-details .signup-terms {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center; }

.justify-center, .user-credentials .user-details__inner {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center; }

.justify-between, .user-credentials .user-details__inner .language-selector, .login-social {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between; }

.justify-around {
  -ms-flex-pack: distribute;
  -webkit-justify-content: space-around;
  -moz-justify-content: space-around;
  justify-content: space-around; }

.justify-start {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  justify-content: flex-start; }

.justify-end, .user-credentials .user-details .signup-wrap {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  -webkit-justify-content: flex-end;
  -moz-justify-content: flex-end;
  justify-content: flex-end; }

.item-center, .user-credentials .user-details .signup-wrap, .user-credentials .user-details__inner .language-selector, .login-social {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center; }

.item-start {
  -webkit-box-align: start;
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  -moz-align-items: flex-start;
  align-items: flex-start; }

.item-end {
  -webkit-box-align: end;
  -ms-flex-align: end;
  -webkit-align-items: flex-end;
  -moz-align-items: flex-end;
  align-items: flex-end; }

.item-stretch, .user-credentials {
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  -webkit-align-items: stretch;
  -moz-align-items: stretch;
  align-items: stretch; }

.item-nowrap {
  -ms-flex-wrap: none;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap; }

.item-wrap {
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap; }

.graytbl-header {
  color: #202124;
  font-size: 12px;
  font-weight: 700; }

.whitetbl-header {
  color: #3c4043 !important;
  font-size: 12px;
  font-weight: 700; }

.tblrow-color {
  color: #3c4043;
  font-size: 13px;
  font-weight: 400; }

.tblprimary-color {
  color: #3c4043;
  font-size: 13px;
  font-weight: 700; }

body.light {
  --admin-nav-background: #051e34;
  --admin-nav-border-color: #7c78786b;
  --admin-nav-font-color: #FFFFFFCC;
  --admin-nav-active-color: #669DF6;
  --page-content-background: #fafafa;
  --admin-header-content-background: #f1f5f9;
  --button-background-primary: #06a7e1;
  --white-text:#fff;
  --background-white-color: #fff;
  --button-background-secondary: #fff;
  --button-background-primary-hover: #40a9ff;
  --button-background-secondary-outline-hover: #40a9ff;
  --button-background-outline: #d9d9d9;
  --button-color-primary: #fff;
  --button-color-secondary: #0c0b0ba6;
  --ant-tab-inactive-color: #476282;
  --ant-tab-active-color: #1967d2;
  --page-content-h3-color: #222;
  --page-content-h5-color: #555;
  --page-content-h4-color: #3c4043;
  --primary-active-color: #1890ff;
  --primary-inactive-color: #a0a7b4;
  --primary-warning-color: #F99D1B;
  --dark-text-color: #666666;
  --sm-card-h5-color: #222;
  --overlay-background-color: #00000080;
  --profile-tag-color: #ad6f19;
  --profile-tag-background-color: #ebd7bd;
  --nav--hover-background: #ffffff14;
  --nav-admin-section-highlight: #47628233;
  --nav-admin-section-hover: #47628266;
  --black-text: #000000;
  --drk-text: #212121;
  --med-drk-text: #424242;
  --med-text: #616161;
  --med-lgt-text: #757575;
  --lgt-text: #9E9E9E;
  --warning-text: #E65100;
  --accent-text-color: #051e34;
  --nav-admin-section-header: rgba(255, 255, 255, 0.5);
  --right-nav-logout-color: #ffa600;
  --dropdown-background-color: #1a2c3a;
  --dropdown-border-color: #424242;
  --dropdown-background-hover-color: #ffffff14;
  --page-header-svg-color: #424242;
  --page-header-svg-hover: #669DF6;
  --page-header-svg-background: #55545414;
  --paid-color-green: #388E3C;
  --payment-due-color-orange: #F57C00;
  --not-paid-color-red: #D32F2F;
  --voided-color-grey: #616161;
  --pending-color-lgtblue: #29B6F6;
  --posted-color-lgtblue: #0288D1;
  --table-header-color: #05213a;
  --table-row-seperator: rgb(1, 9, 15);
  --star-rating-1-color: #FF5722;
  --star-rating-2-color: #FF9800;
  --star-rating-3-color: #ababab;
  --star-rating-4-color: #03A9F4;
  --star-rating-5-color: #4CAF50;
  --disabled-button-color: #999999;
  --admin-nav-logo-color: #FFFFFF;
  --detached-toogle-button-color: #ffa500;
  --filter-card-icon-color: #76bfe0;
  --filter-location-card-background: #051e34, #0d4475;
  --walmart-audit-active-card-background: #d58f28, #f9a326;
  --filter-listing-card-background: #0d4475, #29679e;
  --timeline-dot-background: #94a5b9;
  --timeline-icon-color: #81d4f9;
  --custom-popover-background: #f1f5f9;
  --comment-filter-background: #81d4f9;
  --account-filter-background: #9c27b0;
  --response-rate-background:#f99d1b;
  --campaign-period-color:#e9a94f;
  --default-background-color: rgb(231, 228, 228);
  --svg-icon-color:#669DF6;
  --button-inactive-state-color: #cccccc;
  --button-active-state-color:#d2dfec;
  --location-background-color: #7fbae7;
  --group-background-color: #ffa500;
  --error-background-color:#fff2f4;
  --error-field-color:#E30000;
  --error-border-color:#E30000;
  --background-drk-black: #000000;
  --background-white-color: #fff;
  --background-med-black: #0d0d0d;
  --border-med-black:#555;
  --placeholder-lgt-black:#777;
  --button-background-lgt-black:#565656;
  --dash-neg-background: rgb(255, 228, 230);
  --dash-pos-background: rgb(209, 250, 229);
  --dash-neutral-background:rgb(245, 245, 245);
  --dash-neg-color: rgb(159, 18, 57);
  --dash-pos-color: rgb(6, 95, 70);
  --dash-neutral-color: rgb(64, 64, 64);
  --dash-avg-text-color:#737373;
  --dash-info-text-color:rgb(115, 115, 115);
  --dash-metric-text-color: #1d4de8;
  --dash-card-box-shadow: 0px 4px 16px -2px rgba(0, 0, 0, 0.08),
  0px 2px 4px -1px rgba(0, 0, 0, 0.08);
  --dash-card-border-color: rgb(229, 229, 229)
; }

.user-credentials {
  height: 100vh; }
  .user-credentials .user-info {
    flex: 1 1 50%;
    width: 50%;
    max-width: 50%;
    overflow: hidden;
    background-size: cover;
    position: relative;
    height: 100%;
    background-color: #fff !important; }
    @media (max-width: 1000px) {
      .user-credentials .user-info {
        display: none; } }
    .user-credentials .user-info .info-conent {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      padding: 30px;
      width: 100%;
      height: 100%;
      z-index: 3; }
      .user-credentials .user-info .info-conent .login-logo {
        position: absolute;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: nowrap;
        text-align: center;
        max-width: 240px; }
    .user-credentials .user-info .user-content {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      padding: 0px;
      width: 100%;
      height: 100%;
      z-index: 3; }
      .user-credentials .user-info .user-content .login-logo {
        position: absolute;
        top: 40px;
        left: 40px;
        z-index: 99;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: nowrap;
        text-align: center;
        max-width: 240px; }
      .user-credentials .user-info .user-content .signup-image {
        height: 100%; }
        .user-credentials .user-info .user-content .signup-image img {
          height: 100%;
          width: 100%; }
  .user-credentials .user-details {
    flex: 1 1 50%;
    width: 50%;
    max-width: 50%; }
    @media (max-width: 1000px) {
      .user-credentials .user-details {
        flex: 1 1 100%;
        width: 100%;
        max-width: 100%; } }
    .user-credentials .user-details .signup-wrap {
      flex: 0 1 auto;
      height: 100px;
      padding: 0 5%; }
      .user-credentials .user-details .signup-wrap span {
        flex: 0 0 auto;
        margin-right: 10px; }
      .user-credentials .user-details .signup-wrap a {
        flex: 0 0 auto;
        border: solid 1px rgba(0, 0, 0, 0.08);
        padding: 8px 10px;
        font-weight: 600;
        text-transform: uppercase;
        border-radius: 4px;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px; }
    .user-credentials .user-details .marketting-message {
      text-align: center;
      padding-bottom: 30px;
      font-size: 20px; }
    .user-credentials .user-details__inner {
      flex: 1 1 auto;
      position: relative;
      position: relative;
      padding: 0 25% 0 25%; }
      .user-credentials .user-details__inner .fav-icon-class {
        margin-bottom: 20px; }
        .user-credentials .user-details__inner .fav-icon-class img {
          width: 2rem;
          height: 2rem; }
      .user-credentials .user-details__inner h3 {
        text-align: left;
        font-size: 26px;
        font-weight: 800; }
      .user-credentials .user-details__inner .language-selector {
        flex: 0 1 auto;
        width: 100%; }
      .user-credentials .user-details__inner .ant-dropdown-trigger {
        padding: 0; }
      .user-credentials .user-details__inner svg {
        width: 1.2rem;
        height: 1.2rem; }
      .user-credentials .user-details__inner .anticon-down {
        width: 1rem;
        height: 1rem; }
      .user-credentials .user-details__inner ul {
        margin-top: 25px; }
        .user-credentials .user-details__inner ul .name-section {
          display: flex;
          flex-direction: column;
          margin-bottom: 16px;
          gap: 1rem;
          width: 100%; }
          .user-credentials .user-details__inner ul .name-section .section-items {
            display: flex;
            flex-direction: column;
            width: 100%; }
            .user-credentials .user-details__inner ul .name-section .section-items .input-blocks-section {
              color: #7c7a7a;
              font-weight: 550;
              width: 100%;
              display: flex;
              flex: 1 1 auto;
              gap: 16px; }
              .user-credentials .user-details__inner ul .name-section .section-items .input-blocks-section .bolck-items {
                width: 100%;
                display: flex;
                gap: 2px;
                flex-direction: column; }
                .user-credentials .user-details__inner ul .name-section .section-items .input-blocks-section .bolck-items input {
                  flex: 1 1 auto;
                  background: #fff;
                  border-color: #ddd;
                  border-width: 2px;
                  padding: 10px !important;
                  border-style: solid;
                  padding: 0px;
                  line-height: 35px;
                  width: 100%;
                  border-radius: 5px;
                  -webkit-border-radius: 5px;
                  -moz-border-radius: 5px; }
        .user-credentials .user-details__inner ul li {
          margin-bottom: 16px; }
          .user-credentials .user-details__inner ul li .label-block {
            display: flex;
            width: 100%;
            margin-bottom: 2px;
            color: #7c7a7a;
            font-weight: 550; }
          .user-credentials .user-details__inner ul li input {
            flex: 1 1 auto;
            background: #fff;
            border-color: #ddd !important;
            border-width: 2px;
            border-style: solid;
            padding: 0px;
            line-height: 35px;
            width: 100%;
            border-radius: 5px;
            padding-left: 10px;
            padding-right: 35px !important; }
            .user-credentials .user-details__inner ul li input:active {
              border-color: #ddd !important; }
          .user-credentials .user-details__inner ul li button {
            margin-top: 10px;
            flex: 0 0 auto;
            padding: 12px 20px;
            background-color: #06a7e1;
            text-transform: uppercase;
            color: #ffffff;
            border-radius: 25px;
            -webkit-border-radius: 25px;
            -moz-border-radius: 25px; }
            .user-credentials .user-details__inner ul li button span {
              flex: 0 0 auto;
              padding: 0px 6px; }
          .user-credentials .user-details__inner ul li .login-with {
            flex: 1 1 auto;
            width: 100%; }
    .user-credentials .user-details .signup-terms {
      flex: 0 1px;
      padding: 0 0 30px 0; }
      .user-credentials .user-details .signup-terms span,
      .user-credentials .user-details .signup-terms a {
        margin-right: 4px; }

.login-social {
  justify-content: center; }
  .login-social .micro-soft__btn {
    margin-left: 15px;
    flex: 0 0 auto;
    width: 45%; }
    .login-social .micro-soft__btn a {
      display: block;
      position: relative;
      text-align: center;
      padding: 0px 15px 0px 63px;
      margin: 0px;
      height: 47px;
      line-height: 48px;
      color: #ffffff !important;
      font-weight: 600;
      font-size: 16px;
      background: #067ae1;
      -webkit-box-shadow: 0, 2px, 4px 0, rgba(0, 0, 0, 0.125);
      -moz-box-shadow: 0, 2px, 4px 0, rgba(0, 0, 0, 0.125);
      box-shadow: 0, 2px, 4px 0, rgba(0, 0, 0, 0.125); }
      .login-social .micro-soft__btn a span {
        color: #fff !important;
        text-align: center; }
        .login-social .micro-soft__btn a span:first-child {
          position: absolute;
          width: 46px;
          height: 45px;
          color: #fff !important;
          font-size: 22px;
          line-height: 48px;
          left: 1px;
          top: 1px; }
        .login-social .micro-soft__btn a span:span:last-child {
          text-align: right; }

.translation-icon {
  border: 1px solid var(--lgt-text);
  border-radius: 8px;
  padding: 0 10px;
  font-weight: 600;
  height: 24px;
  font-size: 14px;
  display: flex;
  align-items: center;
  color: #3c4043; }
  .translation-icon svg {
    width: 18px !important;
    height: 18px !important;
    fill: var(--med-lgt-text); }
