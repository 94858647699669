body.light {
  --admin-nav-background: #051e34;
  --admin-nav-border-color: #7c78786b;
  --admin-nav-font-color: #FFFFFFCC;
  --admin-nav-active-color: #669DF6;
  --page-content-background: #fafafa;
  --admin-header-content-background: #f1f5f9;
  --button-background-primary: #06a7e1;
  --white-text:#fff;
  --background-white-color: #fff;
  --button-background-secondary: #fff;
  --button-background-primary-hover: #40a9ff;
  --button-background-secondary-outline-hover: #40a9ff;
  --button-background-outline: #d9d9d9;
  --button-color-primary: #fff;
  --button-color-secondary: #0c0b0ba6;
  --ant-tab-inactive-color: #476282;
  --ant-tab-active-color: #1967d2;
  --page-content-h3-color: #222;
  --page-content-h5-color: #555;
  --page-content-h4-color: #3c4043;
  --primary-active-color: #1890ff;
  --primary-inactive-color: #a0a7b4;
  --primary-warning-color: #F99D1B;
  --dark-text-color: #666666;
  --sm-card-h5-color: #222;
  --overlay-background-color: #00000080;
  --profile-tag-color: #ad6f19;
  --profile-tag-background-color: #ebd7bd;
  --nav--hover-background: #ffffff14;
  --nav-admin-section-highlight: #47628233;
  --nav-admin-section-hover: #47628266;
  --black-text: #000000;
  --drk-text: #212121;
  --med-drk-text: #424242;
  --med-text: #616161;
  --med-lgt-text: #757575;
  --lgt-text: #9E9E9E;
  --warning-text: #E65100;
  --accent-text-color: #051e34;
  --nav-admin-section-header: rgba(255, 255, 255, 0.5);
  --right-nav-logout-color: #ffa600;
  --dropdown-background-color: #1a2c3a;
  --dropdown-border-color: #424242;
  --dropdown-background-hover-color: #ffffff14;
  --page-header-svg-color: #424242;
  --page-header-svg-hover: #669DF6;
  --page-header-svg-background: #55545414;
  --paid-color-green: #388E3C;
  --payment-due-color-orange: #F57C00;
  --not-paid-color-red: #D32F2F;
  --voided-color-grey: #616161;
  --pending-color-lgtblue: #29B6F6;
  --posted-color-lgtblue: #0288D1;
  --table-header-color: #05213a;
  --table-row-seperator: rgb(1, 9, 15);
  --star-rating-1-color: #FF5722;
  --star-rating-2-color: #FF9800;
  --star-rating-3-color: #ababab;
  --star-rating-4-color: #03A9F4;
  --star-rating-5-color: #4CAF50;
  --disabled-button-color: #999999;
  --admin-nav-logo-color: #FFFFFF;
  --detached-toogle-button-color: #ffa500;
  --filter-card-icon-color: #76bfe0;
  --filter-location-card-background: #051e34, #0d4475;
  --walmart-audit-active-card-background: #d58f28, #f9a326;
  --filter-listing-card-background: #0d4475, #29679e;
  --timeline-dot-background: #94a5b9;
  --timeline-icon-color: #81d4f9;
  --custom-popover-background: #f1f5f9;
  --comment-filter-background: #81d4f9;
  --account-filter-background: #9c27b0;
  --response-rate-background:#f99d1b;
  --campaign-period-color:#e9a94f;
  --default-background-color: rgb(231, 228, 228);
  --svg-icon-color:#669DF6;
  --button-inactive-state-color: #cccccc;
  --button-active-state-color:#d2dfec;
  --location-background-color: #7fbae7;
  --group-background-color: #ffa500;
  --error-background-color:#fff2f4;
  --error-field-color:#E30000;
  --error-border-color:#E30000;
  --background-drk-black: #000000;
  --background-white-color: #fff;
  --background-med-black : #0d0d0d;
  --border-med-black:#555;
  --placeholder-lgt-black:#777;
  --button-background-lgt-black:#565656;
  
  --dash-neg-background: rgb(255, 228, 230);
  --dash-pos-background: rgb(209, 250, 229);
  --dash-neutral-background:rgb(245, 245, 245);
  --dash-neg-color: rgb(159, 18, 57);
  --dash-pos-color: rgb(6, 95, 70);
  --dash-neutral-color: rgb(64, 64, 64);

  --dash-avg-text-color:#737373;
  --dash-info-text-color:rgb(115, 115, 115);
  --dash-metric-text-color : #1d4de8;
  
  --dash-card-box-shadow: 0px 4px 16px -2px rgba(0, 0, 0, 0.08),
  0px 2px 4px -1px rgba(0, 0, 0, 0.08);

  --dash-card-border-color: rgb(229, 229, 229)
}