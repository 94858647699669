@media print {

    *,
    *::before,
    *::after {
        text-shadow: none !important;
        box-shadow: none !important;
        -webkit-print-color-adjust: exact;
        print-color-adjust: exact;
    }

    @page {
        size: A4 portrait;
        margin: 25mm 25mm 25mm 25mm;
    }


    body {
        background: #fff !important;
    }

    svg:not(:root) {
        overflow: visible !important;
    }

    svg {
        display: block;
        width: 100% !important;
        min-width: 100% !important;
        max-width: 100% !important;
    }

    .page-break {
        height: 0;
        page-break-before: always;
        margin: 0;
        border-top: none;
    }
}