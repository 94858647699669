@media print and (color) {


    .recharts-responsive-container {
        width: 100% !important;
    }

    .reviews-values {
        text-align: center;
        font-size: 48px;
        font-weight: 600;
    }

    .reviews-icon {
        display: block;
        padding: 4px;
        text-align: center;
        position: relative;
    }

    .reviews-icon span {
        display: inline-block;
        position: relative;
        background-color: #fff !important;
        padding: 4px;
        -webkit-border-radius: 3px;
        border-radius: 3px;
        border: #ebebeb solid 1px;
        z-index: 1;
    }

    .reviews-content {
        padding: 2px 0 8px 0;
        font-size: 16px;
        text-align: center;
    }

    .reviews-values {
        text-align: center;
        font-size: 48px;
        font-weight: 600;
    }

    .summary-content {
        display: block;
        font-size: 16px;
    }

    .rating-bar {
        display: block;
    }

    .rating-bar .bar-item {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: flex-start;
        align-content: center;
        margin-bottom: 8px;
    }

    .rating-bar .bar-item:last-child {
        margin-bottom: 0px;
    }

    .bar-item .bar-label {
        flex: 0 0 auto;
        font-size: 11px;
        letter-spacing: 4px;
        margin-right: 2px;
    }

    .bar-item [class*="ratingbar-"] {
        flex: 1 1 auto;
        position: relative;
        margin: 0px;
    }

    .bar-item .bar {
        display: block;
        margin: 0px;
        padding: 0px;
        height: 18px;
        opacity: 0.8;
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        -ms-transition: all 0.3s ease;
        transition: all 0.3s ease;
    }

    .ratingbar-5-star-replies .bar {
        background-color: #6bcbee !important;
    }

    .ratingbar-4-star-replies .bar {
        background-color: #6ac391 !important;
    }

    .ratingbar-3-star-replies .bar {
        background-color: #dadfe6 !important;
    }

    .ratingbar-2-star-replies .bar {
        background-color: #fdbd62 !important;
    }

    .ratingbar-1-star-replies .bar {
        background-color: #f89673 !important;
    }

    .ratingbar-5-star .bar {
        background-color: #6bcbee !important;
        -webkit-print-color-adjust: exact;
    }

    .ratingbar-5-star:hover .bar {
        background-color: #5ab6d8;
    }

    .ratingbar-4-star .bar {
        background-color: #7ad7a3 !important;
        -webkit-print-color-adjust: exact;
    }

    .ratingbar-4-star:hover .bar {
        background-color: #6ac391;
    }

    .ratingbar-3-star .bar {
        background: #dadfe6 !important;
        -webkit-print-color-adjust: exact;
    }

    .ratingbar-3-star:hover .bar {
        background-color: #c2c9d2;
    }

    .ratingbar-2-star .bar {
        background-color: #fdbd62 !important;
        -webkit-print-color-adjust: exact;
    }

    .ratingbar-2-star:hover .bar {
        background-color: #e4a955;
    }

    .ratingbar-1-star .bar {
        background-color: #f89673 !important;
        -webkit-print-color-adjust: exact;
    }

    .ratingbar-1-star:hover .bar {
        background-color: #dd805e;
    }

    .bar-value {
        color: #333;
        position: absolute;
        font-size: 11px;
        top: 0px;
        left: 3px;
    }

    .score {
        font-size: 50px;
        text-align: center;
        line-height: 55px;
        font-style: normal !important;
        font-weight: 200 !important;
    }

    .insight-values {
        padding: 30px 0;
        background-color: #f7f7f7;
    }

    .insight-values span {
        display: block;
        padding-left: 20px;
    }

    .insight-values span:first-child {
        font-size: 34px;
        font-weight: 600;
    }

    .insight-values span:last-child {
        font-size: 15px;
        color: #9f9f9f;
    }

    .insight-labels {
        padding-top: 10px;
        padding-bottom: 30px;
    }

    .insight-labels span {
        display: block;
        text-align: center;
        font-size: 16px;
    }

    .insight-labels span:first-child {
        font-weight: 600;
        font-size: 24px;
    }

    .row {
        margin-top: 15px;
    }

    .anticon-star:before {
        color: #6bcbee;
    }

    .review_chart__wrapper .recharts-wrapper {
        float: left !important;
        width: 100% !important;
        overflow: hidden !important;
    }

    .below30 {
        color: #F15922 !important;
    }

    .below50 {
        color: #F99D1B !important;
    }

    .greaterthan50 {
        color: #2FB66A !important;
    }

    .star-rating-block span,
    .star-rating-block [class*="react-stars-"]:before {
        color: #6BCBEE !important;
    }

    .star-rating-block .empty_rating span,
    .star-rating-block [class*="react-stars-"],
    .star-rating-block [class*="react-stars-"]+span {
        color: #DEDEDE !important;
    }

    .page-break {
        height: 0;
        page-break-before: always;
        margin: 0;
        border-top: none;
    }
}