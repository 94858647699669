@import url(https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,300;1,400;1,500;1,700;1,900&display=swap);
/*---------Color-----------*/
/*--  font size  --*/
/*--  Group color  --*/
/*=====
alpha
========*/
/*=====
linear-gradien
========*/
/*====
transition
======*/
/*====
transform
======*/
/*----
placeholder
-----*/
/*----
flex
-----*/
/*-=====
opasity
=======*/
/*-=====
appearance
=======*/
/*-=====
filter
=======*/
/*-=====
react select
=======*/
/*-=====
mediaquery
=======*/
/*-=====
Animation
=======*/
/*=====
alpha
========*/
/*=====
linear-gradien
========*/
/*====
transition
======*/
/*====
transform
======*/
/*----
placeholder
-----*/
/*----
flex
-----*/
/*-=====
opasity
=======*/
/*-=====
appearance
=======*/
/*-=====
filter
=======*/
/*-=====
react select
=======*/
/*-=====
mediaquery
=======*/
/*-=====
Animation
=======*/
.flex-column, .sidebar-block, .sidebar-block .menu-block, .sidebar-block .menu-block .sidebar-menu, .sidebar-block .setting-block a {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column; }

.inline-flex {
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: horizontal;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row; }

.flexbox, .sidebar-block .logo, .sidebar-block .logo .logo-minimised, .sidebar-block .menu-block .sidebar-menu li a, .sidebar-block .menu-block .sidebar-menu li a .nav-icon, .sidebar-block .menu-block .sidebar-menu li a .nav-icon.sentiment, .sidebar-block .setting-block, .sidebar-block .setting-block a .nav-icon {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: horizontal;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row; }

.flex-reverse {
  -webkit-box-direction: reverse;
  -webkit-box-orient: horizontal;
  -webkit-flex-direction: row-reverse;
  -moz-flex-direction: row-reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse; }

.column-reverse {
  -webkit-box-direction: reverse;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column-reverse;
  -moz-flex-direction: column-reverse;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse; }

.all-center, .sidebar-block .logo, .sidebar-block .logo .logo-minimised, .sidebar-block .menu-block .sidebar-menu li a .nav-icon, .sidebar-block .menu-block .sidebar-menu li a .nav-icon.sentiment, .sidebar-block .setting-block, .sidebar-block .setting-block a, .sidebar-block .setting-block a .nav-icon {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center; }

.justify-center {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center; }

.justify-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between; }

.justify-around {
  -ms-flex-pack: distribute;
  -webkit-justify-content: space-around;
  -moz-justify-content: space-around;
  justify-content: space-around; }

.justify-start {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  justify-content: flex-start; }

.justify-end {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  -webkit-justify-content: flex-end;
  -moz-justify-content: flex-end;
  justify-content: flex-end; }

.item-center, .sidebar-block .menu-block .sidebar-menu li a {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center; }

.item-start {
  -webkit-box-align: start;
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  -moz-align-items: flex-start;
  align-items: flex-start; }

.item-end {
  -webkit-box-align: end;
  -ms-flex-align: end;
  -webkit-align-items: flex-end;
  -moz-align-items: flex-end;
  align-items: flex-end; }

.item-stretch {
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  -webkit-align-items: stretch;
  -moz-align-items: stretch;
  align-items: stretch; }

.item-nowrap, .sidebar-block .menu-block .sidebar-menu li a {
  -ms-flex-wrap: none;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap; }

.item-wrap {
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap; }

.graytbl-header {
  color: #202124;
  font-size: 12px;
  font-weight: 700; }

.whitetbl-header {
  color: #3c4043 !important;
  font-size: 12px;
  font-weight: 700; }

.tblrow-color {
  color: #3c4043;
  font-size: 13px;
  font-weight: 400; }

.tblprimary-color {
  color: #3c4043;
  font-size: 13px;
  font-weight: 700; }

.sidebar-block {
  position: relative;
  height: 100vh;
  padding-bottom: 61px; }
  .sidebar-block .logo {
    flex: 0 0 auto;
    height: 60px;
    overflow: hidden;
    background-color: #ffffff; }
    .sidebar-block .logo .logo-expanded {
      flex: 0 0 auto;
      display: none; }
    .sidebar-block .logo .logo-minimised {
      flex: 0 0 auto;
      width: 48px;
      height: 48px;
      overflow: hidden; }
      .sidebar-block .logo .logo-minimised img {
        height: 100%; }
  .sidebar-block .menu-block {
    margin: 0;
    flex: 1 1 auto; }
    .sidebar-block .menu-block .sidebar-menu {
      position: relative; }
      .sidebar-block .menu-block .sidebar-menu li {
        flex: 0 auto;
        border-color: #5c6067 transparent #474646 transparent;
        border-style: solid;
        border-width: 1px 0 1px 0;
        position: relative; }
        .sidebar-block .menu-block .sidebar-menu li:nth-last-of-type(2) {
          margin: 0; }
          .sidebar-block .menu-block .sidebar-menu li:nth-last-of-type(2):before {
            position: absolute;
            width: 100%;
            height: 1px;
            background-color: #5c6067;
            content: '';
            bottom: -2px; }
        .sidebar-block .menu-block .sidebar-menu li a {
          -moz-transition: all 2ms ease-in-out;
          -o-transition: all 2ms ease-in-out;
          -webkit-transition: all 2ms ease-in-out;
          transition: all 2ms ease-in-out;
          padding: 0 18px;
          min-height: 52px;
          color: #c4c4c5;
          letter-spacing: 0;
          font-size: 14px !important;
          position: relative; }
          .sidebar-block .menu-block .sidebar-menu li a em {
            position: absolute;
            font-style: normal;
            font-size: 11px;
            line-height: 18px;
            font-weight: 300;
            bottom: 8px;
            right: 4px;
            background: #06a7e1;
            padding: 0 5px;
            color: #fff !important;
            border-radius: 2px; }
          .sidebar-block .menu-block .sidebar-menu li a.active, .sidebar-block .menu-block .sidebar-menu li a:hover, .sidebar-block .menu-block .sidebar-menu li a:focus {
            letter-spacing: 0;
            -moz-transition: all 0.5s ease-in-out;
            -o-transition: all 0.5s ease-in-out;
            -webkit-transition: all 0.5s ease-in-out;
            transition: all 0.5s ease-in-out; }
          .sidebar-block .menu-block .sidebar-menu li a .nav-icon {
            flex: 0 auto;
            min-width: 30px;
            max-width: 30px;
            min-height: 30px;
            max-height: 30px;
            margin: 0px;
            padding: 0px;
            -moz-transition: all 2ms ease-in-out;
            -o-transition: all 2ms ease-in-out;
            -webkit-transition: all 2ms ease-in-out;
            transition: all 2ms ease-in-out; }
            .sidebar-block .menu-block .sidebar-menu li a .nav-icon img {
              display: block;
              width: 100%; }
            .sidebar-block .menu-block .sidebar-menu li a .nav-icon i {
              font-size: 24px;
              -moz-transition: all 0.5s ease-in-out;
              -o-transition: all 0.5s ease-in-out;
              -webkit-transition: all 0.5s ease-in-out;
              transition: all 0.5s ease-in-out; }
            .sidebar-block .menu-block .sidebar-menu li a .nav-icon.sentiment i {
              font-size: 16px !important; }
              .sidebar-block .menu-block .sidebar-menu li a .nav-icon.sentiment i.flip {
                -webkit-transform: rotateY(180deg);
                -ms-transform: rotateY(180deg);
                -o-transform: rotateY(180deg);
                transform: rotateY(180deg); }
          .sidebar-block .menu-block .sidebar-menu li a .nav-label {
            opacity: 0;
            height: 0;
            flex: 1 auto;
            margin-left: 6px;
            text-transform: capitalize;
            -moz-transition: all 1ms ease-in-out;
            -o-transition: all 1ms ease-in-out;
            -webkit-transition: all 1ms ease-in-out;
            transition: all 1ms ease-in-out; }
  .sidebar-block .setting-block {
    position: absolute;
    width: 100%;
    height: 65px;
    bottom: 0;
    left: 0;
    border: 0;
    background-color: #06a7e1 !important; }
    .sidebar-block .setting-block a {
      flex: 1 1 auto;
      background-color: #06a7e1 !important;
      -moz-transition: all 0.5s ease-in-out;
      -o-transition: all 0.5s ease-in-out;
      -webkit-transition: all 0.5s ease-in-out;
      transition: all 0.5s ease-in-out; }
      .sidebar-block .setting-block a .nav-icon {
        flex: 0 auto;
        width: 30px;
        height: 30px;
        margin: 0px;
        padding: 0px;
        -moz-transition: all 0.5s ease-in-out;
        -o-transition: all 0.5s ease-in-out;
        -webkit-transition: all 0.5s ease-in-out;
        transition: all 0.5s ease-in-out; }
        .sidebar-block .setting-block a .nav-icon img {
          display: block;
          width: 100%; }
        .sidebar-block .setting-block a .nav-icon i {
          font-size: 24px;
          -moz-transition: all 0.5s ease-in-out;
          -o-transition: all 0.5s ease-in-out;
          -webkit-transition: all 0.5s ease-in-out;
          transition: all 0.5s ease-in-out; }
      .sidebar-block .setting-block a .nav-label {
        flex: 1 auto;
        margin: 0;
        display: none;
        color: #ffffff !important;
        text-transform: capitalize;
        -moz-transition: all 0.8s ease-in-out;
        -o-transition: all 0.8s ease-in-out;
        -webkit-transition: all 0.8s ease-in-out;
        transition: all 0.8s ease-in-out; }
      .sidebar-block .setting-block a:hover, .sidebar-block .setting-block a:focus, .sidebar-block .setting-block a.active {
        background-color: #06a7e1;
        color: #ffffff;
        -moz-transition: all 0.5s ease-in-out;
        -o-transition: all 0.5s ease-in-out;
        -webkit-transition: all 0.5s ease-in-out;
        transition: all 0.5s ease-in-out; }
        .sidebar-block .setting-block a:hover .nav-icon, .sidebar-block .setting-block a:focus .nav-icon, .sidebar-block .setting-block a.active .nav-icon {
          margin: 0; }
          .sidebar-block .setting-block a:hover .nav-icon i, .sidebar-block .setting-block a:focus .nav-icon i, .sidebar-block .setting-block a.active .nav-icon i {
            color: #ffffff;
            -moz-transition: all 0.5s ease-in-out;
            -o-transition: all 0.5s ease-in-out;
            -webkit-transition: all 0.5s ease-in-out;
            transition: all 0.5s ease-in-out; }
    .sidebar-block .setting-block:hover {
      background-color: #06a7e1;
      color: #ffffff !important; }

.collapsed {
  padding: 0; }
  .collapsed .sidebar-block {
    margin: 0; }
    .collapsed .sidebar-block .logo {
      flex: 0 0 auto; }
      .collapsed .sidebar-block .logo .logo-expanded {
        flex: 0 0 auto;
        display: block;
        width: 180px; }
      .collapsed .sidebar-block .logo .logo-minimised {
        flex: 0 0 auto;
        display: none; }
    .collapsed .sidebar-block .sidebar-menu {
      margin: 0; }
      .collapsed .sidebar-block .sidebar-menu li {
        margin: 0; }
        .collapsed .sidebar-block .sidebar-menu li a {
          -moz-transition: all 2ms ease-in-out;
          -o-transition: all 2ms ease-in-out;
          -webkit-transition: all 2ms ease-in-out;
          transition: all 2ms ease-in-out; }
          .collapsed .sidebar-block .sidebar-menu li a .nav-icon {
            flex: 0 auto;
            -moz-transition: all 5ms ease-in-out;
            -o-transition: all 5ms ease-in-out;
            -webkit-transition: all 5ms ease-in-out;
            transition: all 5ms ease-in-out; }
            .collapsed .sidebar-block .sidebar-menu li a .nav-icon.sentiment {
              padding: 0; }
              .collapsed .sidebar-block .sidebar-menu li a .nav-icon.sentiment i {
                font-size: 14px !important; }
          .collapsed .sidebar-block .sidebar-menu li a .nav-label {
            flex: 0 1 auto;
            opacity: 1;
            height: auto;
            -moz-transition: all 1s ease-in-out;
            -o-transition: all 1s ease-in-out;
            -webkit-transition: all 1s ease-in-out;
            transition: all 1s ease-in-out; }
