
@import '../../../../../sass/themes/index.scss';
@import '../../../../../sass/icons/index.scss';
.add-profile-modal-Wrapper{
    display: flex;
    // width: 1000px;
    height: 720px;
    .left-side-section{
        background-color: #ebebeb;
        padding: 20px 0;
        flex: 40%;
        max-width: 40%;
        .head-section{
            display: flex;
            align-items: center;
            padding: 20px 30px;
            gap: 5px;
            img{
                width: $icon-size-xl;
                height: $icon-size-xl;;
                // border-radius: 5px;
                
            }
            h2{
                font-size: 24px;
                font-weight: 700;
                color: $drk-text;
            }
        }
        .body-section{
            flex: 1;
            box-sizing: border-box;
            overflow: hidden; 
            height: 300px;
            width: 100%;
            margin: 50px 0 40px 0;
            .social-media-icon-1, .social-media-icon-2,.social-media-icon-3,.social-media-icon-4,.social-media-icon-5,.social-media-icon-6{
                height: inherit;
                box-sizing: border-box;
                width: 120%;
                display: flex;
                align-items: center;
                justify-content: center;
                // background-image: $new-profile-left1;
                background-size: cover;
                margin-left: -35px;
                // margin-top: -20px;
            }
            .social-media-icon-1{
                background-image: $new-profile-left1;

            }
            .social-media-icon-2{
                background-image: $new-profile-left2;

            }
            .social-media-icon-3{
                background-image: $new-profile-left3;

            }
            .social-media-icon-4{
                background-image: $new-profile-left4;

            }
            .social-media-icon-5{
                background-image: $new-profile-left5;

            }
            .social-media-icon-6{
                background-image: $new-profile-left6;

            }

        }
        .left-Footer{
            display: flex;
            flex-direction: column;
            padding: 40px 30px;
            .footer-section{
                display: flex;
                flex-direction: column;
                gap: 10px;
                // justify-content: center;
                // align-items: center;
                
                    font-weight: 600;
                    color: black;
                .step-label{
                    width: 100%;
                    height: 20px;
                    font-size: 16px;
                    color: $drk-text ;
                    
                }
                .step-content-section{
                    display: flex;
                    flex-direction: column;
                    gap: 4px;
                    .header{
                        font-size: 20px;
                        color: $drk-text;
                    }
                    .content{
                        font-size: 15px;
                        color: $med-text;
                        font-weight: 400;
                        min-height: 45px;
                    }
                
                    .step-count-line{
                        margin-top: 20px;
                        display: flex;
                        gap: 10px;
                        .line{
                            width: 3.7rem;
                            height: 6px;
                            background-color: white;
                            border-radius: 5px;
                        }
                        .line-active{
                            background-color: $black-text;
                        }
                      
                    }
                }


            }
        }
    }

    .right-side-section{
        flex: 60%;
       
        display: flex;
        flex-direction: column;
        gap: 10px;

        .header-section{
            height: 105px;
            border-bottom: 3px solid #f6f7f9;
            justify-content: space-between;
            display: flex;
            // align-items: center;
            .header{
                display: flex;
                flex-direction: column;
                padding: 30px 40px 0 40px;
                // gap: 5px;
                h2{
                    font-size: 24px;
                    font-weight: 700;
                    color: $drk-text;
                }
                .sub-header{
                    color: $med-text;
                    // padding: 0px 40px 10px 40px;
                    font-size: 15px;
                    font-weight: 400;
                    // border-bottom: 4px solid #f6f7f9;
                    margin-bottom: 6px;
                    span{
                        color: $warning-text;
                        margin-left: 1px;
                    }
    
                }
            }
            .modal-close{
                margin: 10px 20px 0 0;
                cursor: pointer;
            svg{
                width: 1rem;
                height: 1rem;
            }
        }
            
        }

        .body-content{
            // padding: 30px 0px 0 40px;
            max-height: 600px;
            // overflow: auto;
            .body-section{
                min-height: 550px;
                max-height: 550px;
                padding: 30px 40px 20px 40px;
                // padding-right: 40px;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                overflow: auto;
                // width: 100%;
                // padding: 10px 0;
                .profile-image-section{
                    display: flex;
                    flex-direction: column;
                    gap: 8px;
                    margin-bottom: 20px;
                    .icon-label{
                        font-size: 17px;
                        font-weight: 600;
                        color: $drk-text;
                    }
                    .profile-upload-block{
                        display: flex;
                        gap: 12px;
                        margin-bottom: 15px;
                        img{
                            width: $icon-size-xxl;
                            height: $icon-size-xxl;
                        }
                        .upload-button-section{
                            display: flex;
                            flex-direction: column;
                            gap: 0px;
                            .upload-button-block{
                                display: flex;
                                gap: 10px;
                            }
                            button{
                                max-width: 8rem;
                                max-height: 1.8rem;
                                border-radius: 10px;
                                font-size: 16px;
                                padding: 15px 10px;
                                font-weight: 700;
                                color: $drk-text;
                            }
                            p{
                                font-size: 14px;
                                color: $med-drk-text;
                            }
                        }

                    }
                }
                .profile-details{
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    margin-left: 0;
                    
                    ul{
                        width: 100%;
                        li{
                            display:flex;
                            flex-direction: column;
                            padding: 0px 0 20px 0 ;
                            gap: 5px;
                            .ant-select-arrow{
                                font-size: 13px;
                            }
                            span{
                                font-size: 17px;
                                font-weight: 600;
                                color: $drk-text;
                            }
                            input{
                                height: 40px;
                                border-radius: 10px;
                                width: 400px;
                                color:  $drk-text;
                                font-size: 15px;
                                &::placeholder{
                                    color: $med-drk-text;

                                }
                            }
                            &::after{
                                content: none;
                            }
                            .ant-select-selection__rendered{
                                font-size: 15px;
                                color: $drk-text;
                                border-color: #e0e0e0;
                            }
                            .ant-select{
                                color: $drk-text ;
                            }
                            .ant-select-selection__placeholder{
                                color:  $med-drk-text;
                            }
                            .invite-user-block{
                                display: flex;
                                flex-direction: column;
                                gap: 10px;
                                .head{
                                    display: flex;
                                    flex-direction: row;
                                    justify-content: space-between;
                                    :last-child{
                                        margin-right: 14.5rem;
                                    }
                                    h3{
                                        font-size: 17px;
                                        font-weight: 700;
                                        color: $drk-text;
                                    }

                                }
                                .content{
                                    display: flex;
                                    flex-direction: row;
                                    // justify-content: space-between;
                                    gap: 10px;
                                    input{
                                        height: 40px;
                                        border-radius: 10px;
                                        width: 300px;
                                        font-size: 15px;
                                        // border: solid 1px rgb(129, 125, 125);
                                        color: $drk-text ;
                                    }
                                    
                                    .ant-input-prefix{
                                        svg{
                                            width: 1rem;
                                            color: $med-drk-text;
                                        }
                                    }
                                    .ant-input-affix-wrapper {
                                        width: inherit;
                                    .ant-input:not(:first-child){
                                        padding-left: 35px;
                                        margin-top: -2px;
                                        width: 300px;
                                    }}
                                    .ant-select-selection{
                                        // max-width: 150px;
                                        height: 40px;
                                        border-radius: 10px;
                                        border-color: #e0e0e0;


                                    }
                                    .ant-select-enabled{
                                        max-width: 150px;
                                    }
                                    .ant-select-selection__rendered{
                                        margin-right:1rem;
                                        font-weight: 400;
                                        font-size: 15px;
                                        color: $drk-text;
                                      

                                        
                                    }
                                    .ant-select-arrow{
                                        font-size: 13px;
                                    }
                                //    font-size: 14px;
                                   
                                }
                                p{
                                    font-size: 14px;
                                    color: $drk-text;
                                    margin-right: 3.5rem;
                                }
                            }
                            .footer-invite{
                                margin-top: 20px;
                                display: flex;
                                flex-direction: column;
                                gap: 10px;
                                margin-right: 3.5rem;
                                .head{
                                    font-size: 17px;
                                    font-weight: 700;
                                    color:$drk-text;
                                }
                                .ant-input{
                                    font-size: 15px;
                                    color: $drk-text;

                                }
                                .custom-text-area {
                                    &::placeholder{
                                    color: $med-drk-text;
                                }}
                            }
                        }
                        .ai-content{
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                            padding-bottom: 30px;
                            .label-section{
                                display: flex;
                                flex-direction: column;
                                justify-content: flex-start;
                                h3{
                                    font-size: 17px;
                                    font-weight: 700;
                                    color: $drk-text;
                                }
                                p{
                                    font-size: 14px;
                                    max-width: 400px;
                                    color: $drk-text;
                                    span{
                                        font-size: 14px;
                                        font-weight: 400;
                                        color: $warning-text;
                                    }
                                }
                                .sub-label{
                                    margin-left: 3rem;
                                }
                            }
                            .switch-element{
                                display: flex;
                                align-items: center;
                                justify-content: flex-end;
                                .ant-switch-inner{
                                    font-size: 13px;
                                    color: white;
                                    font-weight: 400;
                                }
                                .ant-select-selection{
                                    border-radius: 10px;
                                    border-color: #e0e0e0;

                                }
                                .ant-switch{
                                    background-color: $primary-inactive-color;
                                }
                                .ant-switch-checked{
                                    background-color: $primary-active-color;
                                }
                            }
                        }
                      

                        .language-content{
                            display: flex;
                            flex-direction: column;
                            // gap: 10px;
                            margin-bottom: 10px;
                            span{
                                display: flex;
                                flex-direction: column;
                                gap: 0px;
                                h3{
                                    font-size: 17px;
                                    font-weight: 700;
                                    color: $drk-text;
                                }
                                p{
                                    font-weight: 400;
                                    font-size: 14px;
                                    color: $drk-text;
                                }
                               
                               
                                
                            }
                            .content-select{
                                display: flex;
                                flex-direction: row ;
                                gap: 15px;
                            }
                            .label-timezone{
                                font-size: 14px;
                                font-weight: 400;
                                color: $drk-text;
                            }
                            .ant-select{
                                width: 250px;
                            }.ant-select-selection{
                                // width: 250px !important;
                                // overflow: auto;
                                border-color: #e0e0e0;

                                border-radius: 10px;

                            }
                            .ant-select-selection__placeholder{
                                font-weight: 400;
                            }
                            .ant-select-arrow{
                                font-size: 13px;
                            }
                            .ant-select-selection__choice{
                                padding:0px 20px 0px 20px;
                            }
                            .ant-select-selection__choice__content{
                                overflow: visible;
                                
                            }
                            .ant-select-selection__rendered{
                                font-weight: 400;
                            }
                            .ant-select-selection__choice__remove{
                                font-size: 12px;
                                margin-top: 5px;
                                margin-left: 30px;
                            }
                            .ant-select-search__field{
                                // input{
                                    display: none;
                                    pointer-events: none;
                                // }
                            }

                        }
                    }
                    .confirm-image-section{
                        display: flex;
                        justify-content: flex-start;
                        width: 100%;
                        .head{
                            display: flex;
                            gap: 10px;
                            justify-content: flex-start;
                            align-items: flex-start;
                            margin: 0;
                            img{
                                width: 3rem;
                                height: 3rem;
                                // border-radius: 5px;
                            }
                            .head-sub{
                                display: flex;
                                margin-top: 3px;
                                flex-direction: column;
                                .sub-section{
                                    display: flex;
                                    gap: 8px;
                            h3{
                                font-size: 17px;
                                font-weight: 700;
                                color: $drk-text;
                            }
                            p{
                                font-size: 14px;
                                font-weight: 400;
                            }}
                            p{
                                color: $drk-text;
                            }
                        }
                        }
                    }
                    .settings-block{
                        margin-top: 20px;
                        width: 500px;
                        margin-bottom: 20px;
                        .header{
                            h3{
                                font-size: 17px;
                                font-weight: 700;
                                color: black;
                                padding-bottom: 5px;
                            }
                            border-bottom: solid 3px #f6f7f9;
                        }
                        .settings-content{
                            margin-top: 5px;
                            display: flex;
                            
                            gap: 20px;
                            .content-item{
                                // width: 250px;
                                display: flex;
                                flex-direction: column;
                                span{
                                    display: flex;
                                    gap: 5px;
                                    
                                }
                                .label,.item{
                                    // width: 200px;
                                    font-size: 14px;
                                    color: black;
                                }
                            }
                        }
                    }
                    .confirm-reporting{
                        display: flex;
                        flex-direction: column;
                        width: 500px;
                        margin: 30px 0 0 0;
                        .head{
                            margin-bottom: 10px;
                            h3{
                                
                                font-size: 17px !important;
                                font-weight: 700;
                                color: $drk-text;
                                padding-bottom: 5px;
                                

                            }
                            border-bottom: solid 3px #f6f7f9;
                            

                        }
                        .content-wrapper{
                            display: flex;
                            gap: 20px;
                            width: 36rem;
                        
                        .content-section{
                            display: flex;
                            flex-direction: column;
                            min-width: 200px;
                            color: $drk-text;
                            align-items: flex-start;
                           
                            .content{
                                display: flex;
                                gap: 8px;
                                label{
                                    font-size: 15px;
                                }
                                p{
                                    font-size: 15px;
                                    font-weight: 600;
                                }
                            }
                          
                        }
                        .content-profile-block{
                            display: flex;
                            flex-direction: column;
                            // gap: 5px;
                            width: 40rem;
                            
                            .content-item{
                                display: flex;
                            //   min-width: 250px;

                              gap: 5px;
                              align-items: center;
                              span{
                                color: $drk-text;
                                max-width: 200px;
                                font-size: 15px;
                                
                              }
                              .divider{
                               width: 2px;
                              }
                              
                              p{
                                font-size: 15px;
                                color: $drk-text;
                                min-width: 100px;
                                font-weight: 600;
                              }
                             
                        }
                        textarea{
                            min-width: 100px;
                          }
                        }
                    }
                    }
                }

              
            }
        }
        .body-section::-webkit-scrollbar {
            width: 5px; 
          }
          .body-section::-webkit-scrollbar-track {
            background: #f1f1f1;

          }
          .body-section::-webkit-scrollbar-thumb {
            background: #888; 
            border-radius: 10px;

          }
        .footer-content{
            border-top: solid 3px #f6f7f9;
           margin-top: -28px;
            padding: 18px 30px 0 30px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            background-color: white;
            z-index: 999;
            button{
               
                border-radius: 12px;
                padding: 0px 12px;
                gap: 4px;
            }
            .btn-primary{
                background-color: black;
                color: white;
                border-color: black;
                font-weight: 600;

            }
            .btn-default{
                background-color: white;
                color: $drk-text;
                font-weight: 600;
            }
        }
    }
}


.ant-select-props{
    width: 250px !important;

    .ant-select{
        // border: solid 1px #d4d7dc;
        border-radius: 10px;
        height: 40px;

    }
    .ant-select-selection{
        border-radius: 10px;
        height: 40px;
        border-color: #e0e0e0;

    }

}

.ai-block{
    
        margin-top: 20px;
        .ant-switch{
            background-color: $primary-inactive-color;
        }
        .ant-switch-checked{
            background-color: $primary-active-color;
        }
    
}

.invalid{
    .ant-input,.ant-select-selection{
        border: 2px solid $warning-text !important;
        // border-color: rgb(223, 10, 10) !important;
    }
   
}