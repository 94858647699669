@import url(https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,300;1,400;1,500;1,700;1,900&display=swap);
/*---------Color-----------*/
/*--  font size  --*/
/*--  Group color  --*/
/*=====
alpha
========*/
/*=====
linear-gradien
========*/
/*====
transition
======*/
/*====
transform
======*/
/*----
placeholder
-----*/
/*----
flex
-----*/
/*-=====
opasity
=======*/
/*-=====
appearance
=======*/
/*-=====
filter
=======*/
/*-=====
react select
=======*/
/*-=====
mediaquery
=======*/
/*-=====
Animation
=======*/
/*=====
alpha
========*/
/*=====
linear-gradien
========*/
/*====
transition
======*/
/*====
transform
======*/
/*----
placeholder
-----*/
/*----
flex
-----*/
/*-=====
opasity
=======*/
/*-=====
appearance
=======*/
/*-=====
filter
=======*/
/*-=====
react select
=======*/
/*-=====
mediaquery
=======*/
/*-=====
Animation
=======*/
.flex-column, .react-textselect {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column; }

.inline-flex {
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: horizontal;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row; }

.flexbox {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: horizontal;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row; }

.flex-reverse {
  -webkit-box-direction: reverse;
  -webkit-box-orient: horizontal;
  -webkit-flex-direction: row-reverse;
  -moz-flex-direction: row-reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse; }

.column-reverse {
  -webkit-box-direction: reverse;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column-reverse;
  -moz-flex-direction: column-reverse;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse; }

.all-center {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center; }

.justify-center {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center; }

.justify-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between; }

.justify-around {
  -ms-flex-pack: distribute;
  -webkit-justify-content: space-around;
  -moz-justify-content: space-around;
  justify-content: space-around; }

.justify-start {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  justify-content: flex-start; }

.justify-end {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  -webkit-justify-content: flex-end;
  -moz-justify-content: flex-end;
  justify-content: flex-end; }

.item-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center; }

.item-start {
  -webkit-box-align: start;
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  -moz-align-items: flex-start;
  align-items: flex-start; }

.item-end {
  -webkit-box-align: end;
  -ms-flex-align: end;
  -webkit-align-items: flex-end;
  -moz-align-items: flex-end;
  align-items: flex-end; }

.item-stretch {
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  -webkit-align-items: stretch;
  -moz-align-items: stretch;
  align-items: stretch; }

.item-nowrap {
  -ms-flex-wrap: none;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap; }

.item-wrap {
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap; }

.graytbl-header {
  color: #202124;
  font-size: 12px;
  font-weight: 700; }

.whitetbl-header {
  color: #3c4043 !important;
  font-size: 12px;
  font-weight: 700; }

.tblrow-color {
  color: #3c4043;
  font-size: 13px;
  font-weight: 400; }

.tblprimary-color {
  color: #3c4043;
  font-size: 13px;
  font-weight: 700; }

.react-textselect {
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  min-width: 250px;
  border: #a0a7b4 solid 1px;
  position: relative;
  margin: 0px;
  padding: 0;
  line-height: 32px;
  background: #fff; }
  .react-textselect .textselect-value {
    flex: 1 1 auto;
    font-size: 14px;
    position: relative;
    padding: 0px 40px 0px 10px;
    width: 100%;
    color: #696f7b;
    overflow: hidden;
    cursor: pointer;
    -moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out; }
    .react-textselect .textselect-value.customAnimation {
      -moz-transition: all 0.4s ease-in-out;
      -o-transition: all 0.4s ease-in-out;
      -webkit-transition: all 0.4s ease-in-out;
      transition: all 0.4s ease-in-out; }
    .react-textselect .textselect-value:after {
      position: absolute;
      top: 50%;
      right: 10px;
      display: block;
      content: '';
      height: 0;
      width: 0;
      border: .43em solid transparent;
      border-top: 0.43em solid #a0a7b4;
      margin-top: -0.17em; }
  .react-textselect .react-textselect-input {
    position: absolute;
    padding: 0;
    opacity: 0;
    left: -1px;
    top: 100%;
    margin-top: -2px;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 999;
    max-height: 0;
    width: calc(100% + 2px);
    border: #a0a7b4 solid 1px;
    border-top: 0px;
    background-color: #ffffff;
    -moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
    -webkit-box-shadow: 0, 1px, 2px, rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0, 1px, 2px, rgba(0, 0, 0, 0.1);
    box-shadow: 0, 1px, 2px, rgba(0, 0, 0, 0.1);
    border-radius: 0 0 3px 3px;
    -webkit-border-radius: 0 0 3px 3px;
    -moz-border-radius: 0 0 3px 3px; }
    .react-textselect .react-textselect-input li {
      margin: 0px !important;
      position: relative;
      min-height: 32px;
      font-size: 14px;
      color: #696f7b;
      cursor: pointer;
      border-bottom: #ddd solid 1px; }
      .react-textselect .react-textselect-input li:last-child {
        border-bottom: 0px; }
      .react-textselect .react-textselect-input li:hover {
        background: #e8ebf0; }
      .react-textselect .react-textselect-input li:first-child input {
        padding: 2px !important;
        line-height: 22px !important; }
      .react-textselect .react-textselect-input li a {
        display: block;
        min-height: 32px;
        font-size: 14px;
        color: #696f7b;
        padding: 0px 10px !important; }
    .react-textselect .react-textselect-input::-webkit-scrollbar {
      width: 4px; }
    .react-textselect .react-textselect-input::-webkit-scrollbar-track {
      -webkit-box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
      -moz-box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
      box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
      -webkit-box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
      -moz-box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
      box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
      border-radius: 50px;
      -webkit-border-radius: 50px;
      -moz-border-radius: 50px; }
    .react-textselect .react-textselect-input::-webkit-scrollbar-thumb {
      width: 4px;
      background-color: #06a7e1;
      border-radius: 50px;
      -webkit-border-radius: 50px;
      -moz-border-radius: 50px; }
  .react-textselect.open {
    margin: 0; }
    .react-textselect.open .react-textselect-input {
      opacity: 1;
      max-height: 300px;
      -moz-transition: all 0.4s ease-in-out;
      -o-transition: all 0.4s ease-in-out;
      -webkit-transition: all 0.4s ease-in-out;
      transition: all 0.4s ease-in-out; }
  .react-textselect.bright:after {
    border-top: 0.33em solid rgba(255, 255, 255, 0.33); }
