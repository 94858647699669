html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
	border: 0;
	outline: 0;
	font-size: 100%;
	vertical-align: baseline;
}

body {
	line-height: 1;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
	display: block;
}

nav,
ul,
li,
ol {
	list-style: none;
}

blockquote,
q {
	quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
	content: '';
	content: none;
}

a {
	margin: 0;
	padding: 0;
	font-size: 100%;
	vertical-align: baseline;
	background: transparent;
}

ins {
	background-color: #ff9;
	color: #000;
	text-decoration: none;
}

mark {
	background-color: #ff9;
	color: #000;
	font-style: italic;
	font-weight: bold;
}

del {
	text-decoration: line-through;
}

abbr[title],
dfn[title] {
	border-bottom: 1px dotted;
	cursor: help;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

hr {
	display: block;
	height: 1px;
	border: 0;
	border-top: 1px solid #cccccc;
	margin: 1em 0;
	padding: 0;
}

input,
select {
	vertical-align: middle;
}

*,
*::before,
*::after {
	box-sizing: border-box;
	/* 1 */
}

html {
	font-family: sans-serif;
	/* 2 */
	line-height: 1.15;
	/* 3 */
	-webkit-text-size-adjust: 100%;
	/* 4 */
	-ms-text-size-adjust: 100%;
	/* 4 */
	-ms-overflow-style: scrollbar;
	/* 5 */
	-webkit-tap-highlight-color: transparent;
	/* 6 */
}

@-ms-viewport {
	width: device-width;
}

article,
aside,
dialog,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
	display: block;
}

body {
	margin: 0;
	/* 1 */
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
		'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji',
		'Segoe UI Emoji', 'Segoe UI Symbol';
	font-size: 1rem;
	font-weight: normal;
	line-height: 1.5;
	color: #212529;
	text-align: left;
	/* 3 */
	background-color: #fff;
	/* 2 */
}

[tabindex='-1']:focus {
	outline: none !important;
}

hr {
	box-sizing: content-box;
	/* 1 */
	height: 0;
	/* 1 */
	overflow: visible;
	/* 2 */
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin-top: 0;
}

p {
	margin-top: 0;
	margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
	/* 4 */
	text-decoration: underline;
	/* 2 */
	-webkit-text-decoration: underline dotted;
	/* 2 */
	text-decoration: underline dotted;
	/* 2 */
	cursor: help;
	/* 3 */
	border-bottom: 0;
	/* 1 */
}

address {
	margin-bottom: 1rem;
	font-style: normal;
	line-height: inherit;
}

ol,
ul,
dl {
	margin-top: 0;
	margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
	margin-bottom: 0;
}

dt {
	font-weight: bold;
}

dd {
	margin-bottom: 0.5rem;
	margin-left: 0;
	/* Undo browser default */
}

blockquote {
	margin: 0 0 1rem;
}

dfn {
	font-style: italic;
}

b,
strong {
	font-weight: bolder;
}

small {
	font-size: 80%;
}

sub,
sup {
	position: relative;
	font-size: 75%;
	line-height: 0;
	vertical-align: baseline;
}

sub {
	bottom: -0.25em;
}

sup {
	top: -0.5em;
}

a {
	color: #007bff;
	text-decoration: none;
	background-color: transparent;
	-webkit-text-decoration-skip: objects;
}

a:hover {
	color: #0056b3;
	text-decoration: underline;
}

a:not([href]):not([tabindex]) {
	color: inherit;
	text-decoration: none;
}

a:not([href]):not([tabindex]):focus,
a:not([href]):not([tabindex]):hover {
	color: inherit;
	text-decoration: none;
}

a:not([href]):not([tabindex]):focus {
	outline: 0;
}

pre,
code,
kbd,
samp {
	font-family: monospace, monospace;
	/* Correct the inheritance and scaling of font size in all browsers. */
	font-size: 1em;
	/* Correct the odd `em` font sizing in all browsers. */
}

pre {
	/* Remove browser default top margin */
	margin-top: 0;
	/* Reset browser default of `1em` to use `rem`s */
	margin-bottom: 1rem;
	/* Don't allow content to break outside */
	overflow: auto;
	/* We have @viewport set which causes scrollbars to overlap content in IE11 and Edge, so we force a non-overlapping, non-auto-hiding scrollbar to counteract. */
	-ms-overflow-style: scrollbar;
}

/*

Figures

*/

figure {
	/* Apply a consistent margin strategy (matches our type styles). */
	margin: 0 0 1rem;
}

/*

Images and content

*/

img {
	vertical-align: middle;
	border-style: none;
	/* Remove the border on images inside links in IE 10-. */
}

/*
Avoid 300ms click delay on touch devices that support the `touch-action` CSS property.

In particular, unlike most other browsers, IE11+Edge on Windows 10 on touch devices and IE Mobile 10-11 DON'T remove the click delay when `<meta name="viewport" content="width=device-width">` is present. However, they DO support removing the click delay via `touch-action: manipulation`.

See:

* https://getbootstrap.com/docs/4.0/content/reboot/#click-delay-optimization-for-touch

* https://caniuse.com/#feat=css-touch-action

*  https://patrickhlauke.github.io/touch/tests/results/#suppressing-300ms-delay
*/

a,
area,
button,
[role='button'],
input:not([type='range']),
label,
select,
summary,
textarea {
	-ms-touch-action: manipulation;
	touch-action: manipulation;
}

/* 

Tables 

*/

table {
	border-collapse: collapse;
	/* Prevent double borders */
}

caption {
	padding-top: 0.75rem;
	padding-bottom: 0.75rem;
	color: #868e96;
	text-align: left;
	caption-side: bottom;
}

th {
	/* Matches default `<td>` alignment by inheriting from the `<body>`, or the closest parent with a set `text-align`. */
	text-align: inherit;
}

/* 

Forms

*/

/*
Remove the default `border-radius` that macOS Chrome adds.

Details at https://github.com/twbs/bootstrap/issues/24093
*/
button {
	border-radius: 0;
}

/*
Work around a Firefox/IE bug where the transparent `button` background results in a loss of the default `button` focus styles.

Credit: https://github.com/suitcss/base/
*/
button:focus {
	outline: 1px dotted;
	outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
	margin: 0;
	/* Remove the margin in Firefox and Safari */
	font-family: inherit;
	font-size: inherit;
	line-height: inherit;
}

button,
input {
	overflow: visible;
	/* Show the overflow in Edge */
}

button,
select {
	text-transform: none;
	/* Remove the inheritance of text transform in Firefox */
}

/*
1. Prevent a WebKit bug where (2) destroys native `audio` and `video` controls in Android 4.
2. Correct the inability to style clickable types in iOS and Safari.
*/
button,
html [type='button'],
[type='reset'],
[type='submit'] {
	-webkit-appearance: button;
}

/*
Remove inner border and padding from Firefox, but don't restore the outline like Normalize.
*/
button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
	padding: 0;
	border-style: none;
}

input[type='radio'],
input[type='checkbox'] {
	box-sizing: border-box;
	/* 1. Add the correct box sizing in IE 10- */
	padding: 0;
	/* 2. Remove the padding in IE 10- */
}

input[type='date'],
input[type='time'],
input[type='datetime-local'],
input[type='month'] {
	/* 
  Remove the default appearance of temporal inputs to avoid a Mobile Safari bug where setting a custom line-height prevents text from being vertically centered within the input.
  
  See https://bugs.webkit.org/show_bug.cgi?id=139848
  and https://github.com/twbs/bootstrap/issues/11266
  */
	-webkit-appearance: listbox;
}

textarea {
	overflow: auto;
	/* Remove the default vertical scrollbar in IE. */
	/* Textareas should really only resize vertically so they don't break their (horizontal) containers. */
	resize: vertical;
}

fieldset {
	/* 
  Browsers set a default `min-width: min-content;` on fieldsets, unlike e.g. `<div>`s, which have `min-width: 0;` by default. So we reset that to ensure fieldsets behave more like a standard block element.
  
  See https://github.com/twbs/bootstrap/issues/12359
  and https://html.spec.whatwg.org/multipage/#the-fieldset-and-legend-elements  
  */
	min-width: 0;
	/* Reset the default outline behavior of fieldsets so they don't affect page layout. */
	padding: 0;
	margin: 0;
	border: 0;
}

/*
1. Correct the text wrapping in Edge and IE.
2. Correct the color inheritance from `fieldset` elements in IE.
*/
legend {
	display: block;
	width: 100%;
	max-width: 100%;
	/* 1 */
	padding: 0;
	margin-bottom: 0.5rem;
	font-size: 1.5rem;
	line-height: inherit;
	color: inherit;
	/* 2 */
	white-space: normal;
	/* 1 */
}

progress {
	vertical-align: baseline;
	/* Add the correct vertical alignment in Chrome, Firefox, and Opera. */
}

/* 
Correct the cursor style of increment and decrement buttons in Chrome.
*/
[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
	height: auto;
}

[type='search'] {
	/* 
  For details, see https://github.com/twbs/bootstrap/issues/11586.
  */
	outline-offset: -2px;
	-webkit-appearance: none;
}

/*
Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
*/

[type='search']::-webkit-search-cancel-button,
[type='search']::-webkit-search-decoration {
	-webkit-appearance: none;
}

/*
1. Correct the inability to style clickable types in iOS and Safari.
2. Change font properties to `inherit` in Safari.
*/

::-webkit-file-upload-button {
	font: inherit;
	-webkit-appearance: button;
}

/*
Correct element displays
*/

output {
	display: inline-block;
}

summary {
	display: list-item;
	/* Add the correct display in all browsers */
}

template {
	display: none;
	/* Add the correct display in IE */
}

/* 
Always hide an element with the `hidden` HTML attribute (from PureCSS).
Needed for proper display in IE 10-.
*/
[hidden] {
	display: none !important;
}
