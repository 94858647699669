@import "../../sass/themes/index.scss";

.card {
	background: $page-content-background;
	border-radius: 8px;
	padding: 24px 24px 0 24px;
	box-shadow: $dash-card-box-shadow;
	border: 1px solid $dash-card-border-color;
	margin: 8px 0px 8px 4px;
	align-items: flex-start;
	display: flex;
	flex-direction: column;
	// flex-wrap: wrap;
	width: 100%;

	// height: 100%;
	// Responsive Width Adjustments
	@media (min-width: 400px) {
		width: 98.8%;
		height: 600px;
	}

	@media (min-width: 1024px) {
		width: 99.5%;
		height: 600px;
	}

	.headerBlock {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;

		.header {
			h3 {
				font-size: 20px;
				font-weight: 600;
				line-height: 20px;
				letter-spacing: 0em;
				color: $drk-text;
			}

			p {
				margin-top: 5px;
				font-size: 15px;
				font-weight: 600;
				line-height: 20px;
				color: $med-lgt-text;
			}
		}

		.headerSwitch {
			display: flex;
			gap: 10px;
			margin-right: 20px;
		}

	}
}

.loaderBlock {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 600px;
}

.noData {
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 600px;
}

.sourceList {
	padding-top: 20px;
	width: 100%; // Take full width on small screens



	@media (min-width:400px) {
		width: 100%; // Fixed width for medium screens
		height: 600px;
	}

	@media (min-width: 768px) {
		width: 100%; // Fixed width for medium screens
		height: 600px;
	}

	@media (min-width: 1000px) {
		width: 100%; // Fixed width for larger screens
		max-height: 600px;
	}

	@media (min-width: 1024px) {
		width: 100%; // Fixed width for larger screens
		max-height: 600px;
	}
}