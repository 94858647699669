@import url(https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,300;1,400;1,500;1,700;1,900&display=swap);
/*---------Color-----------*/
/*--  font size  --*/
/*--  Group color  --*/
/*=====
alpha
========*/
/*=====
linear-gradien
========*/
/*====
transition
======*/
/*====
transform
======*/
/*----
placeholder
-----*/
/*----
flex
-----*/
/*-=====
opasity
=======*/
/*-=====
appearance
=======*/
/*-=====
filter
=======*/
/*-=====
react select
=======*/
/*-=====
mediaquery
=======*/
/*-=====
Animation
=======*/
/*=====
alpha
========*/
/*=====
linear-gradien
========*/
/*====
transition
======*/
/*====
transform
======*/
/*----
placeholder
-----*/
/*----
flex
-----*/
/*-=====
opasity
=======*/
/*-=====
appearance
=======*/
/*-=====
filter
=======*/
/*-=====
react select
=======*/
/*-=====
mediaquery
=======*/
/*-=====
Animation
=======*/
.flex-column, .notification-menu .notification-list, .notification-menu .notification-list .notification-body, .notification-menu .notification-list .notification-body__item, .notification-menu .notification-list .notification-body__item li, .notification-menu .notification-list .notification-body__item li .notification-content__details, .notification-menu .notification-suggestion .location-details, .notification-menu .notification-suggestion .location-details__body, .notification-menu .notification-suggestion .location-details__body .location-details-item, .notification-menu .notification-suggestion .location-details__body .location-details-item .table-location-detail__content--dateblock li, .notification-menu .notification-suggestion .location-list, .notification-menu .notification-suggestion .location-list__detais, .notification-menu .notification-suggestion .location-list__detais .location-search, .notification-menu .notification-suggestion .location-list__detais .location-search__item, .notification-menu .notification-suggestion .location-list__detais .location-search__item li, .notification-menu .notification-suggestion .location-list__detais .location-item, .notification-menu .notification-suggestion .location-list__body, .notification-menu .notification-suggestion .location-list__body .location-item, .notification-menu .notification-suggestion .location-list__body .location-item li {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column; }

.inline-flex {
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: horizontal;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row; }

.flexbox, .notification-menu, .notification-menu .notification-inner, .notification-menu .notification-list .notification-header, .notification-menu .notification-list .notification-search, .notification-menu .notification-list .notification-body__item li .notification-content, .notification-menu .notification-list .notification-body__item li .notification-content__profile, .notification-menu .notification-list .notification-body__item li .notification-content__details p, .notification-menu .notification-list .notification-body__item li .notification-controls, .notification-menu .notification-list .notification-footer, .notification-menu .notification-suggestion, .notification-menu .notification-suggestion .location-details__header, .notification-menu .notification-suggestion .location-details__body .location-details-item .table-location-detail, .notification-menu .notification-suggestion .location-details__body .location-details-item .table-location-detail__title, .notification-menu .notification-suggestion .location-details__body .location-details-item .table-location-detail__content--dateblock, .notification-menu .notification-suggestion .location-details__body .location-details-item .table-location-detail__content--footer, .notification-menu .notification-suggestion .location-details .location-details-footer, .notification-menu .notification-suggestion .location-list__detais .location-website, .notification-menu .notification-suggestion .location-list__detais .location-search__item li a, .notification-menu .notification-suggestion .location-list__detais .company-profile, .notification-menu .notification-suggestion .location-list__detais .location-item li {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: horizontal;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row; }

.flex-reverse, .notification-menu .notification-inner, .notification-menu .notification-suggestion {
  -webkit-box-direction: reverse;
  -webkit-box-orient: horizontal;
  -webkit-flex-direction: row-reverse;
  -moz-flex-direction: row-reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse; }

.column-reverse {
  -webkit-box-direction: reverse;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column-reverse;
  -moz-flex-direction: column-reverse;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse; }

.all-center, .notification-menu .notification-list .notification-body__item li .notification-content__profile, .notification-menu .notification-suggestion .location-details__body .location-details-item .table-location-detail__title, .notification-menu .notification-suggestion .location-list__detais .company-profile, .notification-menu .notification-suggestion .location-list__detais .location-item li {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center; }

.justify-center {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center; }

.justify-between, .notification-menu .notification-list .notification-header, .notification-menu .notification-list .notification-body__item li .notification-controls, .notification-menu .notification-suggestion .location-details__header {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between; }

.justify-around {
  -ms-flex-pack: distribute;
  -webkit-justify-content: space-around;
  -moz-justify-content: space-around;
  justify-content: space-around; }

.justify-start, .notification-menu .notification-list .notification-body__item li, .notification-menu .notification-suggestion .location-list__body .location-item li {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  justify-content: flex-start; }

.justify-end, .notification-menu {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  -webkit-justify-content: flex-end;
  -moz-justify-content: flex-end;
  justify-content: flex-end; }

.item-center, .notification-menu .notification-list .notification-header, .notification-menu .notification-list .notification-search, .notification-menu .notification-list .notification-body__item li .notification-content__details p, .notification-menu .notification-list .notification-body__item li .notification-controls, .notification-menu .notification-list .notification-footer, .notification-menu .notification-suggestion .location-details__header, .notification-menu .notification-suggestion .location-details__body .location-details-item .table-location-detail__content--footer, .notification-menu .notification-suggestion .location-details .location-details-footer, .notification-menu .notification-suggestion .location-list__detais .location-website, .notification-menu .notification-suggestion .location-list__detais .location-search__item li a {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center; }

.item-start, .notification-menu .notification-list .notification-body__item li, .notification-menu .notification-list .notification-body__item li .notification-content, .notification-menu .notification-suggestion .location-details__body .location-details-item .table-location-detail, .notification-menu .notification-suggestion .location-details__body .location-details-item .table-location-detail__content--dateblock li, .notification-menu .notification-suggestion .location-list__body .location-item li {
  -webkit-box-align: start;
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  -moz-align-items: flex-start;
  align-items: flex-start; }

.item-end {
  -webkit-box-align: end;
  -ms-flex-align: end;
  -webkit-align-items: flex-end;
  -moz-align-items: flex-end;
  align-items: flex-end; }

.item-stretch, .notification-menu .notification-inner, .notification-menu .notification-suggestion .location-details__body .location-details-item .table-location-detail__content--dateblock {
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  -webkit-align-items: stretch;
  -moz-align-items: stretch;
  align-items: stretch; }

.item-nowrap {
  -ms-flex-wrap: none;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap; }

.item-wrap {
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap; }

.graytbl-header {
  color: #202124;
  font-size: 12px;
  font-weight: 700; }

.whitetbl-header {
  color: #3c4043 !important;
  font-size: 12px;
  font-weight: 700; }

.tblrow-color {
  color: #3c4043;
  font-size: 13px;
  font-weight: 400; }

.tblprimary-color {
  color: #3c4043;
  font-size: 13px;
  font-weight: 700; }

body.light {
  --admin-nav-background: #051e34;
  --admin-nav-border-color: #7c78786b;
  --admin-nav-font-color: #FFFFFFCC;
  --admin-nav-active-color: #669DF6;
  --page-content-background: #fafafa;
  --admin-header-content-background: #f1f5f9;
  --button-background-primary: #06a7e1;
  --white-text:#fff;
  --background-white-color: #fff;
  --button-background-secondary: #fff;
  --button-background-primary-hover: #40a9ff;
  --button-background-secondary-outline-hover: #40a9ff;
  --button-background-outline: #d9d9d9;
  --button-color-primary: #fff;
  --button-color-secondary: #0c0b0ba6;
  --ant-tab-inactive-color: #476282;
  --ant-tab-active-color: #1967d2;
  --page-content-h3-color: #222;
  --page-content-h5-color: #555;
  --page-content-h4-color: #3c4043;
  --primary-active-color: #1890ff;
  --primary-inactive-color: #a0a7b4;
  --primary-warning-color: #F99D1B;
  --dark-text-color: #666666;
  --sm-card-h5-color: #222;
  --overlay-background-color: #00000080;
  --profile-tag-color: #ad6f19;
  --profile-tag-background-color: #ebd7bd;
  --nav--hover-background: #ffffff14;
  --nav-admin-section-highlight: #47628233;
  --nav-admin-section-hover: #47628266;
  --black-text: #000000;
  --drk-text: #212121;
  --med-drk-text: #424242;
  --med-text: #616161;
  --med-lgt-text: #757575;
  --lgt-text: #9E9E9E;
  --warning-text: #E65100;
  --accent-text-color: #051e34;
  --nav-admin-section-header: rgba(255, 255, 255, 0.5);
  --right-nav-logout-color: #ffa600;
  --dropdown-background-color: #1a2c3a;
  --dropdown-border-color: #424242;
  --dropdown-background-hover-color: #ffffff14;
  --page-header-svg-color: #424242;
  --page-header-svg-hover: #669DF6;
  --page-header-svg-background: #55545414;
  --paid-color-green: #388E3C;
  --payment-due-color-orange: #F57C00;
  --not-paid-color-red: #D32F2F;
  --voided-color-grey: #616161;
  --pending-color-lgtblue: #29B6F6;
  --posted-color-lgtblue: #0288D1;
  --table-header-color: #05213a;
  --table-row-seperator: rgb(1, 9, 15);
  --star-rating-1-color: #FF5722;
  --star-rating-2-color: #FF9800;
  --star-rating-3-color: #ababab;
  --star-rating-4-color: #03A9F4;
  --star-rating-5-color: #4CAF50;
  --disabled-button-color: #999999;
  --admin-nav-logo-color: #FFFFFF;
  --detached-toogle-button-color: #ffa500;
  --filter-card-icon-color: #76bfe0;
  --filter-location-card-background: #051e34, #0d4475;
  --walmart-audit-active-card-background: #d58f28, #f9a326;
  --filter-listing-card-background: #0d4475, #29679e;
  --timeline-dot-background: #94a5b9;
  --timeline-icon-color: #81d4f9;
  --custom-popover-background: #f1f5f9;
  --comment-filter-background: #81d4f9;
  --account-filter-background: #9c27b0;
  --response-rate-background:#f99d1b;
  --campaign-period-color:#e9a94f;
  --default-background-color: rgb(231, 228, 228);
  --svg-icon-color:#669DF6;
  --button-inactive-state-color: #cccccc;
  --button-active-state-color:#d2dfec;
  --location-background-color: #7fbae7;
  --group-background-color: #ffa500;
  --error-background-color:#fff2f4;
  --error-field-color:#E30000;
  --error-border-color:#E30000;
  --background-drk-black: #000000;
  --background-white-color: #fff;
  --background-med-black: #0d0d0d;
  --border-med-black:#555;
  --placeholder-lgt-black:#777;
  --button-background-lgt-black:#565656;
  --dash-neg-background: rgb(255, 228, 230);
  --dash-pos-background: rgb(209, 250, 229);
  --dash-neutral-background:rgb(245, 245, 245);
  --dash-neg-color: rgb(159, 18, 57);
  --dash-pos-color: rgb(6, 95, 70);
  --dash-neutral-color: rgb(64, 64, 64);
  --dash-avg-text-color:#737373;
  --dash-info-text-color:rgb(115, 115, 115);
  --dash-metric-text-color: #1d4de8;
  --dash-card-box-shadow: 0px 4px 16px -2px rgba(0, 0, 0, 0.08),
  0px 2px 4px -1px rgba(0, 0, 0, 0.08);
  --dash-card-border-color: rgb(229, 229, 229)
; }

.modal {
  background-color: black;
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Overlay effect: translucent background: black w/ partial opacity */
  z-index: 1050;
  /* Overlay effect: positioned over other containers */
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  position: fixed;
  /* Fix position on the top-left corner*/
  top: 0;
  left: 0;
  overflow: auto;
  /* Enable scroll if needed */
  padding-top: 80px;
  /* Location of the content container */ }

/* Modal content */
.modal-content {
  background-color: white;
  width: 70%;
  /* Width in proportion to its parent container*/
  max-width: 500px;
  margin: auto;
  /* Auto margin according to the element width */
  padding: 15px;
  padding-bottom: 40px;
  border: 1px solid black;
  border-radius: 20px;
  /* Optional. Rounds container corners */ }
  .modal-content .share-business-header h1 {
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
    text-align: center;
    color: #202124; }
  .modal-content .share-business-header h3 {
    font-size: 14px;
    padding-bottom: 20px;
    padding-top: 5px;
    text-align: center;
    color: #202124; }
  .modal-content .share-business-body {
    margin: 10px auto;
    width: 100%;
    text-align: center; }
    .modal-content .share-business-body__copy {
      position: absolute;
      padding-left: 348px;
      padding-top: 18px;
      font-size: 20px; }
      .modal-content .share-business-body__copy i {
        cursor: pointer; }
    .modal-content .share-business-body__items {
      display: flex;
      padding-left: 60px;
      padding-top: 5px; }
      .modal-content .share-business-body__items li {
        padding-right: 5px;
        border: 1px solid #dadce0;
        border-radius: 32px;
        display: -webkit-inline-box;
        display: inline-flex;
        font: 400 14px/32px Roboto, RobotoDraft, Helvetica, Arial, sans-serif;
        height: 32px;
        outline: none;
        padding-left: 15px;
        padding-right: 15px;
        margin-right: 8px;
        color: #202124; }
        .modal-content .share-business-body__items li img {
          width: 18px; }
        .modal-content .share-business-body__items li span {
          color: #202124; }
  .modal-content .share-via-email {
    text-align: center;
    margin-top: 18px;
    font-size: 15px; }

.modal-flex {
  display: flex;
  height: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center; }

.modal-info {
  background-color: #888;
  border: 1px solid #888; }

/* Close button */
.close {
  font-size: 2rem !important;
  color: #aaaaaa;
  float: right;
  /* Positioned to the right of the parent container whichever size it is */
  font-size: 25px;
  font-weight: bold; }

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer; }

.notification-menu {
  position: fixed;
  top: 62px;
  right: 0px;
  bottom: 0px;
  height: calc(100vh - 62px);
  min-width: 100%;
  max-width: 100%;
  z-index: 999;
  opacity: 0;
  -moz-transform: translate(100%, 0);
  -o-transform: translate(100%, 0);
  -ms-transform: translate(100%, 0);
  -webkit-transform: translate(100%, 0);
  transform: translate(100%, 0);
  -moz-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out; }
  .notification-menu.open {
    opacity: 1;
    -moz-transform: translate(0);
    -o-transform: translate(0);
    -ms-transform: translate(0);
    -webkit-transform: translate(0);
    transform: translate(0); }
    .notification-menu.open .notification-inner {
      min-width: 90%;
      max-width: 90%;
      opacity: 1;
      -moz-transition: all 300ms ease-in-out;
      -o-transition: all 300ms ease-in-out;
      -webkit-transition: all 300ms ease-in-out;
      transition: all 300ms ease-in-out; }
      .notification-menu.open .notification-inner .notification-list {
        min-width: 32%;
        max-width: 32%;
        border-left: solid 1px rgba(224, 224, 224, 0.3);
        -moz-transition: all 300ms ease-in-out;
        -o-transition: all 300ms ease-in-out;
        -webkit-transition: all 300ms ease-in-out;
        transition: all 300ms ease-in-out;
        -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
        -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1); }
        @media (max-width: 319px) {
          .notification-menu.open .notification-inner .notification-list {
            min-width: 80%;
            max-width: 80%; } }

@media (min-width: 320px) and (max-width: 575.99px) {
  .notification-menu.open .notification-inner .notification-list {
    min-width: 65%;
    max-width: 65%; } }

@media (min-width: 576px) and (max-width: 767px) {
  .notification-menu.open .notification-inner .notification-list {
    min-width: 60%;
    max-width: 60%; } }

@media (min-width: 768px) and (max-width: 1023px) {
  .notification-menu.open .notification-inner .notification-list {
    min-width: 48%;
    max-width: 48%; } }

@media (min-width: 1024px) and (max-width: 1365px) {
  .notification-menu.open .notification-inner .notification-list {
    min-width: 44%;
    max-width: 44%; } }
  .notification-menu .notification-inner {
    flex: 1 1 auto;
    opacity: 0;
    background-color: transparent;
    height: calc(100vh - 63px);
    -moz-transition: all 300ms ease-in-out;
    -o-transition: all 300ms ease-in-out;
    -webkit-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out; }
  .notification-menu .notification-list {
    position: relative;
    background-color: #ffffff;
    z-index: 9;
    min-width: 0;
    max-width: 0;
    height: 100%;
    -moz-transition: all 300ms ease-in-out;
    -o-transition: all 300ms ease-in-out;
    -webkit-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out; }
    .notification-menu .notification-list:before, .notification-menu .notification-list:after {
      bottom: 100%;
      border: solid transparent;
      content: '';
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none; }
    .notification-menu .notification-list:before {
      right: 80px;
      border-bottom-color: #ccc;
      border-width: 13px;
      margin-left: -13px; }
    .notification-menu .notification-list:after {
      right: 81px;
      border-bottom-color: #fff;
      border-width: 12px;
      margin-left: -12px; }
    .notification-menu .notification-list .notification-header {
      flex: 0 1 auto;
      padding: 0 20px;
      min-height: 50px;
      height: 50px;
      border-bottom: solid 1px rgba(224, 224, 224, 0.3); }
      .notification-menu .notification-list .notification-header h3 {
        color: #202124;
        flex: 0 0 auto;
        font-size: 16px; }
      .notification-menu .notification-list .notification-header .notification-clearall {
        cursor: pointer;
        color: #06a7e1;
        font-size: 15px;
        margin-right: 20px;
        margin-left: auto; }
    .notification-menu .notification-list .notification-search {
      padding: 15px 20px; }
      .notification-menu .notification-list .notification-search .notification-filter {
        width: 160px; }
      .notification-menu .notification-list .notification-search li {
        flex: 0 0 auto; }
        .notification-menu .notification-list .notification-search li:not(:last-child) {
          margin-right: 10px; }
        .notification-menu .notification-list .notification-search li:first-child {
          min-width: 20%; }
        .notification-menu .notification-list .notification-search li:nth-child(2) {
          min-width: 30%; }
    .notification-menu .notification-list .notification-body {
      flex: 1 1 auto;
      border: 0;
      max-height: calc(100vh - 100px); }
      .notification-menu .notification-list .notification-body__item {
        background-color: #fff; }
        .notification-menu .notification-list .notification-body__item li {
          flex: 0 1 auto;
          padding: 0.75rem 1rem;
          cursor: pointer;
          border-bottom: 1px solid #e9eef2; }
          .notification-menu .notification-list .notification-body__item li .notification-content {
            flex: 1 1 auto; }
            .notification-menu .notification-list .notification-body__item li .notification-content__profile {
              flex: 0 0 auto;
              width: 40px;
              height: 40px;
              margin-right: 15px;
              font-size: 18px;
              text-transform: uppercase;
              color: #ffffff;
              border-radius: 50%;
              -webkit-border-radius: 50%;
              -moz-border-radius: 50%;
              background-image: -webkit-linear-gradient(legacy-direction(to top), #4d9de2 30%, #9fd1fe 100%);
              background-image: -webkit-linear-gradient(to top, #4d9de2 30%, #9fd1fe 100%);
              background: -ms-linear-gradient(to top, #4d9de2 30%, #9fd1fe 100%);
              background-image: -ms-linear-gradient(to top, #4d9de2 30%, #9fd1fe 100%);
              background-image: linear-gradient(to top, #4d9de2 30%, #9fd1fe 100%); }
            .notification-menu .notification-list .notification-body__item li .notification-content__details h3 {
              color: #202124;
              font-size: 16px;
              font-weight: bold;
              margin-bottom: 2px; }
            .notification-menu .notification-list .notification-body__item li .notification-content__details p {
              font-size: 14px;
              color: #3c4043; }
              .notification-menu .notification-list .notification-body__item li .notification-content__details p:not(:last-child) {
                margin-bottom: 14px; }
              .notification-menu .notification-list .notification-body__item li .notification-content__details p span {
                flex: 1 1 auto; }
              .notification-menu .notification-list .notification-body__item li .notification-content__details p i {
                margin-right: 6px; }
                .notification-menu .notification-list .notification-body__item li .notification-content__details p i.color-orange {
                  color: #e37400; }
          .notification-menu .notification-list .notification-body__item li:hover {
            background-color: #e6f7ff; }
          .notification-menu .notification-list .notification-body__item li .notification-controls {
            margin-top: 2px;
            width: 100%;
            flex: 1 1 auto;
            color: #06a7e1; }
            .notification-menu .notification-list .notification-body__item li .notification-controls .ant-dropdown-trigger {
              border: 0;
              max-width: 30px; }
    .notification-menu .notification-list .notification-footer {
      color: #06a7e1;
      font-size: 15px;
      min-height: 50px;
      cursor: pointer;
      padding: 0 20px;
      border-top: 1px solid #e9eef2; }
  .notification-menu .notification-suggestion {
    flex: 1 1 auto;
    position: relative;
    background-color: transparent;
    z-index: 7;
    height: 100%;
    opacity: 0;
    border-radius: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -moz-transform: translate(100%, 0);
    -o-transform: translate(100%, 0);
    -ms-transform: translate(100%, 0);
    -webkit-transform: translate(100%, 0);
    transform: translate(100%, 0);
    -moz-transition: all 300ms ease-in-out;
    -o-transition: all 300ms ease-in-out;
    -webkit-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out; }
    .notification-menu .notification-suggestion.show {
      opacity: 1;
      -moz-transform: translate(0);
      -o-transform: translate(0);
      -ms-transform: translate(0);
      -webkit-transform: translate(0);
      transform: translate(0); }
      .notification-menu .notification-suggestion.show .location-details {
        min-width: 60%;
        max-width: 60%; }
    .notification-menu .notification-suggestion .location-details {
      flex: 1 1 auto;
      min-width: 0;
      max-width: 0;
      overflow: hidden;
      border-left: solid 1px rgba(224, 224, 224, 0.3);
      background-color: #ffffff; }
      .notification-menu .notification-suggestion .location-details__header {
        flex: 0 1 auto;
        padding: 0 20px;
        min-height: 50px;
        height: 50px;
        border-bottom: solid 1px rgba(224, 224, 224, 0.3); }
        .notification-menu .notification-suggestion .location-details__header h3 {
          color: #202124;
          flex: 0 0 auto;
          font-size: 16px; }
        .notification-menu .notification-suggestion .location-details__header span {
          cursor: pointer; }
      .notification-menu .notification-suggestion .location-details__body {
        flex: 1 1 auto;
        max-height: calc(100vh - 100px); }
        .notification-menu .notification-suggestion .location-details__body .location-details-item .table-location-detail {
          color: #202124;
          padding: 1rem;
          -moz-transition: all 0.8s ease-in-out;
          -o-transition: all 0.8s ease-in-out;
          -webkit-transition: all 0.8s ease-in-out;
          transition: all 0.8s ease-in-out; }
          .notification-menu .notification-suggestion .location-details__body .location-details-item .table-location-detail:hover {
            background-color: rgba(224, 224, 224, 0.05);
            -moz-transition: all 0.8s ease-in-out;
            -o-transition: all 0.8s ease-in-out;
            -webkit-transition: all 0.8s ease-in-out;
            transition: all 0.8s ease-in-out; }
          .notification-menu .notification-suggestion .location-details__body .location-details-item .table-location-detail:not(:last-child) {
            border-bottom: solid 1px rgba(224, 224, 224, 0.3); }
          .notification-menu .notification-suggestion .location-details__body .location-details-item .table-location-detail__title {
            background-color: rgba(26, 115, 232, 0.5);
            color: #ffffff;
            max-width: 30px;
            min-width: 30px;
            max-height: 30px;
            min-height: 30px;
            padding: 5px;
            border-radius: 50%;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%; }
          .notification-menu .notification-suggestion .location-details__body .location-details-item .table-location-detail__content {
            flex: 1 1 auto;
            padding-left: 1rem; }
            .notification-menu .notification-suggestion .location-details__body .location-details-item .table-location-detail__content h2 {
              color: #202124;
              font-weight: bold;
              font-size: 16px;
              margin-bottom: 10px; }
            .notification-menu .notification-suggestion .location-details__body .location-details-item .table-location-detail__content--dateblock li {
              flex: 1 1 auto;
              padding: 6px 12px;
              border: solid 1px rgba(224, 224, 224, 0.2);
              border-radius: 4px;
              -webkit-border-radius: 4px;
              -moz-border-radius: 4px; }
              .notification-menu .notification-suggestion .location-details__body .location-details-item .table-location-detail__content--dateblock li:not(:last-child) {
                margin-right: 10px; }
              .notification-menu .notification-suggestion .location-details__body .location-details-item .table-location-detail__content--dateblock li span {
                flex: 1 1 auto;
                padding-bottom: 7px; }
                .notification-menu .notification-suggestion .location-details__body .location-details-item .table-location-detail__content--dateblock li span:nth-child(1) {
                  font-weight: bold; }
            .notification-menu .notification-suggestion .location-details__body .location-details-item .table-location-detail__content--footer {
              margin-top: 1rem; }
              .notification-menu .notification-suggestion .location-details__body .location-details-item .table-location-detail__content--footer .ant-btn {
                min-height: 26px;
                height: 26px; }
              .notification-menu .notification-suggestion .location-details__body .location-details-item .table-location-detail__content--footer .ant-btn-default {
                margin-right: 10px;
                font-weight: 600;
                color: var(--drk-text); }
              .notification-menu .notification-suggestion .location-details__body .location-details-item .table-location-detail__content--footer .action-status {
                font-size: 16px;
                margin: 0;
                margin-left: 5px;
                margin-bottom: 15px; }
      .notification-menu .notification-suggestion .location-details .location-details-footer {
        min-height: 50px;
        max-height: 50px;
        cursor: pointer;
        padding: 0 20px;
        border-top: 1px solid #e9eef2; }
    .notification-menu .notification-suggestion .location-list {
      flex: 0 1 auto;
      max-width: 40%;
      min-width: 40%;
      overflow: hidden;
      background-color: #ffffff; }
      .notification-menu .notification-suggestion .location-list__detais {
        padding: 15px; }
        .notification-menu .notification-suggestion .location-list__detais .location-website {
          padding: 20px 0;
          border-bottom: solid 1px rgba(160, 167, 180, 0.3); }
          .notification-menu .notification-suggestion .location-list__detais .location-website i {
            color: blue;
            font-size: 19px;
            margin-right: 6px; }
          .notification-menu .notification-suggestion .location-list__detais .location-website a {
            flex: 1 1 auto;
            padding-left: 8px; }
            .notification-menu .notification-suggestion .location-list__detais .location-website a:hover {
              color: blue;
              -moz-transition: all 0.8s ease-in-out;
              -o-transition: all 0.8s ease-in-out;
              -webkit-transition: all 0.8s ease-in-out;
              transition: all 0.8s ease-in-out; }
        .notification-menu .notification-suggestion .location-list__detais .location-search {
          padding: 20px 0;
          border-bottom: solid 1px rgba(160, 167, 180, 0.3); }
          .notification-menu .notification-suggestion .location-list__detais .location-search h3 {
            font-size: 16px;
            margin: 0;
            margin-bottom: 15px;
            color: #70757a; }
          .notification-menu .notification-suggestion .location-list__detais .location-search__item li {
            flex: 1 1 auto; }
            .notification-menu .notification-suggestion .location-list__detais .location-search__item li:not(:last-child) {
              margin-bottom: 15px; }
            .notification-menu .notification-suggestion .location-list__detais .location-search__item li a {
              flex: 1 1 auto;
              width: 100%; }
              .notification-menu .notification-suggestion .location-list__detais .location-search__item li a span {
                flex: 0 0 auto; }
                .notification-menu .notification-suggestion .location-list__detais .location-search__item li a span:hover {
                  color: #0056b3; }
                .notification-menu .notification-suggestion .location-list__detais .location-search__item li a span:nth-child(1) {
                  margin-right: 12px;
                  min-width: 18px;
                  max-width: 18px;
                  min-height: 18px;
                  min-height: 18px;
                  overflow: hidden; }
                .notification-menu .notification-suggestion .location-list__detais .location-search__item li a span svg {
                  width: 100%;
                  height: 100%; }
        .notification-menu .notification-suggestion .location-list__detais .company-profile {
          background-color: rgba(6, 167, 225, 0.5);
          color: #ffffff;
          font-size: 18px;
          max-width: 45px;
          min-width: 45px;
          max-height: 45px;
          min-height: 45px;
          border-radius: 50%;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%; }
        .notification-menu .notification-suggestion .location-list__detais h3 {
          font-size: 18px;
          margin-top: 20px; }
        .notification-menu .notification-suggestion .location-list__detais .location-item {
          margin-top: 20px; }
          .notification-menu .notification-suggestion .location-list__detais .location-item li span {
            flex: 0 0 auto; }
            .notification-menu .notification-suggestion .location-list__detais .location-item li span:nth-child(1) {
              font-size: 16px;
              margin-right: 10px; }
      .notification-menu .notification-suggestion .location-list__body {
        flex: 1 1 auto;
        padding: 0 20px; }
        .notification-menu .notification-suggestion .location-list__body .location-item li {
          padding: 0;
          flex: 0 1 auto; }
          .notification-menu .notification-suggestion .location-list__body .location-item li a {
            height: auto;
            padding: 0;
            text-align: center; }

.store-details {
  display: flex;
  flex-direction: column; }

.dropdown-super-user-menu .dropdown {
  margin-left: -20px;
  margin-top: -10px; }

.dropdown-super-user-menu .dropdown-toggle::after {
  display: none; }

.dropdown-super-user-menu i {
  margin-top: 4px; }

.dropdown-super-user-menu .dropdown-menu {
  margin-left: -22rem;
  right: 0px; }

.super-user-block {
  margin-right: -25px;
  margin-top: 15px;
  z-index: 1;
  cursor: pointer; }
  .super-user-block .super-user-image {
    border-radius: 50%;
    width: 2.1rem;
    height: 2.1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #06a7e1;
    font-weight: 600; }
  .super-user-block img {
    border-radius: 50%;
    max-width: 2rem;
    height: 2rem; }

.super-user-popup {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  padding: 10px;
  max-width: 100%; }
  .super-user-popup .header {
    font-size: 16px;
    padding-left: 10px;
    padding-bottom: 4px;
    color: #9E9E9E; }
  .super-user-popup .user-section {
    margin-top: 5px;
    box-shadow: 1px 0px 4px 1px rgba(61, 61, 61, 0.5);
    border-radius: 50px;
    display: flex;
    padding: 10px;
    gap: 10px;
    margin-bottom: 10px;
    align-items: center;
    max-width: 100%; }
    .super-user-popup .user-section .image {
      width: 3rem; }
      .super-user-popup .user-section .image img {
        min-width: 3rem !important;
        height: 3rem;
        border-radius: 50%; }
    .super-user-popup .user-section .super-user-image {
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 600;
      font-size: 18px;
      width: 3rem;
      height: 3rem;
      border-radius: 50%;
      background-color: #06a7e1; }
    .super-user-popup .user-section .details {
      display: flex;
      flex-direction: column; }
      .super-user-popup .user-section .details .name {
        font-size: 16px;
        color: #424242;
        margin-top: -2px; }
      .super-user-popup .user-section .details .email {
        max-width: 200px;
        overflow-wrap: break-word;
        font-size: 14px;
        color: #9E9E9E;
        margin-top: -2px; }
      .super-user-popup .user-section .details .role {
        font-size: 14px;
        color: #9E9E9E;
        margin-top: -2px;
        text-transform: lowercase; }
      .super-user-popup .user-section .details .role:first-letter {
        text-transform: capitalize; }
