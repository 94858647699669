@import './icons';

$google-icon: var(--icon-google);
$nav-background-image: var(--admin-nav-background-image);
$icon-size-xs:var(--icon-size-xs);
$icon-size-sm:var(--icon-size-sm);
$icon-size-md:var(--icon-size-md);
$icon-size-lg:var(--icon-size-lg);
$new-profile-left1:var( --new-profile-left1);
$new-profile-left2:var( --new-profile-left2);
$new-profile-left3:var( --new-profile-left3);
$new-profile-left4:var( --new-profile-left4);
$new-profile-left5:var( --new-profile-left5);
$new-profile-left6:var( --new-profile-left6);
$icon-size-xl:var(--icon-size-xl);
$icon-size-xxl:var(--icon-size-xxl);

