@import '../themes/index.scss';

input[type='text'],
input[type='file'],
.ant-input-number-input,
.ant-input,
.form-control,
.css-151xaom-placeholder {
  border-color: $border-color;
  color: $text-color;
  @include border-radius(3px);

  @include placeholder {
    color: $placeholder;
  }

  &:focus-within,
  &:focus,
  &:hover {
    @include box-shadow(0 0 0 transparent !important);
    outline: 0px !important;
  }
}

.ant-btn {
  height: 35px;
  text-align: center;
  font-size: 14px;
  @extend .inline-flex;
  @extend .item-center;
  @include border-radius(3px);
  @include transition(all 0.1s ease-in-out);

  span {
    flex: 1 1 auto;
    text-align: center;
  }

  i {
    flex: 0 0 auto;
  }

  &:not(:last-child) {
    margin-right: 0.75rem;
    margin-left: 0;
  }

  &:focus-within,
  &:focus,
  &:hover {
    @include box-shadow(0 0 0 transparent !important);
    outline: 0px !important;
  }

  .anticon {
    flex: 0 0 auto;
  }

  &.ant-btn-primary {
    color: $white-color;
    border: 0;

    &[disabled] {
      border: 0;
      color: rgba(0, 0, 0, 0.3) !important;

      span {
        color: rgba(0, 0, 0, 0.3) !important;
      }
    }

    span {
      color: $white-color !important;
    }
  }
}

.ant-tooltip-content {
  margin: 0;

  .ant-tooltip-arrow {
    bottom: 3px;
  }

  .ant-tooltip-inner {
    font-size: 12px;
    padding: 3px 5px;
    min-height: auto;
  }
}

.ant-input {
  height: 35px;
  line-height: 35px;
  padding: 4px 12px;
  border-color: $border-color;
  @include border-radius(3px);
  @include transition(all 0.2s ease-in-out);
  @include box-shadow(0, 0, 0, rgba(255, 255, 255, 0));

  &.number-box {
    max-width: 60px;
    text-align: right;
  }

  &.review-box {
    max-width: 104px;
  }

  &.month-picker {
    position: relative;
    min-height: 35px;
    max-width: 240px;
    @extend .flexbox;
    @extend .item-center;
    @extend .item-nowrap;

    .month-input {
      border: 0;
      outline: 0;
      max-width: 44%;
      height: 99%;
      text-align: center;
      background-color: transparent;
    }

    .month-separator {
      line-height: 10px;
      height: 10px;
      display: inline-block;
      min-width: 10px;
      color: rgba(0, 0, 0, 0.45);
      white-space: nowrap;
      text-align: center;
      vertical-align: top;
      pointer-events: none;
    }

    .anticon-calendar {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.25);
      position: absolute;
      top: 50%;
      right: 12px;
      z-index: 1;
      font-size: 13px;
      width: 14px;
      height: 14px;
      flex: 0 auto;
      color: rgba(0, 0, 0, 0.25);
      line-height: 1;
      margin: 0;
      @include transform(translateY(-50%));
    }
  }
}

.css-1pcexqc-container,
.css-ssi0ig-container {
  margin: 0;
  min-width: 180px;
  min-height: 34px;
  width: 100%;
  @extend .flex-column;

  input[type='hidden'] {
    display: none;
  }

  .css-bg1rzq-control,
  .css-1szy77t-control,
  .css-14ojxsb-control,
  .css-3esr4u-a11yText,
  .css-foqyaq-control {
    flex: 0 0 auto;
    min-height: 34px;
    border: solid 1px $border-color;
    @extend .flexbox;
    @extend .item-center;
    @extend .item-nowrap;
    @include border-radius(3px);
    @include transition(all 0.2s ease-in-out);
    @include box-shadow(0 0 0 transparent);

    &:hover {
      border-color: #ced4da !important;
    }

    .css-1hwfws3 {
      flex: 1 1 auto;
      min-height: 34px;
      padding: 0 10px;
      margin: 0px;
      position: relative;
      @extend .flexbox;
      @extend .item-center;

      .css-dvua67-singleValue,
      .css-1g6gooi {
        color: #808080;
        margin: 0;

        input {
          margin: 0px;
        }
      }

      .css-es53b3-multiValue {
        background-color: rgba(6, 167, 225, 0.06);
        border: rgba(6, 167, 225, 0.8) solid 1px;

        .css-12jo7m5 {
          border-right: rgba(6, 167, 225, 0.8) solid 1px;
          color: $theam-blue;
        }

        .css-1alnv5e {
          color: $theam-blue;

          svg {
            fill: $theam-blue;
          }
        }
      }
    }

    .css-1wy0on6 {
      flex: 0 0 auto;
      padding: 0;
      padding: 0px 10px;
      @extend .flexbox;
      @extend .item-center;

      .css-16pqwjk-indicatorContainer,
      .css-1thkkgx-indicatorContainer {
        margin: 0;
        cursor: pointer;
        padding: 0px;
        @extend .flexbox;
        @extend .all-center;

        .css-19bqh2r {
          min-width: 16px !important;
          min-height: 16px !important;
          max-width: 16px !important;
          max-height: 16px !important;

          path {
            fill: #a6a9b3;
          }
        }

        &:hover {
          margin: 0;

          .css-19bqh2r {
            fill: $red-color;
          }
        }

        &:first-child {
          width: 16px;
        }
      }

      .css-bgvzuu-indicatorSeparator,
      .css-622gbt-indicatorSeparator {
        background-color: $border-color;
        width: 1px;
        margin: 8px 0 8px 0;
        padding: 0px;
        display: none;
      }

      .css-bial7t-loadingIndicator,
      .css-k8s2js-loadingIndicator {
        @extend .flexbox;
        @extend .all-center;
        margin: 0px;
        padding: 0px;

        span {
          flex: 0 0 auto;
          max-width: 6px !important;
          min-width: 6px !important;
          height: 6px !important;
          overflow: hidden;
          margin: 0 1px !important;
          @include border-radius(50%);
        }

        .css-pujnn1,
        .css-16vlcq {
          background-color: $theam-blue;
        }

        .css-gji98i,
        .css-1jpxn5b {
          background-color: $theam-red;
        }

        .css-1frpy8x,
        .css-in1tv9 {
          background-color: $theam-green;
        }
      }
    }

    &:focus {
      border-color: $theam-blue;
      @include border-radius(0);
    }
  }

  .css-1szy77t-control {
    border-color: $theam-blue;
  }

  .form-field-name {
    border: 0px;

    &:focus {
      border: 0;
      @include border-radius(0);
    }
  }

  .css-kj6f9i-menu {
    display: block !important;
    padding: 0;
    margin: 0;
    z-index: 99;
    @include border-radius(0);
    @include transition(all 0.8s ease-in-out);
  }

  &.size-select {
    flex: 0 0 auto;
    max-width: 60px !important;
    min-width: 60px !important;
  }

  &.star-rate {
    min-width: 87px;
    max-width: 87px;
    max-height: 35px;
  }

  &.font-family {
    min-width: 167px;
    max-width: 167px;
  }

  &.full-with {
    min-width: 100%;
  }

  &.min-width {
    min-width: 220px;
  }

  &.sort-order {
    min-width: 135px;
  }
}

[ant-click-animating-without-extra-node]:after {
  animation: 0s !important;
}

.ant-radio-group {
  @extend .flexbox;
  @extend .item-center;

  .ant-radio-wrapper {
    margin-right: 0;
    @extend .flexbox;
    @extend .item-center;

    &:not(:last-child) {
      margin-right: 1rem;
    }

    span.ant-radio+* {
      margin: 0;
      padding: 0 0 0 6px;
      font-weight: 700;
    }
  }

  &.reply-approval {
    margin: 0;

    .ant-radio-wrapper {
      flex: 0 0 auto;

      &:first-child {
        margin-right: 0px;
      }
    }

    .reply-default {
      flex: 0 0 auto;
      padding-left: 6px;
      margin-right: 1rem;
      margin-top: -6px;
    }
  }

  .conform-settings {
    margin: 0;
  }

  .ant-radio-button-wrapper {
    flex: 0 auto;
    height: 35px;
    line-height: 25px;
    background-color: #f8f8f8;
    border: solid 1px $border-color;
    @include border-radius(3px);

    &:first-child {
      @include border-radius(3px 0 0 3px);
    }

    &:nth-child(2) {
      @include border-radius(0);
    }

    &:last-child {
      @include border-radius(0 3px 3px 0);
    }

    &.ant-radio-button-wrapper-checked,
    &.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled),
    &.ant-radio-button-wrapper-checked:focus-within {
      @include box-shadow(0 0 0 transparent);
      outline: 0px !important;
      border-color: $theam-blue;
      background-color: $white-color !important;
    }

    span {
      font-size: 12px;
      font-weight: 400;
      color: $text-color;

      a {
        line-height: 35px;
        color: $text-color;
      }
    }

    .ant-radio-button {
      font-weight: 500;
    }

    &:first-child {
      margin-right: -1px;
    }

    &:last-child {
      margin-left: -1px;
    }
  }

  &.month-selecter {
    @extend .flexbox;
    @extend .item-center;

    .ant-radio-button-wrapper {
      margin: 0 0 0 -1px;
    }
  }
}

.ant-calendar-picker {
  margin: 0;
  min-height: 35px;

  .ant-calendar-picker-input {
    margin: 0;
    @extend .flexbox;
    @extend .item-center;
    @extend .item-nowrap;

    .ant-calendar-range-picker-separator {
      line-height: 10px;
      height: 10px;
    }

    .ant-calendar-picker-icon {
      flex: 0 auto;
      color: rgba(0, 0, 0, 0.25);
      margin: 0;
      font-size: 13px;
      @include transform(translateY(-50%));
    }
  }

  &.granular-calender {
    min-height: 35px;
    max-width: 240px;
  }
}

.ant-calendar-month {
  margin: 0;
  width: 200px !important;

  .ant-calendar-month-calendar-content {
    width: 200px;

    .ant-calendar-month-header-wrap {
      height: 150px;
      width: 200px;
    }
  }
}

.ant-calendar-picker-container {
  font-family: $font-web;

  .ant-calendar {
    line-height: 18px !important;
    color: rgba(0, 0, 0, 0.65) !important;
    overflow: hidden;
    max-width: 478px;
    @include border-radius(0);

    .ant-calendar-month-panel-tbody {
      margin: 0;

      .ant-calendar-month-panel-cell {
        padding: 0px !important;
        margin: 0px !important;
        line-height: 0 !important;
        text-align: center;
      }
    }

    &.ant-calendar-time {
      width: 231px;
    }

    .ant-calendar-my-select {
      font-weight: 700;
    }
  }

  .ant-calendar-panel {
    padding: 0;

    .ant-calendar-date-panel {
      margin: 0;
      font-size: 12px;

      .ant-calendar-input {}

      .ant-calendar-range-left {
        margin: 0;

        .ant-calendar-header {
          margin: 0;

          .ant-calendar-month-select,
          .ant-calendar-year-select {
            font-weight: 700 !important;
            color: rgba(0, 0, 0, 0.65);
          }
        }
      }

      .ant-calendar-range-right {
        margin: 0;

        .ant-calendar-header {
          margin: 0;

          .ant-calendar-month-select,
          .ant-calendar-year-select {
            font-weight: 700 !important;
            color: rgba(0, 0, 0, 0.65);
          }
        }
      }

      .ant-calendar-body {
        padding: 4px 8px;

        .ant-calendar-date {
          width: 20px;
          height: 20px;
          text-align: center;
          line-height: 18px;
        }
      }
    }

    .ant-calendar-footer {
      padding: 0px;

      .ant-calendar-footer-btn {
        @extend .flexbox;
        @extend .item-center;
        @extend .justify-end;
        padding: 0;

        .ant-calendar-today-btn {
          flex: 0 0 auto;
          padding-left: 12px;
          margin-right: auto;
        }

        .ant-calendar-time-picker-btn {
          flex: 0 0 auto;
        }

        .ant-calendar-ok-btn {
          flex: 0 0 auto;
          margin-right: 12px;
          color: $white-color !important;
          @include border-radius(0);
        }

        .ant-calendar-footer-extra {
          flex: 1 1 auto;
          padding: 6px;
          @extend .flexbox;
          @extend .item-center;
          @extend .justify-between;

          .ant-tag {
            flex: 0 0 auto;
            margin: 0px;

            &.ant-tag-blue {
              border: 0px;
              margin: 0;
              color: $theam-blue;
              background-color: transparent;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}

.ant-tag {
  position: relative;
  cursor: default;
  border-radius: 0px;
  margin-bottom: 8px;
  @include border-radius(12px);

  &:before,
  &:after {
    content: ' \25CF';
    font-size: 6px;
    color: #fff !important;
    position: absolute;
  }

  &:before {
    left: 2px;
  }

  &:after {
    right: 2px;
  }
}

.ant-tag-checkable {

  // background-color: orange;
  &:before,
  &:after {
    display: none;
  }

  &:hover {
    cursor: pointer;
    color: #000000A6 !important;
  }
}

.ant-tag-checkable-checked {
  background-color: rgba(46, 45, 45, 0.15);
  color: #000000A6 !important;

  &:before,
  &:after {
    display: none;
  }

  &:hover {
    cursor: pointer;
    color: black !important;
  }
}

.ant-input-number {
  margin: 0;
  height: 35px;
  border-color: #a0a7b4;
  @include border-radius(3px);

  &:focus {
    @include box-shadow(0 0 0 transparent !important);
    outline: 0px !important;
  }
}

.ant-checkbox-wrapper {
  flex: 0 0 auto;

  .ant-checkbox {
    border: 0;
    width: 16px;
    height: 16px;
    overflow: hidden;
    outline: 0px !important;

    .ant-checkbox-input {
      margin: 0px;
      padding: 0px;
      border: 0px;
    }

    .ant-checkbox-inner {
      margin: 0;
      width: 16px;
      height: 16px;
      border: solid 1px $border-color;
      @include border-radius(0);
      @include box-shadow(0 0 0 transparent !important);
    }

    &.ant-checkbox-checked {
      margin: 0;

      .ant-checkbox-inner {
        margin: 0;
        border-color: $theam-blue;

        &:after {
          left: 4px;
          top: 7px;
        }
      }
    }

    &.ant-checkbox-indeterminate {
      margin: 0;

      .ant-checkbox-inner {
        margin: 0;

        &:after {
          left: 7px;
          top: 7px;
        }
      }
    }
  }
}

.ant-dropdown {
  margin: 0;

  .ant-dropdown-menu {
    @extend .flex-column;

    .ant-dropdown-menu-item {
      @extend .flexbox;
      @extend .item-center;

      a {
        padding: 4px 10px;
        @extend .flexbox;
        @extend .item-center;

        i {
          margin-right: 6px;
        }
      }
    }

    &.location-dropbox {
      margin-top: -10px;
      border-radius: 4px;
      min-width: 200px;

      .ant-dropdown-menu-item {
        @extend .flexbox;
        @extend .item-center;
        @extend .justify-between;

        span {
          flex: 0 0 auto;

          &:last-child {
            color: $theam-blue;
          }
        }

        &:hover {

          // color: $white-color;
          color: rgb(27, 26, 26) span {
            // color: $white-color;
            color: rgb(27, 26, 26)
          }
        }
      }
    }
  }
}

.ant-transfer-list-content-item,
.ant-transfer-list-header {
  @extend .flexbox;
  @extend .item-center;
}

.ant-transfer-list {
  margin: 0;

  &-header {
    @extend .flexbox;
    @extend .item-center;

    &-selected {
      flex: 1 1 auto;
      @extend .flexbox;
      @extend .item-center;

      span {
        flex: 0 auto;
      }
    }
  }
}

.ant-transfer-list-body-search-wrapper {
  margin: 0px;
  border-bottom: 1px solid #d9d9d9;

  .ant-input {
    border: 0 !important;
  }
}

.ant-dropdown {
  margin: 0;

  .ant-dropdown-menu {
    background-color: $white-color ;
    margin-top: 12px !important;

    .ant-dropdown-menu-item {
      display: block;
      font-size: 12px;
      padding: 8px 16px;

      a {
        padding: 4px 10px;
        @extend .flexbox;
        @extend .item-center;

        i {
          margin-right: 6px;
        }
      }
    }
  }
}

.ant-dropdown {
  margin: 0;

  .ant-dropdown-menu {
    margin: 0;
    padding: 4px 0 4px 0 !important;
    min-width: 160px;
    max-height: 300px;
    overflow-y: auto;
    overflow-x: hidden;
    max-width: 380px;
    @include box-shadow(0, 3px, 20px, rgba($black-color, 0.12));

    .ant-dropdown-menu-item {
      font-size: 14px !important;
      min-height: 30px;
      color: #666;
      // margin-left: 3px;

      &:hover {
        // background-color: $theam-blue;
        background-color: #f5f5f5;
        // color: $white-color;
        color: rgb(126, 124, 124);
      }

      &.custom-wrapper {
        padding: 0px !important;
        @extend .flex-column;
        @extend .all-center;

        .custom-links {
          background-color: $white-color;
          display: grid;
          grid-template-columns: auto auto auto;
          grid-template-rows: auto;
          align-content: start;
          grid-gap: 0;
          padding: 10px;

          &__item {
            flex: 1 1 auto;
            @extend .flex-column;
            @extend .all-center;

            a {
              flex: 1 1 auto;
              min-width: 100px;
              min-height: 100px;
              max-height: 100px;
              padding: 2px;
              border: solid 1px transparent;
              @extend .flex-column;
              @extend .all-center;
              @include border-radius(4px);
              @include transition(all 0.5s ease-in-out);

              .nav-icon {
                flex: 0 0 auto;
                margin: 0;
                min-width: 36px;
                max-width: 36px;
                min-height: 36px;
                max-height: 36px;
                @extend .flexbox;
                @extend .all-center;

                img {
                  width: 100%;
                  height: 100%;
                }
              }

              .nav-label {
                margin: 0;
                flex: 0 1 auto;
                margin-top: 8px;
                display: block;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                line-height: 30px;
                color: $text-color;
              }

              &:hover {
                border: solid 1px #eff2f7;
                @include transition(all 0.3s ease-in-out);

                .nav-label {
                  color: $theam-blue;
                }
              }
            }
          }
        }

        &:hover {
          background-color: transparent;
          color: $text-color;
        }
      }
    }
  }
}

.ant-dropdown-trigger {
  @extend .flexbox;
  @extend .item-center;
  @extend .justify-between;
  @include border-radius(3px);
  box-shadow: none;
  padding: 0 12px;
  font-size: 14px;
  line-height: 33px;
  height: 35px;

  &.location-table {
    border: 0;
    padding: 0;
    @extend .all-center;
  }
}

.ant-notification-notice {
  position: relative;
  padding: 15px;
  @extend .flexbox;
  @extend .item-center;

  .ant-notification-notice-content {
    flex: 1 1 auto;

    .ant-notification-notice-message {
      flex: 0 0 auto;
      margin: 0px;
      padding: 0px;
    }

    .ant-notification-notice-description {
      flex: 0 0 auto;
      margin: 0;
      padding: 0px 0 0 15px;
      max-width: 300px;
    }

    .anticon.ant-notification-notice-icon {
      position: static;
      flex: 0 0 auto;

      .anticon {
        font-size: 26px;
        margin: 0;
      }
    }

    .ant-notification-notice-icon {
      position: relative;
    }

    .ant-notification-notice-with-icon {
      flex: 0 0 auto;
      @extend .flexbox;
      @extend .item-center;
    }
  }

  .ant-notification-notice-close {
    position: static;
    width: 20px;
    height: 20px;
    @extend .flexbox;
    @extend .all-center;

    i {
      font-size: 14px;
      color: #8f8e8e;
    }

    &:hover {
      margin: 0;

      i {
        color: $theam-blue;
      }
    }
  }
}

.ant-modal-wrap {
  margin: 0;
  background-color: rgba($text-color, 0.5);

  .ant-modal {
    padding: 0 !important;
    background-color: transparent;

    .ant-modal-content {
      position: relative;
      overflow: hidden;
      background-color: white;
      @extend .flex-column;

      .ant-modal-close {
        width: 30px;
        height: 30px;
        right: 30px;
        top: 12px;
        padding: 0;
        @extend .flexbox;
        @extend .all-center;
        @include transition(all 0.8s ease-in-out);

        .ant-modal-close-x {
          width: 20px;
          height: 20px;
          @extend .flexbox;
          @extend .all-center;

          .anticon {
            color: rgba($side-bar, 0.9);
            font-weight: 700;
            font-size: 18px;
          }
        }

        &:hover {
          border: 0;
          @include transition(all 0.5s ease-in-out);

          .anticon {
            color: $theam-blue;
          }
        }
      }

      .ant-modal-header {
        flex: 0 1 auto;
        background-color: transparent;
        padding: 1rem 1.5rem;
        border-color: $line-color;
        @extend .flex-column;
        @include border-radius(0);

        .ant-modal-title {
          flex: 1 1 auto;
          font-size: 20px;
          font-weight: 600;
          padding-right: 30px;
          color: #202124;
          @extend .flexbox;
          @extend .item-center;
          @extend .justify-between;

          h3,
          h4 {
            font-size: 20px;
            font-weight: 600;
            color: #202124;
          }
        }
      }

      .ant-modal-body {
        flex: 1 1 auto;
        padding: 2rem 1.5rem;
        @extend .flex-column;
        @include border-radius(0);

        .role-name-field {
          max-width: 100%;
        }
      }

      .ant-modal-footer {
        flex: 0 1 auto;
        padding: 1rem 1.5rem;
        border-color: $line-color;
        background-color: transparent;
        @extend .flexbox;
        @extend .item-center;
        @extend .justify-end;
        @include border-radius(0);
      }
    }
  }

  &.explore-window {
    @extend .flex-column;
    overflow: hidden;

    .ant-modal {
      width: 100% !important;
      height: 100vh !important;
      top: 0 !important;
      padding: 0px !important;
      max-height: 100vh !important;
      transform-origin: 0 0 !important;
      background-color: #f1f5f9 !important;
      @include transform(translate(0, 0) !important);
      @include transition(all 0.5s ease-in-out);

      .ant-modal-content {
        position: relative !important;
        @include border-radius(0);

        .ant-modal-body {
          padding: 0;
        }

        .ant-modal-close {
          top: 0.85rem;
          right: 1.5rem;
          @extend .flexbox;
          @extend .all-center;

          .ant-modal-close-x {
            flex: 0 0 auto;
            width: 30px;
            height: 30px;

            .anticon {
              color: $side-bar !important;
              font-size: 24px;
            }
          }
        }
      }
    }
  }

  &.automations-window,
  &.aiAgent-window {
    @extend .flex-column;

    .ant-modal {
      width: 98% !important;
      height: calc(100vh - 4%) !important;
      top: 2% !important;

      .ant-modal-body {
        padding: 0 !important;
      }

      .ant-modal-content {
        height: 100%;
      }
    }
  }

  &.model-window {
    .ant-modal {
      .ant-modal-content {
        border-radius: 1rem;

        .ant-modal-close {
          display: none !important;
        }

        .ant-modal-body {
          padding: 0px !important;
        }
      }
    }
  }
}

.ant-tabs {
  max-height: 100%;
  padding: 0;
  flex: 1 1 auto;
  @extend .flex-column;

  .ant-tabs-content {
    flex: 1 1 auto;
    height: 100%;

    .ant-tabs-tabpane {
      @extend .flex-column;
    }
  }

  .ant-tabs-bar {
    width: 100%;
    margin: 0;
    @extend .flex-column;

    .ant-tabs-nav {
      flex: 1 1 auto;

      .ant-tabs-tab {
        font-size: 14px;
        // padding: 1.5rem 0;
        font-weight: 400;

        .icon-tab {
          @extend .flexbox;
          @extend .all-center;

          span {
            flex: 0 0 auto;

            &:first-child {
              @extend .flexbox;
              @extend .all-center;
            }
          }
        }
      }
    }
  }

  &.automations-tab {
    @extend .flex-column;

    .ant-tabs-bar {
      @extend .flex-column;

      .ant-tabs-nav-container {
        flex: 1 1 auto;

        .ant-tabs-nav-wrap {
          flex: 1 1 auto;

          .ant-tabs-nav-scroll {
            @extend .flex-column;
            @extend .all-center;
          }
        }
      }
    }
  }

  &.competitor-maintab {
    @include transition(all 0.4s ease-in-out);

    .ant-tabs-bar {
      padding: 0 20px;
      background-color: #fff;

      .ant-tabs-nav {
        @include transition(all 0.4s ease-in-out);

        .ant-tabs-tab {
          padding: 10px;
          font-weight: 400;
          font-size: 16px;
        }
      }

      .ant-tabs-nav-wrap {
        @include transition(all 0.4s ease-in-out);
      }
    }
  }

  &.competitive-second {
    @include transition(all 0.4s ease-in-out);

    .ant-tabs-bar {
      padding: 0 35px !important;
      height: 34px !important;
      background-color: #efefef !important;

      .ant-tabs-nav-wrap {
        @include transition(all 0.4s ease-in-out);

        .ant-tabs-tab {
          height: 34px !important;
          line-height: 34px !important;
          padding: 0 !important;
          font-size: 14px !important;
          color: #333339 !important;

          &.ant-tabs-tab-active {
            font-weight: 600 !important;
          }
        }

        .report-subitem .ant-tabs-bar .ant-tabs-nav-wrap .ant-tabs-ink-bar-animated {
          display: none !important;
        }

        .ant-tabs-ink-bar-animated {
          display: none !important;
        }
      }
    }
  }
}

.ant-breadcrumb {
  padding: 5px 0px;
  font-size: 14px;

  a {
    color: rgba(0, 0, 0, 0.65);
    -webkit-transition: color 0.3s;
    transition: color 0.3s;
  }

  span {
    font-size: 14px;

    &:last-child {
      font-weight: 700;
      font-weight: bold;
    }
  }
}

.ant-popover {
  min-width: auto !important;
  max-width: 100% !important;
  width: auto !important;

  .ant-popover-inner-content {
    padding: 10px !important;
  }

  .ant-popover-title {
    margin: 0;

    .upload-header {
      @extend .flexbox;
      @extend .item-center;
      @extend .justify-between;

      span {
        flex: 0 auto;
        text-transform: uppercase;
        line-height: 22px;
      }

      i {
        font-size: 15px;
        cursor: pointer;

        &:hover {
          color: $theam-blue;
        }
      }
    }
  }

  .date-selection {
    flex: 1 1 auto;
    @extend .flex-column;
    @extend .item-center;

    &__input {
      flex: 1 1 auto;
      margin-bottom: 10px;

      .edit-upload {
        max-width: 300px;
      }
    }

    &__btn {
      flex: 0 0 auto;

      .ant-btn-primary[disabled] {
        border: 0;
        color: #333333 !important;
      }
    }
  }
}

.ant-table-wrapper {
  @extend .flex-column;

  .ant-table {
    margin: 0;

    .ant-table-content {
      font-size: 12px;
      position: relative;

      .ant-table-thead {
        margin: 0;

        tr {
          margin: 0;

          th {
            background: $table-head;
            vertical-align: middle;
            padding: 16px 8px;
            position: relative;
            @extend .graytbl-header;

            &.ant-table-column-has-filters {
              margin: 0;
              padding: 16px 8px;

              .anticon-filter {
                display: inline-block;
                position: relative;
                width: 16px;
                height: 16px;
                line-height: 6px;
                border: 0px;
                padding: 0;
                text-align: center;
                @extend .flexbox;
                @extend .all-center;

                svg {
                  top: 3px;
                  left: 3px;
                  margin: 0;
                }
              }
            }
          }
        }
      }

      .ant-table-body {
        margin: 0;

        tr {
          margin: 0;

          td {
            vertical-align: middle;
            padding: 12px 8px;
            position: relative;
            @extend .tblrow-color;

            a {
              color: $theam-blue;
            }

            .tblprimary-data {
              @extend .flex-column;

              .primary-head {
                @extend .tblprimary-color;
              }
            }
          }
        }
      }
    }
  }

  &.cursor-pointer {
    margin: 0;

    .ant-table-thead {
      margin: 0;

      tr {
        margin: 0;

        th {
          cursor: pointer;
        }
      }
    }

    .ant-table-body {
      margin: 0;

      tr {
        margin: 0;

        td {
          cursor: pointer;
        }
      }
    }
  }

  &.tbl-white {
    margin: 0;

    .ant-table-thead {
      margin: 0;

      tr {
        margin: 0;

        th {
          background: $white-color !important;
          @extend .whitetbl-header;
        }
      }
    }
  }

  &.administrative-table {
    min-height: calc(100vh - 205px);

    .ant-table-column-title {
      font-size: 14px;
      font-weight: 700;
    }

    // .ant-table-tbody{
    //   tr{
    //   td{
    //   padding-left: 25px !important;
    //   padding-right: 25px;

    // }
    // }
    // }
    .ant-spin-nested-loading {
      margin: 0;

      .ant-spin-container {
        margin: 0;

        .ant-table-layout-fixed {
          margin: 0;

          .ant-table-fixed {
            table-layout: auto;

            .ant-table-thead {
              margin: 0;

              tr {
                margin: 0;
              }
            }
          }
        }
      }
    }
  }

  &.analysis-table {
    margin: 0;

    .ant-table-thead {
      padding: 0;

      tr {
        margin: 0;

        th {
          vertical-align: middle;
          padding: 4px 5px;

          &.post-views,
          &.filter-control {
            padding-right: 5px !important;

            .filter-display {
              border: 0px !important;
              right: auto !important;
              left: 50%;
              padding: 0px;
              @include transform(translate(-50%, 0));
            }
          }
        }
      }
    }

    .ant-table-tbody {
      padding: 0;

      .ant-table-row {
        padding: 0;

        td {
          padding: 6px 10px;
          vertical-align: middle;

          &.filter-control,
          &.post-views {
            text-align: center;
          }

          &:nth-child(1) {
            width: 4%;
          }

          &:nth-child(2) {
            width: 6%;
          }

          &:nth-child(3) {
            width: 14%;
          }

          &:nth-child(4) {
            width: 4%;
          }

          &:nth-child(5) {
            width: 5%;
          }

          &:nth-child(6) {
            width: 7%;
          }

          &:nth-child(7) {
            width: 5%;
          }

          &:nth-child(8) {
            width: 5%;
          }

          &:nth-child(9) {
            width: 12%;
          }

          &:nth-child(10) {
            width: 20%;
          }

          &:nth-child(11) {
            width: 4%;
          }

          &:nth-child(12) {
            width: 4%;
          }

          &:nth-child(13) {
            width: 4%;
          }
        }
      }
    }
  }

  &.validate-schedule {
    .ant-table-row {
      padding: 0;

      td {
        margin: 0;

        &:nth-child(1) {
          width: 4%;
        }

        &:nth-child(2) {
          width: 12%;
        }

        &:nth-child(3) {
          width: 9%;
        }

        &:nth-child(4) {
          width: 10%;
        }

        &:nth-child(5) {
          width: 5%;
        }

        &:nth-child(6) {
          width: 5%;
        }

        &:nth-child(7) {
          width: 10%;
        }

        &:nth-child(8) {
          width: 33%;
        }

        &:nth-child(9) {
          width: 6%;
        }

        &:nth-child(10) {
          width: 6%;
        }
      }
    }
  }
}

.ant-pagination {
  flex: 1 1 auto;
  @extend .flexbox;
  @extend .item-center;
  @extend .justify-end;

  li {
    flex: 0 0 auto;
    border: 0;
    padding: 0px;
    height: 30px;
    margin-right: 8px;
    margin-bottom: 0 !important;
    @include border-radius(0);

    a {
      flex: 0 0 auto;
      width: 30px;
      height: 30px;
      line-height: 30px;
      font-size: 14px;
      margin: 0px;
      padding: 0px;
      border: solid 1px #d9d9d9;
      @extend .flexbox;
      @extend .all-center;
      @include border-radius(3px);
      @include transition(all 0.8s ease-in-out);

      &.ant-pagination-item-link {
        @extend .flexbox;

        .ant-pagination-item-container {
          @extend .flexbox;
          @extend .all-center;
          position: relative;
          width: 100%;
          height: 30px;
        }
      }
    }

    &.ant-pagination-jump-next,
    &.ant-pagination-jump-prev {
      flex: 0 0 auto;

      a {
        border: 0px;

        .ant-pagination-item-container {
          @extend .flexbox;
          @extend .all-center;
          width: 100%;
          height: 30px;
        }
      }
    }

    &.ant-pagination-total-text,
    &.ant-pagination-item {
      color: #3c4043;
    }

    &.ant-pagination-item-active {
      flex: 0 0 auto;

      a {
        background-color: $theam-blue;
        border-color: $theam-blue;
        color: $white-color;

        &:hover {
          color: $white-color;
        }
      }
    }

    &.ant-pagination-prev,
    &.ant-pagination-next {
      flex: 0 0 auto;

      a {
        border: 0px;
      }
    }

    &.ant-pagination-options {
      margin-right: 0px;
      margin-left: 0;
      @extend .flexbox;
      @extend .all-center;

      .ant-select {
        flex: 0 0 auto;
        min-width: auto;
        margin: 0px;
        max-height: 30px;
        min-height: 30px;

        .ant-select-selection {
          max-width: 100px;
          max-height: 30px;
          min-height: 30px;
          @extend .flexbox;
          @extend .item-center;
          color: #3c4043;
        }
      }
    }

    &.ant-pagination-total-text {
      @extend .flexbox;
      @extend .item-center;
    }
  }
}

.sentenceSelect {
  position: absolute;
  top: 100%;
  left: 0;
  min-width: 150px !important;
  max-width: 300px !important;
  z-index: 9999;

  .ant-select-selection {
    margin-top: 4px;
    width: 22rem !important;
  }

  .ant-select-selection__rendered {
    // max-height: 40px !important;
    overflow: auto !important;
    overflow-x: hidden !important;
  }

  .ant-select-selection__rendered::-webkit-scrollbar {
    width: 4px;
    height: 8px;
    cursor: pointer;
  }

  .ant-select-selection__rendered::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 2px;
  }

  .css-bg1rzq-control {
    min-height: 30px !important;
  }
}


.ant-time-picker-input {
  @include border-radius(3px);
}

.table {
  margin: 0;
  border: 0px;

  tbody {
    width: 800px;
  }
}

.campaign-main {
  margin: 0;
  padding-right: 80px;

  .ant-steps {
    @extend .flexbox;
    @extend .item-center;

    &-item {
      // flex: 1 1 auto;
      @extend .flexbox;
      @extend .item-center;

      &.ant-steps-item-process {
        padding: 0;

        .ant-steps-item-icon {
          background-color: $theam-blue;
        }
      }

      &-icon {
        border: solid 2px $theam-blue;
        @extend .flexbox;
        @extend .all-center;
        @include border-radius(50%);
      }
    }
  }

  .steps-action {
    @extend .flexbox;
    @extend .item-center;
    margin-bottom: 20px;

    .ant-btn {
      margin-right: 10px;
    }

    .campaign-validation {
      flex: 0 0 auto;
      margin-left: 10px;

      .anticon {
        margin-right: 8px;
        font-size: 16px !important;

        svg {
          fill: $theam-blue;
        }
      }
    }
  }
}

.ant-calendar-month-panel-selected-cell {
  margin: 0;

  .ant-calendar-month-panel-month {
    background-color: $theam-blue;
    color: $white-color !important;
  }
}

.ant-select {
  flex: 1 1 auto;
  min-height: 35px;

  &.ant-select-open {
    background-color: transparent;

    .ant-select-selection {
      border-color: $theam-blue;
      @include box-shadow(0 0 0 transparent !important);
    }
  }

  &.ant-select-focused {
    background-color: transparent;

    &:not(.ant-select-disabled),
    &:not(.ant-select-customize-input) {
      .ant-select-selection {
        outline: 0;
        border-color: $theam-blue;
        @include box-shadow(0 0 0 transparent !important);
      }
    }
  }

  .ant-select-selection {
    min-height: 35px;
    border: solid 1px #a0a7b4;
    @include border-radius(3px);

    &__rendered {
      position: relative;
      min-height: 35px;
      @extend .flexbox;
      @extend .item-center;
    }

    &__placeholder {
      color: $text-color;
      margin: 0;
      height: auto;
      @include transform(translate(0, -50%));
    }
  }

  &.reviews-mail {
    width: 100%;
  }
}

/* ---------- Am chart ------------- */
.amcharts-amexport-menu-level-0 {
  z-index: 9999 !important;
  top: -40px !important;

  .amcharts-amexport-item-blank {
    margin: 0;
    z-index: 999 !important;
    width: auto !important;
    height: auto !important;
    min-height: auto !important;
    opacity: 1 !important;
    background-color: #fff !important;

    a {
      display: block;
      overflow: hidden;
      width: 36px !important;
      height: 36px !important;
      cursor: pointer !important;
      background-color: $white-color;
      background-image: url(/images/chart-icon.png);
      background-position: 50%;
      background-repeat: no-repeat;
      background-size: 10px;
      @include transition(all 0.4s ease-in-out);
      @include box-shadow(1px 1px 3px 0 rgba(0, 0, 0, 0.5));
      @include border-radius(50%);

      &:hover {
        background-color: #737272 !important;
        background-image: url(/images/chart-icon-white.png);
        @include transition(all 0.4s ease-in-out);
      }
    }

    .amcharts-amexport-menu-level-1,
    .amcharts-amexport-menu-level-2 {
      border: #e5e5e5 solid 1px;

      li {
        margin: 0 !important;
        width: auto !important;
        height: auto !important;
        padding: 0px !important;
        background-color: #fff;
        border-bottom: #e5e5e5 solid 1px;

        a {
          width: auto !important;
          height: auto !important;
          margin: 0 !important;
          border: 0 !important;
          color: #333333;
          cursor: pointer !important;
          padding: 8px 15px !important;
          background-color: #fff !important;
          background-image: none !important;
          @include border-radius(0);
          @include box-shadow(0, 0, 0, 0 rgba(0, 0, 0, 0));

          &:hover {
            background-color: #737272 !important;
            color: #fff;
          }
        }

        &:last-child {
          border-bottom: 0;
        }
      }
    }
  }
}

.ant-affix {
  z-index: 9 !important;
}

.ant-message {
  margin: 0;
  z-index: 5555555555555;
  @extend .flex-column;
  @extend .all-center;

  .ant-message-notice {
    margin: 0;
    flex: 0 0 auto;

    .ant-message-notice-content {
      flex: 0 0 auto;
      @extend .flexbox;
      @extend .item-center;

      i {
        font-size: 20px;
        float: left;
        margin: 0;
        padding: 0;
      }

      span {
        margin: 0;
        padding: 0 0 0 10px;
      }
    }
  }
}

.ant-switch {
  min-width: 40px;
  max-height: 20px;
  margin: 0;
  background-color: #a0a7b4;
  @extend .flex-column;

  .ant-switch-inner {
    flex: 1 1 auto;
    max-height: 20px;
    line-height: 18px;
  }

  &.ant-switch-checked {
    background-color: $primary-active-color;
  }

  &:after {
    min-width: 16px;
    min-height: 16px;
    width: 16px;
    height: 16px;
  }

  &.fixed-width {
    min-width: 64px;
  }

  &.on-off {
    min-width: 50px;
  }
}

/* ---- table dropdown ---*/
.ant-table-filter-dropdown {
  max-width: 300px;
}

/* ---- table dropdown END ---*/
.upload-wrap {
  min-height: 150px;
  position: relative;

  &.ant-upload-picture-card-wrapper {
    @extend .flex-column;
    background-color: $right-header-background;
    border: 1px dashed #d9d9d9;
    @include border-radius(4px);

    .ant-upload-select-picture-card {
      flex: 1 1 auto;
    }

    .ant-upload-list-picture-card {
      flex: 1 1 auto;
      position: relative;
      z-index: 0;
      padding: 0;
      margin: 0;
      @extend .flex-column;

      .ant-upload-list-picture-card-container {
        flex: 1 1 auto;
        width: 100% !important;
        height: 100% !important;
        margin: 0;
        @extend .flex-column;
        @extend .all-center;

        .ant-upload-list-item {
          margin: 0;
          padding: 0;
          width: auto;
          height: 100%;
          border: 0;

          .ant-upload-list-item-info {
            margin: 0;
            height: 100%;
            @extend .flexbox;
            @extend .all-center;

            &:before {
              left: 0;
            }

            span {
              flex: 1 1 auto;

              .ant-upload-list-item-thumbnail {
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }

  .ant-upload-select-picture-card {
    flex: 1 1 auto;
    position: relative;
    background-color: transparent;
    border: 0;
    z-index: 2;
    width: 100%;
    margin: 0 !important;

    .ant-upload {
      @extend .flexbox;
      @extend .all-center;
    }
  }

  .ant-upload-list-picture-card {
    margin: 0;
  }
}

.MuiTypography-root {
  font-size: $table-header-column-font-size !important;

  svg {
    height: $icon-size-xs;
    width: $icon-size-xs;
  }
}

.table-user-wrapper,
.table-user-workspace-wrapper {
  .MuiButtonBase-root.Mui-disabled.MuiIconButton-root.Mui-disabled svg {
    display: none;
    content: '';
  }

  .MuiButtonBase-root:hover {
    background-color: #fff;

  }

  .MuiButtonBase-root-MuiIconButton-root:hover {
    background-color: none;
  }

  .MuiButtonBase-root.Mui-disabled.MuiIconButton-root.Mui-disabled button {
    display: none;
    content: '';
  }

  .MuiAlert-message {
    display: none;
  }

  .MuiTooltip-popper {
    display: none !important;
  }

  .rotating {
    animation: spin 1s linear infinite;

  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  .Mui-TableHeadCell-Content-Wrapper {
    color: $drk-text;
    font-weight: 700;
    font-size: $table-header-column-font-size;

    svg {
      height: $icon-size-xs !important;
      width: $icon-size-xs !important;
    }


  }

  .MuiPaper-root {
    box-shadow: none !important;
  }

  thead > tr:first-child > th:first-child {
    padding-left: 24px;
  }
  tbody > tr > td:first-child {
    padding-left: 24px;
  }

  .MuiTableCell-root {
    // padding-left: 24px;
    font-size: 1rem;
    color: $med-drk-text;

    a {
      color: $primary-active-color;
      max-width: 150px;
      word-break: break-all;
    }

    a:hover {
      color: $primary-active-color;
    }
  }

  .ant-table-thead {

    tr,
    th {
      padding: $page-content-padding !important;
      font-size: 14px !important;
      font-weight: 700;

      img {
        width: 24px;
        height: 24px;
      }
    }
  }

  .ant-table-tbody {

    tr,
    td {
      padding: $page-content-padding !important;

    }


  }

  .ant-pagination {
    padding-right: 32px !important;
  }
}

.table-user-workspace-wrapper {

  .MuiTableCell-root {
    padding-left: 5px !important;

  }
}

.column-font-sm {
  font-size: $font-size-sm;
  font-weight: 400;
}

.column-font-xs {
  font-size: $font-size-xs;
  font-weight: 400;

}

.column-font-normal {
  font-size: 14px;
}

.column-font-med {
  font-size: 13px;
  line-height: 18px;

  p {
    font-size: 13px;
    line-height: 18px;
  }


}

.column-select {
  display: flex;

  .ant-select-selection {
    border-radius: 8px;
  }
}

.column-font-med-width {
  font-size: 14px;
  // max-width: 300px; 
  // min-width:100px;
  // word-break: break-all;
  // display: flex;
  // flex-wrap: wrap;

}