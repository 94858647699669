@import '../helpers/mixins';
@import '../themes/index.scss';
@import '../fonts/index.scss';
@import '../general/index.scss';
@import '../icons/index.scss';

.error {
	border-color: #f60101 !important;
}

.hide {
	display: none;
}

.ant-layout-divider {
	@extend .flexbox;
	@extend .item-center;
	@extend .justify-between;

	span {
		flex: 1 1 auto;
		height: 2px;

		&.divider-red {
			background: #f2602c;
		}

		&.divider-blue {
			background: #11abe2;
		}

		&.divider-yellow {
			background: #f9a125;
		}

		&.divider-green {
			background: #38b970;
		}
	}
}

/*---- sidebar END ---*/
.main-wrapper {
	@extend .flexbox;
	@extend .item-start;
	min-height: 100vh;
	@include transition(all 0.4s ease-in-out);

	&__sidebar {
		flex: 0 0 auto;
		min-width: 70px;
		max-width: 70px;
		overflow: hidden;
		position: sticky;
		opacity: 1;
		height: 100vh;
		top: 0;
		max-height: 100%;
		background-color: $side-bar;
		@include transition(all 0.4s ease-in-out);
	}

	&__content {
		flex: 1 1 auto;
		overflow: hidden;
		max-width: 100vw;
		min-height: 100vh;
		@extend .flex-column;
		@include transition(all 0.4s ease-in-out);
	}

	&.close-side {
		@include transition(all 0.7s ease-in-out);

		.main-wrapper__sidebar {
			min-width: 0;
			max-width: 0;
			@include transition(all 0.7s ease-in-out);
		}

		.main-wrapper__content {
			@include transition(all 0.7s ease-in-out);
		}

		.slide-icon {
			display: none;
		}
	}

	&.collapsed {
		@include transition(all 0.2s ease-in-out);

		.main-wrapper__sidebar {
			flex: 1 0 auto;
			min-width: 200px;
			max-width: 200px;
			@include transition(all 0.2s ease-in-out);
		}
	}
}

.loader-main,
.graph-loader {
	@extend .flexbox;
	@extend .all-center;
	height: calc(100vh - 67px);

	.loader {
		border: 2px solid #cccccc;
		border-top: 2px solid $theam-blue;
		opacity: 0.8;
		width: 30px;
		height: 30px;
		-webkit-animation: spin 0.5s linear infinite;
		animation: spin 0.5s linear infinite;
		@include border-radius(50%);
	}



	@-webkit-keyframes spin {
		0% {
			-webkit-transform: rotate(0deg);
		}

		100% {
			-webkit-transform: rotate(360deg);
		}
	}

	@keyframes spin {
		0% {
			transform: rotate(0deg);
		}

		100% {
			transform: rotate(360deg);
		}
	}
}

.loader-main-chart {
	@extend .flexbox;
	@extend .all-center;
	height: 100%;

	.loader {
		border: 2px solid #cccccc;
		border-top: 2px solid $theam-blue;
		opacity: 0.8;
		width: 30px;
		height: 30px;
		-webkit-animation: spin 0.5s linear infinite;
		animation: spin 0.5s linear infinite;
		@include border-radius(50%);
	}



	@-webkit-keyframes spin {
		0% {
			-webkit-transform: rotate(0deg);
		}

		100% {
			-webkit-transform: rotate(360deg);
		}
	}

	@keyframes spin {
		0% {
			transform: rotate(0deg);
		}

		100% {
			transform: rotate(360deg);
		}
	}
}

.graph-loader {
	height: 100%;
}

.notes-loader .loader-main {
	height: 100px !important;
	margin: 0px 0 10px 0px !important;
}

.new-card {
	padding: 20px 0;
	height: 100%;
	@extend .flex-column;
	@extend .all-center;

	h3 {
		font-size: 23px;
		color: #aeaeae;
		margin-top: 10px;
	}

	.new-card-icon {
		cursor: pointer;
		width: 70px;
		height: 70px;
		background: #56c386;
		text-align: center;
		@extend .flexbox;
		@extend .all-center;
		@include border-radius(50%);

		svg {
			width: 50%;
			height: 50%;

			path {
				fill: $white-color;
			}
		}

		i {
			color: #fff !important;
			font-size: 60px;
		}
	}
}

.add-block {
	@extend .flexbox;
	@extend .all-center;
	height: calc(100vh - 200px);

	.card {
		max-width: 55%;
		min-width: 55%;

		&-body {
			margin: 0;

			h3 {
				font-size: 18px;
				margin-bottom: 15px;
			}
		}
	}
}

.input-wrapper {
	display: block;
	width: 100%;
	position: relative;

	.error-message {
		position: absolute;
		right: 10px;
		top: 10px;
		color: $red-color;
		font-size: 12px;
	}
}

.dashboard-outer {
	flex: 1 1 auto;
	@extend .flex-column;

	@media print {
		display: block;
	}

	.print-header {
		@extend .flexbox;
		@extend .item-center;

		@media print {
			display: block;
		}
	}

	.dashboard {
		flex: 1 1 auto;
		padding: 10px;
		@extend .flex-column;

		@media print {
			padding: 0px;
			display: block;
		}

		.row {
			flex: 1 1 auto;

			&:not(:last-child) {
				margin-bottom: 20px;
			}

			.card {
				height: 100%;
			}
		}

		.dashboard-group {
			margin-bottom: 10px;
			@extend .flex-column;

			.row {
				flex: 1 1 auto;
				margin-right: -5px;
				margin-left: -5px;
				margin-bottom: 10px;

				[class^='col-'],
				[class*='col-'] {
					padding: 0 5px;

					.card {
						height: 100%;

						&-header {
							margin: 0;

							.reviews-download {
								flex: 0 0 auto;
								margin-right: 40px;
							}
						}
					}
				}
			}
		}

		.report-options {
			@extend .item-center;
		}

		.report-header {
			font-weight: 600;

			.header-location {
				margin-right: 10px;
			}
		}

		.insights-graph {
			position: relative;
			height: 300px;
			width: 100%;

			.recharts-responsive-container {
				text-align: center;

				.recharts-wrapper {
					display: inline-block;

					.recharts-legend-wrapper {
						text-align: center;

						@media print {
							border-top: 0px;
						}
					}
				}
			}
		}

		.daily-insights {
			padding: 20px;
			height: 250px;
			width: 100%;

			@media print {
				padding: 20px;
				display: block;
			}
		}
	}
}

.header-container {
	flex: 0 1 auto;
	@extend .flexbox;
	@extend .item-center;
	padding: 0;
	height: 60px;
	background-color: $white-color;

	.slide-icon {
		flex: 0 0 auto;

		i {
			display: block;
			cursor: pointer;
			font-size: 20px;

			&.ant-tooltip-open {
				font-size: 20px !important;
			}
		}
	}

	.header-right {
		@extend .flexbox;
		@extend .item-stretch;
		padding: 0;
		margin-left: auto;
		margin-bottom: 0px;
		height: 65px;

		li {
			flex: 0 0 auto;
			position: relative;
			@extend .flexbox;

			.header-link {
				padding: 0px 15px;
				position: relative;
				font-weight: 700;
				border: 0 !important;
				@extend .flexbox;
				@extend .all-center;
				@include transition(all 0.8s ease-in-out);

				i {
					font-size: 20px;
				}

				&:hover {
					//color: $theam-blue;
					@include transition(all 0.8s ease-in-out);
				}

				.ant-btn {
					padding: 0 !important;
				}

				.button-badge {
					background-color: #f58258;
					color: #fff;
					padding: 1px 5px;
					font-size: 10px;
					position: absolute;
					top: 13px;
					right: 9px;
					@include border-radius(2px);
				}
			}

			a {
				span {
					&:hover {
						//color: $theam-blue;
						@include transition(all 0.8s ease-in-out);
					}
				}
			}

			.user-avatar {
				height: 65px;
				max-width: 36px;
				margin: 0;
				padding: 0;
				@extend .flexbox;
				@extend .all-center;

				img {
					min-width: 30px;
					max-width: 30px;
					min-height: 30px;
					max-height: 30px;
					@include border-radius(50%);
				}
			}

			&:first-child {
				margin: 0;

				a {
					color: $theam-blue;
					@include transition(all 0.8s ease-in-out);
				}
			}

			&.dropdown {
				position: relative;

				.dropdown-toggle {
					margin: 0;

					span {
						flex: 0 0 auto;

						&.angle-down {
							font-size: 28px;
						}
					}

					&:hover {
						font-weight: 700;
					}

					&:after {
						content: '';
						border: 0;
					}
				}

				&.show {
					margin: 0;

					.dropdown-toggle {
						@include transition(all 0.2s ease-in-out);
					}
				}

				.dropdown-menu {
					right: 10px !important;
					left: auto !important;
					top: 60px !important;
					width: auto;
					padding: 10px 0;
					@include border-radius(0px 0px 6px 6px);

					li {
						display: block;
						padding: 0px;
						margin: 0;

						&:first-child {
							margin-bottom: 1rem;
						}

						.popup-image {
							padding: 10px;
							min-width: 280px;
							position: relative;
							@extend .flex-column;
							@extend .all-center;

							&:after {
								position: absolute;
								content: '';
								bottom: 0px;
								left: 20px;
								height: 1px;
								background-color: $border-color;
								width: calc(100% - 40px);
							}

							.avatar-popup {
								height: 72px;
								width: 72px;
								padding: 2px;
								margin-bottom: 20px;
								@extend .flexbox;
								@extend .all-center;
								@include border-radius(50%);

								img {
									min-width: 70px;
									max-width: 70px;
									min-height: 70px;
									max-height: 70px;
									@include border-radius(50%);
								}
							}

							h3 {
								font-size: 16px;
								font-weight: 700;
								color: $black-color;
								margin-bottom: 6px;
							}

							span {
								color: #9c9c9c;
								font-size: 12px;
								padding-bottom: 3px;
							}
						}

						a {
							@extend .flexbox;
							@extend .item-center;
							padding: 8px 20px;
							font-size: 14px;
							font-weight: 400;
							color: $text-color;
							height: auto !important;
							@include transition(all 0.3s ease-in-out);

							span {
								flex: 1 1 auto;
								word-break: normal;
								white-space: nowrap;
								color: #212121;
							}

							i {
								font-size: 16px;
								margin-right: 8px;
							}

							&:hover {
								background-color: $theam-blue;
								color: $white-color !important;
								@include transition(all 0.2s ease-in-out);

								span {
									color: $white-color !important;
								}
							}
						}
					}
				}
			}

			&.notification {
				position: relative;

				.notification-toggle {
					margin: 0;

					span {
						flex: 0 0 auto;

						&.angle-down {
							font-size: 28px;
						}
					}

					&:hover {
						// color: $theam-blue; // for header text
						font-weight: 700;
					}

					&:after {
						content: '';
						border: 0;
					}
				}
			}

			.language-selection {
				.ant-btn {
					border: 0px;
					font-size: 12px !important;
				}
			}

			.custom-navicon {
				height: 19px;
				width: 19px;
				@extend .flexbox;
				@extend .all-center;

				svg {
					flex: 1 1 auto;
					width: 100%;
					height: 100%;
				}
			}
		}

		.toggle-chat {
			@extend .flexbox;
			width: 35px;
			cursor: pointer;
		}
	}
}

.rating-block {
	@extend .flexbox;
	@extend .item-center;
	@extend .justify-between;

	.star-rating {
		flex: 0 0 auto;
		height: 35px;
		width: 35px;
		border-color: $border-color;
		border-width: 1px;
		border-style: solid;
		@include border-radius(3px 0 0 3px);
		@extend .flexbox;
		@extend .all-center;

		i {
			flex: 0 0 auto;
			width: 20px;
			height: 20px;
			font-size: 18px;
			color: #6bcbee;
		}
	}

	.universal-refresh {
		flex: 0 0 auto;
		@include border-radius(0 3px 3px 0);
		border-left: 0px;
	}

	.universal-btn {
		flex: 1 1 auto;
	}

	.ant-btn {
		flex: 0 0 auto;
		border-color: $border-color;
		border-width: 1px 1px 1px 0;
		border-style: solid;
		height: 35px;

		&.ant-dropdown-trigger {
			min-width: 120px;

			span {
				display: block;
				width: 100%;
				text-align: center;
			}
		}
	}

	.ant-input-number {
		flex: 1 1 auto;
		height: auto;
		padding: 0px;
		height: 35px;
		border-color: $border-color;
		border-width: 1px 1px 1px 0;
		padding: 0px 3px;
		border-style: solid;
		line-height: 35px;
		@include border-radius(3px);

		.ant-input-number-handler-wrap {
			height: 35px;
			background-color: transparent;
		}

		.ant-input-number-input-wrap {
			height: 35px;
			line-height: 34px;

			.ant-input-number-input {
				padding: 0px;
				float: left;
				height: 33px;
				line-height: 33px;
				text-align: center;
				margin: 0;
				@include border-radius(3px);
			}
		}
	}
}

.star-rating-block {
	@extend .flexbox;
	@extend .all-center;
}

.reply-location {
	@extend .flexbox;
	@extend .item-center;

	span {
		flex: 0 0 auto;

		&:first-child {
			margin-right: 10px;
		}
	}
}

.location-search-wrap {
	@extend .flexbox;
	@extend .item-center;
	border-color: $border-color;

	span {
		flex: 0 0 auto;

		&:last-child {
			margin-right: 0px;
		}
	}

	.ant-dropdown-trigger {
		border: 0;
	}
}

.roles-features-table {
	padding: 8px 0;

	td {
		padding: 6px 0 6px 50px;

		.ant-dropdown-link {
			margin: 0;
			min-width: auto;
		}
	}
}

.local-comment {
	@extend .flex-column;

	&:not(:last-child) {
		margin-bottom: 1rem;
	}

	textarea {
		height: 80px;
		padding: 5px 10px;
		font-size: 14px;

		@include placeholder {
			color: #808080;
		}
	}

	.comment-input {
		margin: 0;
	}
}

.page-header,
.page-header-campaign {
	flex: 0 1 auto;
	@extend .flexbox;
	@extend .item-center;
	@extend .item-wrap;
	border: none;
	min-height: 60px;
	margin: 0px;
	z-index: 0;
	padding: $page-content-padding;

	h1,
	h3 {
		flex: 0 1 auto;
		font-weight: 800;
		letter-spacing: -.025rem;
		font-size: 30px;
		line-height: 36px;
		margin-right: 20px;
		color: #1E293B;
		font-family: "Inter var", ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
	}

	.verify-status {
		flex: 0 0 auto;
		width: 20px;
		height: 20px;
		margin-left: 8px;
		margin-bottom: -2px;
	}

	.update-sheet {
		margin-left: 6px;
	}

	.ant-btn {
		margin-left: auto;
	}

	.integration-wrap {
		@extend .flexbox;
		@extend .item-center;

		span {
			flex: 0 0 auto;
			margin-right: 6px;

			&:last-child {
				margin-right: 0;
			}

			&:first-child,
			&:nth-child(3) {
				font-weight: 600;
				color: $theam-blue;
			}
		}
	}

	.show-menu {
		margin-left: auto;
		margin-bottom: 0px;
		cursor: pointer;
		padding-bottom: 0px;
		font-size: 14px;

		.recreate {
			border: 1px solid #dadce0;
			padding: 5px 12px;
			margin-left: 8px;
			border-radius: 4px;

			&:hover {
				opacity: 0.8;
			}
		}

		a {
			color: #108ee9;
			padding-left: 8px;
			margin-left: 10px;
			font-size: 12px;
			font-weight: 650;
			border: 1px solid #dadce0;
			padding: 6px 12px;
			line-height: 16px;
			cursor: pointer;
			text-align: center;
			border-radius: 7px;

			&:hover {
				opacity: 0.8;
			}
		}

		.ant-btn {
			height: 28px;
			padding: 0px 8px;
			font-weight: 600;
			background-color: $red-color !important;
		}
	}

	.admin-page-header {
		flex: 0 0 auto;
		margin: 0px;
		font-size: 18px;
	}

	.date-selector {
		flex: 0 auto;
		margin-left: auto;
		@extend .flexbox;
		@extend .item-center;
		@extend .item-wrap;

		.download-location {
			flex: 0 auto;
			margin-right: 15px;
			font-weight: 700;
			padding: 0 10px;
			background-color: $theam-orange;
			border-color: $theam-orange;
			color: white;
			@extend .flexbox;
			@extend .item-center;

			span {
				flex: 0 0 auto;

				&:not(:last-child) {
					margin-right: 8px;
				}
			}
		}

		.ant-dropdown-trigger {
			@extend .flexbox;
			@extend .item-center;

			span {
				flex: 0 0 auto;
				margin-right: 8px;
			}
		}

		.granularity-radio {
			flex: 0 0 auto;
			margin-right: 10px;
		}

		.granularity-picker {
			flex: 0 0 auto;
			margin-right: 10px;
		}

		.dash-button-block {
			flex: 0 0 auto;
			@extend .flexbox;
			@extend .item-center;

			.download-button {
				flex: 0 auto;
				margin: 0;
				background: url('/images/download-pdf.png') no-repeat;
				height: 35px;
				width: 29px;
			}

			.add-report-button {
				flex: 0 0 auto;
				margin-left: 10px;
				background: url('/images/add-file.png') no-repeat 0 5px;
				height: 35px;
				width: 29px;
				cursor: pointer;
			}

			span {
				flex: 0 0 auto;
				margin-left: 10px;
			}

			span.select-filter-name {
				margin-left: 0px;
			}
		}

		.ant-dropdown-trigger {
			border: solid 1px $border-color;
			margin-left: 8px;
			@extend .flexbox;
			@extend .item-center;
			@include border-radius(3px);

			span {
				margin: 0;
				padding: 0px;
			}

			i {
				margin: 0;
			}
		}

		.ant-dropdown-link {
			border: 0;
			padding: 0px;
		}

		.location-search {
			flex: 0 0 auto;
		}

		.download-location {
			flex: 0 auto;
			margin-left: 8px;
			font-weight: 700;
			padding: 0 10px;
			background-color: #f99d1c;
			border-color: #f99d1c;
			@extend .flexbox;
			@extend .item-center;

			span {
				flex: 0 0 auto;
				height: auto;

				&:first-child {
					margin-right: 8px;
					color: #f99d1c;
					background-color: #fff !important;
					width: 25px;
					height: 25px;
					line-height: 24px;
					font-size: 16px;
					@include border-radius(50%);
				}

				&:last-child {
					line-height: 0px;
					flex: 1 1 auto;
					color: $white-color !important;
				}
			}
		}
	}

	.permissions-button-block {
		flex: 0 0 auto;
		margin-left: auto;
		@extend .flexbox;

		.ant-btn {
			@extend .flexbox;
			@extend .item-center;

			i {
				flex: 0 0 auto;
				margin-right: 6px;
			}

			span {
				margin-left: 0px !important;
			}
		}

		.ant-input-search {
			margin-right: 12px;
		}
	}

	.add-button-block {
		padding-left: 20px;

		.ant-btn {
			@extend .flexbox;
			@extend .item-center;
		}
	}

	.type-menu {
		margin-left: auto;
	}

	.postbutton {
		color: $graytbl-font;
		font-weight: 600;
		padding: 5px 34px;

		&.active {
			background: rgba($text-color, 0.15);
			border-radius: 14px;
		}
	}
}

.page-header-campaign {
	background-color: #F1F5F9;
}

.users-sync {
	margin-left: 10px;
}

.source-image {
	flex: 0 0 auto;
	overflow: hidden;
	width: 30px;
	height: 30px;
	@extend .flexbox;
	@extend .all-center;

	i {
		font-size: 24px;
	}
}

.role-options {
	margin: 0;
	min-width: 700px;

	.shedule-type {
		@extend .flexbox;
		@extend .item-center;
		padding-top: 8px;

		label {
			flex: 0 0 auto;
			margin-right: 15px;
		}

		.info-notification-fetch {
			margin-left: 4px;
		}
	}
}

.review-settings,
.review-settings-campaign {
	flex: 1 1 auto;
	margin: 0px;
	@extend .flex-column;
	// max-height: 100vh;

}

.portfolio-settings {
	margin: 0px;

	.report-management {
		min-height: calc(100vh - 250px);

		.empty-state {
			min-height: calc(100vh - 153px) !important;
		}
	}
}

.fetcher-health {
	margin: 0;
	padding: $page-content-padding;

	.card {
		min-height: auto !important;
		padding: 20px 10px;

		&-body {
			width: 100%;
			height: 300px;
		}

		.card-header {
			padding: 0;

			h3 {
				color: #666;
			}
		}
	}
}

.back-button {
	color: #108ee9;
	cursor: pointer;
	margin-bottom: 4px;
}

.entity-list {
	@extend .flexbox;
	@extend .item-center;
	@extend .justify-between;
	flex: 1 1 auto;

	&__search {
		margin-left: auto;
		flex: 0 auto;
		padding-right: 40px;
		@extend .flexbox;
		@extend .item-center;

		span {
			flex: 0 0 auto;
			margin-right: 8px;
		}

		.location-search {
			flex: 0 0 auto;
		}
	}
}

.content-state {
	@extend .flexbox;
	@extend .all-center;
	background-color: $white-color !important;

	&__inner {
		@extend .flex-column;
		@extend .all-center;
		margin-bottom: 25px;

		.add-pro-icon {
			flex: 0 0 auto;
			@extend .flex-column;
			@extend .all-center;
			max-width: 120px;
		}

		h3 {
			flex: 0 0 auto;
			font-size: 22px;
			margin-bottom: 15px;
			//color: #999;
			color: #70757a;
		}

		span {
			color: #3c4043;
		}

		p {
			flex: 0 0 auto;
			font-size: 14px;
		}

		.add-buttom-block {
			flex: 1 1 auto;
			margin: 0;
			padding-top: 10px;
		}

		a {
			color: $theam-blue !important;
		}
	}
}

.empty-state {
	min-height: calc(100vh - 70px);
}

.empty-state,
.content-state {
	flex: 1 1 auto;
	//min-height: 300px;
	padding: 30px 0;
	background-color: $white-color !important;
	@extend .flex-column;
	@extend .all-center;

	&__inner {
		@extend .flex-column;
		@extend .all-center;
		margin-bottom: 25px;

		.add-pro-icon {
			flex: 0 0 auto;
			@extend .flex-column;
			@extend .all-center;
			max-width: 120px;
		}

		h3 {
			flex: 0 0 auto;
			font-size: 22px;
			margin-bottom: 15px;
			//color: #999;
			color: #70757a;
		}

		span {
			color: #3c4043;
		}

		p {
			flex: 0 0 auto;
			font-size: 14px;
		}

		.add-buttom-block {
			flex: 1 1 auto;
			margin: 0;
			padding-top: 10px;
		}

		a {
			color: $theam-blue !important;
		}
	}
}

.content-state {
	min-height: calc(100vh - 500px);
}

.settings {
	flex: 1 1 auto;
	position: relative;
	@extend .flexbox;
	@extend .item-stretch;

	&__sidebar {
		flex: 0 1 auto;
		//box-sizing: border-box;
		min-height: calc(100vh - 120px);
		position: sticky;
		top: 106px;
		min-width: 230px;
		max-width: 230px;
		padding: 0px;
		background: #fff;
		border-right: 1px solid #e9eef2;

		&--header {
			@extend .flexbox;
			@extend .item-center;
			font-size: 24px;
			padding: 0 15px;
			height: 60px;
			font-weight: 300;
			background-color: #f8f8f8;
		}

		&--menu {
			margin: 0px;
			padding: 0px;
			@extend .flex-column;

			li {
				flex: 1 1 auto;
				padding: 0;
				font-size: 15px;

				a {
					color: #555;
					padding: 12px 15px;
					text-decoration: none;
					position: relative;
					@extend .flexbox;
					@extend .item-center;

					.setting-arrow {
						position: absolute;
						right: 20px;
						top: 50%;
						margin: 0;
						padding: 0;
						@include transform(translate(0, -50%));
					}

					span {
						flex: 1 1 auto;
					}

					img {
						flex: 0 0 auto;
						width: 16px;
						margin-right: 10px;
					}

					i {
						flex: 0 0 auto;
						width: 20px;
						height: 20px;
						line-height: 20px;
						margin-right: 10px;
						@extend .flexbox;
						@extend .all-center;
					}

					&.active {
						background: #f2f2f2;

						&:after {
							width: 2px;
							height: 100%;
							position: absolute;
							top: 0px;
							right: -2px;
							background-color: #f2f2f2;
							content: '';
							z-index: 3;
						}
					}
				}
			}

			&.manage-post {
				background-color: #fff;
				border-right: 0px;

				li {
					flex: 1 1 auto;

					a {
						margin: 0;
						@extend .flexbox;
						@extend .item-center;

						i {
							width: 30px;
							height: 30px;
							background-color: $theam-blue;
							color: $white-color;
							@extend .flexbox;
							@extend .all-center;
							@include border-radius(50%);
						}
					}

					&.submenu-group {
						margin-top: 1.5rem;
					}
				}
			}
		}
	}

	&__content {
		flex: 1 1 auto;
		min-height: calc(100vh - 120px);
		margin: 0;
		padding: 0;
		background-color: transparent;
		@extend .flex-column;
	}

	&__content_portfolio {
		flex: 1 1 auto;
		margin: 0;
		padding: 0;
		background-color: white;
	}
}

.navigation-heading {
	@extend .flexbox;
	@extend .item-center;
	@extend .justify-end;
	padding-bottom: 10px;
	border-bottom: #f2f2f2 solid 1px;

	h3 {
		flex: 0 auto;
		margin-right: auto;
		padding: 0px;
	}

	.navigation-icon {
		flex: 0 auto;
		width: 20px;
		height: 20px;
	}

	a {
		flex: 0 auto;
		width: 20x;
		height: 20px;

		i {
			font-size: 20px;
			color: $text-color;
		}
	}
}

.card-title {
	@extend .flexbox;
	@extend .item-center;
	@extend .justify-end;
	padding: 15px 10px;

	h3 {
		margin-right: auto;
	}

	span {
		flex: 0 auto;
		margin-right: 8px;

		&:last-child {
			margin-right: 0px;
			height: 20px;
		}

		.edit-btn {
			@extend .flexbox;
			@extend .all-center;
			width: 20px;
			height: 20px;
			line-height: 15px;
			text-align: center;
			background-color: rgba(255, 255, 255, 0.2);
		}
	}
}

.ant-dropdown-link {
	@extend .flexbox;
	@extend .item-center;
	@extend .justify-between;
	min-width: 80px;

	span {
		flex: 0 0 auto;
		padding: 0px;
	}

	.anticon {
		font-weight: 700;
		margin-left: 4px !important;
		font-size: 14px !important;
	}
}

.contact-info {
	@extend .flexbox;
	@extend .item-center;
	flex-wrap: wrap;

	li {
		flex: 0 0 auto;
		margin-right: 18px;

		&:last-child {
			margin-right: 0px;
		}

		a {
			display: block;
			font-size: 14px;
			margin: 0;
			color: $theam-blue !important;
			cursor: pointer;
			text-decoration: underline !important;
		}
	}
}

.organization-cards {
	display: grid;
	grid-template-columns: repeat(auto-fill, 230px);
	align-items: center;
	align-content: start;
	grid-gap: 2rem;
	min-height: calc(100vh - 250px);
	padding: 15px;

	.organization-item {
		grid-template-columns: repeat(4, 1fr);
		width: 250px;
		margin: 0;
		padding: 0;
		height: 160px;
		overflow: hidden;
		background-color: $white-color !important;
		@include box-shadow(0, 0px, 3px, rgba(0, 0, 0, 0.1), false);
		@include border-radius(3px);

		.add-organization-card {
			flex: 1 1 auto;
			height: 100%;
			@extend .flex-column;
			@extend .all-center;

			h3 {
				font-size: 18px;
			}
		}

		.card {
			flex: 1 1 auto;
			background: $white-color;
			@extend .flex-column;

			&-header {
				margin: 0px;
				background-color: #d8d8d7 !important;
				padding: 10px;
				height: 40px;
				@extend .flexbox;
				@extend .item-center;
				@extend .justify-end;

				h3 {
					flex: 0 0 auto;
					margin-right: auto;
					font-size: 14px;
					color: #555;
					// text-transform: uppercase;
				}

				.edit-btn,
				span,
				.view-btn {
					flex: 0 0 auto;
					height: 20px;
					line-height: 20px;
					padding: 0px 4px;
					text-align: center;
					@include border-radius(3px);

					.material-icons {
						font-size: 20px;
						margin: 0;
						padding: 0;
					}
				}

				span {
					margin-right: 0px;
					padding: 0;
				}

				.edit-btn {
					margin-right: 8px;
					@include linear-gradient(#0599ce, #0291c4);
				}

				.edit-accountname {
					margin-right: 8px;
				}

				.view-btn {
					@include linear-gradient(#676d79, #5c626d);

					.material-icons {
						color: $white-color !important;
					}
				}
			}

			&-body {
				flex: 1 1 auto;
				padding: 10px;
				height: calc(180px - 100px);
				@extend .flex-column;
				@extend .item-center;

				p {
					font-size: 14px;
					text-align: center;
					margin: 0px;
				}
			}

			&-footer {
				flex: 1 1 auto;
				padding: 10px;
				height: 40px;
				@extend .flexbox;
				@extend .item-center;

				.checkbox {
					flex: 0 0 auto;
					margin-right: 8px;
					z-index: 100;
				}

				span {
					flex: 0 0 auto;
				}

				.account-refresh-btn {
					flex: 0 0 auto;
					margin-left: auto;
					cursor: pointer;

					i {
						font-size: 16px;
						margin: 0px;
						width: 20px;
						height: 20px;
						text-align: center;
						line-height: 20px;
					}

					&:hover {
						padding: 0;

						i {
							font-size: 16px;
							margin: 0px;
							width: 20px;
							height: 20px;
						}
					}
				}
			}

			&.activated {
				flex: 1 1 auto;

				.card-header {
					background-color: $theam-blue !important;

					h3 {
						color: $white-color;

						span {
							color: $white-color;
						}
					}

					.edit-btn,
					span,
					.view-btn {
						flex: 0 0 auto;

						.material-icons {
							color: $white-color !important;
						}
					}
				}
			}
		}
	}
}

.settings-item {
	flex: 1 1 auto;
	margin-top: 15px;
	min-height: 150px;

	&__header {
		@extend .flexbox;
		@extend .item-center;
		margin-bottom: 15px;

		span {
			flex: 0 0 auto;
			margin-right: 10px;
		}

		.ant-input-number {
			flex: 0 0 auto;
			max-width: 60px;
			text-align: center;
			margin-right: 10px;
		}
	}

	&__type {
		@extend .flexbox;
		@extend .item-center;
		margin-bottom: 15px;

		label {
			flex: 0 0 auto;
			margin-right: 10px;
			margin-bottom: 0px;
			font-weight: 400;
		}

		span {
			flex: 0 0 auto;
			cursor: pointer;
			width: 35px;
			height: 35px;
			text-align: center;
			line-height: 35px;
			background-color: $theam-blue;
			@include border-radius(50%);

			i {
				font-size: 18px;
				color: $white-color !important;
			}
		}
	}

	&__time {
		@extend .flexbox;
		@extend .item-center;

		label {
			flex: 0 0 auto;
			margin-right: 10px;
			margin-bottom: 0px;
			font-weight: 400;
		}
	}
}

.response-choice {
	.empty-page {
		height: 300px;
	}

	.response-source {
		height: 100px;
		display: flex;
		border-bottom: 1px solid rgba(0, 0, 0, 0.09);
		align-items: center;
		justify-content: space-between;
		gap: 10px;
		padding-left: 10px;
		padding-right: 20px;
		padding-bottom: 10px;
		// @include box-shadow(
		// 		0px 0px 0px 0px rgba(0, 0, 0, 0.02),
		// 		0px 0px 0px 1px rgba(0, 0, 0, 0.06)
		// 	);

		.sources {
			display: flex;
			gap: 20px;
			align-items: center;

			button {
				padding: 20px 30px;
				border-radius: 14px;
				min-width: 150px;
				color: $drk-text;
				font-weight: 500;
				background: #FAFAFA;
				border: 1px solid $lgt-text;
			}

			.active {
				background: linear-gradient(to bottom, #0B2C49, #1d5383);
				color: white !important;
				border: none !important;


			}

			.selection-label {
				span {
					font-size: 20px;
					font-weight: 700;
					color: $med-drk-text;
					word-break: break-all;


				}
			}
		}

		.create-button {
			margin-right: 10px;
			margin-left: 200px;
			align-items: center;

			button {
				padding: 20px 20px;
				align-items: center;
				border-radius: 14px;
				// width: 170px;
			}

		}
	}

	@media (max-width: 1100px) {

		/* Tablet and below */
		.response-source {
			flex-direction: column;
			/* Stack elements vertically */
			gap: 5px;
			/* Reduce the gap */
			height: auto;
			/* Adjust height */
			padding-left: 0;
			/* Center padding */
			padding: 10px 0;
			align-items: center;

			.sources {
				button {
					width: auto {}

					;
				}
			}

			.create-button {
				margin-right: 20px;
				margin-left: 0;
				align-items: center;
				justify-content: center;

				button {

					width: auto;
				}

			}
		}
	}


}

.campaign-area {
	@extend .flex-column;
	padding: 15px 0 15px 0;
	margin-top: 5px;

	.approval-content {
		padding: 20px;
		box-shadow: 0 0 0 0.5px var(--lgt-text);
		border-radius: 12px;
		margin: 20px 0;
		width: 50%;

		.approval-workflow {
			display: flex;
			flex-direction: column;
			gap: 15px;

			p {
				font-size: 17px;
				font-weight: 600;
				color: $med-drk-text;
				display: flex;
				flex-wrap: wrap;
				line-height: 1.5;
			}
		}

		.approval-next {
			margin: 20px 0;
			font-size: 17px;
			font-weight: 600;
			color: $med-drk-text;
		}
	}

	.ai-campaign-block {
		padding: 20px;
	}

	&__header {
		padding: 4px 0 0 0;
		margin-bottom: 15px;

		h3 {
			font-size: 20px;
			margin-bottom: 8px;
			font-weight: bold !important;
		}
	}

	&__listing {
		flex: 1 1 auto;
		@extend .flexbox;
		@extend .item-center;
		margin-bottom: 20px;

		li {
			@extend .flexbox;
			@extend .item-center;
			margin-right: 10px;

			&:last-child {
				margin-right: 0px;
			}

			.item {
				@extend .flexbox;
				@extend .item-center;
				height: 20px;
				line-height: 20px;
				padding: 0px 8px;

				.anticon-star {
					flex: 0 0 auto;
					margin-left: 6px;

					svg {
						fill: $theam-blue;
					}
				}
			}
		}
	}

	&__activate {
		display: flex;
		margin-bottom: 10px;
	}

	&__category {
		flex: 1 1 auto;
		margin-bottom: 30px;
		max-width: 40%;

		&__steptwo {
			margin-bottom: 10px !important;
			flex: 1 1 auto;
			margin-bottom: 30px;
			max-width: 40%;

			@include respond-to(mobile) {
				max-width: 80%;
			}

			@include respond-to(tablet) {
				max-width: 80%;
			}

			@include respond-to(tablet-landscape) {
				max-width: 60%;
			}

			.label-text {
				font-size: 14px;
				margin-bottom: 8px;
				font-weight: bold !important;
				@extend .flexbox;
				@extend .item-center;

				span {
					flex: 0 0 auto;
					line-height: 20px;
					height: 20px;

					&.info-icon {
						width: 20px;
						margin-left: 3px;
						@extend .flexbox;
						@extend .all-center;

						i {
							font-size: 14px;
							opacity: 0.5;
							margin: 0;
						}
					}
				}
			}

			textarea {
				display: block;
				border-color: #a0a7b4;
				width: 100%;
				padding: 10px;
			}
		}

		@include respond-to(mobile) {
			max-width: 80%;
		}

		@include respond-to(tablet) {
			max-width: 80%;
		}

		@include respond-to(tablet-landscape) {
			max-width: 60%;
		}

		.label-text {
			font-size: 14px;
			margin-bottom: 8px;
			font-weight: bold;
			@extend .flexbox;
			@extend .item-center;

			span {
				flex: 0 0 auto;
				line-height: 20px;
				height: 20px;

				&.info-icon {
					width: 20px;
					margin-left: 3px;
					@extend .flexbox;
					@extend .all-center;

					i {
						font-size: 14px;
						opacity: 0.5;
						margin: 0;
					}
				}
			}
		}

		textarea {
			display: block;
			border-color: #a0a7b4;
			width: 100%;
			padding: 10px;
		}
	}

	&__rating {
		flex: 1 1 auto;
		max-width: 30%;

		li {
			flex: 1 1 auto;
			margin-bottom: 20px;

			label {
				display: block;
				margin-bottom: 8px;
				font-weight: 400;
			}

			.ant-input-number {
				margin-right: 10px;
			}

			span {
				margin-right: 10px;
			}

			&.schedule-block {
				flex: 1 1 auto;
				@extend .flexbox;
				@extend .item-center;

				span {
					flex: 0 0 auto;
					margin-right: 20px;

					&:last-child {
						margin-right: 0px;
						cursor: pointer;
						width: 30px;
						height: 30px;
						text-align: center;
						line-height: 30px;
						color: $white-color !important;
						background-color: $theam-blue;
						@include border-radius(50%);
					}
				}
			}
		}
	}

	&__type,
	&__time {
		@extend .flexbox;
		@extend .item-center;
		margin-bottom: 15px;
		margin-top: 15px;

		label {
			flex: 0 0 auto;
			margin-right: 10px;
			margin-bottom: 0px;
			font-weight: 400;
			min-width: 180px;
		}

		span {
			flex: 0 0 auto;
			cursor: pointer;
			width: 35px;
			height: 35px;
			text-align: center;
			line-height: 35px;
			background-color: $theam-blue;
			@include border-radius(50%);

			i {
				font-size: 18px;
				color: $white-color !important;
			}
		}
	}

	&__confirm {
		flex: 1 1 auto;
		max-width: 50%;
		margin: -8px;
		@extend .flexbox;
		@extend .item-center;
		@extend .item-wrap;

		li {
			flex: 1 1 auto;
			padding: 8px;
			margin: 8px;
			min-width: calc(50% - 16px);
			max-width: calc(50% - 16px);
			@extend .flexbox;
			@extend .item-center;
			@include box-shadow(0, 0px, 2px, rgba(0, 0, 0, 0.16));
			@include border-radius(2px);

			&:last-child {
				margin-bottom: 0;
			}

			span {
				flex: 1 1 auto;
				max-width: 50%;

				&:last-child {
					font-weight: 600;
				}
			}
		}
	}

	&__delay {
		flex: 0 0 auto;
		@extend .flexbox;
		@extend .item-center;

		span,
		.ant-input-number {
			flex: 0 0 auto;
			margin-right: 8px;
		}
	}
}

.add-integration-outer {
	@extend .flexbox;
	@extend .all-center;
	min-height: calc(100vh - 250px);

	.empty-state__inner {
		flex: 0 0 auto;
		max-width: 40%;
		@extend .flex-column;
		@extend .all-center;

		td {
			text-align: left;
		}

		h3 {
			font-size: 24px;
			margin: 15px 0;
			color: #9d9696;
			text-align: center;
		}

		p {
			color: #9d9696;
			margin: 0;
			text-align: center;
		}

		a {
			color: #1a73e8 !important;
		}

		a:hover {
			color: #0056b3 !important;
		}
	}
}

.post-content {
	flex: 1 1 auto;
	padding-top: 0.5rem;

	&.response {
		border-left: 3px solid #f1eeee;
		margin-left: 19px;
		padding-left: 35px;
	}

	&__comment {
		display: flex;
		justify-content: space-between;

		p {
			font-size: 14px;
			// padding-right: 100px;
			// width: 42rem;
			margin-right: 10px;
		}

		.post-actions {
			display: flex;
			flex-direction: column;
			margin-right: -6px;

			:last-child {
				margin-top: -2px;
			}

			li {

				.action-items {
					display: flex;
					cursor: pointer;

					svg {
						width: 2rem;
						height: 2rem;
					}

					a {
						width: 5rem;
						height: 1.5rem;
						// border-radius: 10px;
						// border: solid 1px rgba(179, 177, 177, 0.8);
						display: flex;
						// gap: 4px;
						justify-content: center;
						align-items: center;

					}
				}
			}
		}
	}

	&__QA {
		padding: 2px 0px 2px 55px;
		background-color: white;
		border-bottom-left-radius: 10px;
		border-bottom-right-radius: 10px;
		margin-top: -4px;

	}

	&__title {
		p {
			font-size: 14px;
			margin-bottom: 10px;
		}
	}

	&__addtag {
		flex: 1 1 auto;
		padding: 1rem 0 1.0rem 0;

		.tag-wrapper {
			display: flex;
			gap: 2rem;

			.tag-section {
				width: 15rem;
				// min-width: 15rem;
				display: flex;
				flex-wrap: wrap;
				align-items: flex-start;
			}

			.laguage-tag {
				.ant-tag {
					flex: 0 0 auto;
					position: relative;
					font-size: 12px;
					background: #f2f2f2;
					padding: 1px 6px;
					margin-right: 8px;
					line-height: 18px;
					border: none;
					border-radius: 9px !important;
					margin-top: 2px;

					&:after {
						display: none;
					}

					&:before {
						display: none;
					}

					@include border-radius(3px);

					a {
						position: absolute;
						display: none;
						right: -4px;
						top: -4px;
						font-size: 11px;
						text-align: center;
						line-height: 10px;
						color: #fff;
						width: 12px;
						height: 12px;
						background-color: rgba(241, 89, 34, 0.4);
						@include border-radius(50%);

						&:hover {
							background: rgba(241, 89, 34, 0.8);
							color: #fff;
						}
					}
				}
			}

			.sentiment-section {
				width: 15rem;
				// min-width: 10rem;
				display: flex;
				flex-direction: column;
				flex-wrap: wrap;
				cursor: pointer;

				.sentiment-head {
					display: flex;
					gap: 5px;
					font-weight: 600;
					color: #555353;
				}

				.sentiment-item {
					max-width: 12rem;
					font-weight: 600;

					.content-items:not(:empty):not(:last-child):after {
						content: ", ";
						color: rgba(158, 152, 152, 0.8);
					}
				}
			}
		}
	}

	&__controls {
		padding: 1rem 0 0 0;
		background-color: $white-color;
		border-top: solid 1px rgba($border-color, 0.8);
		@extend .flexbox;
		@extend .item-center;
		@include border-radius(6px);

		li {
			flex: 0 0 auto;
			margin-right: 14px;
			font-size: 14px;

			.compose,
			.saved-replies,
			.explore-content,
			.aiResponse {
				width: 6.5rem;
				height: 2rem;
				cursor: pointer;
				justify-content: center;
				align-items: center;
				display: flex;
				gap: 5px;
				border-radius: 15px;

				color: white;

				svg {
					width: 1rem;
					height: 1rem;
				}
			}

			.compose {
				background-color: #03a9f4;
			}

			.saved-replies {

				background-color: #0972bc;
			}

			.explore-content {
				background-color: #0e3a83;
			}

			.aiResponse {
				background-color: #ff9800;
			}

			button,
			a {
				border: 0;
				padding: 5px 0;
				background-color: transparent;
				border-color: transparent;
				@extend .flexbox;
				@extend .item-center;

				i {
					font-size: 16px;
					margin-right: 8px;
					color: rgba(0, 0, 0, 0.5);
				}

				&.replied-review {
					border-color: #21ba19;
					border-style: solid;
					border-width: 0 0 3px 0;
					background-color: transparent;
					color: #21ba19;

					i {
						color: #21ba19;
					}
				}

				&.internal-reply,
				&:active,
				&:hover {
					border-color: #06a7e1;
					color: #06a7e1;

					i {
						color: #06a7e1;
					}
				}

				&.close-reply {
					border-color: grey;
					color: grey;

					i {
						color: grey;
					}
				}

				&.external-page:hover {
					border-color: #21ba19 !important;
					color: #21ba19 !important;

					i {
						color: #21ba19 !important;
					}
				}
			}

			&.drop-select {
				padding-left: 60px;

				.ant-dropdown-trigger {
					@extend .flexbox;
					@extend .all-center;
				}
			}
		}
	}
}

.tag-action {
	display: flex;
	gap: 10px;
}

.role-actions {
	@extend .flexbox;

	a {
		width: 25px;
		height: 25px;
		border: #ccc solid 1px;
		flex: 0 0 auto;
		margin-right: 8px;
		@extend .flexbox;
		@extend .all-center;
		@include border-radius(3px);
		@include transition(all 0.4s ease-in-out);

		&:hover {
			background: #108ee9 !important;
			border: #108ee9 solid 1px;
			color: $white-color !important;
			@include transition(all 0.4s ease-in-out);

			svg path {
				fill: #fff;
			}

			svg rect {
				stroke: #fff;
			}
		}
	}
}

.fetch-actions {
	@extend .flexbox;

	a {
		width: 25px;
		height: 25px;
		border: #ccc solid 1px;
		flex: 0 0 auto;
		margin-right: 8px;
		@extend .flexbox;
		@extend .all-center;
		@include border-radius(3px);
		@include transition(all 0.4s ease-in-out);

		&.fetch-active {
			background-color: $theam-blue;
		}
	}
}

.breadcrumb-block {
	@extend .flexbox;
	@extend .item-center;
	height: 40px;
	background-color: $white-color !important;
	padding: 0 15px;

	.breadcrumb {
		flex: 0 0 auto;
		margin: 0px;
		padding: 0px;
		background-color: transparent;
		@include border-radius(0);
		@include transition(all 0.4s ease-in-out);

		.breadcrumb-item a {
			color: #1a73e8;

			&:hover {
				color: #0056b3;
			}
		}
	}

	span {
		flex: 0 0 auto;
		margin-left: auto;
		color: $theam-blue;

		a {
			color: #1a73e8 !important;

			&:hover {
				color: #0056b3;
			}
		}
	}
}

.review-right {
	flex: 0 0 auto;
	padding: 2px 0;
	margin-left: auto;
	@extend .flexbox;
	@extend .item-center;

	&__item {
		flex: 0 0 auto;
		width: 100px;
		height: 30px;
		margin-left: 6px;
		margin-top: 2px;
		cursor: pointer;
		@extend .flexbox;
		@extend .all-center;
		@include border-radius(2px);

		i {
			font-size: 16px;
		}

		button {
			background-color: transparent;
			border: 0;
			width: 30px;
			height: 30px;
			flex: 0 0 auto;
			@extend .flexbox;
			@extend .all-center;
		}
	}
}

.block-timing {
	@extend .flexbox;
	@extend .item-center;
	@extend .justify-between;
	margin-bottom: 10px;

	h5 {
		@extend .flexbox;
		@extend .item-center;
	}

	.ant-btn {
		width: 30px;
		height: 30px;
		@extend .flexbox;
		@extend .all-center;
	}
}

.ant-table-filter-dropdown-link {
	color: $theam-blue !important;
}

.change-profile {
	text-align: center;

	.ant-btn-primary {
		margin-right: 10px;
	}
}

.delete-button {
	@extend .flexbox;
	@extend .item-center;
	@extend .justify-end;
	border-top: solid 1px #ebebeb;
	margin-top: 20px;
	padding-top: 20px;

	.ant-btn {
		margin-right: 10px;

		&:last-child {
			margin-right: 0;
		}
	}
}

.options-sub-heading {
	margin: 20px 0px;

	a {
		color: $theam-blue !important;
	}
}

.email-input,
.user-permissions {
	@extend .flexbox;
	@extend .item-center;
	margin: 10px 0;
	padding: 0px 20px;

	span {
		flex: 0 0 auto;
		min-width: 120px;
		margin-right: 10px;
	}
}

.hint-text {
	color: #9ea3c2;
	margin: 20px 10px 10px 0;
	padding: 0px 20px;
}

.post-reviews {
	display: block;
	margin-top: 15px;

	ul {
		@extend .flexbox;
		@extend .item-center;
		@extend .item-wrap;

		li {
			flex: 0 0 auto;
			background-color: #f1f9ff;
			border: #e8f1f8 solid 1px;
			padding: 3px;
			margin-right: 8px;
			margin-bottom: 8px;
			@extend .flexbox;
			@extend .item-center;
			@include border-radius(3px);

			img {
				width: 20px;
				height: 20px;
				margin-right: 6px;
			}

			a {
				position: relative;
				flex: 0 0 auto;
				padding-left: 6px;

				&:before {
					content: '';
					display: block;
					position: absolute;
					width: 1px;
					height: 10px;
					background-color: #d9e2e9;
					top: 4px;
					left: 0;
				}
			}

			.profile-icon,
			.disabled-profile-icon {
				width: 30px;
				display: block;
				position: absolute;
				left: -35px;
				top: 15px;
				text-align: center;
				line-height: 30px;
				font-size: 20px;
				height: 30px;
				@include border-radius(3px);
				@include transition(all 0.8s ease-in-out);
			}

			.profile-icon {
				color: #fff !important;
				line-height: 14px;
				padding: 4px;
				border: solid 1px #ccc;
			}

			.disabled-profile-icon {
				color: #06a7e1;
				background: #f5f5f6;
				cursor: default;

				&:hover {
					border: solid 1px #018cbe;
					@include transition(all 0.8s ease-in-out);
				}
			}
		}
	}
}

.review-block {
	@extend .flex-column;

	.location-review {
		flex: 1 1 auto;
		padding: 1rem;
		@extend .flexbox;

		&:not(:last-child) {
			border-bottom: solid 1px #ebebeb;
		}

		.review-nodata {
			flex: 1 1 auto;
			min-height: 280px;
			@extend .flexbox;
			@extend .all-center;
		}

		.profile-icon,
		.disabled-profile-icon {
			flex: 0 0 auto;
			padding: 1rem;
			padding: 4px;
			width: 40px;
			height: 40px;
			border: solid 1px #ccc;
			@include border-radius(50%);
			@include transition(all 0.8s ease-in-out);
			@extend .flexbox;
			@extend .all-center;

			img {
				max-width: 80%;
			}
		}

		.disabled-profile-icon {
			cursor: not-allowed;

			img {
				-webkit-filter: grayscale(100%);
				filter: grayscale(100%);
			}
		}

		.location-rating {
			flex: 1 1 auto;
			margin-left: 8px;
			@extend .flex-column;

			&__heading {
				flex: 1 1 auto;
				@extend .flexbox;
				@extend .item-center;
				@extend .justify-between;

				h4 {
					font-size: 14px;
					font-weight: 600;
				}
			}

			.review-set-el {
				margin: 0 !important;
				padding: 8px 0 0 0;
			}
		}
	}
}

.review-item-detail {
	margin: 0;

	&__head {
		@extend .flexbox;
		@extend .item-center;
		@extend .justify-between;

		.review-right {
			@extend .flexbox;
			@extend .item-center;

			&__item {
				flex: 0 0 auto;
				border: #d9d9d9 solid 1px !important;
				width: 30px;
				height: 30px;
				margin: 0 6px 0 0s;
				cursor: pointer;
				@include border-radius(3px);

				&:last-child {
					margin-right: 0px;
				}
			}
		}
	}
}

.mglocation-entity {
	@extend .flexbox;
	@extend .item-center;
	@extend .justify-between;
	margin-bottom: 10px;

	a {
		flex: 0 auto;
		padding: 4px 6px;
		color: #555;
		cursor: pointer;
		@include border-radius(3px);

		&:first-child {
			border: #d9d9d9 solid 1px;

		}

		&:last-child {
			background-color: #06a7e1;
			// border-color: #06a7e1;
			color: #fff !important;
		}

		button {
			background-color: #06a7e1;
			height: 30px;
		}

		i {
			font-size: 14px;
			margin-right: 4px;
		}
	}
}

.add-wrapper {
	@extend .flexbox;
	@extend .item-center;
	@extend .justify-between;
	margin-bottom: 6px;

	.Select {
		flex: 1 auto;
		width: 100%;
	}

	.close-icon {
		flex: 0 auto;
		margin-left: 10px;
		width: 36px;
		height: 36px;
		font-size: 14px;
		border: 1px solid #a0a7b4;
		color: #ccc;
		cursor: pointer;
		text-align: center;
		line-height: 36px;
		@extend .flexbox;
		@extend .all-center;
		@include border-radius(3px);

		&:hover {
			color: #aaaaaa;
			border-color: #aaaaaa;
		}
	}
}

.react-tags {
	@extend .flexbox;
	@extend .item-center;
	flex-wrap: wrap;

	&__search {
		flex: 0 0 auto;
		max-width: 100px;
		min-width: 80px;
		flex: 0 0 auto;
		position: relative;
		padding: 0px;
		height: 26px;

		input {
			border: 0;
			border-radius: 10px !important;
			height: 24px;
			width: 100%;
			padding: 1px 10px;
			font-size: 12px;
			background-color: transparent;
			position: absolute;
			border: 1px solid #ccc;
			z-index: 99;
			@include border-radius(3px);
		}
	}

	&__selected {
		@extend .flexbox;
		@extend .item-center;
		flex-wrap: wrap;

		.tags {
			flex: 0 0 auto;
			position: relative;
			font-size: 12px;
			background: #f2f2f2;
			padding: 1px 6px;
			margin-right: 8px;
			line-height: 18px;
			@include border-radius(9px);

			a {
				position: absolute;
				display: none;
				right: -4px;
				top: -4px;
				font-size: 11px;
				text-align: center;
				line-height: 10px;
				color: #fff;
				width: 12px;
				height: 12px;
				background-color: rgba(241, 89, 34, 0.4);
				@include border-radius(50%);

				&:hover {
					background: rgba(241, 89, 34, 0.8);
					color: #fff;
				}
			}
		}

		&:hover {
			a {
				display: block;
			}
		}
	}

	&__suggestions {
		position: absolute;
		min-width: 180px;
		top: 100%;
		padding: 0.5rem;
		left: 0;
		width: 100%;
		background: $white-color;
		@include box-shadow(0 2px 8px rgba($black-color, 0.2));
		@include border-radius(6px);

		ul {
			padding: 0;
			@extend .flex-column;

			li {
				flex: 1 1 auto;
				padding: 6px 8px;

				&:not(:last-child) {
					border-bottom: 1px solid rgba($black-color, 0.06);
				}

				&:hover {
					cursor: pointer;
					background: #eee;
				}

				&.is-active {
					background: #b7cfe0;
				}

				&.is-disabled {
					opacity: 0.5;
				}

				mark {
					text-decoration: underline;
					background: none;
				}
			}
		}
	}
}

.present-tags {
	margin: 0;

	.react-tags {
		margin: 0;

		&__search {
			display: none;
		}

		&__selected {
			flex: 0 0 auto;

			.tags {
				padding: 1px 6px;
			}
		}
	}
}

.review-info {
	flex: 1 1 auto;
	height: 100%;
	@extend .flex-column;
	@extend .justify-between;
	border-right: 1px solid #e9eef2;

	.info-content {
		flex: 1 1 auto;

		&__list {
			display: block;
			margin: 0;
			padding: 0;

			li {
				padding: 16px 12px 16px;
				cursor: pointer;
				position: relative;
				border-bottom: 1px solid #e9eef2;
				width: 100%;

				&:last-child {
					border-bottom: 0px;
				}

				&:hover,
				&.active {
					background-color: #f0f4f7;
				}

				&.unread .review-rating__head {
					font-weight: 600;
				}
			}
		}
	}

	.react-autosuggest__container {
		padding: 10px;
		margin: 0px;

		.react-autosuggest__input {
			@include border-radius(3px);
		}
	}

	.add-saved-reply {
		padding: 10px;
		background: #fdfdfd;

		a {
			text-decoration: underline;
			color: $theam-blue;
		}
	}
}

.review-chat-wrap {
	margin-left: 75px;
	margin-right: 12px;

	.review-chat__item {
		margin-top: 1rem;
		padding: 5px 10px 5px 0px;
		background-color: white;
		@include border-radius(10px);
		@include box-shadow(0 2px 8px rgba(0, 0, 0, 0.1));
	}
}

.google-post {
	@extend .flexbox;
	@extend .item-center;
	padding: 10px;
	border-bottom: solid 1px rgba(0, 0, 0, 0.08);

	&__content {
		flex: 1 1 auto;
	}
}

.google-type {
	// flex: 0 0;
	margin-bottom: 8px;
	// width: 100%;
	@extend .flexbox;
	@extend .item-center;
	@extend .justify-between;

	h5 {
		margin-bottom: 0 !important;
		flex: 0 auto;
		color: #3c4043;
		font-weight: 600;
	}

	p {
		color: #b4b4b4;
		margin-right: 6px;
	}

	.event-label {
		flex: 0 0 auto;
		overflow: hidden;
		@include border-radius(4px);

		&__inner {
			margin: 0;
			padding: 2px 15px 2px 8px;
			height: 22px;
			background-color: #2fb66a;
			color: #fff !important;
			position: relative;
			@extend .flexbox;
			@extend .item-center;

			i {
				margin-right: 6px;
			}

			.label {
				margin: 0;
				padding: 0 4px 0 0;
				font-size: 11px;
				font-weight: 600;
			}

			&:after,
			&:before {
				position: absolute;
				content: '';
				right: -17px;
				background-color: #fff !important;
				width: 30px;
				height: 30px;
			}

			&:after {
				-ms-transform: rotate(40deg);
				-webkit-transform: rotate(40deg);
				transform: rotate(40deg);
				bottom: -18px;
			}

			&:before {
				top: -18px;
				-ms-transform: rotate(-40deg);
				-webkit-transform: rotate(-40deg);
				transform: rotate(-40deg);
			}
		}
	}

	&__datepane {
		@extend .flexbox;
		@extend .item-center;

		label {
			margin-right: 6px;
			margin-bottom: 0;
			font-size: 12px;
		}

		span {
			font-size: 12px;
		}
	}

	.event-tagging {
		flex: 0 0 auto;
		color: $white-color;
		margin-right: 15px;
		padding: 2px 10px;
		@extend .flexbox;
		@extend .item-center;
		@include border-radius(3px);
		font-size: 12px;

		&.iconcircle {
			-webkit-border-radius: 50%;
			min-width: 40px;
			max-width: 44px;
			min-height: 40px;
			max-height: 40px;
			padding-left: 8px;
			margin-left: 5px !important;
			margin-right: 5px;

			i {
				font-size: 15px;
				padding-left: 5px;
			}

			svg {
				// margin-left: 3px;
				width: 100%;
				height: auto;
				margin-bottom: -10px
			}
		}
	}
}

.chat-filter {
	@extend .flexbox;
	@extend .item-center;

	ul {
		@extend .flexbox;
		@extend .item-center;

		li {
			flex: 0 0 auto;

			&:not(:last-child) {
				margin-right: 12px;
			}
		}
	}

	.post-icon {
		border-style: solid;
		border-color: #676767;
		border-width: 0.094rem;
		background-color: #f7f7f7;
	}

	.ant-dropdown-trigger {
		width: 25px;
		height: 25px;
		text-align: center;
		line-height: 20px;
		padding: 0;
		margin: 0;
		border: 0;
		@extend .flexbox;
		@extend .all-center;

		span {
			display: inline-block;
			margin: 0;
			height: 3px;
			position: relative;
			width: 3px;
			background-color: #9399a2;
			-webkit-border-radius: 50%;
			border-radius: 50%;

			&:after,
			&:before {
				position: absolute;
				content: '';
				height: 3px;
				width: 3px;
				padding: 0;
				border: 0;
				left: 0;
				background-color: #9399a2;
				-webkit-border-radius: 50%;
				border-radius: 50%;
			}

			&:after {
				top: -6px;
			}

			&:before {
				bottom: -6px;
			}
		}
	}
}

.invite-user {
	.email-input {
		margin: 0;

		span {
			color: #3c4043;
		}
	}
}

.post-icon {
	width: 30px;
	height: 30px;
	margin: 0;
	padding: 0;
	text-align: center;
	cursor: pointer;
	overflow: hidden;
	@extend .flexbox;
	@extend .all-center;
	@include border-radius(50%);

	&.active {
		border-color: $blue-color;
	}

	img {
		width: 16px;
		display: inline-block;
	}

	i {
		font-size: 16px;
		margin-top: 2px;
	}
}

.account-refresh-btn {
	max-height: calc(100vh - 200px);
}

.entity-wrapper {
	max-height: calc(100vh - 300px);
}

.entity-wrapper-location-control {
	max-height: calc(100vh - 221px);
	min-height: calc(100vh - 221px);
}

.entitytag-pagination {
	padding-top: 1rem;
	@extend .flex-column;
}

.upload-image {
	margin: 15px 0;
	@extend .flex-column;
	@extend .all-center;

	.ant-upload-picture-card-wrapper {
		@extend .flex-column;

		.ant-upload-list {
			flex: 1 1 auto;
			@extend .flex-column;
			@extend .all-center;
		}

		.ant-upload-list-picture-card-container {
			margin: 0;
			flex: 1 1 auto;
			width: 100%;
			height: 250px;
			@extend .flex-column;
		}

		.ant-upload,
		.ant-upload-list-item {
			margin: 0 !important;
			width: 100%;
			height: 250px;
			@extend .flex-column;
			@extend .all-center;
		}
	}
}

.intercom-lightweight-app {
	display: none;
}

.intercom-lightweight-app-launcher {
	bottom: 80px !important;
}

.blank-text {
	color: #70757a;
}

.color-picker {
	flex: 1 1 auto;
	@extend .flex-column;

	&__block {
		position: relative;
		@extend .flexbox;
		@extend .item-center;
		@extend .justify-end;

		label {
			font-weight: 400;
			margin-right: auto !important;
			font-size: 14px;
		}

		&--display {
			flex: 0 0 auto;
			width: 30px;
			height: 30px;
			margin-right: 10px;
			text-align: center;
			@include border-radius(50%);
		}

		&--icon {
			flex: 0 0 auto;
			cursor: pointer;
			position: relative;

			.input-box {
				flex: 0 0 auto;
				width: 100px;
				height: 35px;
				text-align: center;
				//border: solid 1px rgba(0, 0, 0, 0.6);
				@include border-radius(3px);
			}
		}

		.color-picker-palette {
			position: absolute;
			z-index: 100;
			top: 100%;
			right: 4px;
			text-align: center;
			padding-bottom: 8px;

			.color-picker-cover {
				position: fixed;
				top: 0px;
				left: 0px;
				right: 0px;
				bottom: 0px;
			}

			.chrome-picker {
				margin-bottom: 5px;
				background-color: $white-color;
				@include box-shadow(0, 1px, 2px, rgba(0, 0, 0, 0.01));
			}

			.ant-btn {
				margin: 5px;
				height: 22px;
				padding: 0px 8px;
			}
		}
	}
}

.outbox-error {
	color: red;
	font-weight: 600;
	@extend .flexbox;
	@extend .all-center;
}

.info-list {
	flex: 0 1 auto;
	margin-bottom: 20px;
	@extend .flexbox;
	@extend .item-center;

	li {
		flex: 0 0 auto;
		@extend .flexbox;
		@extend .all-center;

		&:not(:last-child) {
			margin-right: 8px;
		}

		.user-image,
		.user-name {
			flex: 0 0 auto;
			overflow: hidden;
			min-width: 36px;
			min-height: 36px;
			max-width: 36px;
			max-height: 36px;

			text-transform: uppercase;
			@extend .flexbox;
			@extend .all-center;
			@include border-radius(50%);

			img {
				width: 100%;
			}
		}

		.user-image {
			background-color: $border-color;
		}

		.user-name {
			@include linear-gradient(to top, (#e2c54d 30%, #f9e29e 100%));
		}
	}
}

.filter-search {
	margin-bottom: 5px;
	position: relative;
	margin-top: 5px;
	margin-right: 8px;
	@extend .flexbox;
	@extend .item-center;

	&__icon {
		flex: 0 0 auto;
		min-width: 20px;
		min-height: 20px;
		position: absolute;
		left: 10px;
		top: 9px;
		@extend .flexbox;
		@extend .all-center;

		i {
			font-size: 18px;
			color: rgba($text-color, 0.5);
		}

		.loader {
			border: 2px solid #cccccc;
			border-top: 2px solid $theam-blue;
			opacity: 0.8;
			width: 20px;
			height: 20px;
			-webkit-animation: spin 0.5s linear infinite;
			animation: spin 0.5s linear infinite;
			@include border-radius(50%);
		}
	}

	&__close {
		flex: 0 0 auto;
		min-width: 20px;
		min-height: 20px;
		position: absolute;
		right: 3px;
		top: 9px;
		cursor: pointer;

		i {
			color: rgba($text-color, 0.5);
		}
	}

	input {
		width: 100%;
		font-size: 13px;
		line-height: 35px;
		min-height: 36px;
		border: 1px solid #06a7e1;
		padding: 0 30px 0px 38px;
		@include border-radius(6px);
	}

	.searchBoxTouched {
		border: 1px solid #515151;
	}
}

.post-rating {
	@extend .flexbox;
	@extend .all-center;
}

.review-reply {
	position: relative;
	// padding: 0.125rem;
	// border: 1px solid $line-color;
	// border-radius: 10px;
	@extend .flex-column;

	.reply-options {
		// padding: 0.75rem;
		// background: rgba($line-color, 0.5);
		// border-bottom: 1px solid $line-color;

	}

	.reply-feild {
		// @extend .flex-column;
		display: flex;
		gap: 1rem;

		&:not(:first-child) {
			margin: 0.125rem 0;
		}

		textarea {
			border: 1px solid $line-color;
			border-radius: 10px !important;
			height: 125px;
			@include border-radius(0);
		}
	}

	.reply-button {
		@extend .flexbox;
		@extend .item-center;
		padding: 0.75rem;
		background: rgba($line-color, 0.5);
		font-size: 14px;

		.hyper-link {
			flex: 0 0 auto;
			margin-right: 0.75rem;
			text-decoration: underline;

			&:hover {
				color: $theam-blue;
				text-decoration: underline;
			}
		}
	}

	.mail-controls-update {
		flex-direction: column;
		display: flex;
		@extend .item-center;
		flex-wrap: wrap;
		gap: 0.5rem;
		margin-right: -52px;
		// padding: 0.5rem 0.75rem 0.75rem;
		// background: rgba($line-color, 0.5);
		font-size: 14px;

		li {
			flex: 0 0 auto;
			margin-right: 10px;
			padding-top: 0.25rem;
		}

		.hyper-link {
			flex: 0 0 auto;
			text-decoration: underline;

			&:hover {
				color: $theam-blue;
				text-decoration: underline;
			}
		}

		.mail-details {
			flex: 0 0 auto;
			@extend .flexbox;
			@extend .item-center;

			.control-wrapper {
				display: flex;
				cursor: pointer;
				// justify-content: center;
				// gap: px;
				// border: solid 1px rgba(179, 177, 177, 0.8);
				border-radius: 10px;
				width: 5rem;
				font-size: 14px;
				align-items: center;
				height: 2rem;
				margin-top: 4px;

				svg {
					width: 2rem;
					height: 2rem;
				}

			}
		}

	}

	.reply-content-section {
		display: flex;
		justify-content: space-between;
		margin-right: 42px;

		.reply-content {
			display: flex;

			// margin-left: -55px;
			li {
				background-color: #fff;
				padding: 0 !important;

				// margin-left: -55px;
				svg {
					width: 1rem;
					height: 1rem;
				}
			}

			.post-reply {
				min-width: 130px;
				max-width: 100%;
				// margin: 0 12px 0 0;
				padding: 21px;
				display: flex;
				justify-content: center;
				align-items: center;
				cursor: pointer;
				border-radius: 20px;
				gap: 4px;
				height: 28px;
				background-color: #03a9f4 !important;
				color: #fff;

				svg {
					width: 1rem;
					height: 1rem;
				}
			}

			.post-reply-external {
				min-width: 130px;
				max-width: 100%;
				// margin: 0 12px 0 0;
				padding: 21px;
				display: flex;
				justify-content: center;
				align-items: center;
				cursor: pointer;
				border-radius: 20px;
				gap: 4px;
				height: 28px;
				background-color: #cf4c2c !important;
				color: #fff;

				svg {
					width: 1rem;
					height: 1rem;
				}
			}

			.post-regenetate {
				min-width: 130px;
				max-width: 100%;
				margin: 0 0 0 1rem;
				padding: 21px;
				display: flex;
				justify-content: center;
				align-items: center;
				cursor: pointer;
				border-radius: 20px;
				gap: 4px;
				height: 28px;
				background-color: #ff9800 !important;
				color: #fff;
			}

			.approval-reply {
				min-width: 130px;
				max-width: 100%;
				margin: 0 0 0 1rem;
				padding: 21px;
				display: flex;
				justify-content: center;
				align-items: center;
				cursor: pointer;
				border-radius: 20px;
				gap: 4px;
				height: 28px;
				background-color: #81d4f9;
				color: #0e5085;
			}

			.save-as-reply {
				display: flex;
				justify-content: center;
				// margin-left: 1re;
				align-items: center;
				cursor: pointer;
				border: solid 1px rgba(179, 177, 177, 0.8);
				min-width: 110px;
				max-width: 100%;
				margin: 0 12px 0 0;
				padding: 21px;
				height: 28px;
				border-radius: 20px;
				// margin-left: -55px;
			}

		}
	}

	.mail-controls {
		@extend .flexbox;
		@extend .item-center;
		flex-wrap: wrap;
		padding: 0.5rem 0.75rem 0.75rem;
		background: rgba($line-color, 0.5);
		font-size: 14px;

		li {
			flex: 0 0 auto;
			margin-right: 10px;
			padding-top: 0.25rem;

			&:last-child {
				margin-right: 0px;
			}
		}

		.hyper-link {
			flex: 0 0 auto;
			text-decoration: underline;

			&:hover {
				color: $theam-blue;
				text-decoration: underline;
			}
		}

		.mail-details {
			flex: 0 0 auto;
			@extend .flexbox;
			@extend .item-center;
		}
	}
}

.model-list {
	@extend .flex-column;

	li {
		flex: 1 1 auto;

		&:not(:last-child) {
			margin-bottom: 1.25rem;
			@extend .flex-column;
		}

		label {
			margin: 0 0 5px 0;
			@extend .flexbox;
			@extend .item-center;

			span {
				flex: 0 0 auto;
			}

			a {
				margin-left: 4px;
				flex: 0 0 auto;
				width: 14px;
				height: 14px;
				@extend .flexbox;
				@extend .all-center;
			}
		}
	}
}

.google-audit {
	@extend .flex-column;

	.audit-list {
		margin: 0;

		.audit-logs {
			margin-bottom: 10px;
			padding-bottom: 10px;
			border-bottom: #f2f2f2 solid 1px;
			@extend .flex-column;

			&:last-child {
				margin-bottom: 0px;
			}
		}

		.aduit-inner {
			@extend .flexbox;
			@extend .item-center;

			&__normal {
				margin-right: 4px;
				font-size: 14px;
				color: #787878;
			}

			&__highlate {
				margin-right: 4px;
				font-weight: 500;
				color: #000;
			}

			&__red {
				margin-right: 4px;
				font-size: 14px;
				color: #f15922;
			}

			&__blue {
				margin-right: 4px;
				font-size: 14px;
				color: #06a7e1;
			}

			&__orange {
				margin-right: 4px;
				font-size: 14px;
				color: #f99d1b;
			}

			&__grey {
				margin-right: 4px;
				font-weight: 500;
				color: #c0c0c0;
			}

			&__space {
				margin-right: 4px;
			}
		}
	}

	.google-locationlog {
		@extend .flex-column;

		.google-pagination {
			margin-top: 1rem;
		}
	}
}

.address-info {
	border-top: solid 1px #ebebeb;
	@extend .flex-column;
}

.auditlog-edit,
.auditlog-delete,
.auditlog-add {
	padding: 0 0.5rem;
	display: inline-block;
	text-transform: uppercase;
	font-weight: 600;
	font-size: 10px;
	line-height: 19px;
	cursor: pointer;
	color: $white-color;
	height: 20px;
	@include border-radius(3px);
}

.auditlog-edit {
	background-color: #f99d1b;
}

.auditlog-delete {
	background-color: #f15922;
}

.auditlog-add {
	background-color: #06a7e1;
}

.location-controls {
	min-height: 35px;
	margin-bottom: 2px;
	position: relative;
	@extend .flex-column;

	.history-button {
		position: absolute;
		top: 8px;
		z-index: 99;
		left: 0;
		@extend .flexbox;
		@extend .all-center;
		@include transition(all 0.4s ease-in-out);

		span {
			flex: 0 0 auto;

			&:first-child {
				min-width: 16px;
				min-height: 16px;
				max-width: 16px;
				max-height: 16px;
				overflow: hidden;
				margin-right: 2px;
				@extend .flexbox;
				@extend .all-center;

				svg {
					width: 50%;
					height: 50%;

					path {
						fill: $border-color;
					}
				}
			}

			&:last-child {
				color: $border-color;
			}
		}

		&:hover {
			@include transition(all 0.4s ease-in-out);

			span {
				@include transition(all 0.4s ease-in-out);

				&:first-child {
					@include transition(all 0.4s ease-in-out);

					path {
						fill: $theam-blue;
						@include transition(all 0.4s ease-in-out);
					}
				}

				&:last-child {
					color: $theam-blue;
					@include transition(all 0.4s ease-in-out);
				}
			}
		}
	}

	&.active {
		background-color: $theam-blue;
		@include border-radius(6px 6px 0 0);

		.controls-tab {
			margin: 0;

			li {
				margin: 0;

				a {
					color: rgba($white-color, 0.8);

					&.active {
						color: $white-color;

						&:after {
							background-color: $white-color;
						}
					}
				}
			}
		}
	}

	.controls-list {
		padding: 0 10px;
		flex: 1 1 auto;
		@include transition(all 0.4s ease-in-out);
		@extend .flexbox;
		@extend .item-center;

		li {
			flex: 0 0 auto;
			color: $white-color;
			@extend .flexbox;
			@extend .item-center;
			margin-bottom: 0px !important;

			&:not(:last-child) {
				margin-right: 1.5rem;
			}

			span {
				font-size: 14px;
			}

			a {
				margin-left: 14px;
				flex: 1 1 auto;
				cursor: pointer;
				font-size: 15px;
				@extend .flexbox;
				@extend .item-center;

				&.ant-dropdown-trigger {
					border: 0;
					padding: 0;
				}

				span {
					flex: 0 0 auto;
					color: $white-color;

					&.controls-icon {
						@extend .flexbox;
						@extend .all-center;
						flex: 0 0 auto;
						margin-right: 0px;
						font-size: 18px;
						min-width: 20px;
						min-height: 20px;
						max-width: 20px;
						max-height: 20px;

						svg {
							width: 55%;
							height: 55%;

							path {
								fill: $white-color;
							}
						}

						i {
							font-size: 15px;
						}
					}
				}
			}
		}

		&.sidemenu {
			margin: 0;

			li {
				&:not(:last-child) {
					margin-right: 0.75rem;
				}
			}
		}
	}

	.controls-tab {
		position: absolute;
		// right: 265px;
		left: 650px;
		min-height: 35px;
		top: 0;
		@extend .flexbox;

		li {
			flex: 0 0 auto;
			@extend .flex-column;

			&:not(:last-child) {
				margin-right: 15px;
			}

			a {
				color: $text-color;
				position: relative;
				font-weight: 600;
				flex: 1 1 auto;
				background-color: transparent;
				padding: 0px 10px;
				@extend .flexbox;
				@extend .all-center;

				&:after {
					height: 3px;
					width: 100%;
					content: '';
					position: absolute;
					bottom: 0;
					left: 0;
					background-color: transparent;
				}

				&.active {
					margin: 0;
					color: $theam-blue;

					&:after {
						background-color: $theam-blue;
					}
				}
			}
		}
	}
}

.page-total {
	margin-left: auto;
}

.ant-tabs-section {

	// position: absolute;
	.ant-tabs-bar {
		background-color: #fff;
	}

	.ant-tabs-content {
		// background-color:$sub-content-background;
	}

	.ant-tabs-nav-wrap {
		padding-left: $tab-nav-padding-left;
	}

	.ant-tabs-tab:hover {
		color: $ant-tab-inactive-color;
	}

	.ant-tabs-tab {
		color: #476282;
		font-size: $ant-tab-header-font-size !important;
		font-weight: $ant-tab-header-font-weight !important;
	}

	.ant-tabs-tab-active {
		color: $ant-tab-active-color;
	}

	.ant-tabs-nav-container {
		line-height: 1;
	}

}

.btn-modal-secondary {
	background-color: $button-background-secondary;
	border-radius: $button-border-radius !important;
	color: $button-color-secondary;
	font-size: $button-font-size !important;
	// padding: $button-padding-y $button-padding-x;
}

.btn-modal-primary {
	background-color: $button-background-primary;
	border-radius: $button-border-radius !important;
	color: $button-color-primary;
	font-size: $button-font-size !important;

	// padding: $button-padding-y $button-padding-x;
	&:hover,
	&:active {
		background-color: $button-background-primary-hover;
		color: $button-color-primary;
	}
}


.fetcher-details {
	padding: $page-content-padding;
}


.primary-btn {
	background-color: $button-background-primary;
	border-radius: $button-border-radius !important;
	color: $button-color-primary;
	font-weight: $button-font-weight;
	font-size: $button-font-size !important;
	border: none;

	&:hover {
		background-color: $button-background-primary-hover !important;
		color: $button-color-primary !important;
	}

	&:active,
	&:focus {
		background-color: $button-background-primary;
		color: $button-color-primary;
	}

	&:disabled {
		background-color: #f5f5f5 !important;
		color: $lgt-text !important;
		cursor: not-allowed;
	}
}

.secondary-btn {
	background-color: $button-background-secondary;
	border-radius: $button-border-radius !important;
	color: $button-color-secondary;
	font-size: $button-font-size !important;
	font-weight: $button-font-weight;
}

.btn-padding-sm {
	padding: $button-padding-sm-y $button-padding-sm-x;
}

.btn-padding-xs {
	padding: $button-padding-sm-y $button-padding-xs-x;

}

.btn-padding-md {
	padding: $button-padding-sm-y $button-padding-md-x;

}

.btn-padding-lg {
	padding: $button-padding-sm-y $button-padding-lg-x;

}

.larger-button {
	height: 50px !important;
	font-size: 16px !important;
	border-radius: 12px !important;
}


.icon-xs {
	width: $icon-size-xs;
	height: $icon-size-xs;
}