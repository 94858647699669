@import './fonts';

// $nav-font-color: var(--nav-font-color);
$nav-font-size: var(--admin-nav-font-size);
$nav-font-weight:var(--admin-nav-font-weight);
$nav-link-gap:var(--admin-nav-link-gap);
$header-h1-font-size:var(--admin-header-h1-font-size);
$header-h1-font-weight:var(--admin-header-h1-font-weight);
$header-h1-line-height:var(--admin-header-h1-line-height);
$button-font-size:var(--button-font-size);
$button-font-weight:var(--button-font-weight);
$ant-tab-header-font-size:var(--ant-tab-header-font-size);
$ant-tab-header-font-weight:var(--ant-tab-header-font-weight);
$sm-card-h5-font-size:var(--sm-card-h5-font-size);
$sm-card-h5-font-weight:var(--sm-card-h5-font-weight);
$table-header-column-font-size:var(--table-header-column-font-size);
$font-size-xs:var(--font-size-xs);
$font-size-sm:var(--font-size-sm);
$nav-admin-header-font-size:var(--nav-admin-header-font-size);
$page-header-svg-icon-width:var(--page-header-svg-icon-width);
$page-header-svg-icon-height:var(--page-header-svg-icon-height);
$font-size-medium: var(--font-size-medium);