.iconTooltip {
	position: absolute;
	top:0;
	left: 0;
	right: 0;
	padding: 12px;
	box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);
	z-index: 5;
	border-radius: 8px;
	animation: tooltip-effect 0.3s;
	width: max-content;
	height: max-content;
	background-color: #fff;
}

@keyframes tooltip-effect {
	from {
		margin-top: 4px;
		opacity: 0;
	}
}
