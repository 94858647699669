body {
    --admin-nav-font-size: 14px;
    --admin-nav-font-weight: 700;
    --admin-nav-link-gap:10px;
    --admin-header-h1-font-size: 20px;
    --admin-header-h1-font-weight:600;
    --admin-header-h1-line-height:28px;
    --button-font-size:14px;
    --button-font-weight: 600;
    --ant-tab-header-font-size: 16px;
    --ant-tab-header-font-weight: 600;
    --sm-card-h5-font-size:15px;
    --sm-card-h5-font-weight:600;
    --table-header-column-font-size:14px;
    --font-size-xs:.625em;
    --font-size-sm: .75em;
    --nav-admin-header-font-size: 13px;
    --page-header-svg-icon-width: 20px;
    --page-header-svg-icon-width: 20px;
    --font-size-medium: 13px;

}