body{
    --admin-nav-background-position: left 0 bottom 0;
    --admin-nav-background-no-repeat: no-repeat;
    --admin-nav-background-size: 256px 556px;
    --admin-nav-bar-height:100vh;
    --admin-nav-open-width:256px;
    --admin-nav-collapse-width: 68px;
    --admin-nav-collapse-margin-left: 55px;
    --admin-nav-collapse-border-radius: 50%;
    --admin-nav-collapse-svg-width:12px;
    --admin-nav-collapse-svg-height:12px;
    --admin-nav-admin-section-margin-left:18px;
    --admin-nav-admin-section-svg-width:20px;
    --admin-nav-admin-section-svg-height:20px;
    --admin-background-transparent: transparent;
    --admin-header-section-height:48px;
    --admin-header-section-padding:24px;
    --border-radius-50: 50%;
    --button-border-radius: 20px;
    --button-padding-xs-x: 16px;
    --button-padding-xs-y:10px;
    --button-padding-sm-x: 20px;
    --button-padding-sm-y:12px;
    --button-padding-md-x: 25px;
    --button-padding-md-y:14px;
    --button-padding-lg-x: 30px;
    --button-padding-lg-y:16px;
    --sm-card-border:5px;
    --tab-nav-padding-left: 24px;
    --page-content-padding: 15px 32px 15px 24px;
    --overlay-background-transition:0.3s ease-in-out;
    --input-margin-left-header: 32%;
    --header-input-height: 32px;

    --profile-overlay-image: url('/images/overlay_background1.png');
}