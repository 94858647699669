@import '../../sass/themes/index.scss';

.dashboard {
  display: flex;
  flex: 1 0 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;
  background: $page-content-background;
  padding: 24px 0px;
  height: 100%;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 24px;
  background-color: $page-content-background;

  .title {
    font-size: 30px;
    font-weight: 900;
    color: $drk-text;
  }

  .dateSelector {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-left: auto;
    margin-right: 10px;
    gap: 10px;
  }
}

.disbaleGranularity {
  opacity: 0.4;
  cursor: not-allowed;
}

.disableSelector {
  pointer-events: none;
}


.tabHeader {
  width: 100%;

  :global {
    .ant-tabs-nav {
      //   border-bottom: 2px solid #e0e0e0;
      padding-bottom: 5px;

    }

    .ant-tabs-nav-scroll {
      margin-left: 20px;
    }

    .ant-tabs-tab {
      font-size: 15px !important;
      font-weight: 600 !important;
      color: $ant-tab-inactive-color !important;
      padding: 8px 16px;
      margin: 0 8px;
      transition: color 0.3s ease, border-color 0.3s ease;

      &:hover {
        color: $ant-tab-active-color;
      }

      &.ant-tabs-tab-active {
        color: $ant-tab-active-color !important;
        // border-bottom: 2px solid #1890ff;
      }
    }

    .ant-tabs-ink-bar {
      background-color: $ant-tab-active-color !important;
      height: 2px;
      display: none;
    }
  }
}

.content {
  display: flex;
  flex: 1 0 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 0px;
  align-self: stretch;
  background: $page-content-background;
  padding-top: 32px;
  padding-bottom: 48px;
  // padding-right: 24px;
  // padding-left: 24px;
  overflow: auto;
  overflow-x: hidden;
  max-height: calc(100vh - 222px);

  &::-webkit-scrollbar {
    display: block;
    width: 6px;
    height: auto;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgb(189, 188, 188);
    border-radius: 5px;
  }

  &::-webkit-scrollbar-track {
    background-color: #fff;
    height: 5px;
    width: 5px;
  }
}


.toolTipStyle {
  :global {
    .ant-tooltip-inner {
      font-size: 14px;
      padding: 5px 10px;
    }
  }
}

.metricBlock {
	display: flex;
	width: 100%;
	flex-wrap: wrap;
	align-items: flex-start;
	justify-content: space-between;
	padding: 0 24px;

	.border {
		display: flex;
		height: 1px;
		width: 100%;
		flex: 0 0 auto;
		flex-direction: column;
		align-items: center;
		gap: 8px;
		background: rgb(229, 229, 229);
		margin: 32px 0;
	}
}

.graphBlock {
	display: flex;
	width: 100%;
	flex: 1 0 0px;
	// flex-wrap: wrap;
	align-items: flex-start;
	gap: 8px;
	padding: 0 28px;


	.border {
		display: flex;
		height: 1px;
		width: 100%;
		flex: 0 0 auto;
		flex-direction: column;
		align-items: center;
		gap: 8px;
		background: rgb(229, 229, 229);
		margin-top: 32px;

	}
}

.reviewsTable {
	display: flex;
	width: 100%;
	flex-direction: column;
	padding: 0 24px;

	.border {
		display: flex;
		height: 1px;
		width: 100%;
		flex: 0 0 auto;
		flex-direction: column;
		align-items: center;
		gap: 8px;
		background: rgb(229, 229, 229);
		margin: 32px 0;
	}

}
.border {
    display: flex;
    height: 1px;
    width: 100%;
    flex: 0 0 auto;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    background: rgb(229, 229, 229);
    margin: 0px 52px;
}


.metricCard {
	background: $page-content-background;
	border-radius: 8px;
	padding: 12px;
	box-shadow: $dash-card-box-shadow;
	border: 1px solid $dash-card-border-color;
	margin: 4px;
	align-items: flex-start;
	display: flex;
	flex-direction: column;
	gap: 16px;
	width: 100%; // Make sure it occupies full width of its container

	// Responsive Width Adjustments
	@media (min-width: 768px) {
		flex: 1 1 calc(33.33% - 16px);
	}

	@media (min-width: 1024px) {
		flex: 1 1 calc(20% - 16px);
	}

	h3 {
		color: $drk-text;
		font-size: 17px;
		font-weight: 600;
		line-height: 20px;
	}
}

.loaderBlock {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 72px;
}

.metricValue {
	font-size: 30px;
	font-weight: 600;
	line-height: 36px;
	color: $dash-metric-text-color;
	font-weight: 600;
}

.avg {
	font-size: 13px;
	font-weight: 400;
	line-height: 16px;
	color: $dash-avg-text-color;
	display: flex;
	align-items: center;
	justify-content: center;
}

.additionalInfo {
	font-size: 13px;
	font-weight: 400;
	line-height: 16px;
	color: $dash-avg-text-color;
}

.footer {
	display: flex;
	align-items: center;
	width: 100%;
	justify-content: space-between;

	.info {
		display: flex;
		align-items: center;
		gap: 5px;
		min-height: 20px;

		p {
			font-size: 13px;
			font-weight: 400;
			line-height: 16px;
			letter-spacing: 0em;
			color: $dash-info-text-color;
		}
	}
}

.change {
	font-size: 0.9rem;
	font-weight: bold;
	max-width: 70px;
	&.positive {
		.arrow {
			transform: rotate(40deg); // Pointing up
		}
	}

	&.negative {
		.arrow {
			transform: rotate(140deg);
		}
	}

	&.neutral {
		.arrow {
			transform: rotate(0deg);
		}
	}

	&.positive {
		background: $dash-pos-background;
		border-radius: 4px;
		display: flex;
		height: 20px;
		gap: 0px;
		border: 1px solid $dash-pos-background;
		padding: 0px 8px;
		align-items: center;

		span {
			white-space: nowrap;
			font-size: 12px;
			font-weight: 400;;
			line-height: 16px;
			letter-spacing: 0em;
			color: $dash-pos-color;
		}
		svg{
			height: 20px;
			width: 20px;
			fill: $dash-pos-color;
		}
	}

	&.negative {
		background: $dash-neg-background;
		border-radius: 4px;
		display: flex;
		height: 20px;
		gap: 0px;
		border: 1px solid $dash-neg-background;
		padding: 0px 8px;
		align-items: center;

		span {
			white-space: nowrap;
			font-size: 12px;
			font-weight: 400;;
			line-height: 16px;
			letter-spacing: 0em;
			color: $dash-neg-color;
		}
		svg{
			height: 20px;
			width: 20px;
			fill: $dash-neg-color;
		}
	}

	&.neutral {
		background: $dash-neutral-background;
		border-radius: 4px;
		display: flex;
		height: 20px;
		gap: 0px;
		border: 1px solid $dash-neutral-background;
		padding: 0px 8px;
		align-items: center;

		span {
			white-space: nowrap;
			font-size: 12px;
			font-weight: 400;;
			line-height: 16px;
			letter-spacing: 0em;
			color: $dash-neutral-color;
		}
		svg{
			height: 20px;
			width: 20px;
			fill: $dash-neutral-color;
		}
	}
}

.infoIcon {
	margin-right: 4px;
	font-size: 0.8rem;
	color: #888;
}