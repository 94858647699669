@import '../../sass/themes/index.scss';

.container {
  margin: 0;
  min-width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: white;
  padding: 20px; 
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);

  .logo {
    img {
      width: 45px;
      height: 45px;
    }
  }

  .verification-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 100%;

    h1 {
      font-size: 28px; /* Adjusted for better responsiveness */
      margin-bottom: 15px;
      font-weight: 700;
      color: $med-drk-text;
    }

    p {
      color: $med-lgt-text;
      margin-bottom: 15px;
      font-size: 20px; /* Adjusted for better responsiveness */
      line-height: 1.5rem;
    }

    .code-inputs {
      display: flex;
      justify-content: space-evenly;
      width: 100%;
      max-width: 400px; /* Adjusted for better responsiveness */
      gap: 10px;

      .code-input {
        width: 55px;
        height: 55px;
        text-align: center;
        font-size: 24px;
        border: 2px solid #d9d9d9;
        border-radius: 8px;
        outline: none;
        display: flex;
        
        &:focus {
          border-color: $primary-active-color;
        }
        
        &.error {
          background-color: $error-background-color;
          border-color: $error-border-color;
        }
      
      }
     
    }
    .error-field{
      display: flex;
      gap: 3px;
      font-size: 14px;
      width: 100%;
      flex-direction: row;
      margin-bottom: 15px;
      margin-left: 4rem;
      color: $error-field-color;
      svg{
        width: 14px;
        height: 14px;
        fill: $error-field-color;
      }
    }

    .resend {
      margin-top: 15px;

      a {
        color: $primary-active-color;
        text-decoration: none;
        font-size: 15px;
        &:hover{
          text-decoration: underline;
        }
        
      }
      .verify-code{
        display: flex;
        gap: 10px;
        font-size: 16px;
      }
    }
  }
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
  .container {
    padding: 15px; /* Adjusted padding for smaller screens */
  }

  .verification-block {
    h1 {
      font-size: 20px; /* Adjusted font size for smaller screens */
    }

    p {
      font-size: 14px; /* Adjusted font size for smaller screens */
    }

    .code-inputs {
      .code-input {
        width: 35px; /* Adjusted size for smaller screens */
        height: 35px; /* Adjusted size for smaller screens */
        font-size: 18px; /* Adjusted font size for smaller screens */
      }
    }
  }
}

@media (max-width: 480px) {
  .container {
    padding: 10px; /* Further adjusted padding for very small screens */
  }

  .verification-block {
    h1 {
      font-size: 18px; /* Further adjusted font size for very small screens */
    }

    p {
      font-size: 12px; /* Further adjusted font size for very small screens */
    }

    .code-inputs {
      .code-input {
        width: 30px; /* Further adjusted size for very small screens */
        height: 30px; /* Further adjusted size for very small screens */
        font-size: 16px; /* Further adjusted font size for very small screens */
      }
    }
  }
}
