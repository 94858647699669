@import "../../sass/themes/index.scss";

.card {
	background: $page-content-background;
	border-radius: 8px;
	padding: 24px 48px 10px 24px;
	box-shadow: $dash-card-box-shadow;
	border: 1px solid $dash-card-border-color;
	margin: 8px 0px 8px 4px;
	align-items: flex-start;
	display: flex;
	flex-direction: column;
	// flex-wrap: wrap;
	width: 100%;
	min-height: 0;

	// height: 100%;
	// Responsive Width Adjustments
	@media (min-width: 400px) {
		width: 98.8%;
		// height: 100%;
	}

	@media (min-width: 1024px) {
		width: 99.5%;
		// height: 100%;
	}

	.headerSction {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;

		.header {
			display: flex;
			flex-direction: column;

			h3 {
				font-size: 20px;
				font-weight: 600;
				line-height: 20px;
				letter-spacing: 0em;
				color: $drk-text;
			}

			p {
				margin-top: 5px;
				font-size: 15px;
				font-weight: 600;
				line-height: 20px;
				color: $med-lgt-text;
			}
		}

	}
}



.loaderBlock {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 300px;
}

.infoWrapper {
	display: flex;
	padding: 0 20%;
	width: 100%;
	align-items: center;
	justify-content: space-between;

	.infoContent {
		display: flex;
		flex-direction: column;
		gap: 5px;

		.info {
			display: flex;
			flex-direction: column;

			font-size: 16px;
			text-align: center;
			color: $med-drk-text;

			:first-child {
				font-weight: 600;
				font-size: 24px;
			}

		}
	}
}

.noData {
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 200px;
}

// .sourceList {
// 	padding-top: 20px;
// 	width: 100%; // Take full width on small screens
// 	height: 300px; // Height auto adjusts based on content
// 	// align-items: center;

// 	@media (min-width: 768px) {
// 		width: 100%; // Fixed width for medium screens
// 		height: 300px; // Fixed height for medium screens
// 	}

// 	@media (min-width:400px) {
// 		width: 100%; // Fixed width for medium screens
// 		height: 250px; // Fixed height for medium screens
// 	}

// 	@media (min-width: 1024px) {
// 		width: 100%; // Fixed width for larger screens
// 		height: 300px; // Fixed height for larger screens
// 	}
// }
.sourceList {
	padding-top: 20px;
	width: 100%; // Take full width
	height: auto; // Let the height adjust dynamically to the content
	// overflow: auto; // Add scrollbars if the content exceeds the available space
	@media (min-width: 768px) {
		width: 100%; // Ensure it stays responsive
	}

	@media (min-width: 400px) {
		width: 100%;
	}

	@media (min-width: 1024px) {
		width: 100%;
	}
}