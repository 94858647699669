@import url(https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,300;1,400;1,500;1,700;1,900&display=swap);
/*---------Color-----------*/
/*--  font size  --*/
/*--  Group color  --*/
/*=====
alpha
========*/
/*=====
linear-gradien
========*/
/*====
transition
======*/
/*====
transform
======*/
/*----
placeholder
-----*/
/*----
flex
-----*/
/*-=====
opasity
=======*/
/*-=====
appearance
=======*/
/*-=====
filter
=======*/
/*-=====
react select
=======*/
/*-=====
mediaquery
=======*/
/*-=====
Animation
=======*/
/*=====
alpha
========*/
/*=====
linear-gradien
========*/
/*====
transition
======*/
/*====
transform
======*/
/*----
placeholder
-----*/
/*----
flex
-----*/
/*-=====
opasity
=======*/
/*-=====
appearance
=======*/
/*-=====
filter
=======*/
/*-=====
react select
=======*/
/*-=====
mediaquery
=======*/
/*-=====
Animation
=======*/
.flex-column, .portfolio-profile .profile-block, .portfolio-profile .profile-block__content, .portfolio-profile .profile-block__content .content-list li {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column; }

.inline-flex {
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: horizontal;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row; }

.flexbox, .portfolio-profile .profile-block__header, .portfolio-profile .profile-block__header .portfolio-profile-brand, .portfolio-profile .profile-block__header .portfolio-profile-brand span, .portfolio-profile .profile-block__content .content-list, .portfolio-profile .profile-block__content .content-list li .list-top, .portfolio-profile .profile-block__content .content-list li .list-bottom {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: horizontal;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row; }

.flex-reverse {
  -webkit-box-direction: reverse;
  -webkit-box-orient: horizontal;
  -webkit-flex-direction: row-reverse;
  -moz-flex-direction: row-reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse; }

.column-reverse {
  -webkit-box-direction: reverse;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column-reverse;
  -moz-flex-direction: column-reverse;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse; }

.all-center, .portfolio-profile .profile-block__header .portfolio-profile-brand span, .portfolio-profile .profile-block__content .content-list li, .portfolio-profile .profile-block__content .content-list li .list-bottom {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center; }

.justify-center, .portfolio-profile .profile-block__content {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center; }

.justify-between, .portfolio-profile .profile-block__header {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between; }

.justify-around {
  -ms-flex-pack: distribute;
  -webkit-justify-content: space-around;
  -moz-justify-content: space-around;
  justify-content: space-around; }

.justify-start {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  justify-content: flex-start; }

.justify-end {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  -webkit-justify-content: flex-end;
  -moz-justify-content: flex-end;
  justify-content: flex-end; }

.item-center, .portfolio-profile .profile-block__header, .portfolio-profile .profile-block__header .portfolio-profile-brand, .portfolio-profile .profile-block__content .content-list, .portfolio-profile .profile-block__content .content-list li .list-top {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center; }

.item-start {
  -webkit-box-align: start;
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  -moz-align-items: flex-start;
  align-items: flex-start; }

.item-end {
  -webkit-box-align: end;
  -ms-flex-align: end;
  -webkit-align-items: flex-end;
  -moz-align-items: flex-end;
  align-items: flex-end; }

.item-stretch {
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  -webkit-align-items: stretch;
  -moz-align-items: stretch;
  align-items: stretch; }

.item-nowrap {
  -ms-flex-wrap: none;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap; }

.item-wrap {
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap; }

.graytbl-header {
  color: #202124;
  font-size: 12px;
  font-weight: 700; }

.whitetbl-header {
  color: #3c4043 !important;
  font-size: 12px;
  font-weight: 700; }

.tblrow-color {
  color: #3c4043;
  font-size: 13px;
  font-weight: 400; }

.tblprimary-color {
  color: #3c4043;
  font-size: 13px;
  font-weight: 700; }

.portfolio-profile {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(440px, 440px));
  grid-template-rows: auto;
  align-content: start;
  grid-gap: 15px;
  padding: 20px; }
  .portfolio-profile .profile-block {
    height: 100%;
    background-color: #ffffff;
    -webkit-box-shadow: 0 2px 6px rgba(33, 33, 33, 0.16);
    -moz-box-shadow: 0 2px 6px rgba(33, 33, 33, 0.16);
    box-shadow: 0 2px 6px rgba(33, 33, 33, 0.16); }
    .portfolio-profile .profile-block__header {
      flex: 0 1 auto;
      padding: 10px; }
      .portfolio-profile .profile-block__header .portfolio-profile-brand {
        cursor: pointer; }
        .portfolio-profile .profile-block__header .portfolio-profile-brand span {
          flex: 0 0 auto;
          width: 44px;
          height: 44px;
          margin-right: 15px;
          font-size: 18px;
          text-transform: uppercase;
          color: #ffffff;
          border-radius: 50%;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          background-image: -webkit-linear-gradient(legacy-direction(to top), #4d9de2 30%, #9fd1fe 100%);
          background-image: -webkit-linear-gradient(to top, #4d9de2 30%, #9fd1fe 100%);
          background: -ms-linear-gradient(to top, #4d9de2 30%, #9fd1fe 100%);
          background-image: -ms-linear-gradient(to top, #4d9de2 30%, #9fd1fe 100%);
          background-image: linear-gradient(to top, #4d9de2 30%, #9fd1fe 100%); }
        .portfolio-profile .profile-block__header .portfolio-profile-brand h3 {
          font-size: 20px;
          font-weight: 500;
          text-transform: capitalize; }
      .portfolio-profile .profile-block__header .portfolio-profile-delete {
        flex: 0 0 auto; }
    .portfolio-profile .profile-block__content {
      flex: 1 1 auto;
      min-height: 80px;
      padding: 10px; }
      .portfolio-profile .profile-block__content .content-list {
        flex: 0 1 auto; }
        .portfolio-profile .profile-block__content .content-list li {
          flex: 1 1 auto;
          padding: 20px 10px;
          max-width: 33.333%;
          border: solid 1px rgba(224, 224, 224, 0.1);
          border-radius: 6px;
          -webkit-border-radius: 6px;
          -moz-border-radius: 6px; }
          .portfolio-profile .profile-block__content .content-list li:not(:last-child) {
            margin-right: 10px; }
          .portfolio-profile .profile-block__content .content-list li .list-top span {
            flex: 0 0 auto;
            font-size: 14px;
            color: rgba(60, 64, 67, 0.9); }
            .portfolio-profile .profile-block__content .content-list li .list-top span:first-child {
              margin-right: 8px;
              font-size: 18px;
              color: rgba(224, 224, 224, 0.9); }
          .portfolio-profile .profile-block__content .content-list li .list-bottom {
            font-size: 24px;
            font-weight: 600;
            color: #3c4043; }
