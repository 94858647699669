@import "../../sass/themes/index.scss";

.card {
	background: $page-content-background;
	border-radius: 8px;
	padding: 24px 48px 0 24px;
	box-shadow: $dash-card-box-shadow;
	border: 1px solid $dash-card-border-color;
	margin: 8px 0px 8px 4px;
	align-items: flex-start;
	display: flex;
	flex-direction: column;
	// flex-wrap: wrap;
	width: 100%;
	height: 600px;

	// height: 100%;
	// Responsive Width Adjustments
	@media (min-width: 400px) {
		width: 98.8%;
		height: 600px;
	}

	@media (min-width: 1024px) {
		width: 99.5%;
		height: 600px;
	}

	h3 {
		font-size: 20px;
		font-weight: 600;
		line-height: 20px;
		letter-spacing: 0em;
		color: $drk-text;
	}

	p {
		margin-top: 5px;
		font-size: 15px;
		font-weight: 600;
		line-height: 20px;
		color: $med-lgt-text;
	}
}

.loaderBlock {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 300px;
}

.noData {
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 100%;
}

.sourceList {
	padding-top: 20px;
	width: 100%; // Take full width on small screens
	height: 600px; // Height auto adjusts based on content
	// align-items: center;

	@media (min-width: 768px) {
		width: 100%; // Fixed width for medium screens
		height: 600px; // Fixed height for medium screens
	}

	@media (min-width:400px) {
		width: 100%; // Fixed width for medium screens
		height: 600px; // Fixed height for medium screens
	}

	@media (min-width: 1024px) {
		width: 100%; // Fixed width for larger screens
		height: 600px; // Fixed height for larger screens
	}
}