.happiness {
	background: linear-gradient(to bottom right, #334155, #4f46e5);
	h3 {
		color: #ffffff !important;
	}
	.metric-value {
		color: rgb(191, 219, 254) !important;
	}
	.additional-Info,
	.avg-value {
		color: #dbeafe !important;
	}
}
