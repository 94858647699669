@import url(https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,300;1,400;1,500;1,700;1,900&display=swap);
/*---------Color-----------*/
/*--  font size  --*/
/*--  Group color  --*/
/*=====
alpha
========*/
/*=====
linear-gradien
========*/
/*====
transition
======*/
/*====
transform
======*/
/*----
placeholder
-----*/
/*----
flex
-----*/
/*-=====
opasity
=======*/
/*-=====
appearance
=======*/
/*-=====
filter
=======*/
/*-=====
react select
=======*/
/*-=====
mediaquery
=======*/
/*-=====
Animation
=======*/
/*=====
alpha
========*/
/*=====
linear-gradien
========*/
/*====
transition
======*/
/*====
transform
======*/
/*----
placeholder
-----*/
/*----
flex
-----*/
/*-=====
opasity
=======*/
/*-=====
appearance
=======*/
/*-=====
filter
=======*/
/*-=====
react select
=======*/
/*-=====
mediaquery
=======*/
/*-=====
Animation
=======*/
.flex-column {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column; }

.inline-flex {
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: horizontal;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row; }

.flexbox {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: horizontal;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row; }

.flex-reverse {
  -webkit-box-direction: reverse;
  -webkit-box-orient: horizontal;
  -webkit-flex-direction: row-reverse;
  -moz-flex-direction: row-reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse; }

.column-reverse {
  -webkit-box-direction: reverse;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column-reverse;
  -moz-flex-direction: column-reverse;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse; }

.all-center {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center; }

.justify-center {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center; }

.justify-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between; }

.justify-around {
  -ms-flex-pack: distribute;
  -webkit-justify-content: space-around;
  -moz-justify-content: space-around;
  justify-content: space-around; }

.justify-start {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  justify-content: flex-start; }

.justify-end {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  -webkit-justify-content: flex-end;
  -moz-justify-content: flex-end;
  justify-content: flex-end; }

.item-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center; }

.item-start {
  -webkit-box-align: start;
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  -moz-align-items: flex-start;
  align-items: flex-start; }

.item-end {
  -webkit-box-align: end;
  -ms-flex-align: end;
  -webkit-align-items: flex-end;
  -moz-align-items: flex-end;
  align-items: flex-end; }

.item-stretch {
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  -webkit-align-items: stretch;
  -moz-align-items: stretch;
  align-items: stretch; }

.item-nowrap {
  -ms-flex-wrap: none;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap; }

.item-wrap {
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap; }

.graytbl-header {
  color: #202124;
  font-size: 12px;
  font-weight: 700; }

.whitetbl-header {
  color: #3c4043 !important;
  font-size: 12px;
  font-weight: 700; }

.tblrow-color {
  color: #3c4043;
  font-size: 13px;
  font-weight: 400; }

.tblprimary-color {
  color: #3c4043;
  font-size: 13px;
  font-weight: 700; }

body.light {
  --admin-nav-background: #051e34;
  --admin-nav-border-color: #7c78786b;
  --admin-nav-font-color: #FFFFFFCC;
  --admin-nav-active-color: #669DF6;
  --page-content-background: #fafafa;
  --admin-header-content-background: #f1f5f9;
  --button-background-primary: #06a7e1;
  --white-text:#fff;
  --background-white-color: #fff;
  --button-background-secondary: #fff;
  --button-background-primary-hover: #40a9ff;
  --button-background-secondary-outline-hover: #40a9ff;
  --button-background-outline: #d9d9d9;
  --button-color-primary: #fff;
  --button-color-secondary: #0c0b0ba6;
  --ant-tab-inactive-color: #476282;
  --ant-tab-active-color: #1967d2;
  --page-content-h3-color: #222;
  --page-content-h5-color: #555;
  --page-content-h4-color: #3c4043;
  --primary-active-color: #1890ff;
  --primary-inactive-color: #a0a7b4;
  --primary-warning-color: #F99D1B;
  --dark-text-color: #666666;
  --sm-card-h5-color: #222;
  --overlay-background-color: #00000080;
  --profile-tag-color: #ad6f19;
  --profile-tag-background-color: #ebd7bd;
  --nav--hover-background: #ffffff14;
  --nav-admin-section-highlight: #47628233;
  --nav-admin-section-hover: #47628266;
  --black-text: #000000;
  --drk-text: #212121;
  --med-drk-text: #424242;
  --med-text: #616161;
  --med-lgt-text: #757575;
  --lgt-text: #9E9E9E;
  --warning-text: #E65100;
  --accent-text-color: #051e34;
  --nav-admin-section-header: rgba(255, 255, 255, 0.5);
  --right-nav-logout-color: #ffa600;
  --dropdown-background-color: #1a2c3a;
  --dropdown-border-color: #424242;
  --dropdown-background-hover-color: #ffffff14;
  --page-header-svg-color: #424242;
  --page-header-svg-hover: #669DF6;
  --page-header-svg-background: #55545414;
  --paid-color-green: #388E3C;
  --payment-due-color-orange: #F57C00;
  --not-paid-color-red: #D32F2F;
  --voided-color-grey: #616161;
  --pending-color-lgtblue: #29B6F6;
  --posted-color-lgtblue: #0288D1;
  --table-header-color: #05213a;
  --table-row-seperator: rgb(1, 9, 15);
  --star-rating-1-color: #FF5722;
  --star-rating-2-color: #FF9800;
  --star-rating-3-color: #ababab;
  --star-rating-4-color: #03A9F4;
  --star-rating-5-color: #4CAF50;
  --disabled-button-color: #999999;
  --admin-nav-logo-color: #FFFFFF;
  --detached-toogle-button-color: #ffa500;
  --filter-card-icon-color: #76bfe0;
  --filter-location-card-background: #051e34, #0d4475;
  --walmart-audit-active-card-background: #d58f28, #f9a326;
  --filter-listing-card-background: #0d4475, #29679e;
  --timeline-dot-background: #94a5b9;
  --timeline-icon-color: #81d4f9;
  --custom-popover-background: #f1f5f9;
  --comment-filter-background: #81d4f9;
  --account-filter-background: #9c27b0;
  --response-rate-background:#f99d1b;
  --campaign-period-color:#e9a94f;
  --default-background-color: rgb(231, 228, 228);
  --svg-icon-color:#669DF6;
  --button-inactive-state-color: #cccccc;
  --button-active-state-color:#d2dfec;
  --location-background-color: #7fbae7;
  --group-background-color: #ffa500;
  --error-background-color:#fff2f4;
  --error-field-color:#E30000;
  --error-border-color:#E30000;
  --background-drk-black: #000000;
  --background-white-color: #fff;
  --background-med-black: #0d0d0d;
  --border-med-black:#555;
  --placeholder-lgt-black:#777;
  --button-background-lgt-black:#565656;
  --dash-neg-background: rgb(255, 228, 230);
  --dash-pos-background: rgb(209, 250, 229);
  --dash-neutral-background:rgb(245, 245, 245);
  --dash-neg-color: rgb(159, 18, 57);
  --dash-pos-color: rgb(6, 95, 70);
  --dash-neutral-color: rgb(64, 64, 64);
  --dash-avg-text-color:#737373;
  --dash-info-text-color:rgb(115, 115, 115);
  --dash-metric-text-color: #1d4de8;
  --dash-card-box-shadow: 0px 4px 16px -2px rgba(0, 0, 0, 0.08),
  0px 2px 4px -1px rgba(0, 0, 0, 0.08);
  --dash-card-border-color: rgb(229, 229, 229)
; }

body {
  --admin-nav-background-position: left 0 bottom 0;
  --admin-nav-background-no-repeat: no-repeat;
  --admin-nav-background-size: 256px 556px;
  --admin-nav-bar-height:100vh;
  --admin-nav-open-width:256px;
  --admin-nav-collapse-width: 68px;
  --admin-nav-collapse-margin-left: 55px;
  --admin-nav-collapse-border-radius: 50%;
  --admin-nav-collapse-svg-width:12px;
  --admin-nav-collapse-svg-height:12px;
  --admin-nav-admin-section-margin-left:18px;
  --admin-nav-admin-section-svg-width:20px;
  --admin-nav-admin-section-svg-height:20px;
  --admin-background-transparent: transparent;
  --admin-header-section-height:48px;
  --admin-header-section-padding:24px;
  --border-radius-50: 50%;
  --button-border-radius: 20px;
  --button-padding-xs-x: 16px;
  --button-padding-xs-y:10px;
  --button-padding-sm-x: 20px;
  --button-padding-sm-y:12px;
  --button-padding-md-x: 25px;
  --button-padding-md-y:14px;
  --button-padding-lg-x: 30px;
  --button-padding-lg-y:16px;
  --sm-card-border:5px;
  --tab-nav-padding-left: 24px;
  --page-content-padding: 15px 32px 15px 24px;
  --overlay-background-transition:0.3s ease-in-out;
  --input-margin-left-header: 32%;
  --header-input-height: 32px;
  --profile-overlay-image: url('/images/overlay_background1.png'); }

body {
  --admin-nav-font-size: 14px;
  --admin-nav-font-weight: 700;
  --admin-nav-link-gap:10px;
  --admin-header-h1-font-size: 20px;
  --admin-header-h1-font-weight:600;
  --admin-header-h1-line-height:28px;
  --button-font-size:14px;
  --button-font-weight: 600;
  --ant-tab-header-font-size: 16px;
  --ant-tab-header-font-weight: 600;
  --sm-card-h5-font-size:15px;
  --sm-card-h5-font-weight:600;
  --table-header-column-font-size:14px;
  --font-size-xs:.625em;
  --font-size-sm: .75em;
  --nav-admin-header-font-size: 13px;
  --page-header-svg-icon-width: 20px;
  --page-header-svg-icon-width: 20px;
  --font-size-medium: 13px; }

.verifyPageWrapper {
  display: flex; }
  .verifyPageWrapper .rightSection {
    padding: 40px;
    background-color: #fff;
    width: 100%;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100%;
    max-height: 100vh;
    overflow: auto; }
