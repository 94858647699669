@import url(https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,300;1,400;1,500;1,700;1,900&display=swap);
/*---------Color-----------*/
/*--  font size  --*/
/*--  Group color  --*/
/*=====
alpha
========*/
/*=====
linear-gradien
========*/
/*====
transition
======*/
/*====
transform
======*/
/*----
placeholder
-----*/
/*----
flex
-----*/
/*-=====
opasity
=======*/
/*-=====
appearance
=======*/
/*-=====
filter
=======*/
/*-=====
react select
=======*/
/*-=====
mediaquery
=======*/
/*-=====
Animation
=======*/
/*=====
alpha
========*/
/*=====
linear-gradien
========*/
/*====
transition
======*/
/*====
transform
======*/
/*----
placeholder
-----*/
/*----
flex
-----*/
/*-=====
opasity
=======*/
/*-=====
appearance
=======*/
/*-=====
filter
=======*/
/*-=====
react select
=======*/
/*-=====
mediaquery
=======*/
/*-=====
Animation
=======*/
.flex-column, .current-image .image-list__item {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column; }

.inline-flex {
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: horizontal;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row; }

.flexbox, .current-image h3, .current-image .image-list {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: horizontal;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row; }

.flex-reverse {
  -webkit-box-direction: reverse;
  -webkit-box-orient: horizontal;
  -webkit-flex-direction: row-reverse;
  -moz-flex-direction: row-reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse; }

.column-reverse {
  -webkit-box-direction: reverse;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column-reverse;
  -moz-flex-direction: column-reverse;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse; }

.all-center, .current-image .image-list__item {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center; }

.justify-center {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center; }

.justify-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between; }

.justify-around {
  -ms-flex-pack: distribute;
  -webkit-justify-content: space-around;
  -moz-justify-content: space-around;
  justify-content: space-around; }

.justify-start {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  justify-content: flex-start; }

.justify-end {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  -webkit-justify-content: flex-end;
  -moz-justify-content: flex-end;
  justify-content: flex-end; }

.item-center, .current-image h3 {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center; }

.item-start {
  -webkit-box-align: start;
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  -moz-align-items: flex-start;
  align-items: flex-start; }

.item-end {
  -webkit-box-align: end;
  -ms-flex-align: end;
  -webkit-align-items: flex-end;
  -moz-align-items: flex-end;
  align-items: flex-end; }

.item-stretch, .current-image .image-list {
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  -webkit-align-items: stretch;
  -moz-align-items: stretch;
  align-items: stretch; }

.item-nowrap {
  -ms-flex-wrap: none;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap; }

.item-wrap {
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap; }

.graytbl-header {
  color: #202124;
  font-size: 12px;
  font-weight: 700; }

.whitetbl-header {
  color: #3c4043 !important;
  font-size: 12px;
  font-weight: 700; }

.tblrow-color {
  color: #3c4043;
  font-size: 13px;
  font-weight: 400; }

.tblprimary-color {
  color: #3c4043;
  font-size: 13px;
  font-weight: 700; }

.portfolio-name {
  margin: 0;
  padding: 12px; }
  .portfolio-name h3 {
    font-size: 16px;
    margin-bottom: 10px; }
  .portfolio-name h4 {
    font-size: 14px;
    display: inline-block;
    padding: 8px 15px;
    border: 1px solid #f2f2f2;
    border-radius: 4px; }
  .portfolio-name h4 i {
    display: inline-block;
    margin-left: 8px;
    font-size: 16px;
    cursor: pointer; }

.current-image {
  margin-bottom: 20px;
  padding: 12px; }
  .current-image h3 {
    font-size: 16px;
    margin-bottom: 10px; }
    .current-image h3 span {
      line-height: 14px;
      flex: 0 0 auto;
      margin-right: 8px; }
    .current-image h3 i {
      color: #cccccc; }
  .current-image .image-list__item {
    flex: 0 0 auto;
    margin-right: 10px;
    padding: 10px 20px;
    min-width: 120px;
    max-width: 400px;
    border: solid 1px #f2f2f2;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px; }
    .current-image .image-list__item:last-child {
      margin-right: 0; }
    .current-image .image-list__item .item-image {
      max-width: 400px;
      height: 70px; }
      .current-image .image-list__item .item-image img {
        height: 45px;
        max-width: 400px; }

.upload-portfolio-image {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  margin: 15px 0; }

.upload-portfolio-image .upload-portfolio-wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 500px;
  height: 300px; }

.upload-portfolio-image .upload-portfolio-wrap .ant-upload.ant-upload-select-picture-card .ant-upload {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center; }

.upload-portfolio-image .upload-portfolio-wrap .ant-upload.ant-upload-select-picture-card {
  flex: 1 1 auto;
  margin: 0;
  padding: 0;
  float: none;
  height: 300px;
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 9; }

.upload-portfolio-image .upload-portfolio-wrap .ant-upload-list-picture-card .ant-upload-list-item {
  flex: 1 1 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  height: 300px;
  width: 100%;
  float: none;
  margin: 0; }

.upload-portfolio-image .upload-portfolio-wrap .ant-upload-list-picture-card {
  width: 100%; }

.upload-portfolio-image .upload-portfolio-wrap .ant-upload-list-picture-card .ant-upload-list-item img {
  max-width: 100%;
  display: block; }

.upload-portfolio-image .upload-portfolio-wrap .ant-upload-list-picture-card .ant-upload-list-item-thumbnail {
  height: calc(100% - 10px); }

.upload-portfolio-image .upload-portfolio-wrap .ant-upload-list-picture-card .ant-upload-list-item-info:before {
  left: 0;
  top: 0; }
